import React, { useState, useRef } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Label, Button, Progress } from "reactstrap";
import secureLocalStorage from "react-secure-storage";
import { mimoLoader } from "../../store/actions";
import { useDispatch } from "react-redux";
import axios from "axios";

const MimoCreate = ({
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [mimoApkData, setMimoApkData] = useState({
    app_name: "MService",
    app_version: "",
    file: null,
    fileType: "apk",
  });
  const [loading, setLoading] = useState(null);
  const [lState, setLState] = useState(null);
  const fileRef = useRef();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file") {
      if (
        mimoApkData.fileType === "apk" &&
        files[0].type !== "application/vnd.android.package-archive"
      ) {
        setErrorAlert(true);
        setDescription("File format is incorrect (Please upload an .APK File)");
        setMimoApkData((prevState) => ({
          ...prevState,
          file: null,
        }));
        fileRef.current.value = "";
      } else {
        setMimoApkData((prevState) => ({
          ...prevState,
          [name]: files[0],
        }));
      }
    } else {
      setMimoApkData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    if (!mimoApkData.file) {
      setLoading(false);
      setErrorAlert(true);
      setDescription("Please select a file for upload");
    } else {
      dispatch(mimoLoader(true));

      const { REACT_APP_URL } = process.env;
      let data = secureLocalStorage.getItem("auth");
      let token = data.replace(/['"]+/g, "");

      const formData = new FormData();
      formData.append("file", mimoApkData.file);
      formData.append("app_name", mimoApkData.app_name);
      formData.append("app_version", mimoApkData.app_version);
      formData.append("fileType", mimoApkData.fileType);

      axios
        .post(`${REACT_APP_URL}/api/auth/createMimoApk`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type":
              "multipart/form-data; charset=utf-8; boundary=" +
              Math.random().toString().substr(2),
          },
          onUploadProgress: (e) => {
            const { loaded, total } = e;
            let percent = Math.floor((loaded * 100) / total);
            setLState(true);
            setLoading(percent);
          },
        })
        .then((res) => {
          if (res.data.error) {
            setErrorAlert(true);
            setDescription(res.data.error);
          } else {
            toggleModal();
            setSuccessAlert(true);
            setDescription("APK has been successfully uploaded");
          }
        });

      dispatch(mimoLoader(false));
    }
  };

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        <FormGroup>
          <Label>Select File Type</Label>
          <br></br>
          <select
            style={{ marginLeft: "0px", width: "100%" }}
            required
            name="fileType"
            onChange={handleChange}
            className="custom-select"
          >
            <option defaultValue value="apk">
              Apk
            </option>
            <option value="hex">Hex</option>
          </select>
        </FormGroup>

        <FormGroup>
          <Label>Application Name</Label>
          <br></br>
          <select
            style={{ marginLeft: "0px", width: "100%" }}
            required
            name="app_name"
            onChange={handleChange}
            className="custom-select"
          >
            <option defaultValue value="MService">
              MService
            </option>
            <option value="MLock">MLock</option>
            <option value="MLauncher">MLauncher</option>
            <option value="MTemp">MTemp</option>
            <option value="MServiceManager">MServiceManager</option>
          </select>
        </FormGroup>

        <AvField
          name="app_version"
          label="Application Version"
          placeholder="Type Something"
          type="text"
          value={mimoApkData.activation_key}
          onChange={handleChange}
          errorMessage="Enter Application Version"
          validate={{ required: { value: true } }}
        />

        <FormGroup>
          <Label>Upload Apk</Label>
          <br></br>
          <input
            type="file"
            name="file"
            onChange={handleChange}
            className="filestyle"
            data-buttonname="btn-secondary"
            ref={fileRef}
          />
        </FormGroup>

        {lState ? (
          <div className="">
            <div className="mb-4">
              <Progress color="primary" value={loading}>
                {loading}
              </Progress>
            </div>
          </div>
        ) : (
          ""
        )}

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default MimoCreate;
