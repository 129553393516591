import React, { useState, useEffect } from "react";
import { Col, Row, Card, CardBody, FormGroup, Label } from "reactstrap";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import { AvForm } from "availity-reactstrap-validation";
import { getDeviceUpdate } from "./redux/deviceApi";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";

const DeviceUpdate = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Devices Updates", link: "#" },
  ]);
  const [updateData, setUpdateData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [customerId, setCustomerId] = useState("all");
  const [statusFilter, setStatusFilter] = useState("all");

  useEffect(() => {
    (async () => {
      props.setBreadcrumbItems("Devices Updates", breadcrumbItems, true);

      try {
        const {
          data: { data: updateData },
        } = await getDeviceUpdate(
          getCurrentUser().role_id === 1 ? "all" : getCurrentUser().customer_id
        );

        setUpdateData(updateData);
        setFilteredData(updateData);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Devices Updates", breadcrumbItems, false);
    })();

    const interval = setInterval(async () => {
      const {
        data: { data: updateData },
      } = await getDeviceUpdate(
        getCurrentUser().role_id === 1 ? "all" : getCurrentUser().customer_id
      );

      setCustomerId("all");
      setStatusFilter("all");
      setUpdateData(updateData);
      setFilteredData(updateData);
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const handleCustomer = (id) => {
    setCustomerId(id);
    setFilteredData(
      updateData.filter((item) =>
        id == "all"
          ? statusFilter == "all"
            ? item
            : statusFilter == "pending"
            ? item.updateStatus == "pending"
            : statusFilter == "updated"
            ? item.updateStatus == "updated"
            : item.updateStatus == "failed"
          : statusFilter == "all"
          ? item.customerId == id
          : statusFilter == "pending"
          ? item.customerId == id && item.updateStatus == "pending"
          : statusFilter == "updated"
          ? item.customerId == id && item.updateStatus == "updated"
          : item.customerId == id && item.updateStatus == "failed"
      )
    );
  };

  const handleFilters = (e) => {
    const { value } = e.target;
    setStatusFilter(value);
    setFilteredData(
      updateData.filter((item) =>
        value == "all"
          ? customerId == "all"
            ? item
            : item.customerId == customerId
          : customerId == "all"
          ? value == "pending"
            ? item.updateStatus == "pending"
            : value == "updated"
            ? item.updateStatus == "updated"
            : item.updateStatus == "failed"
          : value == "pending"
          ? item.customerId == customerId && item.updateStatus == "pending"
          : value == "updated"
          ? item.customerId == customerId && item.updateStatus == "updated"
          : item.customerId == customerId && item.updateStatus == "failed"
      )
    );
  };

  const tableData = {
    columns: [
      {
        label: "Company",
        field: "customer",
        sort: "asc",
        width: 150,
      },
      {
        label: "Device Name",
        field: "deviceName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mac Address",
        field: "macAddress",
        sort: "asc",
        width: 270,
      },
      {
        label: "App Name",
        field: "appName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Old Version",
        field: "oldVersion",
        sort: "asc",
        width: 100,
      },
      {
        label: "New Version",
        field: "newVersion",
        sort: "asc",
        width: 150,
      },
      {
        label: "Update Status",
        field: "updateStatus",
        sort: "asc",
        width: 100,
      },
    ],
    rows: filteredData.map((item) => ({
      customer: item.customer,
      deviceName: item.deviceName,
      macAddress: item.macAddress,
      appName: item.appName,
      oldVersion: item.oldVersion,
      newVersion: item.newVersion ? item.newVersion : "-",
      updateStatus:
        item.updateStatus === "pending" ? (
          <h5>
            <span className="badge badge-info">PENDING</span>
          </h5>
        ) : item.updateStatus === "updated" ? (
          <h5>
            <span className="badge badge-success">UPDATED</span>
          </h5>
        ) : (
          <h5>
            <span className="badge badge-danger">FAILED</span>
          </h5>
        ),
    })),
  };

  return (
    <>
      <Col xs="12">
        <Card>
          <CardBody>
            <Col xs="12" className="row mb-3">
              <Col xs="10">
                <Row>
                  <Col xs="3">
                    {getCurrentUser().role_id === 1 ? (
                      <AvForm>
                        <SelectCustomer
                          className=""
                          fieldError={null}
                          customerId={customerId}
                          handleCustomer={handleCustomer}
                        />
                      </AvForm>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col xs="3">
                    <Label>Select Device Status</Label>
                    <br></br>
                    <select
                      className="custom-select"
                      style={{ marginLeft: "0px" }}
                      name="statusFilter"
                      onChange={(event) => {
                        handleFilters(event);
                      }}
                    >
                      <option defaultValue value="all">
                        All
                      </option>
                      <option value="pending">Pending</option>
                      <option value="updated">Updated</option>
                      <option value="failed">Failed</option>
                    </select>
                  </Col>
                </Row>
              </Col>
            </Col>

            <MDBDataTable responsive bordered striped data={tableData} />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(DeviceUpdate);
