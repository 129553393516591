import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getGroupData(id) {
  return axios.get(`/api/auth/getGroups/${id}`, getHeader());
}

export function createGroup(data) {
  return axios.post("/api/auth/addGroup", data, getHeader());
}

export function editGroup(id) {
  return axios.get(`/api/auth/editGroup/${id}`, getHeader());
}

export function getGroupDevices(id) {
  return axios.get(`/api/auth/getGroupDevices/${id}`, getHeader());
}

export function updateGroup(data) {
  return axios.post(`/api/auth/updateGroup`, data, getHeader());
}

export function deleteGroup(id) {
  return axios.get(`/api/auth/deleteGroup/${id}`, getHeader());
}
