import React, { useState } from "react";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../services/authService";
import { createProduct } from "./redux/firmwareImagesApi";
import { mimoLoader } from "../../store/actions";
import { FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";

const CreateFirmProduct = ({
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [firmwareProData, setFirmwareProData] = useState({
    resellerId:
      getCurrentUser().role_id === 3 ? "all" : getCurrentUser().reseller_id,
  });
  const [resellerError, setResellerError] = useState(null);

  const handleReseller = (id) => {
    setFirmwareProData((prevState) => ({
      ...prevState,
      resellerId: id === "all" ? "all" : parseInt(id),
    }));
    setResellerError(id === "all" ? "Please select a reseller" : null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFirmwareProData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (firmwareProData.resellerId === "all") {
      setResellerError("Please select a reseller");
      return false;
    }

    (async () => {
      dispatch(mimoLoader(true));
      try {
        await createProduct(firmwareProData);

        toggleModal();
        setSuccessAlert(true);
        setDescription("Product has been successfully created");
      } catch (ex) {
        setErrorAlert(true);
        setDescription(ex.response.data.message);
      }
      dispatch(mimoLoader(false));
    })();
  };

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        {getCurrentUser().role_id === 3 ? (
          <SelectReseller
            className=""
            fieldError={resellerError}
            resellerId={firmwareProData.resellerId}
            handleReseller={handleReseller}
          />
        ) : (
          ""
        )}

        <AvField
          name="product"
          label="Product Name"
          placeholder="Enter name"
          type="text"
          value={firmwareProData.product}
          onChange={handleChange}
          errorMessage="Enter Product Name"
          validate={{ required: { value: true } }}
        />

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default CreateFirmProduct;
