import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { remoteDevice } from "../MDM/redux/manageDevicesApi";
import { getProfiles } from "../Profile/redux/profileApi";
import SweetAlert from "react-bootstrap-sweetalert";

const ProfileModal = (props) => {
  const [togModal, setTogModal] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [profileData, setProfileData] = useState({ profileName: "" });
  const [successAlert, setSuccessAlert] = useState(false);
  const [profileError, setProfileError] = useState(null);

  const toggle = () => {
    setTogModal(!togModal);
  };

  useEffect(() => {
    (async () => {
      try {
        const { data: profiles } = await getProfiles(props.data.customer_id);
        setProfiles(profiles);
      } catch (ex) {
        console.error(ex);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    const submittedData = {
      data: profileData,
      command: "profiles",
      macAddress: JSON.parse(props.data.mac_address).filter(
        (item) => item !== "00:00:00:00:00:00"
      ),
      header: {
        userName: "Admin",
        password: "0000",
      },
    };

    if (Object.values(submittedData.data).some((value) => value === "")) {
      setProfileError("Please select a profile");
      return false;
    }

    try {
      await remoteDevice(submittedData);
      setSuccessAlert(true);
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          title="Success"
          success
          onConfirm={() => {
            setSuccessAlert(false);
          }}
        >
          Profile has been successfully assigned
        </SweetAlert>
      ) : (
        ""
      )}

      {/* BUTTON */}
      <i
        className="mr-1 mdi mdi-layers-plus iconHover"
        style={{ color: "#408EBA", fontSize: "23px" }}
        role="button"
        onClick={() => {
          toggle();
        }}
      ></i>

      <Modal isOpen={togModal} toggle={toggle} autoFocus={true} centered={true}>
        <ModalHeader toggle={toggle}>Profiles</ModalHeader>
        <ModalBody>
          <AvForm>
            <AvField
              type="select"
              name="profileName"
              label="Select Profile"
              helpMessage={
                profileError ? (
                  <span className="text-danger h6">{profileError}</span>
                ) : (
                  ""
                )
              }
              onChange={(e) => {
                setProfileData((prevProps) => ({
                  ...prevProps,
                  profileName: e.target.value,
                }));
                setProfileError(null);
              }}
            >
              <option value=""> Select Profile</option>
              {profiles.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.profile_name}
                </option>
              ))}
            </AvField>
          </AvForm>
        </ModalBody>

        <ModalFooter>
          <Button
            type="button"
            color="secondary"
            className="waves-effect"
            onClick={toggle}
          >
            Close
          </Button>
          <Button
            type="button"
            color="primary"
            className="waves-effect waves-light"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ProfileModal;
