import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import axios from "axios";

//Import Images
import download from "../../../assets/images/download.png";
import secureLocalStorage from "react-secure-storage";

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      user: JSON.parse(secureLocalStorage.getItem("user")),
      data: {},
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidMount() {
  }
  render() {
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            <img
              className="rounded-circle header-profile-user"
              src={
                this.state.data.picture
                  ? process.env.REACT_APP_URL + "/" + this.state.data.picture
                  : download
              }
            />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem tag="a" href="/profile">
              <i className="mdi mdi-account-circle font-size-17 text-muted align-middle mr-1"></i>{" "}
              Profile
            </DropdownItem>
            <DropdownItem tag="a" href="/settings" className="d-block">
              <span className="badge badge-success float-right"></span>
              <i className="mdi mdi-settings font-size-17 text-muted align-middle mr-1"></i>{" "}
              Settings
            </DropdownItem>
            <DropdownItem tag="a" href="#" divider></DropdownItem>
            <DropdownItem tag="a" href="/logout" className="text-danger">
              <i className="mdi mdi-power font-size-17 text-muted align-middle mr-1 text-danger"></i>{" "}
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default withRouter(ProfileMenu);
