import React, { useState, useEffect } from "react";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { getCustomerData, deleteCustomer } from "./redux/customerApi";
import ModalLayout from "../../component/Common/ModalLayout";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import { AvForm } from "availity-reactstrap-validation";
import { Col, Row, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import EditCompany from "./EditCompany";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import "./datatables.scss";
import CompanyCreate from "./CompanyCreate";

const Company = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Companies", link: "/company" },
  ]);
  const [companyData, setCompanyData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [resellerId, setResellerId] = useState("all");
  const [modalShow, setModalShow] = useState(false);
  const [modalShowCreate, setModalShowCreate] = useState(false);

  useEffect(() => {
    if ((modalShow, modalShowCreate)) return;
    (async () => {
      props.setBreadcrumbItems("Companies", breadcrumbItems, true);

      try {
        const { data: companyData } = await getCustomerData();

        setCompanyData(companyData);
        setFilteredData(companyData);
      } catch (error) {
        console.error(error);
      }

      props.setBreadcrumbItems("Companies", breadcrumbItems, false);
    })();
  }, [modalShow, modalShowCreate]);

  const handleDelete = async () => {
    props.setBreadcrumbItems("Companies", breadcrumbItems, true);

    try {
      const { data: response } = await deleteCustomer(customerId);
      const currentData = companyData.filter((item) => item.id !== customerId);

      setSuccessAlert(true);
      setDescription(response.message);
      setCompanyData(currentData);
      setFilteredData(
        currentData.filter((item) =>
          resellerId === "all" ? item : item.reseller_id == resellerId
        )
      );
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("Companies", breadcrumbItems, false);
  };

  const handleReseller = (id) => {
    setResellerId(id);
    setFilteredData(
      companyData.filter((item) =>
        id === "all" ? item : item.reseller_id == id
      )
    );
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  const tableData = {
    columns: [
      {
        label: "Company",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 270,
      },
      {
        label: "Address",
        field: "address",
        sort: "asc",
        width: 200,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 200,
      },
    ],
    rows: filteredData.map((obj) => ({
      ...obj,

      actions: (
        <>
          <i
            className="mdi mdi-square-edit-outline mr-1 iconHover"
            style={{ color: "#20C0ED", fontSize: "23px" }}
            role="button"
            onClick={() => {
              setCustomerId(obj.id);
              toggleModal();
            }}
          ></i>

          <i
            className="mdi mdi mdi-delete  mr-1 iconHover"
            style={{
              color: "#DB4C3F",
              fontSize: "23px",
            }}
            role="button"
            onClick={() => {
              setConfirmAlert(true);
              setCustomerId(obj.id);
            }}
          ></i>
        </>
      ),
    })),
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Col xs="12">
                <Row className="align-items-center mb-3">
                  {getCurrentUser().role_id === 3 ? (
                    <Col xs="3">
                      <AvForm>
                        <SelectReseller
                          className=""
                          fieldError={null}
                          resellerId={resellerId}
                          handleReseller={handleReseller}
                        />
                      </AvForm>
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col xs="2">
                    <Button
                      style={{ marginTop: "10px" }}
                      onClick={toggleModalCreate}
                    >
                      Create Company
                    </Button>
                  </Col>
                </Row>
              </Col>

              <MDBDataTable
                responsive
                bordered
                striped
                entries={100}
                data={tableData}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modalShow ? (
        <ModalLayout
          toggleModal={toggleModal}
          isOpen={modalShow}
          title="Edit Company"
          size="lg"
        >
          <EditCompany
            customerId={customerId}
            toggleModal={toggleModal}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Create Company"
          size="lg"
        >
          <CompanyCreate
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(Company);
