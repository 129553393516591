import React, { Component } from "react";
import {
  isUserAuthenticated,
  getLoggedInUser,
  isUserTwoFactorAuth,
  getPermissions,
} from "./helpers/authUtils";
import {
  twoFactorRoutes,
  resellerProtectedRoutes,
  publicRoutes,
  userProtectedRoutes,
  superAdminRoutes,
} from "./routes/";
import NonAuthLayout from "./component/NonAuthLayout/NonAuthLayout";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import VerticalLayout from "./component/Layout/VerticalLayout";
import { connect } from "react-redux";
import AppRoute from "./routes/route";
import "./theme.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      checkUserAuth: null,
      check2FA: null,
      permissions: [],
    };
  }

  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  componentDidMount() {
    this.setState({
      user: getLoggedInUser(),
      checkUserAuth: isUserAuthenticated(),
      check2FA: isUserTwoFactorAuth(),
      permissions: getPermissions(),
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { check2FA, checkUserAuth, permissions } = this.state;

    if (!isUserAuthenticated() && checkUserAuth) {
      this.setState({
        checkUserAuth: isUserAuthenticated(),
        user: getLoggedInUser(),
        permissions: getPermissions(),
      });
    }

    if (isUserAuthenticated() && !checkUserAuth) {
      this.setState({
        checkUserAuth: isUserAuthenticated(),
        user: getLoggedInUser(),
        permissions: getPermissions(),
      });
    }

    // For two factor auth
    if (!isUserTwoFactorAuth() && check2FA) {
      this.setState({
        check2FA: isUserTwoFactorAuth(),
        user: getLoggedInUser(),
        permissions: getPermissions(),
      });
    }

    if (isUserTwoFactorAuth() && !check2FA) {
      this.setState({
        check2FA: isUserTwoFactorAuth(),
        user: getLoggedInUser(),
        permissions: getPermissions(),
      });
    }
  }

  render() {
    const { user, checkUserAuth, check2FA, permissions } = this.state;
    const Layout = this.getLayout();

    return (
      <>
        <Router>
          <Switch>
            {checkUserAuth === false
              ? publicRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    component={route.component}
                    layout={NonAuthLayout}
                    key={idx}
                    isAuthProtected={false}
                  />
                ))
              : ""}

            {user?.role_id === 2
              ? user?.two_factor_status === "enable" &&
                (!user?.two_factor_verified_at || !check2FA)
                ? twoFactorRoutes.map((route, idx) => (
                    <AppRoute
                      path={route.path}
                      component={route.component}
                      layout={NonAuthLayout}
                      key={idx}
                      isAuthProtected={true}
                      isUser={true}
                    />
                  ))
                : userProtectedRoutes.map((route, idx) => (
                    <AppRoute
                      path={route.path}
                      component={route.component}
                      layout={Layout}
                      key={idx}
                      isAuthProtected={true}
                      isUser={true}
                    />
                  ))
              : ""}

            {user?.role_id === 1
              ? user?.two_factor_status === "enable" &&
                (!user?.two_factor_verified_at || !check2FA)
                ? twoFactorRoutes.map((route, idx) => (
                    <AppRoute
                      path={route.path}
                      component={route.component}
                      layout={NonAuthLayout}
                      key={idx}
                      isAuthProtected={true}
                      isUser={true}
                    />
                  ))
                : resellerProtectedRoutes.map((route, idx) => (
                    <AppRoute
                      path={route.path}
                      component={route.component}
                      layout={Layout}
                      key={idx}
                      isAuthProtected={true}
                      isUser={false}
                    />
                  ))
              : ""}

            {user?.role_id === 3
              ? user?.two_factor_status === "enable" &&
                (!user?.two_factor_verified_at || !check2FA)
                ? twoFactorRoutes.map((route, idx) => (
                    <AppRoute
                      path={route.path}
                      component={route.component}
                      layout={NonAuthLayout}
                      key={idx}
                      isAuthProtected={true}
                      isUser={true}
                    />
                  ))
                : superAdminRoutes.map((route, idx) => (
                    <AppRoute
                      path={route.path}
                      component={route.component}
                      layout={Layout}
                      key={idx}
                      isAuthProtected={true}
                      isUser={false}
                    />
                  ))
              : ""}
          </Switch>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
