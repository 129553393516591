import React, { useState, useEffect } from "react";
import { getCustomerData } from "../../pages/Company/redux/customerApi";
import { AvField } from "availity-reactstrap-validation";

export const SelectCustomers = ({
  className,
  fieldError,
  resellerId,
  customerId,
  handleCustomer,
}) => {
  const [customerData, setCustomerData] = useState([]);
  const [customerFilterData, setCustomerFilterData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const { data: customerData } = await getCustomerData();
        setCustomerData(customerData);
        setCustomerFilterData(customerData);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    setCustomerFilterData(
      customerData.filter((item) =>
        resellerId === "all" ? item : item.reseller_id == resellerId
      )
    );
  }, [resellerId]);

  const handleChange = (e) => {
    const { value } = e.target;
    handleCustomer(value);
  };

  return (
    <>
      <AvField
        type="select"
        name="customerId"
        label="Select Company"
        helpMessage={
          fieldError ? <span className="text-danger h6">{fieldError}</span> : ""
        }
        className={className}
        value={customerId}
        onChange={handleChange}
      >
        <option value="MIMO">MIMO - Mainline Firmware </option>
        {customerFilterData.map((item) => (
          <option key={item.id} value={item.name}>
            {item.name}
          </option>
        ))}
      </AvField>
    </>
  );
};
