import React, { Component } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { setBreadcrumbItems } from "../../store/actions";
import { AvForm, AvField } from "availity-reactstrap-validation";
import secureLocalStorage from "react-secure-storage";
import * as deviceApi from "./redux/deviceApi";
import { getActKeyData } from "../ActivationKey/redux/activationKeyApi";
import { getCustomerData } from "../Company/redux/customerApi";

class deviceCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Mimo", link: "/" },
        { title: "Devices", link: "/device" },
        { title: "Create", link: "#" },
      ],
      user: JSON.parse(secureLocalStorage.getItem("user")),

      customerData: [],
      data: {
        customer: null,
        csvMacAddress: [],
        newMacAddress: [],
      },
      csvData: [],
      keyData: [],
      newMacAddress: [
        {
          device_name: null,
          serial_number: null,
          wifi_mac_address: null,
          activationKey: null,
        },
      ],
      existMacAddress: [],

      showMultiBox: false,
      successAlert: false,
      errorAlert: false,
      description: "",
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  async componentDidMount() {
    const { breadcrumbItems } = this.state;
    this.props.setBreadcrumbItems("Create Device", breadcrumbItems, true);

    if (this.state.user.role_id === 1) {
      const { data: customerData } = await getCustomerData();
      const { data: aKeyData } = await getActKeyData("all");
      this.setState({
        customerData: customerData,
        keyData: aKeyData.data,
        filterKeyData: aKeyData.data,
      });
    } else {
      const { data: aKeyData } = await getActKeyData(
        this.state.user.customer_id
      );
      this.setState({
        keyData: aKeyData.data,
      });
    }

    this.props.setBreadcrumbItems("Create Device", breadcrumbItems, false);
  }

  handleChange = (event) => {
    event.preventDefault();

    if (event.target.name == "csvFile") {
      if (event.target.files[0]?.size > 524288000) {
        event.target.value = null;
        this.setState({
          errorAlert: true,
          description: "File Size is larger than 500MB",
        });
      } else if (event.target.files[0]?.type !== "application/vnd.ms-excel") {
        event.target.value = null;
        this.setState({
          errorAlert: true,
          description: "File format is incorrect (Please upload .CSV file)",
        });
      } else {
        const { REACT_APP_URL } = process.env;
        let data = secureLocalStorage.getItem("auth");
        let token = data.replace(/['"]+/g, "");
        this.props.setBreadcrumbItems(
          "Device Create",
          this.state.breadcrumbItems,
          true
        );
        const formData = new FormData();
        formData.append("csvFile", event.target.files[0]);

        axios
          .post(`${REACT_APP_URL}/api/auth/csv-upload`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data.error) {
              this.setState(
                {
                  description: res.data.error,
                  errorAlert: true,
                },
                () => {
                  this.props.setBreadcrumbItems(
                    "Device Create",
                    this.state.breadcrumbItems,
                    false
                  );
                }
              );
            } else {
              this.setState(
                {
                  csvData: res.data,
                  data: {
                    ...this.state.data,
                    csvMacAddress: res.data,
                  },
                },
                () => {
                  this.props.setBreadcrumbItems(
                    "Create Group",
                    this.state.breadcrumbItems,
                    false
                  );
                }
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else {
      this.setState({
        data: {
          ...this.state.data,
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  handleCustomer = async (e) => {
    this.setState({
      filterKeyData: this.state.keyData.filter(
        (item) => item.customer_id == e.target.value
      ),
    });
  };

  handleAtivationKey = (event, obj) => {
    this.state.data.csvMacAddress.map((item) => {
      if (item.id == obj.id) {
        return (item.activationKey = event.target.value);
      }
    });
  };

  handleSubmit = async () => {
    if (
      this.state.csvData.length === 0 &&
      this.state.newMacAddress[0].wifi_mac_address === null
    ) {
      this.setState({
        errorAlert: true,
        description: "Device is not added",
      });
    } else {
      let tempError = false;
      this.state.newMacAddress.map((item) => {
        if (
          this.state.data.customer !== null &&
          item.wifi_mac_address !== null &&
          (item.activationKey === null || item.activationKey === "")
        ) {
          tempError = true;
          this.setState({
            errorAlert: true,
            description:
              "Please select activation key for " +
              "(" +
              item.wifi_mac_address +
              ")",
          });
        }
      });
      this.state.data.csvMacAddress.map((item) => {
        if (
          this.state.data.customer != null &&
          item.wifi_mac_address !== null &&
          (item.activationKey === null || item.activationKey === "")
        ) {
          tempError = true;
          this.setState({
            errorAlert: true,
            description:
              "Please select activation key for " +
              "(" +
              item.wifi_mac_address +
              ")",
          });
        }
      });

      if (!tempError) {
        const { breadcrumbItems } = this.state;
        this.props.setBreadcrumbItems("Create Device", breadcrumbItems, true);

        try {
          const { data: deviceData } = await deviceApi.storeDevice(
            this.state.data
          );
          this.setState({
            successAlert: true,
            description: "Devices are created",
            existMacAddress: deviceData.existMacAddress,
          });
        } catch (ex) {
          this.setState({
            errorAlert: true,
            description: "Devices are not created",
          });
        }
      }
    }
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.props.history.replace("/device");
  };

  // add remove
  handleBoxChange = (i, e) => {
    if (e.target.name == "device_name") {
      const values = [...this.state.newMacAddress];
      values[i].device_name = e.target.value;
      this.setState({
        data: {
          ...this.state.data,
          newMacAddress: values,
        },
      });
    } else if (e.target.name == "serial_number") {
      const values = [...this.state.newMacAddress];
      values[i].serial_number = e.target.value;
      this.setState({
        data: {
          ...this.state.data,
          newMacAddress: values,
        },
      });
    } else if (e.target.name == "activationKey") {
      const values = [...this.state.newMacAddress];
      values[i].activationKey = e.target.value;
      this.setState({
        data: {
          ...this.state.data,
          newMacAddress: values,
        },
      });
    } else {
      const values = [...this.state.newMacAddress];
      values[i].wifi_mac_address = e.target.value;
      this.setState({
        data: {
          ...this.state.data,
          newMacAddress: values,
        },
      });
    }
  };
  handleBoxAdd = () => {
    const values = [...this.state.newMacAddress];
    values.push({
      device_name: null,
      serial_number: null,
      wifi_mac_address: null,
      activationKey: null,
    });
    this.setState({
      newMacAddress: values,
    });
  };
  handleBoxRemove = (i) => {
    const values = [...this.state.newMacAddress];
    values.splice(i, 1);

    if (values.length === 0) {
      this.setState({
        newMacAddress: [
          {
            device_name: "",
            serial_number: "",
            wifi_mac_address: "",
            activationKey: "",
          },
        ],
        data: {
          ...this.state.data,
          newMacAddress: values,
        },
      });
    } else {
      this.setState({
        newMacAddress: values,
        data: {
          ...this.state.data,
          newMacAddress: values,
        },
      });
    }
  };

  render() {
    const { successAlert, errorAlert, description } = this.state;
    const csvData = {
      columns: [
        {
          label: "Serial Number",
          field: "serialNumber",
          sort: "desc",
        },
        {
          label: "Mac Address",
          field: "macAddress",
          sort: "desc",
        },
        {
          label: "Activation Key",
          field: "activationKey",
          sort: "desc",
        },
      ],

      rows: this.state.csvData.map((obj) => ({
        ...obj,
        serialNumber: obj["serial_number"],
        macAddress: obj["wifi_mac_address"],

        activationKey:
          (this.state.user.role_id === 1 &&
            this.state.data.customer !== null) ||
          this.state.user.role_id === 2 ? (
            <select
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Activation Key"
              onChange={(event) => {
                this.handleAtivationKey(event, obj);
              }}
              name="activationKey"
              className="custom-select"
            >
              <option defaultValue value="">
                Select Key
              </option>
              {this.state.keyData.map((key, index) => {
                return (
                  <option key={index} value={key.activation_key}>
                    {key.activation_key}
                  </option>
                );
              })}
            </select>
          ) : (
            "Not Required"
          ),
      })),
    };

    return (
      <React.Fragment>
        {successAlert ? (
          <SweetAlert
            success
            title="Success"
            onConfirm={() => {
              this.setState({ successAlert: false });
              this.props.history.replace("/Device");
            }}
          >
            {description} <br></br>
            <br></br>
            {this.state.existMacAddress?.length ? (
              <>
                <h5>
                  Devices with the following MAC addresses already exist in the
                  system
                </h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Serial Number</th>
                      <th scope="col">Mac Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.existMacAddress?.map((item, index) => (
                      <tr key={index}>
                        <td>{item["serial_number"]}</td>
                        <td>{item["wifi_mac_address"]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              ""
            )}
          </SweetAlert>
        ) : null}
        {errorAlert ? (
          <SweetAlert
            error
            title="Failed"
            onConfirm={() => {
              this.setState({ errorAlert: false });
            }}
          >
            {description}
          </SweetAlert>
        ) : null}

        <Row>
          <Col xl="6" md="12">
            <Card>
              <CardBody>
                <AvForm onValidSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label>Select Company</Label>
                    <select
                      required
                      onChange={(e) => {
                        this.handleChange(e);
                        this.handleCustomer(e);
                      }}
                      name="customer"
                      className="custom-select"
                    >
                      <option defaultValue value="">
                        Select Company
                      </option>
                      {this.state.customerData.map((data) => {
                        return (
                          <option key={data.id} value={data.id}>
                            {data.name}
                          </option>
                        );
                      })}
                    </select>
                  </FormGroup>

                  <FormGroup>
                    <div>
                      {this.state.showMultiBox ? (
                        <>
                          <Label>Add Devices</Label>
                          {this.state.newMacAddress.map((field, idx) => {
                            return (
                              <div className="col-12 row" key={`${idx}`}>
                                <div
                                  className="col-1"
                                  style={{ textAlign: "end" }}
                                >
                                  <button
                                    type="button"
                                    className="waves-effect waves-light btn btn-success"
                                    onClick={() => {
                                      this.handleBoxAdd();
                                    }}
                                  >
                                    <i className="mdi mdi-plus"></i>
                                  </button>
                                </div>

                                <div className="col-2">
                                  <AvField
                                    value={field.device_name}
                                    name="device_name"
                                    id="device_name"
                                    placeholder="Name"
                                    type="text"
                                    onChange={(e) => {
                                      this.handleBoxChange(idx, e);
                                    }}
                                    errorMessage="Enter Device Name"
                                    validate={{ required: { value: false } }}
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="device_name"
                                  >
                                    Enter Device Name / Customer Name (Mimo Test
                                    Device)
                                  </UncontrolledTooltip>
                                </div>

                                <div className="col-3">
                                  <AvField
                                    value={field.serial_number}
                                    name="serial_number"
                                    id="serial_number"
                                    placeholder="Serial No."
                                    type="text"
                                    onChange={(e) => {
                                      this.handleBoxChange(idx, e);
                                    }}
                                    errorMessage="Enter Serial Number"
                                    validate={{ required: { value: true } }}
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="serial_number"
                                  >
                                    Enter Serial Number (AOBCDE5FGH)
                                  </UncontrolledTooltip>
                                </div>

                                <div className="col-3">
                                  <AvField
                                    value={field.wifi_mac_address}
                                    name="wifi_mac_address"
                                    id="wifi_mac_address"
                                    placeholder="Wifi Mac Address"
                                    type="text"
                                    onChange={(e) => {
                                      this.handleBoxChange(idx, e);
                                    }}
                                    errorMessage="Enter Wifi Mac Address"
                                    validate={{ required: { value: true } }}
                                  />
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="wifi_mac_address"
                                  >
                                    Enter Wifi Mac Address (a0:b1:cc:de:e1:6f)
                                  </UncontrolledTooltip>
                                </div>

                                {this.state.data.customer ? (
                                  <div className="col-2">
                                    <select
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      title="Activation Key"
                                      onChange={(e) => {
                                        this.handleBoxChange(idx, e);
                                      }}
                                      name="activationKey"
                                      className="custom-select"
                                    >
                                      <option defaultValue value="">
                                        Select Key
                                      </option>
                                      {this.state.filterKeyData.map(
                                        (key, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={key.activation_key}
                                            >
                                              {key.activation_key}
                                            </option>
                                          );
                                        }
                                      )}
                                    </select>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="col-1">
                                  <button
                                    type="button"
                                    className="waves-effect waves-light btn btn-danger"
                                    onClick={() => {
                                      this.handleBoxRemove(idx);
                                    }}
                                  >
                                    <i className="mdi mdi-minus"></i>
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label>
                      Upload CSV File{" "}
                      <i
                        style={{
                          fontSize: "18px",
                          cursor: "pointer",
                          color: "#2E79BA",
                        }}
                        className="mdi mdi-help-circle-outline"
                        id="csvRule"
                      ></i>
                      <UncontrolledPopover target="csvRule" placement="right">
                        <PopoverBody
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          <ul>
                            <li>File format must be 'CSV'</li>
                            <li>
                              First column contain 'Serial Number' and second
                              column contain 'Wifi Mac Address'
                            </li>
                          </ul>
                        </PopoverBody>
                      </UncontrolledPopover>
                    </Label>
                    <br></br>

                    <input
                      type="file"
                      name="csvFile"
                      onChange={this.handleChange}
                      className="filestyle size"
                      data-buttonname="btn-secondary"
                    />
                  </FormGroup>

                  <FormGroup className="mb-0">
                    <div>
                      <Button
                        type="submit"
                        color="primary"
                        className="waves-effect waves-light mr-1"
                        id="sa-title"
                      >
                        Submit
                      </Button>

                      <Button
                        type="reset"
                        onClick={this.handleCancel}
                        color="secondary"
                        className="waves-effect"
                        id="e1"
                      >
                        Cancel
                      </Button>

                      <div style={{ float: "right", marginRight: "20px" }}>
                        <div className="row">
                          <label className="mr-4" style={{ marginTop: "4px" }}>
                            Add Serial Number
                          </label>
                          <input
                            onChange={(e) => {
                              if (e.target.checked == true) {
                                this.setState({
                                  showMultiBox: true,
                                });
                              } else {
                                this.setState({
                                  showMultiBox: false,
                                  newMacAddress: [
                                    {
                                      device_name: null,
                                      serial_number: null,
                                      wifi_mac_address: null,
                                      activationKey: null,
                                    },
                                  ],
                                  data: {
                                    ...this.state.data,
                                    newMacAddress: [],
                                  },
                                });
                              }
                            }}
                            type="checkbox"
                            className="form-control-sm"
                          />
                        </div>
                      </div>
                    </div>
                  </FormGroup>
                </AvForm>
              </CardBody>
            </Card>
          </Col>

          {this.state.csvData.length ? (
            <Col xl="6" md="12">
              <Card>
                <CardBody>
                  <h4 className="card-title">CSV Devices Detail</h4>
                  <Row>
                    <Col>
                      <MDBDataTable
                        responsive
                        bordered
                        striped
                        data={csvData}
                        paging={false}
                        searching={true}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ) : (
            ""
          )}
        </Row>
      </React.Fragment>
    );
  }
}

export default connect(null, { setBreadcrumbItems })(deviceCreate);
