import React, { useState, useEffect, useRef } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { editGroup, updateGroup } from "./redux/GroupApi";
import { getProfiles } from "../Profile/redux/profileApi";
import { getDevices } from "../Device/redux/deviceApi";
import { MDBDataTable, MDBInput } from "mdbreact";
import { mimoLoader } from "../../store/actions";
import ViewModal from "../Device/ViewModal";
import { useDispatch } from "react-redux";
import {
  Col,
  Row,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

const EditGroup = ({
  groupObject,
  toggleModal,
  setSuccessAlert,
  setDescription,
  setErrorAlert,
}) => {
  const dispatch = useDispatch();
  const [groupData, setGroupData] = useState({
    deviceIds: [],
    header: {
      userName: "Admin",
      password: "0000",
    },
  });
  const [devicesData, setDevicesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [checkAllFlag, setCheckAllFlag] = useState(false);
  const thisRef = useRef();

  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const { data: groupData } = await editGroup(groupObject.id);
        const { data: deviceData } = await getDevices(groupObject.customer_id);

        setGroupData((prevState) => ({
          ...prevState,
          ...groupData,
        }));
        setDevicesData(deviceData);
        setFilteredData(
          deviceData
            .map((item) => {
              if (
                JSON.parse(groupData.mac_address).find(
                  (exItem) => exItem.device_id === item.id
                )
              ) {
                return {
                  ...item,
                  isChecked: true,
                };
              }
              return item;
            })
            .sort((a, b) => {
              if (a.isChecked && !b.isChecked) {
                return -1;
              } else if (!a.isChecked && b.isChecked) {
                return 1;
              } else {
                return 0;
              }
            })
        );
      } catch (ex) {
        console.error(ex);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  useEffect(() => {
    let activePage = thisRef.current.state.activePage;
    thisRef.current.changeActivePage(activePage);

    let topCheckBox = document.getElementsByClassName("header-checkbox");
    topCheckBox[0].checked = checkAllFlag;
    topCheckBox[1].checked = checkAllFlag;
  }, [filteredData]);

  // Submit Data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setGroupData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleValidation = () => {
    const macArr = filteredData
      .filter((item) => item.isChecked)
      .map((item) => item.mac_address);

    const deviceIdArr = filteredData
      .filter((item) => item.isChecked)
      .map((item) => item.id);

    if (!deviceIdArr.length) {
      setErrorAlert(true);
      setDescription("You are not selected any device");
    } else {
      handleSubmit({
        ...groupData,
        deviceIds: deviceIdArr,
        macAddress: macArr,
        groupProfiles: undefined,
      });
    }
  };

  const handleSubmit = async (data) => {
    dispatch(mimoLoader(true));
    try {
      await updateGroup(data);

      toggleModal();
      setSuccessAlert(true);
      setDescription("Group has been successfully updated");
    } catch (ex) {
      console.error(ex);
      setErrorAlert(true);
      setDescription("Failed to update group");
    }
    dispatch(mimoLoader(false));
  };

  // Handling Checkbox
  const changePage = (page) => {
    handleTopCheckbox(page);
  };

  const handleTopCheckbox = (page) => {
    let activeItem = thisRef.current.state.pages[page];
    let topCheckBox = document.getElementsByClassName("header-checkbox");
    if (activeItem) {
      let isActive = activeItem.every((item) => {
        return item.isChecked === true;
      });
      topCheckBox[0].checked = isActive;
      topCheckBox[1].checked = isActive;
    }
  };

  const handleAllChecked = (e) => {
    let activePage = thisRef.current.state.activePage;
    let listOfItems = thisRef.current.state.pages[activePage].map(
      (item) => item.id
    );

    setCheckAllFlag(e.target.checked);
    setFilteredData(
      filteredData.map((item) => {
        if (
          listOfItems.some((obj) => {
            return obj === item.id;
          })
        ) {
          return { ...item, isChecked: e.target.checked };
        } else {
          return { ...item };
        }
      })
    );
  };

  const toggleCheckbox = (id) => {
    setFilteredData(
      filteredData.map((item) => {
        if (item.id === id) {
          return { ...item, isChecked: !item.isChecked };
        } else {
          return { ...item };
        }
      })
    );
  };

  const tableData = {
    columns: [
      {
        label: (
          <MDBInput
            type="checkbox"
            defaultChecked={false}
            onChange={(e) => {
              handleAllChecked(e);
            }}
            className="header-checkbox"
            style={{ fontSize: "2px" }}
          />
        ),
        field: "check",
        sort: "disabled",
        width: 200,
      },

      {
        label: "Device Name",
        field: "device_name",
        sort: "desc",
        width: 200,
      },

      {
        label: "IP Address",
        field: "ip_address",
        sort: "desc",
        width: 200,
      },

      {
        label: "Mac Address",
        field: "mac_address",
        sort: "desc",
        width: 200,
      },

      {
        label: "Status",
        field: "status",
        sort: "desc",
        width: 200,
      },

      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: filteredData.map((obj, key) => ({
      check: (
        <MDBInput
          key={obj.id}
          type="checkbox"
          value={obj.id}
          checked={obj.isChecked ? obj.isChecked : false}
          onChange={() => {
            toggleCheckbox(obj.id);
          }}
          style={{ fontSize: "2px" }}
        />
      ),
      isChecked: obj.isChecked ? obj.isChecked : false,
      id: obj.id,

      device_name: obj.device_name,
      mac_address: obj.mac_address,
      status:
        obj.rebootStatus == 0 ? (
          obj.status == "on" ? (
            <>
              <i
                className="mdi mdi-record-circle-outline"
                style={{ color: "#32CD32", fontSize: "23px" }}
                id={`online_${key}`}
              ></i>
              <UncontrolledTooltip placement="top" target={`online_${key}`}>
                Online
              </UncontrolledTooltip>
            </>
          ) : (
            <>
              <i
                className="mdi mdi-record-circle-outline"
                style={{ color: "#DB4C3F", fontSize: "23px" }}
                id={`offline_${key}`}
              ></i>
              <UncontrolledTooltip placement="top" target={`offline_${key}`}>
                Offline
              </UncontrolledTooltip>
            </>
          )
        ) : (
          <>
            <i
              className="mdi mdi-record-circle-outline"
              style={{ color: "#F4A930", fontSize: "23px" }}
              id={`rebootDevice_${key}`}
            ></i>
            <UncontrolledTooltip placement="top" target={`rebootDevice_${key}`}>
              Reboot Command Issued
            </UncontrolledTooltip>
          </>
        ),

      ip_address: obj.ip_address,

      action: (
        <>
          <ViewModal key={obj.id} data={obj} />
        </>
      ),

      activation_key: obj.activation_key,
      serial_number: obj.serial_number,
      mac_address_ethernet: obj.mac_address_ethernet,
      version_number: obj.version_number,
      androidOS: obj.androidOS,
      model_number: obj.model_number,
      running_mimo_apps: obj.running_mimo_apps,
      public_ip: obj.public_ip,
      mlock_version: obj.mlock_version,
      webViewVersion: obj.webViewVersion,
      ip_addres: obj.ip_address,
      firmware: obj.firmware,
      location: obj.location,
      tags: obj.tags,
    })),
  };

  return (
    <>
      <Row>
        <Col lg="6">
          <AvForm onValidSubmit={handleValidation}>
            <AvField
              name="group_name"
              id="group_name"
              label="Group Name"
              placeholder="Type Name"
              type="text"
              value={groupData.group_name || ""}
              onChange={handleChange}
              errorMessage="Enter Group Name"
              validate={{ required: { value: true } }}
            />
            <UncontrolledTooltip placement="top" target="group_name">
              Enter Group Name (Mimo Device Group)
            </UncontrolledTooltip>

            <FormGroup className="mb-0">
              <div>
                <Button
                  type="submit"
                  color="primary"
                  className="waves-effect waves-light mr-1"
                  id="sa-title"
                >
                  Submit
                </Button>

                <Button
                  type="reset"
                  onClick={toggleModal}
                  color="secondary"
                  className="waves-effect"
                  id="e1"
                >
                  Cancel
                </Button>
              </div>
            </FormGroup>
          </AvForm>
        </Col>

        <Col lg="6" style={{ maxHeight: "680px", overflow: "auto" }}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <MDBDataTable
                    responsive
                    bordered
                    striped
                    data={tableData}
                    ref={thisRef}
                    onPageChange={({ activePage }) => {
                      changePage(activePage - 1);
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default EditGroup;
