import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  FormGroup,
  Button,
  UncontrolledTooltip,
  Label
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import * as authService from "../../services/authService";
import { mimoLoader, setBreadcrumbItems } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { changePassword,addNotificationDL } from "./redux/authApi";
import { connect, useDispatch } from "react-redux";
import Switch from "react-switch";
import styles from "./style.scss";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { getCurrentUser } from "../../services/authService";


const Settings = (props) => {
  const dispatch = useDispatch();
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Settings", link: "#" },
  ]);
  const [passwordData, setPasswordData] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [notification, setNotification] = useState(false);
  const [emails, setEmails] = useState('');
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [user, setUser] = useState('');
  
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  const validateEmails = (emailString) => {
    const emailArray = emailString.split(',').map(email => email.trim());
    for (let email of emailArray) {
      if (!emailRegex.test(email)) {
        return false;
      }
    }
    return true;
  };
  
  const handleDLChange = (e) => {
    const input = e.target.value;
    setEmails(input);
    if (validateEmails(input)) {
      setError('');
    } else {
      setError('One or more email addresses are invalid.');
    }
  };
  
  useEffect(() => {
    dispatch(mimoLoader(true));
    (async () => {
      props.setBreadcrumbItems("Settings", breadcrumbItems);
      const { REACT_APP_URL } = process.env;
      let data = secureLocalStorage.getItem("auth");
      let token = data.replace(/['"]+/g, "");

      axios
        .get(`${REACT_APP_URL}/api/auth/userShow/${getCurrentUser().id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setEmails(res.data.distribution_list||'');
          setNotification(Boolean(res.data.notification_status||false));
          setUser(res.data.user_role_id);
          setShow(true);
        })
        .catch((error) => {
          console.error(error);
        });
      })();
  }, [setBreadcrumbItems]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleNotificationToggle = (e)=>{
    setNotification(e);
  }

  const handleSubmit = async () => {
    props.setBreadcrumbItems("Settings", breadcrumbItems, true);

    let formData = new FormData();
    formData.append("password", passwordData.password);
    formData.append("user_id", authService.getCurrentUser().id);

    if (passwordData.password !== passwordData.passwordConfirm) {
      setErrorAlert(true);
      setDescription("Password does not match");
    } else {
      await changePassword(formData);
      setSuccessAlert(true);
      setDescription("Password Successfully changed");
    }
    props.setBreadcrumbItems("Settings", breadcrumbItems, false);
  };

  const checkDLSubmitValidation = ()=>{
    return notification && emails!='' && error==''
  }

  const trimWhiteSpaces = (emails)=>{
      const email = emails.split(',');
      const removedWhiteSpaceEmails = email.map(mail=> mail.trim());
      return removedWhiteSpaceEmails.join(',');
  }

  const handleNotificationSubmit =async () => {
    if(!notification || checkDLSubmitValidation()){
      const payload = {
        distribution_list:trimWhiteSpaces(emails),
        notification_status:notification
      }
      props.setBreadcrumbItems("Settings", breadcrumbItems, true);
      try {
      const { data: response } = await addNotificationDL(payload);
      setSuccessAlert(true);
      setDescription(response.message);
      setEmails(payload.distribution_list);
      setNotification(payload.notification_status);
    } catch (ex) {
      setErrorAlert(true);
      setDescription(ex.response.message);
    }
    props.setBreadcrumbItems("Settings", breadcrumbItems, false);
    }
  };

return (
    <>
      {successAlert ? (
        <SweetAlert
          title="Success"
          success
          onConfirm={() => {
            setSuccessAlert(false);
            if(description==="Password Successfully changed")
                window.location.href = "/logout";
          }}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          error
          title="Failed"
          onConfirm={() => {
            setErrorAlert(false);
          }}
        >
          {description}
        </SweetAlert>
      ) : null}
  {!show?
<h1>Loading...</h1>:
    <>
      <Row>
        <Col lg="6">
          <Card>
            <CardBody>
              <AvForm>
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <AvField
                    onChange={handleChange}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    value={passwordData.password}
                    placeholder="Enter password"
                    errorMessage="Please enter a password"
                    validate={{
                      required: { value: true },
                      pattern: {
                        value:
                          "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})",
                        errorMessage:
                          "The password you entered doesn't meet the minimum security requirements.",
                      },
                    }}
                  />
                  <UncontrolledTooltip placement="top" target="password">
                    <li className="text-info h6">
                      The string must contain at least 1 lowercase alphabetical
                      character.
                    </li>
                    <li className="text-info h6">
                      The string must contain at least 1 uppercase alphabetical
                      character.
                    </li>
                    <li className="text-info h6">
                      The string must contain at least 1 numeric character.
                    </li>
                    <li className="text-info h6">
                      The string must contain at least one special character.
                    </li>
                    <li className="text-info h6">
                      The string must be eight characters or longer.
                    </li>
                  </UncontrolledTooltip>
                </div>

                <div className="form-group">
                  <label htmlFor="passwordConfirm">Confirm Password</label>
                  <AvField
                    type="password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    className="form-control"
                    name="passwordConfirm"
                    placeholder="Enter confirm password"
                    errorMessage="Please enter a confirm password"
                    validate={{
                      required: { value: true },
                    }}
                  />
                </div>

                <FormGroup className="mb-0">
                  <div>
                    <Button
                      type="submit"
                      color="primary"
                      className="waves-effect waves-light mr-1"
                      id="sa-title"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                    <Button
                      type="reset"
                      onClick={() => props.history.replace("/")}
                      color="secondary"
                      className="waves-effect"
                      id="e1"
                    >
                      Cancel
                    </Button>
                  </div>
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
     {
      user === 1 &&
      <Row>
        <Col lg="6">
          <Card>
            <CardBody>
              <AvForm>
                <div>
                <div className={styles['container']}>
                     <Label>Notification</Label>
                     <div style={{display:"inline",float:"right"}}>
                     <div className={styles['switch-container']}>
                       <Switch
                         height={22}
                         width={45}
                         checked={notification}
                         onChange={handleNotificationToggle}
                       />
                       {notification && (
                         <div className={styles['switch-background']}>
                           <span className={styles.tick}></span>
                         </div>
                       )}
                     </div>
                     </div>
                </div>

            </div>
                <div className="form-group">
                  <label htmlFor="list">Distribution List:</label>
                  <AvField
                    onChange={handleDLChange}
                    id="list"
                    name="list"
                    type="text"
                    disabled={!notification}
                    value={emails}
                    placeholder="Enter email distribution list"
                    errorMessage= {"Please enter a list of Distribution List"} 
                    required={notification}
                  />
                  { emails!='' && error!='' && error.length>0 && <span style={{color:'red'}}>{error}</span>}

                  <UncontrolledTooltip placement="top" target="list" style={{"color":"#29bbe3"}} className="text-info h6">
                      {notification?
                      "Enter comma seperated email distribution list."
                      :"Enable the notification toggle to enter the DL"
                      }
                  </UncontrolledTooltip>
                </div>

                <FormGroup className="mb-0">
                  <div>
                    <Button
                      type="submit"
                      color="primary"
                      className="waves-effect waves-light mr-1"
                      id="sa-title"
                      onClick={handleNotificationSubmit}
                    >
                      Submit
                    </Button>

                    <Button
                      type="reset"
                      onClick={() => props.history.replace("/")}
                      color="secondary"
                      className="waves-effect"
                      id="e1"
                    >
                      Cancel
                    </Button>
                  </div>
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
      }
      </>
      }
    </>
  );
  }

export default connect(null, { setBreadcrumbItems })(Settings);
