import React, { useState, useEffect } from "react";
import {
  Button,
  UncontrolledPopover,
  PopoverBody,
  Col,
  Row,
  Card,
  CardBody,
} from "reactstrap";
import { deleteActKey, getActKeyData } from "./redux/activationKeyApi";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { AvField, AvForm } from "availity-reactstrap-validation";
import ModalLayout from "../../component/Common/ModalLayout";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import ActivationCreate from "./ActivationCreate";
import ActivationEdit from "./ActivationEdit";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import "./datatables.scss";

const ActivationKey = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Activation Key", link: "/activation" },
  ]);
  const [activationKeyData, setActivationKeyData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [keyType, setKeyType] = useState("all");
  const [activationKeyId, setActivationKeyId] = useState({});
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [resellerId, setResellerId] = useState("all");
  const [customerId, setCustomerId] = useState("all");
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShowCreate, setModalShowCreate] = useState(false);

  useEffect(() => {
    if (modalShow || modalShowCreate) return;
    (async () => {
      props.setBreadcrumbItems("Activation Key", breadcrumbItems, true);

      try {
        const {
          data: { data: activationKeyData },
        } = await getActKeyData(customerId);

        setActivationKeyData(activationKeyData);
        setFilteredData(activationKeyData);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Activation Key", breadcrumbItems, false);
    })();
  }, [modalShow, modalShowCreate]);

  const handleDelete = async () => {
    props.setBreadcrumbItems("Activation Key", breadcrumbItems, true);

    try {
      await deleteActKey(activationKeyId);

      setSuccessAlert(true);
      setActivationKeyData(
        activationKeyData.filter(
          (item) => item.activation_key != activationKeyId
        )
      );
      setFilteredData(
        activationKeyData.filter((item) =>
          customerId === "all"
            ? keyType === "all"
              ? item.activation_key != activationKeyId
              : keyType === "activated"
              ? item.activation_key != activationKeyId && item.active === 1
              : item.activation_key != activationKeyId && item.active === 0
            : keyType === "all"
            ? item.activation_key != activationKeyId &&
              item.customer_id == customerId
            : keyType === "activated"
            ? item.activation_key != activationKeyId &&
              item.active === 1 &&
              item.customer_id == customerId
            : item.activation_key != activationKeyId &&
              item.active === 0 &&
              item.customer_id == customerId
        )
      );
      setDescription("Activation key has been successfully deleted");
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("Activation Key", breadcrumbItems, false);
  };

  const handleReseller = (id) => {
    setResellerId(id);
    setCustomerId("all");
    setFilteredData(
      activationKeyData
        .filter((item) => (id === "all" ? item : item.resellerId == id))
        .filter((item) =>
          keyType === "all"
            ? item
            : keyType === "activated"
            ? item.active === 1
            : item.active === 0
        )
    );
  };

  const handleCustomer = (id) => {
    setCustomerId(id);
    setFilteredData(
      activationKeyData
        .filter((item) =>
          resellerId === "all" ? item : item.resellerId == resellerId
        )
        .filter((item) =>
          id === "all"
            ? keyType === "all"
              ? item
              : keyType === "activated"
              ? item.active === 1
              : item.active === 0
            : keyType === "all"
            ? item.customer_id == id
            : keyType === "activated"
            ? item.active === 1 && item.customer_id == id
            : item.active === 0 && item.customer_id == id
        )
    );
  };

  const handleKeyType = (e) => {
    const { value } = e.target;
    setKeyType(value);
    setFilteredData(
      activationKeyData
        .filter((item) =>
          resellerId === "all" ? item : item.resellerId == resellerId
        )
        .filter((item) =>
          value == "all"
            ? customerId === "all"
              ? item
              : item.customer_id == customerId
            : value === "activated"
            ? customerId === "all"
              ? item.active === 1
              : item.active === 1 && item.customer_id == customerId
            : customerId === "all"
            ? item.active === 0
            : item.active === 0 && item.customer_id == customerId
        )
    );
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  const dataTable = {
    columns: [
      {
        label: "Customer Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Application Name",
        field: "app_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Key Type",
        field: "key_type",
        sort: "asc",
        width: 270,
      },
      {
        label: "Expiration Date",
        field: "valid_date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Activation Key",
        field: "activation_key",
        sort: "asc",
        width: 200,
      },
      {
        label: "Device Quantity",
        field: "device_quantity",
        sort: "asc",
        width: 200,
      },
      {
        label: "Active Devices",
        field: "total_active_devices",
        sort: "asc",
        width: 200,
      },
      {
        label: "Comments",
        field: "comments",
        sort: "asc",
        width: 200,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: filteredData.map((obj, index) => ({
      name: obj.name,
      app_name: obj.app_name,
      key_type: obj.key_type,
      valid_date: obj.valid_date,
      activation_key:
        obj.active == 0 ? (
          obj.activation_key
        ) : (
          <h5>
            <span className="badge badge-success">{obj.activation_key}</span>
          </h5>
        ),
      device_quantity: obj.device_quantity,
      total_active_devices: obj.total_active_devices,
      comments: obj.comments ? (
        <>
          <i
            id={`comments_${index}`}
            style={{
              fontSize: "20px",
              cursor: "pointer",
            }}
            className="mdi mdi-comment-text md-18 center"
          ></i>
          <UncontrolledPopover target={`comments_${index}`} placement="left">
            <PopoverBody>{obj.comments}</PopoverBody>
          </UncontrolledPopover>
        </>
      ) : (
        ""
      ),

      actions: (
        <>
          <i
            className="mdi mdi-square-edit-outline mr-1 iconHover"
            style={{ color: "#20C0ED", fontSize: "23px" }}
            role="button"
            onClick={() => {
              setActivationKeyId(obj.activation_key);
              toggleModal();
            }}
          ></i>

          <i
            className="mdi mdi mdi-delete  mr-1 iconHover"
            style={{
              color: "#DB4C3F",
              fontSize: "23px",
            }}
            role="button"
            onClick={() => {
              setConfirmAlert(true);
              setActivationKeyId(obj.activation_key);
            }}
          ></i>
        </>
      ),
    })),
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}

      <Col xs="12">
        <Card>
          <CardBody>
            <AvForm>
              <Col xs="12">
                <Row className="align-items-center mb-3">
                  {getCurrentUser().role_id === 3 ? (
                    <Col xs="3">
                      <SelectReseller
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        handleReseller={handleReseller}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col xs="3">
                    <SelectCustomer
                      className=""
                      fieldError={null}
                      resellerId={resellerId}
                      customerId={customerId}
                      handleCustomer={handleCustomer}
                    />
                  </Col>

                  <Col xs="2">
                    <AvField
                      type="select"
                      name="keyType"
                      label="Select Key Type"
                      onChange={handleKeyType}
                    >
                      <option defaultValue value="all">
                        All
                      </option>
                      <option value="activated">Activated</option>
                      <option value="notActivated">Not Activated</option>
                    </AvField>
                  </Col>

                  <Col xs="3">
                    <button
                      style={{ marginTop: "10px" }}
                      className="waves-effect waves-light btn btn-outline-info mr-3"
                      type="button"
                      onClick={() =>
                        props.history.replace(
                          `/activation-key-report/${customerId}`
                        )
                      }
                    >
                      Report
                    </button>

                    <Button
                      style={{ marginTop: "10px" }}
                      onClick={toggleModalCreate}
                    >
                      Create Activation Key
                    </Button>
                  </Col>
                </Row>
              </Col>
            </AvForm>

            <MDBDataTable
              className="ak1"
              responsive
              bordered
              striped
              entries={100}
              data={dataTable}
            />
          </CardBody>
        </Card>
      </Col>

      {modalShow ? (
        <ModalLayout
          toggleModal={toggleModal}
          isOpen={modalShow}
          title="Edit Activation Key"
          size="lg"
        >
          <ActivationEdit
            activationKeyId={activationKeyId}
            toggleModal={toggleModal}
            setSuccessAlert={setSuccessAlert}
            setDescription={setDescription}
            setErrorAlert={setErrorAlert}
          />
        </ModalLayout>
      ) : (
        ""
      )}

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Create Activation Key"
          size="lg"
        >
          <ActivationCreate
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(ActivationKey);
