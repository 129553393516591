import React, { Component } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Label, Button, Table } from "reactstrap";
import secureLocalStorage from "react-secure-storage";
import Select from "react-select";
import axios from "axios";
import Switch from "react-switch";

class ProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        name: "",
        app_name: "",
        activation_key: "",
      },
      newData: {
        valid_date: "",
        passwordEyeIcon: true,
      },

      app_name: [],
      key: [],
      apkData: [],
      d_name: [{ ip_mode: "Static" }, { ip_mode: "DHCP" }],

      m_name: [
        { enableAutoRefreshWebsiteAfterFixedInterval: "true" },
        { enableAutoRefreshWebsiteAfterFixedInterval: "false" },
      ],
      c_name: [{ disableCookies: "true" }, { disableCookies: "false" }],
      e_name: [{ disableFormData: "true" }, { disableFormData: "false" }],
      f_name: [
        { enableAutoRefreshWebpage: "true" },
        { enableAutoRefreshWebpage: "false" },
      ],
      g_name: [
        { autorefreshTimeInMinutes: "15" },
        { autorefreshTimeInMinutes: "30" },
        { autorefreshTimeInMinutes: "45" },
        { autorefreshTimeInMinutes: "60" },
        { autorefreshTimeInMinutes: "75" },
        { autorefreshTimeInMinutes: "90" },
      ],
      i_name: [{ videoRepeatMode: "all" }, { videoRepeatMode: "one" }],
      j_name: [
        { contentUpdateAllowed: "true" },
        { contentUpdateAllowed: "false" },
      ],

      newArr: [],
      keyArr: [],
      newArr1: [],
      newArr2: [],
      newArr3: [],
      newArr4: [],
      newArr5: [],
      newArr6: [],

      alert: "",
      success: false,
      error: false,
      errors:{},
      user: JSON.parse(secureLocalStorage.getItem("user")),
    };
  }

  handleMulti = (selectedMulti) => {
    if (selectedMulti) {
      this.setState({ selectedMulti }, () => {});

      this.setState(
        {
          newData: {
            ...this.state.newData,
            apk_url: selectedMulti.map((data) => data.value),
          },
        },
        () => {
          var slug = JSON.parse(this.state.newData.slug);
          slug.apk_url = this.state.newData.apk_url;
          this.setState({
            newData: {
              ...this.state.newData,
              slug: JSON.stringify(slug),
            },
          });
        }
      );
    } else {
      this.setState(
        {
          selectedMulti: [],
          data: {
            ...this.state.data,
            apk_url: [],
          },
        },
        () => {
          var slug = JSON.parse(this.state.newData.slug);
          slug.apk_url = [];
          this.setState({
            newData: {
              ...this.state.newData,
              slug: JSON.stringify(slug),
            },
          });
        }
      );
    }
  };

  componentDidMount() {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    axios
      .get(`${REACT_APP_URL}/api/auth/editProfile/${this.props.profileId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.setState({ data: res.data, newData: res.data }, () => {
          this.setState(
            {
              newData: this.state.data,
            },
            () => {
              if (this.state.data.profile_type == "Install User App") {
                var arr = [];
                var apk_url = JSON.parse(this.state.data.slug).apk_url;
                apk_url.map((data) => {
                  arr.push({
                    label: data,
                    value: data,
                  });
                });

                this.setState({
                  newData: {
                    ...this.state.newData,
                    apk_url: apk_url.map((obj) => obj),
                  },
                });

                this.setState(
                  {
                    selectedMulti: arr,
                  },
                  () => {
                    axios
                      .get(
                        `${REACT_APP_URL}/api/auth/apkUrl/${
                          JSON.parse(this.state.data.slug).customer
                        }`,
                        {
                          headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                        },
                        data
                      )
                      .then((res) => {
                        var arr = [];
                        this.setState({ group_data: res.data }, () => {
                          this.state.group_data.map((data) => {
                            arr.push({
                              options: [
                                {
                                  label: data.url,
                                  value:
                                    process.env.REACT_APP_URL + "/" + data.url,
                                },
                              ],
                            });
                          });
                        });

                        this.setState({
                          multi_data: arr,
                        });
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }
                );
              }

              if (this.state.data.profile_type == "MLock Configurations") {
                this.state.m_name.map((item) => {
                  if (
                    item.enableAutoRefreshWebsiteAfterFixedInterval !==
                    JSON.parse(this.state.data.slug)
                      .enableAutoRefreshWebsiteAfterFixedInterval
                  ) {
                    this.setState((prevState) => ({
                      newArr: [
                        ...prevState.newArr,
                        {
                          enableAutoRefreshWebsiteAfterFixedInterval:
                            item.enableAutoRefreshWebsiteAfterFixedInterval,
                        },
                      ],
                    }));
                  }
                });

                this.state.e_name.map((item) => {
                  if (
                    item.disableFormData !==
                    JSON.parse(this.state.data.slug).disableFormData
                  ) {
                    this.setState((prevState) => ({
                      newArr2: [
                        ...prevState.newArr2,
                        { disableFormData: item.disableFormData },
                      ],
                    }));
                  }
                });

                this.state.c_name.map((item) => {
                  if (
                    item.disableCookies !==
                    JSON.parse(this.state.data.slug).disableCookies
                  ) {
                    this.setState((prevState) => ({
                      newArr1: [
                        ...prevState.newArr1,
                        { disableCookies: item.disableCookies },
                      ],
                    }));
                  }
                });

                this.state.f_name.map((item) => {
                  if (
                    item.enableAutoRefreshWebpage !==
                    JSON.parse(this.state.data.slug).enableAutoRefreshWebpage
                  ) {
                    this.setState((prevState) => ({
                      newArr3: [
                        ...prevState.newArr3,
                        {
                          enableAutoRefreshWebpage:
                            item.enableAutoRefreshWebpage,
                        },
                      ],
                    }));
                  }
                });

                this.state.g_name.map((item) => {
                  if (
                    item.autorefreshTimeInMinutes !==
                    JSON.parse(this.state.data.slug).autorefreshTimeInMinutes
                  ) {
                    this.setState((prevState) => ({
                      newArr4: [
                        ...prevState.newArr4,
                        {
                          autorefreshTimeInMinutes:
                            item.autorefreshTimeInMinutes,
                        },
                      ],
                    }));
                  }
                });

                this.state.i_name.map((item) => {
                  if (
                    item.videoRepeatMode !==
                    JSON.parse(this.state.data.slug).videoRepeatMode
                  ) {
                    this.setState((prevState) => ({
                      newArr5: [
                        ...prevState.newArr5,
                        { videoRepeatMode: item.videoRepeatMode },
                      ],
                    }));
                  }
                });

                this.state.j_name.map((item) => {
                  if (
                    item.contentUpdateAllowed !==
                    JSON.parse(this.state.data.slug).contentUpdateAllowed
                  ) {
                    this.setState((prevState) => ({
                      newArr6: [
                        ...prevState.newArr6,
                        { contentUpdateAllowed: item.contentUpdateAllowed },
                      ],
                    }));
                  }
                });

                var uncheck = document.getElementsByTagName("input");
                for (var i = 0; i < uncheck.length; i++) {
                  if (
                    uncheck[i].type == "checkbox" &&
                    JSON.parse(this.state.data.slug).autoStart
                  ) {
                    if (uncheck[i].name == "autoStart") {
                      uncheck[i].checked = true;
                    }
                  }

                  if (
                    uncheck[i].type == "checkbox" &&
                    JSON.parse(this.state.data.slug).rebootDevice
                  ) {
                    if (uncheck[i].name == "rebootDevice") {
                      uncheck[i].checked = true;
                    }
                  }

                  if (
                    uncheck[i].type == "checkbox" &&
                    JSON.parse(this.state.data.slug).refreshScreen
                  ) {
                    if (uncheck[i].name == "refreshScreen") {
                      uncheck[i].checked = true;
                    }
                  }

                  if (
                    uncheck[i].type == "checkbox" &&
                    JSON.parse(this.state.data.slug).loadFileWebview
                  ) {
                    if (uncheck[i].name == "loadFileWebview") {
                      uncheck[i].checked = true;
                    }
                  }
                }
              } else {
                this.state.d_name.map((item) => {
                  if (
                    item.ip_mode !== JSON.parse(this.state.data.slug).ip_mode
                  ) {
                    this.setState((prevState) => ({
                      newArr: [...prevState.newArr, { ip_mode: item.ip_mode }],
                    }));
                  }
                });
              }
            }
          );
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  Offsymbol = (text) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  };
  
  OnSymbol = (text) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  };

  handleCheck = (name,value)=>{
    var s = JSON.parse(this.state.newData.slug);
    s = {
        ...s,
        [name]:value,
      };

      this.setState(
      {
        newData: {
          ...this.state.newData,
          [name]: value,
          slug: JSON.stringify(s),
        },
        errors:{
          ...this.state.errors,
          ip_mode: false,
        }
      }
    );
  }

  handleChange = (event) => {
    event.preventDefault();
    var s = JSON.parse(this.state.newData.slug);
    if(event.target.name === "proxyType" && event.target.value !== "manual")
    {
      delete s.ip;
      delete s.port;
    }
    if(event.target.name === "securityType" && event.target.value !== "wpaEnterprise")
    {
      delete s.userId;
    }

    if (event.target.value == "DHCP") {
      s = {
        [event.target.name]: event.target.value,
      };
    } else {
      s = {
        ...s,
        [event.target.name]: event.target.value,
      };
    }

    this.setState(
      {
        newData: {
          ...this.state.newData,
          [event.target.name]: event.target.value,
          slug: JSON.stringify(s),
        },
        errors:{
          ...this.state.errors,
          ip_mode: false,
        }
      },
      () => {
        if (this.state?.newData.ip_mode == "DHCP") {
          this.setState({
            newData: {
              profile_name: this.state.newData.profile_name,
              profile_type: this.state.newData.profile_type,
              ip_mode: "DHCP",
              slug: this.state.newData.slug,
            },
          });
        }
      }
    );
  };

  handleSubmit = () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");
    axios
      .post(
        `${REACT_APP_URL}/api/auth/updateProfile/${this.props.profileId}`,
        this.state.newData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          this.props.setErrorAlert(true);
          this.props.setDescription(res.data.error);
        } else {
          this.props.toggleModal();
          this.props.setSuccessAlert(true);
          this.props.setDescription("Profile successfully updated");
        }
      })
      .catch((error) => {
        console.error(error);
        this.props.setErrorAlert(true);
        this.props.setDescription("Profile not updated");
      });
  };

  render() {
    const { selectedMulti } = this.state;

    return (
      <>
        <AvForm onValidSubmit={this.handleSubmit}>
          <AvField
            name="profile_name"
            label="Profile Name"
            placeholder="Type Something"
            type="text"
            value={this.state.data.profile_name}
            onChange={this.handleChange}
            errorMessage="Enter Name"
            validate={{ required: { value: true } }}
          />

          <AvField
            name="profile_type"
            label="Profile Type"
            placeholder="Type Something"
            type="text"
            value={
              (this.state.data.profile_type == "Wifi" ? "WiFi Settings" : "") ||
              (this.state.data.profile_type == "Device Setting"
                ? "Device Settings"
                : "") ||
              (this.state.data.profile_type == "Ethernet Configs"
                ? "Ethernet Configs"
                : "") ||
              (this.state.data.profile_type == "MLock Configurations"
                ? "MLock Configurations"
                : "") ||
              (this.state.data.profile_type == "Install User App"
                ? "Install User App"
                : "") ||
              (this.state.data.profile_type == "assignSSIDProfile"
                ? "Assign SSID Profile"
                : "")
            }
            onChange={this.handleChange}
            errorMessage="Enter type"
            readOnly
            validate={{ required: { value: true } }}
          />

          {this.state.data.profile_type == "Wifi" ||
          this.state.data.profile_type == "Ethernet Configs" ? (
            <>
              <FormGroup>
                <div>
                  <Label>Select IP mode</Label>
                  <select
                    required
                    onChange={this.handleChange}
                    name="ip_mode"
                    className="custom-select"
                  >
                    <option
                      defaultValue
                      value={JSON.parse(this.state.data.slug).ip_mode}
                    >
                      {JSON.parse(this.state.data.slug).ip_mode}
                    </option>
                    {this.state.newArr.map((d, index) => {
                      return (
                        <option key={index} value={d.ip_mode}>
                          {d.ip_mode}
                        </option>
                      );
                    })}
                  </select>
                  {this.state.errors.ip_mode && <span style={{ color: 'red' }}>Select Mode</span>}
                </div>
              </FormGroup>

              {JSON.parse(this.state.newData.slug).ip_mode == "Static" &&
              (!this.state.newData.ip_mode ||
                this.state.newData.ip_mode == "Static") ? (
                <>
                  <AvField
                    name="ip_address"
                    label="IP Address"
                    placeholder="192.168.1.1"
                    type="text"
                    value={JSON.parse(this.state.data.slug).ip_address}
                    onChange={this.handleChange}
                    errorMessage="Enter IP"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="gateway"
                    label="Gateway"
                    placeholder="192.168.1.1"
                    type="text"
                    value={JSON.parse(this.state.data.slug).gateway}
                    onChange={this.handleChange}
                    errorMessage="Enter Gateway"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="dns1"
                    label="DNS1"
                    placeholder="8.8.8.8"
                    type="text"
                    value={JSON.parse(this.state.data.slug).dns1}
                    onChange={this.handleChange}
                    errorMessage="Enter DNS"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="dns2"
                    label="DNS2"
                    placeholder="8.8.8.8"
                    type="text"
                    value={JSON.parse(this.state.data.slug).dns2}
                    onChange={this.handleChange}
                    errorMessage="Enter DNS"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="prefix_length"
                    label="Prefix Length"
                    placeholder="Number between 0 - 25"
                    min={0}
                    onChange={this.handleChange}
                    value={JSON.parse(this.state.data.slug).prefix_length}
                    type="number"
                    errorMessage="This value should be between 0 and 25."
                    validate={{
                      required: { value: true },
                      min: { value: 0 },
                      max: { value: 25 },
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}

          {this.state.data.profile_type == "Device Setting" ? (
            <>
              <AvField
                name="brightness"
                label="Brightness Value"
                placeholder="Number between 0 - 255"
                min={0}
                onChange={this.handleChange}
                value={JSON.parse(this.state.data.slug).brightness}
                type="number"
                errorMessage="This value should be between 0 and 255."
                validate={{
                  required: { value: true },
                  min: { value: 0 },
                  max: { value: 255 },
                }}
              />

              <AvField
                name="system_volume"
                label="System Volume"
                placeholder="Number between 0 - 7"
                min={0}
                onChange={this.handleChange}
                value={JSON.parse(this.state.data.slug).system_volume}
                type="number"
                errorMessage="This value should be between 0 and 7."
                validate={{
                  required: { value: true },
                  min: { value: 0 },
                  max: { value: 7 },
                }}
              />

              <AvField
                name="media_volume"
                label="Media Volume"
                placeholder="Number between 0 - 15"
                min={0}
                onChange={this.handleChange}
                value={JSON.parse(this.state.data.slug).media_volume}
                type="number"
                errorMessage="This value should be between 0 and 15."
                validate={{
                  required: { value: true },
                  min: { value: 0 },
                  max: { value: 15 },
                }}
              />

              <AvField
                name="light"
                label="Enter LED Color"
                placeholder="LED Color in HEX CODE e.g 00hh0"
                type="text"
                value={JSON.parse(this.state.data.slug).light}
                onChange={this.handleChange}
                errorMessage="Hex Value"
                validate={{
                  required: { value: true },
                }}
              />
            </>
          ) : (
            ""
          )}

          {this.state.data.profile_type == "MLock Configurations" ? (
            <>
              <FormGroup>
                <div>
                  <AvField
                    name="mode"
                    label="Mode"
                    placeholder="Type Something"
                    type="text"
                    value={JSON.parse(this.state.data.slug).mode}
                    onChange={this.handleChange}
                    errorMessage="Enter type"
                    readOnly
                    validate={{ required: { value: true } }}
                  />
                </div>
              </FormGroup>

              {JSON.parse(this.state.data.slug).mode == "video" ? (
                <>
                  <FormGroup className="mb-0">
                    <div>
                      <Table className="table-centered table-vertical table-nowrap">
                        <tbody>
                          <tr>
                            <td>
                              <label>Auto Start</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "true",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  } else {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "false",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  }
                                }}
                                name="autoStart"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Reboot Device</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "true",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  } else {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "false",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  }
                                }}
                                name="rebootDevice"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Refresh PlayScreen</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "true",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  } else {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "false",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  }
                                }}
                                name="refreshScreen"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <div className="mb-3">
                        <Label>Loop Videos</Label>
                        <br></br>

                        <select
                          required
                          onChange={this.handleChange}
                          name="videoRepeatMode"
                          className="custom-select"
                        >
                          <option
                            defaultValue
                            value={
                              JSON.parse(this.state.data.slug).videoRepeatMode
                            }
                          >
                            {JSON.parse(this.state.data.slug).videoRepeatMode}
                          </option>
                          {this.state.newArr5.map((d, index) => {
                            return (
                              <option key={index} value={d.videoRepeatMode}>
                                {d.videoRepeatMode}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="mb-3">
                        <Label>Enable Content Update</Label>
                        <br></br>

                        <select
                          required
                          onChange={this.handleChange}
                          name="contentUpdateAllowed"
                          className="custom-select"
                        >
                          <option
                            defaultValue
                            value={
                              JSON.parse(this.state.data.slug)
                                .contentUpdateAllowed
                            }
                          >
                            {
                              JSON.parse(this.state.data.slug)
                                .contentUpdateAllowed
                            }
                          </option>
                          {this.state.newArr6.map((d, index) => {
                            return (
                              <option
                                key={index}
                                value={d.contentUpdateAllowed}
                              >
                                {d.contentUpdateAllowed}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <AvField
                        name="videoContentPath"
                        label="Video Path"
                        placeholder="/storage/emulated/"
                        type="text"
                        value={
                          JSON.parse(this.state.data.slug).videoContentPath
                        }
                        onChange={this.handleChange}
                        errorMessage="Enter Path"
                        // validate={{ required: { value: true } }}
                      />

                      {/* Home setting */}
                    </div>
                  </FormGroup>
                </>
              ) : (
                ""
              )}

              {JSON.parse(this.state.data.slug).mode == "web" ? (
                <>
                  <FormGroup className="mb-0">
                    <div>
                      <Table className="table-centered table-vertical table-nowrap">
                        <tbody>
                          <tr>
                            <td>
                              <label>Auto Start</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "true",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  } else {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "false",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  }
                                }}
                                name="autoStart"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Reboot Device</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "true",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  } else {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "false",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  }
                                }}
                                name="rebootDevice"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Refresh PlayScreen</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "true",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  } else {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "false",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  }
                                }}
                                name="refreshScreen"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Load File</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        loadFileWebview: "true",
                                        websiteURL: "",
                                      },
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        loadFileWebview: "false",
                                        loadFileWebviewPathName: "",
                                      },
                                    });
                                  }
                                }}
                                name="loadFileWebview"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      {/* {(this.state.hSetting==false)&& (this.state.sSetting==false)?<> */}

                      {this.state.data.loadFileWebview == "false" ||
                      this.state.data?.loadFileWebview == null ? (
                        <>
                          <div className="mb-3">
                            <Label>Enable Auto Refresh Website</Label>
                            <br></br>

                            <select
                              required
                              onChange={this.handleChange}
                              name="enableAutoRefreshWebsiteAfterFixedInterval"
                              className="custom-select"
                            >
                              <option
                                defaultValue
                                value={
                                  JSON.parse(this.state.data.slug)
                                    .enableAutoRefreshWebsiteAfterFixedInterval
                                }
                              >
                                {
                                  JSON.parse(this.state.data.slug)
                                    .enableAutoRefreshWebsiteAfterFixedInterval
                                }
                              </option>
                              {this.state.newArr.map((d, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={
                                      d.enableAutoRefreshWebsiteAfterFixedInterval
                                    }
                                  >
                                    {
                                      d.enableAutoRefreshWebsiteAfterFixedInterval
                                    }
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="mb-3">
                            <Label>Disable Cookies</Label>
                            <br></br>

                            <select
                              required
                              onChange={this.handleChange}
                              name="disableCookies"
                              className="custom-select"
                            >
                              <option
                                defaultValue
                                value={
                                  JSON.parse(this.state.data.slug)
                                    .disableCookies
                                }
                              >
                                {
                                  JSON.parse(this.state.data.slug)
                                    .disableCookies
                                }
                              </option>
                              {this.state.newArr1.map((d, index) => {
                                return (
                                  <option key={index} value={d.disableCookies}>
                                    {d.disableCookies}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="mb-3">
                            <Label>Disable Saving Form Data</Label>
                            <br></br>

                            <select
                              required
                              onChange={this.handleChange}
                              name="disableFormData"
                              className="custom-select"
                            >
                              <option
                                defaultValue
                                value={
                                  JSON.parse(this.state.data.slug)
                                    .disableFormData
                                }
                              >
                                {
                                  JSON.parse(this.state.data.slug)
                                    .disableFormData
                                }
                              </option>
                              {this.state.newArr2.map((d, index) => {
                                return (
                                  <option key={index} value={d.disableFormData}>
                                    {d.disableFormData}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="mb-3">
                            <Label>
                              Will auto-refresh broken URLs every 10 seconds
                            </Label>
                            <br></br>

                            <select
                              required
                              onChange={this.handleChange}
                              name="enableAutoRefreshWebpage"
                              className="custom-select"
                            >
                              <option
                                defaultValue
                                value={
                                  JSON.parse(this.state.data.slug)
                                    .enableAutoRefreshWebpage
                                }
                              >
                                {
                                  JSON.parse(this.state.data.slug)
                                    .enableAutoRefreshWebpage
                                }
                              </option>
                              {this.state.newArr3.map((d, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={d.enableAutoRefreshWebpage}
                                  >
                                    {d.enableAutoRefreshWebpage}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="mb-3">
                            <Label>Auto-Refresh Time in Minutes</Label>
                            <br></br>

                            <select
                              required
                              onChange={this.handleChange}
                              name="autorefreshTimeInMinutes"
                              className="custom-select"
                            >
                              <option
                                defaultValue
                                value={
                                  JSON.parse(this.state.data.slug)
                                    .autorefreshTimeInMinutes
                                }
                              >
                                {
                                  JSON.parse(this.state.data.slug)
                                    .autorefreshTimeInMinutes
                                }
                              </option>
                              {this.state.newArr4.map((d, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={d.autorefreshTimeInMinutes}
                                  >
                                    {d.autorefreshTimeInMinutes}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <AvField
                            name="websiteURL"
                            label="Website Url"
                            placeholder="www.example.com"
                            type="text"
                            value={JSON.parse(this.state.data.slug).websiteURL}
                            onChange={this.handleChange}
                            errorMessage="Enter Url"
                          />
                        </>
                      ) : (
                        <AvField
                          name="loadFileWebviewPathName"
                          label="File name with address"
                          placeholder=" i.e storage/emulated/0/Download/myweb.html"
                          type="text"
                          value={
                            JSON.parse(this.state.data.slug)
                              .loadFileWebviewPathName
                          }
                          onChange={this.handleChange}
                          errorMessage="Enter Url"
                        />
                      )}
                    </div>
                  </FormGroup>
                </>
              ) : (
                ""
              )}

              {JSON.parse(this.state.data.slug).mode == "app" ? (
                <>
                  <FormGroup className="mb-0">
                    <div>
                      <Table className="table-centered table-vertical table-nowrap">
                        <tbody>
                          <tr>
                            <td>
                              <label>Reboot Device</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "true",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  } else {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "false",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  }
                                }}
                                name="rebootDevice"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Refresh PlayScreen</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "true",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  } else {
                                    var s = JSON.parse(this.state.newData.slug);

                                    s = {
                                      ...s,
                                      [e.target.name]: "false",
                                    };
                                    this.setState({
                                      newData: {
                                        ...this.state.newData,
                                        slug: JSON.stringify(s),
                                      },
                                    });
                                  }
                                }}
                                name="refreshScreen"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      {/* {(this.state.hSetting==false)&& (this.state.sSetting==false)?<> */}

                      <AvField
                        name="defaultApp"
                        label="Default App"
                        placeholder="Type Something"
                        type="text"
                        value={JSON.parse(this.state.data.slug).defaultApp}
                        onChange={this.handleChange}
                        errorMessage="Enter App Name"
                        // validate={{ required: { value: true } }}
                      />

                      {/* Home setting */}
                    </div>
                  </FormGroup>
                </>
              ) : (
                ""
              )}

              {this.state.hSetting == true ? (
                <>
                  <div className="mb-3">
                    <Label>Allow Guest User</Label>
                    <br></br>

                    <select
                      style={{
                        marginLeft: "0px",
                        width: "100%",
                        maxHeight: "50%",
                      }}
                      required
                      onChange={(e) => {
                        this.setState({
                          data: {
                            ...this.state.data,
                            allowGuestUser: e.target.value,
                          },
                        });
                      }}
                      name="allowGuestUser"
                      className="custom-select"
                    >
                      <option defaultValue value="true">
                        True
                      </option>
                      <option value="false">False</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="adminpassword">Admin Password</label>
                    <AvField
                      onChange={this.handleChange}
                      name="newPassword"
                      type="password"
                      value={this.state.data.newPassword}
                      placeholder="Enter Admin password"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="guestPassword"> Guest Password</label>
                    <AvField
                      onChange={this.handleChange}
                      name="guestPassword"
                      type="password"
                      value={this.state.data.guestPassword}
                      placeholder="Enter Guest password"
                    />
                  </div>
                </>
              ) : (
                ""
              )}

              {this.state.sSetting == true ? (
                <>
                  <div className="mb-3">
                    <Label>Enable Scroll Text</Label>
                    <br></br>

                    <select
                      style={{
                        marginLeft: "0px",
                        width: "100%",
                        maxHeight: "50%",
                      }}
                      required
                      onChange={(e) => {
                        this.setState({
                          data: {
                            ...this.state.data,
                            enableScrollText: e.target.value,
                          },
                        });
                      }}
                      name="enableScrollText"
                      className="custom-select"
                    >
                      <option defaultValue value="true">
                        True
                      </option>
                      <option value="false">False</option>
                    </select>
                  </div>

                  <AvField
                    name="scrollingText"
                    label="Scrolling Text"
                    placeholder="Type something"
                    type="text"
                    value={this.state.data.scrollingText}
                    onChange={this.handleChange}
                    errorMessage="Enter text"
                  />

                  <AvField
                    name="scrollingTextSpeed"
                    label="Scrolling Speed"
                    placeholder="Number between 5 - 100"
                    min={5}
                    onChange={this.handleChange}
                    value={this.state.data.scrollingTextSpeed}
                    type="number"
                    errorMessage="This value should be between 5 and 100."
                    validate={{
                      min: { value: 5 },
                      max: { value: 100 },
                    }}
                  />
                </>
              ) : (
                ""
              )}

              {/* {JSON.parse(this.state.data.slug).mode!=='exitMlock' && JSON.parse(this.state.data.slug).mode?<>
<Button onClick={()=>{this.setState({
hSetting:true,
sSetting:false
})}} color="success" className="waves-effect waves-light  mb-3 mr-1" id="sa-title">Guest Setting</Button>

<Button onClick={()=>{this.setState({
sSetting:true,
hSetting:false
})}}  color="danger" className="waves-effect waves-light mb-3 mr-1" id="sa-title">Scrolling Text</Button></>:""} */}
            </>
          ) : (
            ""
          )}

          {this.state.data.profile_type == "Install User App" ? (
            <>

              <FormGroup>
                <div>
                  <Label>Select Apk URL</Label>
                  <Select
                    required
                    value={selectedMulti}
                    isMulti={true}
                    onChange={this.handleMulti}
                    options={this.state.multi_data}
                    className="select2 select2-multiple"
                    maxMenuHeight={100}
                  />
                </div>
              </FormGroup>
            </>
          ) : (
            ""
          )}
          {this.state.data.profile_type == "assignSSIDProfile" ? (
            <>
              <AvField
              name="ssid"
              label="SSID"
              value={JSON.parse(this.state.data.slug).ssid}
              type="text"
              autoComplete="off"
              onChange={this.handleChange}
              errorMessage="Enter SSID"
              validate={{ required: { value: true } }}
            />

            <Label>Security Type</Label>
            <br></br>
            <select
              style={{
                marginLeft: "0px",
                width: "100%",
                maxHeight: "50%",
                marginBottom: "3%",
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Select Job"
              onChange={this.handleChange}
              value={JSON.parse(this.state.newData.slug).securityType}
              name="securityType"
              className="custom-select"
            >
              <option value="none">None</option>
              <option value="wep">WEP</option>
              <option value="wpa/wpa2">WPA/WPA2</option>
              <option value="wpaEnterprise">WPA/WPA2 Enterprise</option>
            </select>

            {JSON.parse(this.state.newData.slug).securityType === "wpaEnterprise" ? (
              <AvField
                name="userId"
                label="User ID"
                placeholder="Enter user id"
                type="text"
                autoComplete="off"
                onChange={this.handleChange}
                value={JSON.parse(this.state.newData.slug).userId}
                errorMessage="Enter the user id"
                validate={{ required: { value: true } }}
              />
            ) : (
              ""
            )}

            <label>Password</label>
            <div className="input-group mb-3">
              <input
                className="form-control"
                name="password"
                placeholder="Enter the network password"
                type={this.state.newData.passwordEyeIcon?"password":"text"}
                onChange={this.handleChange}
                value={JSON.parse(this.state.newData.slug).password}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  {this.state.newData.passwordEyeIcon ? (
                    <i
                      className="fa fa-eye-slash"
                      aria-hidden="true"
                      role="button"
                      onClick={()=>{this.handleCheck("passwordEyeIcon",false)}}
                    ></i>
                  ) : (
                    <i
                      className="fa fa-eye"
                      aria-hidden="true"
                      role="button"
                      onClick={() => {this.handleCheck("passwordEyeIcon",true)}}
                    ></i>
                  )}
                </span>
              </div>
            </div>


            <Label>Hidden</Label>
            <br></br>
            <Switch
              uncheckedIcon={this.Offsymbol("No")}
              checkedIcon={this.OnSymbol("Yes")}
              onColor="#626ed4"
              name="hidden"
              onChange={()=>this.handleCheck("hidden",!(JSON.parse(this.state.newData.slug).hidden))}
              checked={JSON.parse(this.state.newData.slug).hidden}
              className="mr-1"
            />
            <br></br>
            <br></br>

            <Label>Proxy Type</Label>
            <br></br>
            <select
              style={{
                marginLeft: "0px",
                width: "100%",
                maxHeight: "50%",
                marginBottom: "2%",
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Select Job"
              onChange={this.handleChange}
              name="proxyType"
              className="custom-select"
              value={JSON.parse(this.state.newData.slug).proxyType}

            >
              <option value="none">None</option>
              <option value="automatic">Automatic</option>
              <option value="manual">Manual</option>
            </select>

            {JSON.parse(this.state.newData.slug).proxyType === "manual" ? (
              <>
                <AvField
                  name="ip"
                  label="Ip"
                  placeholder="Enter ip"
                  type="text"
                  autoComplete="off"
                  onChange={this.handleChange}
                  value={JSON.parse(this.state.newData.slug).ip}
                  errorMessage="Enter the ip"
                  validate={{ required: { value: true } }}
                />

                <AvField
                  name="port"
                  label="Port"
                  placeholder="Enter port"
                  type="text"
                  autoComplete="off"
                  onChange={this.handleChange}
                  value={JSON.parse(this.state.newData.slug).port}
                  errorMessage="Enter the port"
                  validate={{ required: { value: true } }}
                />
              </>
            ) : (
              ""
            )}
            </>

          ) : (
            ""
          )}

          <FormGroup className="mb-0">
            <Button
              type="submit"
              color="primary"
              className="waves-effect waves-light mr-1"
              id="sa-title"
            >
              Submit
            </Button>

            <Button
              type="reset"
              onClick={this.props.toggleModal}
              color="secondary"
              className="waves-effect"
              id="e1"
            >
              Cancel
            </Button>
          </FormGroup>
        </AvForm>
      </>
    );
  }
}

export default ProfileEdit;
