import React, { useState, useEffect, useRef } from "react";
import { setBreadcrumbItems } from "../../store/actions";
import {
  Col,
  Row,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import {
  getUploadApk,
  deleteApk,
  deleteSelectedApk,
} from "./redux/uploadApkApi";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import ModalLayout from "../../component/Common/ModalLayout";
import { getCurrentUser } from "../../services/authService";
import { Permissions } from "../../services/permissions";
import { AvForm } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable, MDBInput } from "mdbreact";
import CreateUploadApk from "./CreateUploadApk";
import { connect } from "react-redux";
import "./datatables.scss";

const ShowUploadApk = (props) => {
  const thisRef = useRef();
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Upload Apk ", link: "/upload-apk" },
  ]);
  const [resellerId, setResellerId] = useState("all");
  const [customerId, setCustomerId] = useState(
    getCurrentUser().role_id !== 2 ? "all" : getCurrentUser().customer_id
  );
  const [uploadApkData, setUploadApkData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [apkId, setApkId] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [confirmAlertTwo, setConfirmAlertTwo] = useState(false);
  const [description, setDescription] = useState("");
  const [checkedBoxes, setCheckedBoxes] = useState([]);
  const [checkAllFlag, setCheckAllFlag] = useState(false);
  const [modalShowCreate, setModalShowCreate] = useState(false);

  useEffect(() => {
    if (modalShowCreate) return;
    (async () => {
      props.setBreadcrumbItems("Upload Apk ", breadcrumbItems, true);

      try {
        const { data: uploadApkData } = await getUploadApk(customerId);

        setUploadApkData(uploadApkData);
        setFilterData(uploadApkData);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Upload Apk ", breadcrumbItems, false);
    })();
  }, [modalShowCreate]);

  useEffect(() => {
    let activePage = thisRef.current.state.activePage;
    thisRef.current.changeActivePage(activePage);

    let topCheckBox = document.getElementsByClassName("header-checkbox");
    topCheckBox[0].checked = checkAllFlag;
    topCheckBox[1].checked = checkAllFlag;
  }, [filterData]);

  const handleReseller = (id) => {
    setResellerId(id);
    setCustomerId("all");
    setFilterData(
      uploadApkData.filter((item) =>
        id === "all" ? item : item.resellerId == id
      )
    );
  };

  const handleCustomer = (id) => {
    setCustomerId(id === "all" ? "all" : parseInt(id));
    setFilterData(
      uploadApkData.filter((item) =>
        id === "all" ? item : item.customerID == id
      )
    );
  };

  const handleDelete = async () => {
    props.setBreadcrumbItems("Upload Apk ", breadcrumbItems, true);

    try {
      await deleteApk(apkId);
      const currentData = uploadApkData.filter((item) => item.id !== apkId);

      setSuccessAlert(true);
      setDescription("Your data has been deleted.");
      setCheckAllFlag(false);
      setUploadApkData(currentData);
      setFilterData(
        currentData.filter((item) =>
          customerId == "all" ? item : item.customerID == customerId
        )
      );
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("Upload Apk ", breadcrumbItems, false);
  };

  const deleteSelected = async () => {
    props.setBreadcrumbItems("Upload Apk ", breadcrumbItems, true);

    filterData.map((item) => {
      if (item.isChecked && item.isChecked === true) {
        let arr = checkedBoxes;
        arr.push(item.id);
        setCheckedBoxes(arr);
      }
    });

    try {
      await deleteSelectedApk(JSON.stringify(checkedBoxes));
      const currentData = uploadApkData.filter(
        (item) => !checkedBoxes.includes(item.id)
      );

      setSuccessAlert(true);
      setCheckAllFlag(false);
      setDescription("APK successfully deleted");
      setUploadApkData(currentData);
      setFilterData(currentData);
      setCheckedBoxes([]);
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("Upload Apk ", breadcrumbItems, false);
  };

  const copyToClipboard = (url) => {
    const { REACT_APP_URL } = process.env;
    var textArea = document.createElement("textarea");
    textArea.value = REACT_APP_URL + "/" + url;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textArea);
  };

  // handle Checkboxes
  const changePage = (page) => {
    handleTopCheckbox(page);
  };

  const handleTopCheckbox = (page) => {
    let activeItem = thisRef.current.state.pages[page];
    let topCheckBox = document.getElementsByClassName("header-checkbox");
    if (activeItem) {
      let isActive = activeItem.every((item) => {
        return item.isChecked === true;
      });
      topCheckBox[0].checked = isActive;
      topCheckBox[1].checked = isActive;
    }
  };

  const handleAllChecked = (e) => {
    let activePage = thisRef.current.state.activePage;
    let listOfItems = thisRef.current.state.pages[activePage].map(
      (item) => item.id
    );

    setCheckAllFlag(e.target.checked);
    setFilterData(
      filterData.map((item) => {
        if (
          listOfItems.some((obj) => {
            return obj === item.id;
          })
        ) {
          return { ...item, isChecked: e.target.checked };
        } else {
          return { ...item };
        }
      })
    );
  };

  const toggleCheckbox = (id) => {
    setFilterData(
      filterData.map((item) => {
        if (item.id === id) {
          return { ...item, isChecked: !item.isChecked };
        } else {
          return { ...item };
        }
      })
    );
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  // DataTable
  const tableData = {
    columns: [
      {
        label: (
          <MDBInput
            type="checkbox"
            defaultChecked={false}
            onChange={(e) => {
              handleAllChecked(e);
            }}
            className="header-checkbox"
            style={{ fontSize: "2px" }}
          />
        ),
        field: "check",
        sort: "disabled",
        width: 200,
      },
      {
        label: "Application Name",
        field: "app_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "ID",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "File",
        field: "url",
        sort: "disabled",
        width: 270,
      },

      {
        label: "Uploaded by",
        field: "uploadedBy",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: filterData.map((obj) => ({
      ...obj,

      check: (
        <MDBInput
          key={obj.id}
          type="checkbox"
          value={obj.id}
          checked={obj.isChecked ? obj.isChecked : false}
          onChange={() => {
            toggleCheckbox(obj.id);
          }}
          style={{ fontSize: "2px" }}
        />
      ),
      isChecked: obj.isChecked ? obj.isChecked : false,

      app_name: obj.appName,
      uploadedBy: obj.uploadedBy,
      action: (
        <>
          <i
            className="mdi mdi-content-copy  mr-1 iconHover"
            style={{
              color: "#7EB69C",
              fontSize: "23px",
            }}
            role="button"
            id="copyURL"
            onClick={() => copyToClipboard(obj.url)}
          ></i>
          <UncontrolledTooltip placement="top" target="copyURL">
            Copy URL
          </UncontrolledTooltip>

          {Permissions().deleteApkPermi ? (
            <i
              className="mdi mdi mdi-delete  mr-1 iconHover"
              style={{
                color: "#DB4C3F",
                fontSize: "23px",
              }}
              role="button"
              onClick={() => {
                setConfirmAlertTwo(true);
                setApkId(obj.id);
              }}
            ></i>
          ) : (
            ""
          )}
        </>
      ),

      url: (
        <a
          className="btn btn-success waves-effect waves-light mr-3"
          href={process.env.REACT_APP_URL + "/" + obj.url}
        >
          <i className="fas fa-download fa-1.2x"></i> Download ({obj.fileSize}
          MB)
        </a>
      ),
    })),
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            deleteSelected();
          }}
          onCancel={() => {
            setConfirmAlert(false);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {confirmAlertTwo ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlertTwo(false);
            handleDelete();
          }}
          onCancel={() => {
            setConfirmAlertTwo(false);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      <Col xs="12">
        <Card>
          <CardBody>
            <AvForm>
              <Col xs="12">
                <Row className="align-items-center mb-3">
                  {getCurrentUser().role_id === 3 ? (
                    <Col xs="3">
                      <SelectReseller
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        handleReseller={handleReseller}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {getCurrentUser().role_id !== 2 ? (
                    <Col xs="3">
                      <SelectCustomer
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        customerId={customerId}
                        handleCustomer={handleCustomer}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col xs="2">
                    <Row className="align-items-center">
                      <Col xs="6">
                        <Button
                          style={{ marginTop: "10px" }}
                          onClick={toggleModalCreate}
                        >
                          Upload Apk
                        </Button>
                      </Col>

                      {filterData.some((item) => item.isChecked === true) ? (
                        <Col xs="6">
                          <div
                            className="mdi mdi-delete iconHover"
                            style={{
                              color: "#E8314F",
                              fontSize: "35px",
                              marginTop: "10px",
                            }}
                            id="deleteSelectedDevice"
                            role="button"
                            onClick={() => {
                              setConfirmAlert(true);
                            }}
                          ></div>
                          <UncontrolledTooltip
                            placement="top"
                            target="deleteSelectedDevice"
                          >
                            Delete selected apks
                          </UncontrolledTooltip>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </AvForm>

            <MDBDataTable
              responsive
              bordered
              striped
              entries={100}
              data={tableData}
              ref={thisRef}
              onPageChange={({ activePage }) => {
                changePage(activePage - 1);
              }}
            />
          </CardBody>
        </Card>
      </Col>

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Upload Apk"
          size="lg"
        >
          <CreateUploadApk
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(ShowUploadApk);
