import React, { useEffect, useState } from "react";
import { FormGroup, Button, UncontrolledTooltip } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import secureLocalStorage from "react-secure-storage";
import { mimoLoader } from "../../store/actions";
import { editHelp } from "./redux/helpGuideApi";
import { useDispatch } from "react-redux";
import axios from "axios";

const EditHelp = ({
  helpId,
  toggleModal,
  setSuccessAlert,
  setDescription,
  setErrorAlert,
}) => {
  const dispatch = useDispatch();
  const [helpData, setHelpData] = useState({
    category: "",
    fileName: "",
  });
  const [editData, setEditData] = useState({
    category: "",
    fileName: "",
  });

  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const { data: helpData } = await editHelp(helpId);

        setHelpData(helpData);
        setEditData((prevState) => ({
          ...prevState,
          id: helpData.id,
          categoryId: helpData.categoryId,
        }));
      } catch (ex) {
        console.error(ex);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    const formData = new FormData();
    formData.append("id", editData.id);
    formData.append("categoryId", editData.categoryId);
    formData.append("category", editData.category);
    formData.append("fileName", editData.fileName);

    axios
      .post(`${REACT_APP_URL}/api/auth/update-help`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type":
            "multipart/form-data; charset=utf-8; boundary=" +
            Math.random().toString().substr(2),
        },
      })
      .then((res) => {
        if (res.data.error) {
          setErrorAlert(true);
          setDescription(res.data.error);
        } else {
          toggleModal();
          setSuccessAlert(true);
          setDescription("Help guide successfully updated");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        <AvField
          name="category"
          id="category"
          label="Category Name"
          placeholder="Category Name"
          type="text"
          value={helpData.category || ""}
          onChange={handleChange}
          errorMessage="Enter Category Name"
          validate={{ required: { value: true } }}
        />
        <UncontrolledTooltip placement="top" target="category">
          Enter Category Name (MLock Help Guides)
        </UncontrolledTooltip>

        <AvField
          name="fileName"
          id="fileName"
          label="File Name"
          placeholder="File Name"
          type="text"
          value={helpData.fileName || ""}
          onChange={handleChange}
          errorMessage="Enter File Name"
          validate={{ required: { value: true } }}
        />
        <UncontrolledTooltip placement="top" target="category">
          Enter File Name (MLock User Guide v3.1.7)
        </UncontrolledTooltip>

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default EditHelp;
