import { VALIDATE_2FA, TWO_FA_SUCCESSFUL, API_FAILED } from "./actionTypes";

const initialState = {
  code: null,
  apiError: null,
};

const twoFactorAuth = (state = initialState, action) => {
  switch (action.type) {
    case VALIDATE_2FA:
      state = {
        ...state,
        code: null,
      };
      break;
    case TWO_FA_SUCCESSFUL:
      state = {
        ...state,
        check2FA: action.payload,
      };
      break;
    case API_FAILED:
      state = {
        ...state,
        apiError: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default twoFactorAuth;
