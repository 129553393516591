import React, { useState, useEffect } from "react";
import { SelectReseller } from "../../component/Common/SelectReseller";
import ModalLayout from "../../component/Common/ModalLayout";
import { deleteAdmin, getAdminData } from "./redux/adminApi";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import { AvForm } from "availity-reactstrap-validation";
import { Col, Card, CardBody, Row } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CreateAdmin from "./CreateAdmin";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import EditAdmin from "./EditAdmin";
import { Button } from "reactstrap";
import "./datatables.scss";

const Admin = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Admin", link: "/admin" },
  ]);
  const [adminData, setAdminData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [adminId, setAdminId] = useState(null);
  const [description, setDescription] = useState("");
  const [resellerId, setResellerId] = useState("all");
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowCreate, setModalShowCreate] = useState(false);

  useEffect(() => {
    if (modalShow || modalShowCreate) return;
    (async () => {
      props.setBreadcrumbItems("Admin", breadcrumbItems, true);

      const { data: response } = await getAdminData();

      setAdminData(response.data);
      setFilteredData(response.data);

      props.setBreadcrumbItems("Admin", breadcrumbItems, false);
    })();
  }, [modalShow, modalShowCreate]);

  const handleReseller = (id) => {
    setResellerId(id);
    setFilteredData(
      adminData.filter((item) => (id === "all" ? item : item.reseller_id == id))
    );
  };

  const handleDelete = async () => {
    props.setBreadcrumbItems("Admin", breadcrumbItems, true);

    try {
      const { data: response } = await deleteAdmin(adminId);
      const currentData = adminData.filter((item) => item.id !== adminId);

      setConfirmAlert(false);
      setSuccessAlert(true);
      setDescription(response.message);
      setResellerId("all");
      setAdminData(currentData);
      setFilteredData(currentData);
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("Admin", breadcrumbItems, false);
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  const adminTable = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 270,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Two Factor Auth Status",
        field: "TowFaStatus",
        sort: "asc",
        width: 200,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 200,
      },
    ],
    rows: filteredData.map((obj, key) => ({
      ...obj,

      TowFaStatus:
        obj.two_factor_status === "enable" ? (
          <h5>
            <span className="badge badge-success">Enabled</span>
          </h5>
        ) : (
          <h5>
            <span className="badge badge-danger">Disabled</span>
          </h5>
        ),

      actions: (
        <>
          <i
            className="mdi mdi-square-edit-outline mr-1 iconHover"
            style={{ color: "#20C0ED", fontSize: "23px" }}
            role="button"
            onClick={() => {
              setAdminId(obj.id);
              toggleModal();
            }}
          ></i>
          {getCurrentUser().id !== obj.id ? (
            <i
              className="mdi mdi mdi-delete  mr-1 iconHover"
              style={{
                color: "#DB4C3F",
                fontSize: "23px",
              }}
              role="button"
              onClick={() => {
                setConfirmAlert(true);
                setAdminId(obj.id);
              }}
            ></i>
          ) : (
            ""
          )}
        </>
      ),
    })),
  };

  return (
    <>
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}

      <Col xs="12">
        <Card>
          <CardBody>
            <Col xs="12">
              <Row className="align-items-center mb-3">
                {getCurrentUser().role_id === 3 ? (
                  <Col xs="3">
                    <AvForm>
                      <SelectReseller
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        handleReseller={handleReseller}
                      />
                    </AvForm>
                  </Col>
                ) : (
                  ""
                )}

                <Col xs="2">
                  <Button
                    style={{ marginTop: "10px" }}
                    onClick={toggleModalCreate}
                  >
                    Create Admin
                  </Button>
                </Col>
              </Row>
            </Col>

            <MDBDataTable
              responsive
              bordered
              striped
              entries={100}
              data={adminTable}
            />
          </CardBody>
        </Card>
      </Col>

      {modalShow ? (
        <ModalLayout
          toggleModal={toggleModal}
          isOpen={modalShow}
          title="Edit Admin"
          size="lg"
        >
          <EditAdmin
            adminId={adminId}
            toggleModal={toggleModal}
            setSuccessAlert={setSuccessAlert}
            setDescription={setDescription}
            setErrorAlert={setErrorAlert}
          />
        </ModalLayout>
      ) : (
        ""
      )}

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Create Admin"
          size="lg"
        >
          <CreateAdmin
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(Admin);
