import axios from "axios";
import { getHeader } from "../../../services/authService";

const { REACT_APP_URL } = process.env;
axios.defaults.baseURL = REACT_APP_URL;

export function getActKeyData(id) {
  return axios.get(`/api/auth/activation/${id}`, getHeader());
}

export function storeActKey(data) {
  return axios.post("/api/auth/activation/create", data, getHeader());
}

export function editActKey(key) {
  return axios.get(`/api/auth/activation/edit/${key}`, getHeader());
}

export function updateActKey(keyId, data) {
  return axios.post(`/api/auth/activation/update/${keyId}`, data, getHeader());
}

export function deleteActKey(key) {
  return axios.get(`/api/auth/activation/delete/${key}`, getHeader());
}

// Activation Keys Reports
export function activationKeyReport(id) {
  return axios.get(`/api/auth/activationKeyReport/${id}`, getHeader());
}
