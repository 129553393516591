import React from "react";
import LineChart from "../AllCharts/chartist/linechart";
import { Col, Row, Card, CardBody } from "reactstrap";
import "chartist/dist/scss/chartist.scss";

const AutoDownloads = ({ reports }) => {
  return (
    <>
      <Row>
        <Col lg="12">
          <Card style={{ height: "383px" }}>
            <CardBody>
              <h4 className="card-title mb-4">Total Updates By Months</h4>
              <div dir="ltr">
                <LineChart height={238} reports={reports} />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AutoDownloads;
