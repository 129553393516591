import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Alert, Button } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";
import { connect } from "react-redux";
import logo from "../../assets/images/logo.png";
import { forgetUser } from "../../store/actions";
import axios from "axios";

class emailVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      verifyURL: null,
      msg: null,
      passwordEyeIcon: true,
      passwordInputType: "password",
    };
  }

  handleChange = (event) => {
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSubmit = () => {
    const { data, verifyURL } = this.state;

    if (data.password !== data.password_confirmation) {
      this.setState({
        msg: "Password does not match",
      });
    } else {
      const uri = `&password=${encodeURIComponent(data.password)}`;
      axios
        .post(`${verifyURL}`, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          this.props.history.replace(`/login`);
        })
        .catch((error) => {
          console.error(error);
          this.props.history.replace(`/pages-404`);
        });
    }
  };

  componentDidMount() {
    const verifyURL = new URLSearchParams(this.props.location.search).get(
      "queryURL"
    );
    this.setState({
      verifyURL: verifyURL,
    });
  }

  // Show Password
  handleShowPassword(object) {
    this.setState({
      passwordEyeIcon: object ? true : false,
      passwordInputType: object ? "password" : "text",
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.verifyURL ? (
          <div className="account-pages my-5 pt-sm-5">
            <Container>
              <Row className="justify-content-center">
                <Col md="8" lg="6" xl="5">
                  <Card className="overflow-hidden">
                    <CardBody className="pt-0">
                      <h3 className="text-center mt-4">
                        <Link to="/" className="logo logo-admin">
                          <img src={logo} height="80" alt="logo" />
                        </Link>
                      </h3>
                      <div className="p-3">
                        <h4 className="text-muted font-size-18 mb-3 text-center">
                          Activate Account
                        </h4>

                        {this.state.msg !== null ? (
                          <Alert color="danger">{this.state.msg}</Alert>
                        ) : (
                          <Alert color="info">Enter your Password!</Alert>
                        )}

                        {this.props.loginError && (
                          <Alert color="danger">{this.props.loginError}</Alert>
                        )}

                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={this.handleSubmit}
                        >
                          <label>Password</label>
                          <div className="input-group mb-3">
                            <input
                              className="form-control"
                              onChange={this.handleChange}
                              name="password"
                              type={this.state.passwordInputType}
                              placeholder="Enter password"
                              required
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                {this.state.passwordEyeIcon ? (
                                  <i
                                    className="fa fa-eye-slash"
                                    aria-hidden="true"
                                    role="button"
                                    onClick={() => {
                                      this.handleShowPassword(false);
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                    role="button"
                                    onClick={() => {
                                      this.handleShowPassword(true);
                                    }}
                                  ></i>
                                )}
                              </span>
                            </div>
                          </div>

                          <label>Confirm Password</label>
                          <div className="input-group mb-3">
                            <input
                              className="form-control"
                              onChange={this.handleChange}
                              name="password_confirmation"
                              type={this.state.passwordInputType}
                              placeholder="Enter confirm password"
                              required
                            />
                            <div className="input-group-append">
                              <span className="input-group-text">
                                {this.state.passwordEyeIcon ? (
                                  <i
                                    className="fa fa-eye-slash"
                                    aria-hidden="true"
                                    role="button"
                                    onClick={() => {
                                      this.handleShowPassword(false);
                                    }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-eye"
                                    aria-hidden="true"
                                    role="button"
                                    onClick={() => {
                                      this.handleShowPassword(true);
                                    }}
                                  ></i>
                                )}
                              </span>
                            </div>
                          </div>

                          <div className="form-group row">
                            <div className="col-12 text-right">
                              {this.props.loading ? (
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                >
                                  Loading ...
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Submit
                                </Button>
                              )}
                            </div>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { message, loginError, loading } = state.Forget;
  return { message, loginError, loading };
};

export default withRouter(
  connect(mapStatetoProps, { forgetUser })(emailVerify)
);
