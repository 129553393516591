import React from "react";
import { FormGroup, Label, FormText, Col, Input } from "reactstrap";
import Select from "react-select";
import Switch from "react-switch";

const timezoneOptions = [
  {
    options: [
      { label: "Etc/GMT+12", value: "Etc/GMT+12" },
      { label: "Etc/GMT+11", value: "Etc/GMT+11" },
      { label: "Pacific/Midway", value: "Pacific/Midway" },
      { label: "Pacific/Niue", value: "Pacific/Niue" },
      { label: "Pacific/Pago_Pago", value: "Pacific/Pago_Pago" },
      { label: "Pacific/Samoa", value: "Pacific/Samoa" },
      { label: "US/Samoa", value: "US/Samoa" },
      { label: "America/Adak", value: "America/Adak" },
      { label: "America/Atka", value: "America/Atka" },
      { label: "Etc/GMT+10", value: "Etc/GMT+10" },
      { label: "HST", value: "HST" },
      { label: "Pacific/Honolulu", value: "Pacific/Honolulu" },
      { label: "Pacific/Rarotonga", value: "Pacific/Rarotonga" },
      { label: "Pacific/Tahiti", value: "Pacific/Tahiti" },
      { label: "US/Aleutian", value: "US/Aleutian" },
      { label: "US/Hawaii", value: "US/Hawaii" },
      { label: "Pacific/Marquesas", value: "Pacific/Marquesas" },
      { label: "America/Anchorage", value: "America/Anchorage" },
      { label: "America/Juneau", value: "America/Juneau" },
      { label: "America/Metlakatla", value: "America/Metlakatla" },
      { label: "America/Nome", value: "America/Nome" },
      { label: "America/Sitka", value: "America/Sitka" },
      { label: "America/Yakutat", value: "America/Yakutat" },
      { label: "Etc/GMT+9", value: "Etc/GMT+9" },
      { label: "Pacific/Gambier", value: "Pacific/Gambier" },
      { label: "US/Alaska", value: "US/Alaska" },
      { label: "America/Dawson", value: "America/Dawson" },
      { label: "America/Ensenada", value: "America/Ensenada" },
      { label: "America/Los_Angeles", value: "America/Los_Angeles" },
      { label: "America/Santa_Isabel", value: "America/Santa_Isabel" },
      { label: "America/Tijuana", value: "America/Tijuana" },
      { label: "America/Vancouver", value: "America/Vancouver" },
      { label: "America/Whitehorse", value: "America/Whitehorse" },
      { label: "Canada/Pacific", value: "Canada/Pacific" },
      { label: "Canada/Yukon", value: "Canada/Yukon" },
      { label: "Etc/GMT+8", value: "Etc/GMT+8" },
      { label: "Mexico/BajaNorte", value: "Mexico/BajaNorte" },
      { label: "PST8PDT", value: "PST8PDT" },
      { label: "Pacific/Pitcairn", value: "Pacific/Pitcairn" },
      { label: "US/Pacific", value: "US/Pacific" },
      { label: "America/Boise", value: "America/Boise" },
      { label: "America/Cambridge_Bay", value: "America/Cambridge_Bay" },
      { label: "America/Chihuahua", value: "America/Chihuahua" },
      { label: "America/Creston", value: "America/Creston" },
      { label: "America/Dawson_Creek", value: "America/Dawson_Creek" },
      { label: "America/Denver", value: "America/Denver" },
      { label: "America/Edmonton", value: "America/Edmonton" },
      { label: "America/Fort_Nelson", value: "America/Fort_Nelson" },
      { label: "America/Hermosillo", value: "America/Hermosillo" },
      { label: "America/Inuvik", value: "America/Inuvik" },
      { label: "America/Mazatlan", value: "America/Mazatlan" },
      { label: "America/Ojinaga", value: "America/Ojinaga" },
      { label: "America/Phoenix", value: "America/Phoenix" },
      { label: "America/Shiprock", value: "America/Shiprock" },
      { label: "America/Yellowknife", value: "America/Yellowknife" },
      { label: "Canada/Mountain", value: "Canada/Mountain" },
      { label: "Etc/GMT+7", value: "Etc/GMT+7" },
      { label: "MST", value: "MST" },
      { label: " MST7MDT", value: "MST7MDT" },
      { label: " Mexico/BajaSur", value: "Mexico/BajaSur" },
      { label: " Navajo", value: "Navajo" },
      { label: " US/Arizona", value: "US/Arizona" },
      { label: " US/Mountain", value: "US/Mountain" },
      { label: " America/Bahia_Banderas", value: "America/Bahia_Banderas" },
      { label: " America/Belize", value: "America/Belize" },
      { label: " America/Chicago", value: "America/Chicago" },
      { label: " America/Costa_Rica", value: "America/Costa_Rica" },
      { label: " America/El_Salvador", value: "America/El_Salvador" },
      { label: " America/Guatemala", value: "America/Guatemala" },
      { label: " America/Indiana/Knox", value: "America/Indiana/Knox" },
      {
        label: " America/Indiana/Tell_City",
        value: "America/Indiana/Tell_City",
      },
      { label: " America/Knox_IN", value: "America/Knox_IN" },
      { label: " America/Managua", value: "America/Managua" },
      { label: " America/Matamoros", value: "America/Matamoros" },
      { label: " America/Menominee", value: "America/Menominee" },
      { label: " America/Merida", value: "America/Merida" },
      { label: " America/Mexico_City", value: "America/Mexico_City" },
      { label: " America/Monterrey", value: "America/Monterrey" },
      {
        label: " America/North_Dakota/Beulah",
        value: "America/North_Dakota/Beulah",
      },
      {
        label: " America/North_Dakota/Center",
        value: "America/North_Dakota/Center",
      },
      {
        label: " America/North_Dakota/New_Salem",
        value: "America/North_Dakota/New_Salem",
      },
      { label: " America/Rainy_River", value: "America/Rainy_River" },
      { label: " America/Rankin_Inlet", value: "America/Rankin_Inlet" },
      { label: " America/Regina", value: "America/Regina" },
      { label: " America/Resolute", value: "America/Resolute" },
      { label: " America/Swift_Current", value: "America/Swift_Current" },
      { label: " America/Tegucigalpa", value: "America/Tegucigalpa" },
      { label: " America/Winnipeg", value: "America/Winnipeg" },
      { label: " CST6CDT", value: "CST6CDT" },
      { label: " Canada/Central", value: "Canada/Central" },
      { label: " Canada/East-Saskatchewan", value: "Canada/East-Saskatchewan" },
      { label: " Canada/Saskatchewan", value: "Canada/Saskatchewan" },
      { label: " Chile/EasterIsland", value: "Chile/EasterIsland" },
      { label: " Etc/GMT+6", value: "Etc/GMT+6" },
      { label: " Mexico/General", value: "Mexico/General" },
      { label: " Pacific/Easter", value: "Pacific/Easter" },
      { label: " Pacific/Galapagos", value: "Pacific/Galapagos" },
      { label: " US/Central", value: "US/Central" },
      { label: " US/Indiana-Starke", value: "US/Indiana-Starke" },
      { label: " America/Atikokan", value: "America/Atikokan" },
      { label: " America/Bogota", value: "America/Bogota" },
      { label: " America/Cancun", value: "America/Cancun" },
      { label: " America/Cayman", value: "America/Cayman" },
      { label: " America/Coral_Harbour", value: "America/Coral_Harbour" },
      { label: " America/Detroit", value: "America/Detroit" },
      { label: " America/Eirunepe", value: "America/Eirunepe" },
      { label: " America/Fort_Wayne", value: "America/Fort_Wayne" },
      { label: " America/Guayaquil", value: "America/Guayaquil" },
      { label: " America/Havana", value: "America/Havana" },
      {
        label: " America/Indiana/Indianapolis",
        value: "America/Indiana/Indianapolis",
      },
      { label: " America/Indiana/Marengo", value: "America/Indiana/Marengo" },
      {
        label: " America/Indiana/Petersburg",
        value: "America/Indiana/Petersburg",
      },
      { label: " America/Indiana/Vevay", value: "America/Indiana/Vevay" },
      {
        label: " America/Indiana/Vincennes",
        value: "America/Indiana/Vincennes",
      },
      { label: " America/Indiana/Winamac", value: "America/Indiana/Winamac" },
      { label: " America/Indianapolis", value: "America/Indianapolis" },
      { label: " America/Iqaluit", value: "America/Iqaluit" },
      { label: " America/Jamaica", value: "America/Jamaica" },
      {
        label: " America/Kentucky/Louisville",
        value: "America/Kentucky/Louisville",
      },
      {
        label: " America/Kentucky/Monticello",
        value: "America/Kentucky/Monticello",
      },
      { label: " America/Lima", value: "America/Lima" },
      { label: " America/Louisville", value: "America/Louisville" },
      { label: " America/Montreal", value: "America/Montreal" },
      { label: " America/Nassau", value: "America/Nassau" },
      { label: " America/New_York", value: "America/New_York" },
      { label: " America/Nipigon", value: "America/Nipigon" },
      { label: " America/Panama", value: "America/Panama" },
      { label: " America/Pangnirtung", value: "America/Pangnirtung" },
      { label: " America/Port-au-Prince", value: "America/Port-au-Prince" },
      { label: " America/Porto_Acre", value: "America/Porto_Acre" },
      { label: " America/Rio_Branco", value: "America/Rio_Branco" },
      { label: " America/Thunder_Bay", value: "America/Thunder_Bay" },
      { label: " America/Toronto", value: "America/Toronto" },
      { label: " Brazil/Acre", value: "Brazil/Acre" },
      { label: " Canada/Eastern", value: "Canada/Eastern" },
      { label: " Cuba", value: "Cuba" },
      { label: " EST", value: "EST" },
      { label: " EST5EDT", value: "EST5EDT" },
      { label: " Etc/GMT+5", value: "Etc/GMT+5" },
      { label: " Jamaica", value: "Jamaica" },
      { label: " US/East-Indiana", value: "US/East-Indiana" },
      { label: " US/Eastern", value: "US/Eastern" },
      { label: " US/Michigan", value: "US/Michigan" },
      { label: " America/Anguilla", value: "America/Anguilla" },
      { label: " America/Antigua", value: "America/Antigua" },
      { label: " America/Aruba", value: "America/Aruba" },
      { label: " America/Asuncion", value: "America/Asuncion" },
      { label: " America/Barbados", value: "America/Barbados" },
      { label: " America/Blanc-Sablon", value: "America/Blanc-Sablon" },
      { label: " America/Boa_Vista", value: "America/Boa_Vista" },
      { label: " America/Campo_Grande", value: "America/Campo_Grande" },
      { label: " America/Caracas", value: "America/Caracas" },
      { label: " America/Cuiaba", value: "America/Cuiaba" },
      { label: " America/Curacao", value: "America/Curacao" },
      { label: " America/Dominica", value: "America/Dominica" },
      { label: " America/Glace_Bay", value: "America/Glace_Bay" },
      { label: " America/Goose_Bay", value: "America/Goose_Bay" },
      { label: " America/Grand_Turk", value: "America/Grand_Turk" },
      { label: " America/Grenada", value: "America/Grenada" },
      { label: " America/Guadeloupe", value: "America/Guadeloupe" },
      { label: " America/Guyana", value: "America/Guyana" },
      { label: " America/Halifax", value: "America/Halifax" },
      { label: " America/Kralendijk", value: "America/Kralendijk" },
      { label: " America/La_Paz", value: "America/La_Paz" },
      { label: " America/Lower_Princes", value: "America/Lower_Princes" },
      { label: " America/Manaus", value: "America/Manaus" },
      { label: " America/Marigot", value: "America/Marigot" },
      { label: " America/Martinique", value: "America/Martinique" },
      { label: " America/Moncton", value: "America/Moncton" },
      { label: " America/Montserrat", value: "America/Montserrat" },
      { label: " America/Port_of_Spain", value: "America/Port_of_Spain" },
      { label: " America/Porto_Velho", value: "America/Porto_Velho" },
      { label: " America/Puerto_Rico", value: "America/Puerto_Rico" },
      { label: " America/Santiago", value: "America/Santiago" },
      { label: " America/Santo_Domingo", value: "America/Santo_Domingo" },
      { label: " America/St_Barthelemy", value: "America/St_Barthelemy" },
      { label: " America/St_Kitts", value: "America/St_Kitts" },
      { label: " America/St_Lucia", value: "America/St_Lucia" },
      { label: " America/St_Thomas", value: "America/St_Thomas" },
      { label: " America/St_Vincent", value: "America/St_Vincent" },
      { label: " America/Thule", value: "America/Thule" },
      { label: " America/Tortola", value: "America/Tortola" },
      { label: " America/Virgin", value: "America/Virgin" },
      { label: " Atlantic/Bermuda", value: "Atlantic/Bermuda" },
      { label: " Brazil/West", value: "Brazil/West" },
      { label: " Canada/Atlantic", value: "Canada/Atlantic" },
      { label: " Chile/Continental", value: "Chile/Continental" },
      { label: " Etc/GMT+4", value: "Etc/GMT+4" },
      { label: " America/St_Johns", value: "America/St_Johns" },
      { label: " Canada/Newfoundland", value: "Canada/Newfoundland" },
      { label: " America/Araguaina", value: "America/Araguaina" },
      {
        label: " America/Argentina/Buenos_Aires",
        value: "America/Argentina/Buenos_Aires",
      },
      {
        label: " America/Argentina/Catamarca",
        value: "America/Argentina/Catamarca",
      },
      {
        label: " America/Argentina/ComodRivadavia",
        value: "America/Argentina/ComodRivadavia",
      },
      {
        label: " America/Argentina/Cordoba",
        value: "America/Argentina/Cordoba",
      },
      { label: " America/Argentina/Jujuy", value: "America/Argentina/Jujuy" },
      {
        label: " America/Argentina/La_Rioja",
        value: "America/Argentina/La_Rioja",
      },
      {
        label: " America/Argentina/Mendoza",
        value: "America/Argentina/Mendoza",
      },
      {
        label: " America/Argentina/Rio_Gallegos",
        value: "America/Argentina/Rio_Gallegos",
      },
      { label: " America/Argentina/Salta", value: "America/Argentina/Salta" },
      {
        label: " America/Argentina/San_Juan",
        value: "America/Argentina/San_Juan",
      },
      {
        label: " America/Argentina/San_Luis",
        value: "America/Argentina/San_Luis",
      },
      {
        label: " America/Argentina/Tucuman",
        value: "America/Argentina/Tucuman",
      },
      {
        label: " America/Argentina/Ushuaia",
        value: "America/Argentina/Ushuaia",
      },
      { label: " America/Bahia", value: "America/Bahia" },
      { label: " America/Belem", value: "America/Belem" },
      { label: " America/Buenos_Aires", value: "America/Buenos_Aires" },
      { label: " America/Catamarca", value: "America/Catamarca" },
      { label: " America/Cayenne", value: "America/Cayenne" },
      { label: " America/Cordoba", value: "America/Cordoba" },
      { label: " America/Fortaleza", value: "America/Fortaleza" },
      { label: " America/Godthab", value: "America/Godthab" },
      { label: " America/Jujuy", value: "America/Jujuy" },
      { label: " America/Maceio", value: "America/Maceio" },
      { label: " America/Mendoza", value: "America/Mendoza" },
      { label: " America/Miquelon", value: "America/Miquelon" },
      { label: " America/Montevideo", value: "America/Montevideo" },
      { label: " America/Paramaribo", value: "America/Paramaribo" },
      { label: " America/Punta_Arenas", value: "America/Punta_Arenas" },
      { label: " America/Recife", value: "America/Recife" },
      { label: " America/Rosario", value: "America/Rosario" },
      { label: " America/Santarem", value: "America/Santarem" },
      { label: " America/Sao_Paulo", value: "America/Sao_Paulo" },
      { label: " Antarctica/Palmer", value: "Antarctica/Palmer" },
      { label: " Antarctica/Rothera", value: "Antarctica/Rothera" },
      { label: " Atlantic/Stanley", value: "Atlantic/Stanley" },
      { label: " Brazil/East", value: "Brazil/East" },
      { label: " Etc/GMT+3", value: "Etc/GMT+3" },
      { label: " America/Noronha", value: "America/Noronha" },
      { label: " Atlantic/South_Georgia", value: "Atlantic/South_Georgia" },
      { label: " Brazil/DeNoronha", value: "Brazil/DeNoronha" },
      { label: " Etc/GMT+2", value: "Etc/GMT+2" },
      { label: " America/Scoresbysund", value: "America/Scoresbysund" },
      { label: " Atlantic/Azores", value: "Atlantic/Azores" },
      { label: " Atlantic/Cape_Verde", value: "Atlantic/Cape_Verde" },
      { label: " Etc/GMT+1", value: "Etc/GMT+1" },
      { label: " Africa/Abidjan", value: "Africa/Abidjan" },
      { label: " Africa/Accra", value: "Africa/Accra" },
      { label: " Africa/Bamako", value: "Africa/Bamako" },
      { label: " Africa/Banjul", value: "Africa/Banjul" },
      { label: " Africa/Bissau", value: "Africa/Bissau" },
      { label: " Africa/Casablanca", value: "Africa/Casablanca" },
      { label: " Africa/Conakry", value: "Africa/Conakry" },
      { label: " Africa/Dakar", value: "Africa/Dakar" },
      { label: " Africa/El_Aaiun", value: "Africa/El_Aaiun" },
      { label: " Africa/Freetown", value: "Africa/Freetown" },
      { label: " Africa/Lome", value: "Africa/Lome" },
      { label: " Africa/Monrovia", value: "Africa/Monrovia" },
      { label: " Africa/Nouakchott", value: "Africa/Nouakchott" },
      { label: " Africa/Ouagadougou", value: "Africa/Ouagadougou" },
      { label: " Africa/Sao_Tome", value: "Africa/Sao_Tome" },
      { label: " Africa/Timbuktu", value: "Africa/Timbuktu" },
      { label: " America/Danmarkshavn", value: "America/Danmarkshavn" },
      { label: " Antarctica/Troll", value: "Antarctica/Troll" },
      { label: " Atlantic/Canary", value: "Atlantic/Canary" },
      { label: " Atlantic/Faeroe", value: "Atlantic/Faeroe" },
      { label: " Atlantic/Faroe", value: "Atlantic/Faroe" },
      { label: " Atlantic/Madeira", value: "Atlantic/Madeira" },
      { label: " Atlantic/Reykjavik", value: "Atlantic/Reykjavik" },
      { label: " Atlantic/St_Helena", value: "Atlantic/St_Helena" },
      { label: " Eire", value: "Eire" },
      { label: " Etc/GMT", value: "Etc/GMT" },
      { label: " Etc/GMT+0", value: "Etc/GMT+0" },
      { label: " Etc/GMT-0", value: "Etc/GMT-0" },
      { label: " Etc/GMT0", value: "Etc/GMT0" },
      { label: " Etc/Greenwich", value: "Etc/Greenwich" },
      { label: " Etc/UCT", value: "Etc/UCT" },
      { label: " Etc/UTC", value: "Etc/UTC" },
      { label: " Etc/Universal", value: "Etc/Universal" },
      { label: " Etc/Zulu", value: "Etc/Zulu" },
      { label: " Europe/Belfast", value: "Europe/Belfast" },
      { label: " Europe/Dublin", value: "Europe/Dublin" },
      { label: " Europe/Guernsey", value: "Europe/Guernsey" },
      { label: " Europe/Isle_of_Man", value: "Europe/Isle_of_Man" },
      { label: " Europe/Jersey", value: "Europe/Jersey" },
      { label: " Europe/Lisbon", value: "Europe/Lisbon" },
      { label: " Europe/London", value: "Europe/London" },
      { label: " GB", value: "GB" },
      { label: " GB-Eire", value: "GB-Eire" },
      { label: " GMT", value: "GMT" },
      { label: " GMT+0", value: "GMT+0" },
      { label: " GMT-0", value: "GMT-0" },
      { label: " GMT0", value: "GMT0" },
      { label: " Greenwich", value: "Greenwich" },
      { label: " Iceland", value: "Iceland" },
      { label: " Portugal", value: "Portugal" },
      { label: " UCT", value: "UCT" },
      { label: " UTC", value: "UTC" },
      { label: " Universal", value: "Universal" },
      { label: " WET", value: "WET" },
      { label: " Zulu", value: "Zulu" },
      { label: " Africa/Algiers", value: "Africa/Algiers" },
      { label: " Africa/Bangui", value: "Africa/Bangui" },
      { label: " Africa/Brazzaville", value: "Africa/Brazzaville" },
      { label: " Africa/Ceuta", value: "Africa/Ceuta" },
      { label: " Africa/Douala", value: "Africa/Douala" },
      { label: " Africa/Kinshasa", value: "Africa/Kinshasa" },
      { label: " Africa/Lagos", value: "Africa/Lagos" },
      { label: " Africa/Libreville", value: "Africa/Libreville" },
      { label: " Africa/Luanda", value: "Africa/Luanda" },
      { label: " Africa/Malabo", value: "Africa/Malabo" },
      { label: " Africa/Ndjamena", value: "Africa/Ndjamena" },
      { label: " Africa/Niamey", value: "Africa/Niamey" },
      { label: " Africa/Porto-Novo", value: "Africa/Porto-Novo" },
      { label: " Africa/Tunis", value: "Africa/Tunis" },
      { label: " Africa/Windhoek", value: "Africa/Windhoek" },
      { label: " Arctic/Longyearbyen", value: "Arctic/Longyearbyen" },
      { label: " Atlantic/Jan_Mayen", value: "Atlantic/Jan_Mayen" },
      { label: " CET", value: "CET" },
      { label: " Etc/GMT-1", value: "Etc/GMT-1" },
      { label: " Europe/Amsterdam", value: "Europe/Amsterdam" },
      { label: " Europe/Andorra", value: "Europe/Andorra" },
      { label: " Europe/Belgrade", value: "Europe/Belgrade" },
      { label: " Europe/Berlin", value: "Europe/Berlin" },
      { label: " Europe/Bratislava", value: "Europe/Bratislava" },
      { label: " Europe/Brussels", value: "Europe/Brussels" },
      { label: " Europe/Budapest", value: "Europe/Budapest" },
      { label: " Europe/Busingen", value: "Europe/Busingen" },
      { label: " Europe/Copenhagen", value: "Europe/Copenhagen" },
      { label: " Europe/Gibraltar", value: "Europe/Gibraltar" },
      { label: " Europe/Ljubljana", value: "Europe/Ljubljana" },
      { label: " Europe/Luxembourg", value: "Europe/Luxembourg" },
      { label: " Europe/Madrid", value: "Europe/Madrid" },
      { label: " Europe/Malta", value: "Europe/Malta" },
      { label: " Europe/Monaco", value: "Europe/Monaco" },
      { label: " Europe/Oslo", value: "Europe/Oslo" },
      { label: " Europe/Paris", value: "Europe/Paris" },
      { label: " Europe/Podgorica", value: "Europe/Podgorica" },
      { label: " Europe/Prague", value: "Europe/Prague" },
      { label: " Europe/Rome", value: "Europe/Rome" },
      { label: " Europe/San_Marino", value: "Europe/San_Marino" },
      { label: " Europe/Sarajevo", value: "Europe/Sarajevo" },
      { label: " Europe/Skopje", value: "Europe/Skopje" },
      { label: " Europe/Stockholm", value: "Europe/Stockholm" },
      { label: " Europe/Tirane", value: "Europe/Tirane" },
      { label: " Europe/Vaduz", value: "Europe/Vaduz" },
      { label: " Europe/Vatican", value: "Europe/Vatican" },
      { label: " Europe/Vienna", value: "Europe/Vienna" },
      { label: " Europe/Warsaw", value: "Europe/Warsaw" },
      { label: " Europe/Zagreb", value: "Europe/Zagreb" },
      { label: " Europe/Zurich", value: "Europe/Zurich" },
      { label: " MET", value: "MET" },
      { label: " Poland", value: "Poland" },
      { label: " Africa/Blantyre", value: "Africa/Blantyre" },
      { label: " Africa/Bujumbura", value: "Africa/Bujumbura" },
      { label: " Africa/Cairo", value: "Africa/Cairo" },
      { label: " Africa/Gaborone", value: "Africa/Gaborone" },
      { label: " Africa/Harare", value: "Africa/Harare" },
      { label: " Africa/Johannesburg", value: "Africa/Johannesburg" },
      { label: " Africa/Kigali", value: "Africa/Kigali" },
      { label: " Africa/Lubumbashi", value: "Africa/Lubumbashi" },
      { label: " Africa/Lusaka", value: "Africa/Lusaka" },
      { label: " Africa/Maputo", value: "Africa/Maputo" },
      { label: " Africa/Maseru", value: "Africa/Maseru" },
      { label: " Africa/Mbabane", value: "Africa/Mbabane" },
      { label: " Africa/Tripoli", value: "Africa/Tripoli" },
      { label: " Asia/Amman", value: "Asia/Amman" },
      { label: " Asia/Beirut", value: "Asia/Beirut" },
      { label: " Asia/Damascus", value: "Asia/Damascus" },
      { label: " Asia/Gaza", value: "Asia/Gaza" },
      { label: " Asia/Hebron", value: "Asia/Hebron" },
      { label: " Asia/Jerusalem", value: "Asia/Jerusalem" },
      { label: " Asia/Nicosia", value: "Asia/Nicosia" },
      { label: " Asia/Tel_Aviv", value: "Asia/Tel_Aviv" },
      { label: " EET", value: "EET" },
      { label: " Egypt", value: "Egypt" },
      { label: " Etc/GMT-2", value: "Etc/GMT-2" },
      { label: " Europe/Athens", value: "Europe/Athens" },
      { label: " Europe/Bucharest", value: "Europe/Bucharest" },
      { label: " Europe/Chisinau", value: "Europe/Chisinau" },
      { label: " Europe/Helsinki", value: "Europe/Helsinki" },
      { label: " Europe/Kaliningrad", value: "Europe/Kaliningrad" },
      { label: " Europe/Kiev", value: "Europe/Kiev" },
      { label: " Europe/Mariehamn", value: "Europe/Mariehamn" },
      { label: " Europe/Nicosia", value: "Europe/Nicosia" },
      { label: " Europe/Riga", value: "Europe/Riga" },
      { label: " Europe/Sofia", value: "Europe/Sofia" },
      { label: " Europe/Tallinn", value: "Europe/Tallinn" },
      { label: " Europe/Tiraspol", value: "Europe/Tiraspol" },
      { label: " Europe/Uzhgorod", value: "Europe/Uzhgorod" },
      { label: " Europe/Vilnius", value: "Europe/Vilnius" },
      { label: " Europe/Zaporozhye", value: "Europe/Zaporozhye" },
      { label: " Israel", value: "Israel" },
      { label: " Libya", value: "Libya" },
      { label: " Africa/Addis_Ababa", value: "Africa/Addis_Ababa" },
      { label: " Africa/Asmara", value: "Africa/Asmara" },
      { label: " Africa/Asmera", value: "Africa/Asmera" },
      { label: " Africa/Dar_es_Salaam", value: "Africa/Dar_es_Salaam" },
      { label: " Africa/Djibouti", value: "Africa/Djibouti" },
      { label: " Africa/Juba", value: "Africa/Juba" },
      { label: " Africa/Kampala", value: "Africa/Kampala" },
      { label: " Africa/Khartoum", value: "Africa/Khartoum" },
      { label: " Africa/Mogadishu", value: "Africa/Mogadishu" },
      { label: " Africa/Nairobi", value: "Africa/Nairobi" },
      { label: " Antarctica/Syowa", value: "Antarctica/Syowa" },
      { label: " Asia/Aden", value: "Asia/Aden" },
      { label: " Asia/Baghdad", value: "Asia/Baghdad" },
      { label: " Asia/Bahrain", value: "Asia/Bahrain" },
      { label: " Asia/Famagusta", value: "Asia/Famagusta" },
      { label: " Asia/Istanbul", value: "Asia/Istanbul" },
      { label: " Asia/Kuwait", value: "Asia/Kuwait" },
      { label: " Asia/Qatar", value: "Asia/Qatar" },
      { label: " Asia/Riyadh", value: "Asia/Riyadh" },
      { label: " Etc/GMT-3", value: "Etc/GMT-3" },
      { label: " Europe/Istanbul", value: "Europe/Istanbul" },
      { label: " Europe/Kirov", value: "Europe/Kirov" },
      { label: " Europe/Minsk", value: "Europe/Minsk" },
      { label: " Europe/Moscow", value: "Europe/Moscow" },
      { label: " Europe/Simferopol", value: "Europe/Simferopol" },
      { label: " Europe/Volgograd", value: "Europe/Volgograd" },
      { label: " Indian/Antananarivo", value: "Indian/Antananarivo" },
      { label: " Indian/Comoro", value: "Indian/Comoro" },
      { label: " Indian/Mayotte", value: "Indian/Mayotte" },
      { label: " Turkey", value: "Turkey" },
      { label: " W-SU", value: "W-SU" },
      { label: " Asia/Tehran", value: "Asia/Tehran" },
      { label: " Iran", value: "Iran" },
      { label: " Asia/Baku", value: "Asia/Baku" },
      { label: " Asia/Dubai", value: "Asia/Dubai" },
      { label: " Asia/Muscat", value: "Asia/Muscat" },
      { label: " Asia/Tbilisi", value: "Asia/Tbilisi" },
      { label: " Asia/Yerevan", value: "Asia/Yerevan" },
      { label: " Etc/GMT-4", value: "Etc/GMT-4" },
      { label: " Europe/Astrakhan", value: "Europe/Astrakhan" },
      { label: " Europe/Samara", value: "Europe/Samara" },
      { label: " Europe/Saratov", value: "Europe/Saratov" },
      { label: " Europe/Ulyanovsk", value: "Europe/Ulyanovsk" },
      { label: " Indian/Mahe", value: "Indian/Mahe" },
      { label: " Indian/Mauritius", value: "Indian/Mauritius" },
      { label: " Indian/Reunion", value: "Indian/Reunion" },
      { label: " Asia/Kabul", value: "Asia/Kabul" },
      { label: " Antarctica/Mawson", value: "Antarctica/Mawson" },
      { label: " Asia/Aqtau", value: "Asia/Aqtau" },
      { label: " Asia/Aqtobe", value: "Asia/Aqtobe" },
      { label: " Asia/Ashgabat", value: "Asia/Ashgabat" },
      { label: " Asia/Ashkhabad", value: "Asia/Ashkhabad" },
      { label: " Asia/Atyrau", value: "Asia/Atyrau" },
      { label: " Asia/Dushanbe", value: "Asia/Dushanbe" },
      { label: " Asia/Karachi", value: "Asia/Karachi" },
      { label: " Asia/Oral", value: "Asia/Oral" },
      { label: " Asia/Samarkand", value: "Asia/Samarkand" },
      { label: " Asia/Tashkent", value: "Asia/Tashkent" },
      { label: " Asia/Yekaterinburg", value: "Asia/Yekaterinburg" },
      { label: " Etc/GMT-5", value: "Etc/GMT-5" },
      { label: " Indian/Kerguelen", value: "Indian/Kerguelen" },
      { label: " Indian/Maldives", value: "Indian/Maldives" },
      { label: " Asia/Calcutta", value: "Asia/Calcutta" },
      { label: " Asia/Colombo", value: "Asia/Colombo" },
      { label: " Asia/Kolkata", value: "Asia/Kolkata" },
      { label: " Asia/Kathmandu", value: "Asia/Kathmandu" },
      { label: " Asia/Katmandu", value: "Asia/Katmandu" },
      { label: " Antarctica/Vostok", value: "Antarctica/Vostok" },
      { label: " Asia/Almaty", value: "Asia/Almaty" },
      { label: " Asia/Bishkek", value: "Asia/Bishkek" },
      { label: " Asia/Dacca", value: "Asia/Dacca" },
      { label: " Asia/Dhaka", value: "Asia/Dhaka" },
      { label: " Asia/Kashgar", value: "Asia/Kashgar" },
      { label: " Asia/Omsk", value: "Asia/Omsk" },
      { label: " Asia/Qyzylorda", value: "Asia/Qyzylorda" },
      { label: " Asia/Thimbu", value: "Asia/Thimbu" },
      { label: " Asia/Thimphu", value: "Asia/Thimphu" },
      { label: " Asia/Urumqi", value: "Asia/Urumqi" },
      { label: " Etc/GMT-6", value: "Etc/GMT-6" },
      { label: " Indian/Chagos", value: "Indian/Chagos" },
      { label: " Asia/Rangoon", value: "Asia/Rangoon" },
      { label: " Asia/Yangon", value: "Asia/Yangon" },
      { label: " Indian/Cocos", value: "Indian/Cocos" },
      { label: " Antarctica/Davis", value: "Antarctica/Davis" },
      { label: " Asia/Bangkok", value: "Asia/Bangkok" },
      { label: " Asia/Barnaul", value: "Asia/Barnaul" },
      { label: " Asia/Hanoi", value: "Asia/Hanoi" },
      { label: " Asia/Ho_Chi_Minh", value: "Asia/Ho_Chi_Minh" },
      { label: " Asia/Hovd", value: "Asia/Hovd" },
      { label: " Asia/Jakarta", value: "Asia/Jakarta" },
      { label: " Asia/Krasnoyarsk", value: "Asia/Krasnoyarsk" },
      { label: " Asia/Novokuznetsk", value: "Asia/Novokuznetsk" },
      { label: " Asia/Novosibirsk", value: "Asia/Novosibirsk" },
      { label: " Asia/Phnom_Penh", value: "Asia/Phnom_Penh" },
      { label: " Asia/Pontianak", value: "Asia/Pontianak" },
      { label: " Asia/Saigon", value: "Asia/Saigon" },
      { label: " Asia/Tomsk", value: "Asia/Tomsk" },
      { label: " Asia/Vientiane", value: "Asia/Vientiane" },
      { label: " Etc/GMT-7", value: "Etc/GMT-7" },
      { label: " Indian/Christmas", value: "Indian/Christmas" },
      { label: " Asia/Brunei", value: "Asia/Brunei" },
      { label: " Asia/Choibalsan", value: "Asia/Choibalsan" },
      { label: " Asia/Chongqing", value: "Asia/Chongqing" },
      { label: " Asia/Chungking", value: "Asia/Chungking" },
      { label: " Asia/Harbin", value: "Asia/Harbin" },
      { label: " Asia/Hong_Kong", value: "Asia/Hong_Kong" },
      { label: " Asia/Irkutsk", value: "Asia/Irkutsk" },
      { label: " Asia/Kuala_Lumpur", value: "Asia/Kuala_Lumpur" },
      { label: " Asia/Kuching", value: "Asia/Kuching" },
      { label: " Asia/Macao", value: "Asia/Macao" },
      { label: " Asia/Macau", value: "Asia/Macau" },
      { label: " Asia/Makassar", value: "Asia/Makassar" },
      { label: " Asia/Manila", value: "Asia/Manila" },
      { label: " Asia/Shanghai", value: "Asia/Shanghai" },
      { label: " Asia/Singapore", value: "Asia/Singapore" },
      { label: " Asia/Taipei", value: "Asia/Taipei" },
      { label: " Asia/Ujung_Pandang", value: "Asia/Ujung_Pandang" },
      { label: " Asia/Ulaanbaatar", value: "Asia/Ulaanbaatar" },
      { label: " Asia/Ulan_Bator", value: "Asia/Ulan_Bator" },
      { label: " Australia/Perth", value: "Australia/Perth" },
      { label: " Australia/West", value: "Australia/West" },
      { label: " Etc/GMT-8", value: "Etc/GMT-8" },
      { label: " Hongkong", value: "Hongkong" },
      { label: " PRC", value: "PRC" },
      { label: " ROC", value: "ROC" },
      { label: " Singapore", value: "Singapore" },
      { label: " Asia/Pyongyang", value: "Asia/Pyongyang" },
      { label: " Australia/Eucla", value: "Australia/Eucla" },
      { label: " Asia/Chita", value: "Asia/Chita" },
      { label: " Asia/Dili", value: "Asia/Dili" },
      { label: " Asia/Jayapura", value: "Asia/Jayapura" },
      { label: " Asia/Khandyga", value: "Asia/Khandyga" },
      { label: " Asia/Seoul", value: "Asia/Seoul" },
      { label: " Asia/Tokyo", value: "Asia/Tokyo" },
      { label: " Asia/Yakutsk", value: "Asia/Yakutsk" },
      { label: " Etc/GMT-9", value: "Etc/GMT-9" },
      { label: " Japan", value: "Japan" },
      { label: " Pacific/Palau", value: "Pacific/Palau" },
      { label: " ROK", value: "ROK" },
      { label: " Australia/Adelaide", value: "Australia/Adelaide" },
      { label: " Australia/Broken_Hill", value: "Australia/Broken_Hill" },
      { label: " Australia/Darwin", value: "Australia/Darwin" },
      { label: " Australia/North", value: "Australia/North" },
      { label: " Australia/South", value: "Australia/South" },
      { label: " Australia/Yancowinna", value: "Australia/Yancowinna" },
      {
        label: " Antarctica/DumontDUrville",
        value: "Antarctica/DumontDUrville",
      },
      { label: " Asia/Ust-Nera", value: "Asia/Ust-Nera" },
      { label: " Asia/Vladivostok", value: "Asia/Vladivostok" },
      { label: " Australia/ACT", value: "Australia/ACT" },
      { label: " Australia/Brisbane", value: "Australia/Brisbane" },
      { label: " Australia/Canberra", value: "Australia/Canberra" },
      { label: " Australia/Currie", value: "Australia/Currie" },
      { label: " Australia/Hobart", value: "Australia/Hobart" },
      { label: " Australia/Lindeman", value: "Australia/Lindeman" },
      { label: " Australia/Melbourne", value: "Australia/Melbourne" },
      { label: " Australia/NSW", value: "Australia/NSW" },
      { label: " Australia/Queensland", value: "Australia/Queensland" },
      { label: " Australia/Sydney", value: "Australia/Sydney" },
      { label: " Australia/Tasmania", value: "Australia/Tasmania" },
      { label: " Australia/Victoria", value: "Australia/Victoria" },
      { label: " Etc/GMT-10", value: "Etc/GMT-10" },
      { label: " Pacific/Chuuk", value: "Pacific/Chuuk" },
      { label: " Pacific/Guam", value: "Pacific/Guam" },
      { label: " Pacific/Port_Moresby", value: "Pacific/Port_Moresby" },
      { label: " Pacific/Saipan", value: "Pacific/Saipan" },
      { label: " Pacific/Truk", value: "Pacific/Truk" },
      { label: " Pacific/Yap", value: "Pacific/Yap" },
      { label: " Australia/LHI", value: "Australia/LHI" },
      { label: " Australia/Lord_Howe", value: "Australia/Lord_Howe" },
      { label: " Antarctica/Casey", value: "Antarctica/Casey" },
      { label: " Antarctica/Macquarie", value: "Antarctica/Macquarie" },
      { label: " Asia/Magadan", value: "Asia/Magadan" },
      { label: " Asia/Sakhalin", value: "Asia/Sakhalin" },
      { label: " Asia/Srednekolymsk", value: "Asia/Srednekolymsk" },
      { label: " Etc/GMT-11", value: "Etc/GMT-11" },
      { label: " Pacific/Bougainville", value: "Pacific/Bougainville" },
      { label: " Pacific/Efate", value: "Pacific/Efate" },
      { label: " Pacific/Guadalcanal", value: "Pacific/Guadalcanal" },
      { label: " Pacific/Kosrae", value: "Pacific/Kosrae" },
      { label: " Pacific/Norfolk", value: "Pacific/Norfolk" },
      { label: " Pacific/Noumea", value: "Pacific/Noumea" },
      { label: " Pacific/Pohnpei", value: "Pacific/Pohnpei" },
      { label: " Pacific/Ponape", value: "Pacific/Ponape" },
      { label: " Antarctica/McMurdo", value: "Antarctica/McMurdo" },
      { label: " Antarctica/South_Pole", value: "Antarctica/South_Pole" },
      { label: " Asia/Anadyr", value: "Asia/Anadyr" },
      { label: " Asia/Kamchatka", value: "Asia/Kamchatka" },
      { label: " Etc/GMT-12", value: "Etc/GMT-12" },
      { label: " Kwajalein", value: "Kwajalein" },
      { label: " NZ", value: "NZ" },
      { label: " Pacific/Auckland", value: "Pacific/Auckland" },
      { label: " Pacific/Fiji", value: "Pacific/Fiji" },
      { label: " Pacific/Funafuti", value: "Pacific/Funafuti" },
      { label: " Pacific/Kwajalein", value: "Pacific/Kwajalein" },
      { label: " Pacific/Majuro", value: "Pacific/Majuro" },
      { label: " Pacific/Nauru", value: "Pacific/Nauru" },
      { label: " Pacific/Tarawa", value: "Pacific/Tarawa" },
      { label: " Pacific/Wake", value: "Pacific/Wake" },
      { label: " Pacific/Wallis", value: "Pacific/Wallis" },
      { label: " NZ-CHAT", value: "NZ-CHAT" },
      { label: " Pacific/Chatham", value: "Pacific/Chatham" },
      { label: " Etc/GMT-13", value: "Etc/GMT-13" },
      { label: " Pacific/Apia", value: "Pacific/Apia" },
      { label: " Pacific/Enderbury", value: "Pacific/Enderbury" },
      { label: " Pacific/Fakaofo", value: "Pacific/Fakaofo" },
      { label: " Pacific/Tongatapu", value: "Pacific/Tongatapu" },
      { label: " Etc/GMT-14", value: "Etc/GMT-14" },
      { label: " Pacific/Kiritimati", value: "Pacific/Kiritimati" },
    ],
  },
];

const disabledStyle = {
  color: "gray",
  backgroundColor: "hsl(0,0%,95%)",
  cursor: "not-allowed",
};
const fontSize = {
  fontSize: "15px",
};

export const SetDateAndTime = ({
  handleDateAndTime,
  setDateAndTimeData,
  handleSelectGroup,
  errorState
}) => {
  const dateLength = Object.keys(setDateAndTimeData).length !== 0;
  return (
    <>
    {!dateLength==0 && 
      <FormGroup row>
        <Col md={10} style={fontSize}>
          <Label className="mb-0">Automatic Date & Time</Label>
          <FormText className="mt-0">Use network-provided time</FormText>
        </Col>

        <Switch
          height={22}
          width={45}
          checked={setDateAndTimeData?.automaticDateTime}
          onChange={(checked) =>
            handleDateAndTime("automaticDateTime", checked)
          }
        />
      </FormGroup>
}

{!dateLength==0 &&
      <FormGroup row>
        <Col md={10} style={fontSize}>
          <Label className="mb-0">Automatic Time Zone</Label>
          <FormText className="mt-0">Use network-provided time zone</FormText>
        </Col>

        <Switch
          height={22}
          width={45}
          checked={setDateAndTimeData?.automaticTimezone}
          onChange={(checked) =>
            handleDateAndTime("automaticTimezone", checked)
          }
        />
      </FormGroup>
}

{!dateLength==0 &&
      <FormGroup>
        <Label style={fontSize}>Set Date</Label>
        <Input
          type="date"
          onChange={(e) => handleDateAndTime("date", e.target.value)}
          value={setDateAndTimeData?.automaticDateTime ? "" : setDateAndTimeData.date}
          disabled={setDateAndTimeData.automaticDateTime ? true : false}
          style={setDateAndTimeData.automaticDateTime ? disabledStyle : {}}
        />
        {!setDateAndTimeData.automaticDateTime && errorState?.date && <span style={{color:'red'}}>Select Date Error</span>}
      </FormGroup>
}
{!dateLength==0 &&
      <FormGroup>
        <Label style={fontSize}>Set Time</Label>
        <Input
          type="time"
          onChange={(e) => handleDateAndTime("time", e.target.value)}
          value={ setDateAndTimeData?.automaticDateTime ? "" : setDateAndTimeData.time}
          disabled={setDateAndTimeData.automaticDateTime ? true : false}
          style={setDateAndTimeData.automaticDateTime ? disabledStyle : {}}
        />
        { !setDateAndTimeData.automaticDateTime && errorState?.time && <span style={{color:'red'}}>Select Time Error</span>}
      </FormGroup>
}
{!dateLength==0 &&
      <FormGroup>
        <Label style={fontSize}>Select Time Zone</Label>
        <Select
        value={ setDateAndTimeData?.automaticTimezone ? "": setDateAndTimeData.timeZone}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 0,
              position: "relative",
            }),
          }}
          className="select2"
          autoComplete="nope"
          options={timezoneOptions}
          onChange={(e) => {
            handleSelectGroup(e);
            handleDateAndTime("timeZone", e.value);
          }}
          isDisabled={setDateAndTimeData.automaticTimezone ? true : false}
        />
        { !setDateAndTimeData.automaticTimezone && errorState?.timeZone && <span style={{color:'red'}}>Select TimeZone Error</span>}
      </FormGroup>
}
{!dateLength==0 &&
      <FormGroup row>
        <Col md={10} style={fontSize}>
          <Label className="mb-0">Use 24-hours Format</Label>
          <FormText className="mt-0">
            {setDateAndTimeData.use24HourFormat ? "13:00" : "1:00 PM"}
          </FormText>
        </Col>

        <Switch
          height={22}
          width={45}
          checked={setDateAndTimeData?.use24HourFormat}
          onChange={(checked) => handleDateAndTime("use24HourFormat", checked)}
        />
      </FormGroup>
}
    </>
  );
};
