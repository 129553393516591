import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getUploadFiles(id) {
  return axios.get(`/api/auth/uploadVideoShow/${id}`, getHeader());
}

export function deleteApk(id) {
  return axios.get(`/api/auth/removeFile/${id}`, getHeader());
}
