import React, { Component } from "react";
import { Polar } from "react-chartjs-2";

class PolarChart extends Component {
  render() {
    const data = {
      datasets: [
        {
          data: this.props.reports.mserviceVersions
            ? this.props.reports.mserviceVersions.map((item) => {
                return item.totalVersion;
              })
            : [],
          backgroundColor: [
            "#E32636",
            "#FFBF00",
            "#A4C639",
            "#FBCEB1",
            "#7FFFD4",
            "#4B5320",
            "#3B444B",
            "#E9D66B",
            "#B2BEB5",
            "#87A96B",
            "#FF9966",
            "#6D351A",
            "#007FFF",
            "#89CFF0",
            "#A1CAF1",
            "#F4C2C2",
            "#FFD12A",
            "#848482",
            "#98777B",
            "#F5F5DC",
            "#318CE7",
            "#FAF0BE",
            "#0000FF",
            "#DE5D83",
            "#79443B",
            "#CC0000",
            "#B5A642",
            "#66FF00",
            "#BF94E4",
            "#C32148",
            "#FF007F",
            "#08E8DE",
            "#D19FE8",
            "#004225",
            "#CD7F32",
            "#FFC1CC",
            "#E7FEFF",
            "#BD33A4",
            "#702963",
            "#536878",
            "#006B3C",
            "#ED872D",
            "#E34234",
            "#D2691E",
            "#E4D00A",
            "#FBCCE7",
            "#00FF6F",
            "#0047AB",
            "#9BDDFF",
            "#8C92AC",
            "#FF7F50",
            "#08457E",
            "#986960",
            "#CD5B45",
            "#008B8B",
            "#B8860B",
            "#013220",
            "#556B2F",
            "#1560BD",
          ],
          label: "My dataset", // for legend
          hoverBorderColor: "#fff",
        },
      ],
      labels: this.props.reports.mserviceVersions
        ? this.props.reports.mserviceVersions.map((item) => {
            return item.version_number;
          })
        : [],
    };

    return (
      <React.Fragment>
        <Polar width={503} height={238} data={data} />
      </React.Fragment>
    );
  }
}

export default PolarChart;
