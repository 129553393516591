import React from "react";
import { getCurrentUser } from "../../services/authService";
import { Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

const MiniCard = ({ reports }) => {
  return (
    <>
      <Col xl="3" md="6">
        <Card className="mini-stat bg-primary">
          <CardBody className="mini-stat-img">
            <div className="mini-stat-icon">
              <Link style={{ color: "#FFFFFF" }} to="/device">
                <i className={"mdi " + "mdi-devices" + " float-right"}></i>
              </Link>
            </div>

            <div className="text-white">
              <Link style={{ color: "#FFFFFF" }} to="/device">
                <h6 className="text-uppercase mb-3 font-size-16">
                  TOTAL DEVICES
                </h6>
              </Link>

              <h2 className="mb-4">
                {reports.totalDevices ? reports.totalDevices : 0}
              </h2>
              <span className="ml-2"></span>
            </div>
          </CardBody>
        </Card>
      </Col>

      {(getCurrentUser().role_id === 1) | (getCurrentUser().role_id === 3) ? (
        <Col xl="3" md="6">
          <Card className="mini-stat bg-primary">
            <CardBody className="mini-stat-img">
              <div className="mini-stat-icon">
                <Link
                  style={{ color: "#FFFFFF" }}
                  to={{
                    pathname: "/device",
                    activeDevices: reports.activeDevices
                      ? reports.activeDevices
                      : "",
                  }}
                >
                  <i
                    className={"mdi " + "mdi-briefcase-check" + " float-right"}
                  ></i>
                </Link>
              </div>
              <div className="text-white">
                <Link
                  style={{ color: "#FFFFFF" }}
                  to={{
                    pathname: "/device",
                    activeDevices: reports.activeDevices
                      ? reports.activeDevices
                      : "",
                  }}
                >
                  <h6 className="text-uppercase mb-3 font-size-16">
                    Total Activated Devices
                  </h6>
                </Link>

                <h2 className="mb-4">
                  {reports.activeDevices ? reports.activeDevices.length : 0}
                </h2>
                <span className="ml-2"></span>
              </div>
            </CardBody>
          </Card>
        </Col>
      ) : (
        ""
      )}

      <Col xl="3" md="6">
        <Card className="mini-stat bg-primary">
          <CardBody className="mini-stat-img">
            <div className="mini-stat-icon">
              <Link
                style={{ color: "#FFFFFF" }}
                to={{
                  pathname: "/device",
                  onlineDevices: reports.onlineDevices
                    ? reports.onlineDevices
                    : "",
                }}
              >
                <i className={"ti-world" + " float-right"}></i>
              </Link>
            </div>
            <div className="text-white">
              <Link
                style={{ color: "#FFFFFF" }}
                to={{
                  pathname: "/device",
                  onlineDevices: reports.onlineDevices
                    ? reports.onlineDevices
                    : "",
                }}
              >
                <h6 className="text-uppercase mb-3 font-size-16">
                  Total Online Devices
                </h6>
              </Link>

              <h2 className="mb-4">
                {reports.onlineDevices ? reports.onlineDevices.length : 0}
              </h2>
              <span className="ml-2"></span>
            </div>
          </CardBody>
        </Card>
      </Col>

      {(getCurrentUser().role_id === 1) | (getCurrentUser().role_id === 3) ? (
        <Col xl="3" md="6">
          <Card className="mini-stat bg-primary">
            <CardBody className="mini-stat-img">
              <div className="mini-stat-icon">
                <Link
                  style={{ color: "#FFFFFF" }}
                  to={{
                    pathname: "/device",
                    deviceLast48Hours: reports.deviceLast48Hours
                      ? reports.deviceLast48Hours
                      : "",
                  }}
                >
                  <i className={"mdi " + "mdi-devices" + " float-right"}></i>
                </Link>
              </div>
              <div className="text-white">
                <Link
                  style={{ color: "#FFFFFF" }}
                  to={{
                    pathname: "/device",
                    deviceLast48Hours: reports.deviceLast48Hours
                      ? reports.deviceLast48Hours
                      : "",
                  }}
                >
                  <h6 className="text-uppercase mb-3 font-size-16">
                    New Created Devices
                  </h6>
                </Link>

                <h2 className="mb-4">
                  {reports.deviceLast48Hours
                    ? reports.deviceLast48Hours.length
                    : 0}
                </h2>
                <span className="ml-2">Last 48 Hours</span>
              </div>
            </CardBody>
          </Card>
        </Col>
      ) : (
        ""
      )}
    </>
  );
};

export default MiniCard;
