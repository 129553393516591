import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { editAdmin, updateAdmin } from "./redux/adminApi";
import { FormGroup, Label, Button } from "reactstrap";
import { mimoLoader } from "../../store/actions";
import { useDispatch } from "react-redux";

const EditAdmin = ({
  adminId,
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [adminData, setAdminData] = useState({
    name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const { data: response } = await editAdmin(adminId);
        setAdminData(response.data);
      } catch (ex) {
        console.error(ex);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    dispatch(mimoLoader(true));
    try {
      const { data: response } = await updateAdmin(adminData);

      toggleModal();
      setSuccessAlert(true);
      setDescription(response.message);
    } catch (ex) {
      setErrorAlert(true);
      setDescription(ex.response.message);
    }
    dispatch(mimoLoader(false));
  };

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        <AvField
          name="name"
          label="Name"
          placeholder="Type Something"
          type="text"
          value={adminData.name}
          onChange={handleChange}
          errorMessage="Enter Name"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="email"
          label="E-Mail  "
          placeholder="Enter Valid Email"
          type="email"
          onChange={handleChange}
          value={adminData.email}
          errorMessage="Invalid Email"
          validate={{
            required: { value: true },
            email: { value: true },
          }}
        />

        <AvField
          name="phone"
          label="Phone Number  "
          placeholder="Enter Only number"
          type="number"
          onChange={handleChange}
          value={adminData.phone}
          errorMessage="Enter Only Number"
          validate={{
            required: { value: true },
            pattern: {
              value: "^[0-9]+$",
              errorMessage: "Only Numbers",
            },
          }}
        />

        <FormGroup>
          <Label>Two Factor Authentication</Label>
          <select
            className="browser-default custom-select"
            name="two_factor_status"
            value={adminData.two_factor_status}
            onChange={handleChange}
          >
            <option value="enable">Enable</option>
            <option value="disable">Disable</option>
          </select>
        </FormGroup>

        <FormGroup className="mb-0">
          <div>
            <Button
              type="submit"
              color="primary"
              className="waves-effect waves-light mr-1"
              id="sa-title"
            >
              Submit
            </Button>

            <Button
              type="reset"
              onClick={toggleModal}
              color="secondary"
              className="waves-effect"
              id="e1"
            >
              Cancel
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default EditAdmin;
