import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getCustomerData() {
  return axios.get("/api/auth/customer", getHeader());
}

export function storeCustomer(data) {
  return axios.post("/api/auth/customer/create", data, getHeader());
}

export function editCustomer(userID) {
  return axios.get(`/api/auth/customer/edit/${userID}`, getHeader());
}

export function updateCustomer(userID, data) {
  return axios.post(`/api/auth/customer/update/${userID}`, data, getHeader());
}

export function deleteCustomer(id) {
  return axios.get(`/api/auth/customer/delete/${id}`, getHeader());
}
