import React, { useState, useEffect } from "react";
import { FormGroup, Label, Button, Progress } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import secureLocalStorage from "react-secure-storage";
import { editMimoApk } from "./redux/mimoApkApi";
import { useDispatch } from "react-redux";
import axios from "axios";
import { mimoLoader } from "../../store/actions";

const EditMimoApk = ({
  mimoApkId,
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [mimoApkData, setMimoApkData] = useState({
    file_name: "",
    app_name: "",
    version_name: "",
    file: null,
  });
  const [loading, setLoading] = useState(null);
  const [lState, setLState] = useState(null);

  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const { data: apkData } = await editMimoApk(mimoApkId);

        setMimoApkData(apkData);
      } catch (ex) {
        console.error(ex);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    if (name == "file") {
      setMimoApkData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setMimoApkData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    const formData = new FormData();
    if (mimoApkData.file) {
      formData.append("file", mimoApkData.file);
      formData.append("app_name", mimoApkData.app_name);
      formData.append("version_name", mimoApkData.version_name);
      formData.append("file_name", mimoApkData.file_name);
    } else {
      formData.append("app_name", mimoApkData.app_name);
      formData.append("version_name", mimoApkData.version_name);
      formData.append("file_name", mimoApkData.file_name);
    }

    axios
      .post(`${REACT_APP_URL}/api/auth/updateMimoApk/${mimoApkId}`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type":
            "multipart/form-data; charset=utf-8; boundary=" +
            Math.random().toString().substr(2),
        },
        onUploadProgress: (e) => {
          const { loaded, total } = e;
          let percent = Math.floor((loaded * 100) / total);
          setLState(true);
          setLoading(percent);
        },
      })
      .then((response) => {
        if (response.data.error) {
          setErrorAlert(true);
          setDescription(response.data.error);
        } else {
          toggleModal();
          setSuccessAlert(true);
          setDescription("Mimo Apk successfully updated");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        <AvField
          name="app_name"
          label="Application Name"
          placeholder="Type Something"
          type="text"
          value={mimoApkData.app_name}
          onChange={handleChange}
          errorMessage="Enter Application Name"
          validate={{ required: { value: true } }}
          readOnly
        />

        <AvField
          name="version_name"
          label="Version Name"
          placeholder="Type Something"
          type="text"
          value={mimoApkData.version_name}
          onChange={handleChange}
          errorMessage="Enter Version Name"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="file_name"
          label="File name"
          placeholder="Type Something"
          type="text"
          value={mimoApkData.file_name}
          onChange={handleChange}
          errorMessage="Enter File Name"
          readOnly
          validate={{ required: { value: true } }}
        />

        <FormGroup>
          <Label>Upload Apk</Label>
          <br></br>
          <input
            type="file"
            name="file"
            onChange={handleChange}
            className="filestyle"
            data-buttonname="btn-secondary"
          />
        </FormGroup>

        {lState ? (
          <div className="">
            <div className="mb-4">
              <Progress color="primary" value={loading}>
                {loading}
              </Progress>
            </div>
          </div>
        ) : (
          ""
        )}

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default EditMimoApk;
