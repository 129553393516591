import React, { useState, useEffect } from "react";
import { Col, Row, Card, CardBody, FormGroup, Button } from "reactstrap";
import { getRoles, getRolesForCustomer } from "../RolePermission/redux/RolePermissionApi";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { setBreadcrumbItems } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { storeCustomerUser } from "./redux/userApi";
import { connect } from "react-redux";
import {getCurrentUser } from "../../services/authService";

const CreateUser = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "User", link: "/user" },
    { title: "Create User", link: "#" },
  ]);
  const [userData, setUserData] = useState({
    resellerId: getCurrentUser().role_id !== 3 ? getCurrentUser().reseller_id : "all",
    role_id: 2,
    name: "",
    email: "",
    phone: "",
    customerId: props.match.params.id,
    userRoleId: null,
  });
  const [roleData, setRoleData] = useState([]);
  const [fieldError, setFieldError] = useState(null);
  const [sucessAlert, setSucessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState(null);

  useEffect(() => {
    (async () => {
      props.setBreadcrumbItems("Create User", breadcrumbItems, true);

      try {
        if(getCurrentUser().customer_id!==null && getCurrentUser().reseller_id!==null && getCurrentUser().role_id!==null)
        {
          const {
          data: { data: roleData },
        } = await getRolesForCustomer();
        setRoleData(roleData); 
        }
        else{
        const {
          data: { data: roleData },
        } = await getRoles();
        setRoleData(roleData);  
        }
    } catch (error) {
        console.error(error);
      }

      props.setBreadcrumbItems("Create User", breadcrumbItems, false);
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;
    if (name === "userRoleId") {
      parsedValue = parseInt(value);
    }
    setUserData((prevState) => ({
      ...prevState,
      [name]: parsedValue,
    }));
  };

  const validateForm = () => {
    if (!userData.userRoleId) {
      setFieldError("Please select a user role");
      props.setBreadcrumbItems("Create User", breadcrumbItems, false);
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    props.setBreadcrumbItems("Create User", breadcrumbItems, true);

    if (!validateForm()) {
      return;
    }

    try {
      const { data: response } = await storeCustomerUser(userData);

      setSucessAlert(true);
      setDescription(response.message);
    } catch (ex) {
      setErrorAlert(true);
      setDescription(ex.response.data.message);
    }

    props.setBreadcrumbItems("Create User", breadcrumbItems, false);
  };

  const handleCancel = () => {
    props.history.replace("/user");
  };

  return (
    <>
      {sucessAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => {
            setSucessAlert(false);
            props.history.replace("/user");
          }}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          error
          title="Failed"
          onConfirm={() => {
            setErrorAlert(false);
          }}
        >
          {description}
        </SweetAlert>
      ) : null}

      <Row>
        <Col lg="6">
          <Card>
            <CardBody>
              <AvForm onValidSubmit={handleSubmit}>
                <FormGroup>
                  <AvField
                    name="name"
                    label="Name"
                    placeholder="Enter use name"
                    type="text"
                    value={userData.name}
                    onChange={handleChange}
                    errorMessage="Enter user name"
                    validate={{ required: { value: true } }}
                  />
                  <AvField
                    name="email"
                    label="Email"
                    placeholder="Enter valid email"
                    type="email"
                    onChange={handleChange}
                    value={userData.email}
                    errorMessage="Enter valid email"
                    validate={{
                      required: { value: true },
                      email: { value: true },
                    }}
                  />
                  <AvField
                    name="phone"
                    type="number"
                    label="Phone Number"
                    placeholder="Enter phone number"
                    onChange={handleChange}
                    value={userData.phone}
                    validate={{
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Enter only numbers",
                      },
                    }}
                  />

                  <AvField
                    type="select"
                    name="userRoleId"
                    label="Select Role"
                    helpMessage={
                      fieldError ? (
                        <span className="text-danger h6">{fieldError}</span>
                      ) : (
                        ""
                      )
                    }
                    value={userData.userRoleId}
                    onChange={handleChange}
                  >
                    <option value="">-- Select Role --</option>
                    {roleData.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </AvField>
                </FormGroup>

                <FormGroup>
                  <Button
                    type="submit"
                    color="primary"
                    className="btn btn-primary mr-1"
                  >
                    Submit
                  </Button>

                  <Button
                    type="reset"
                    onClick={handleCancel}
                    color="secondary"
                    className="btn btn-secondary ml-1"
                  >
                    Cancel
                  </Button>
                </FormGroup>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(CreateUser);
