import React, { useState, useEffect } from "react";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { getRoles } from "../RolePermission/redux/RolePermissionApi";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../services/authService";
import { mimoLoader } from "../../store/actions";
import { FormGroup, Button } from "reactstrap";
import { storeUser } from "./redux/userApi";
import { useDispatch } from "react-redux";

const CreateUser = ({
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    resellerId:
      getCurrentUser().role_id !== 3 ? getCurrentUser().reseller_id : "all",
    customerId: "all",
    role_id: 2,
    name: "",
    email: "",
    phone: "",
    userRoleId: null,
  });
  const [roleData, setRoleData] = useState([]);
  const [resellerError, setResellerError] = useState(null);
  const [customerError, setCustomerError] = useState(null);
  const [roleError, setRoleError] = useState(null);

  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const {
          data: { data: roleData },
        } = await getRoles();

        setRoleData(roleData);
      } catch (error) {
        console.error(error);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  const handleReseller = (id) => {
    setUserData((prevState) => ({
      ...prevState,
      resellerId: id === "all" ? "all" : parseInt(id),
      customerId: "all",
    }));
    setResellerError(id === "all" ? "Please select a reseller" : null);
  };

  const handleCustomer = (id) => {
    setUserData((prevState) => ({
      ...prevState,
      customerId: id === "all" ? "all" : parseInt(id),
    }));
    setCustomerError(id === "all" ? "Please select a customer" : null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;
    if (name === "userRoleId") {
      parsedValue = parseInt(value);
      setRoleError(!value ? "Please select a user role" : null);
    }
    setUserData((prevState) => ({
      ...prevState,
      [name]: parsedValue,
    }));
  };

  const validateForm = () => {
    if (userData.resellerId === "all") {
      setResellerError("Please select a reseller");
      return false;
    } else if (userData.customerId === "all") {
      setCustomerError("Please select a customer");
      return false;
    } else if (!userData.userRoleId) {
      setRoleError("Please select a user role");
      return false;
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    dispatch(mimoLoader(true));
    try {
      const { data: response } = await storeUser(userData);

      toggleModal();
      setSuccessAlert(true);
      setDescription(response.message);
    } catch (ex) {
      setErrorAlert(true);
      setDescription(ex.response.data.message);
    }
    dispatch(mimoLoader(false));
  };

  return (
    <>
      <AvForm onValidSubmit={validateForm}>
        <FormGroup>
          {getCurrentUser().role_id === 3 ? (
            <SelectReseller
              className=""
              fieldError={resellerError}
              resellerId={userData.resellerId}
              handleReseller={handleReseller}
            />
          ) : (
            ""
          )}

          <SelectCustomer
            className=""
            fieldError={customerError}
            resellerId={userData.resellerId}
            customerId={userData.customerId}
            handleCustomer={handleCustomer}
          />

          <AvField
            name="name"
            label="Name"
            placeholder="Enter use name"
            type="text"
            value={userData.name}
            onChange={handleChange}
            errorMessage="Enter user name"
            validate={{ required: { value: true } }}
          />
          <AvField
            name="email"
            label="Email"
            placeholder="Enter valid email"
            type="email"
            onChange={handleChange}
            value={userData.email}
            errorMessage="Enter valid email"
            validate={{
              required: { value: true },
              email: { value: true },
            }}
          />
          <AvField
            name="phone"
            type="number"
            label="Phone Number"
            placeholder="Enter phone number"
            onChange={handleChange}
            value={userData.phone}
            validate={{
              pattern: {
                value: "^[0-9]+$",
                errorMessage: "Enter only numbers",
              },
            }}
          />

          <AvField
            type="select"
            name="userRoleId"
            label="Select Role"
            helpMessage={
              roleError ? (
                <span className="text-danger h6">{roleError}</span>
              ) : (
                ""
              )
            }
            value={userData.userRoleId}
            onChange={handleChange}
          >
            <option value="">-- Select Role --</option>
            {roleData.map((item) => (
              <option key={item.id} value={item.id}>
                {item.name}
              </option>
            ))}
          </AvField>
        </FormGroup>

        <FormGroup>
          <Button
            type="submit"
            color="primary"
            className="btn btn-primary mr-1"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="btn btn-secondary ml-1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default CreateUser;
