import React, { useState, useRef } from "react";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Label, Button, Progress } from "reactstrap";
import { getCurrentUser } from "../../services/authService";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const CreateUploadApk = ({
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const [uploadApkData, setUploadApkData] = useState({
    resellerId:
      getCurrentUser().role_id !== 3 ? getCurrentUser().reseller_id : "all",
    customerId:
      getCurrentUser().role_id === 2 ? getCurrentUser().customer_id : "all",
    app_name: "",
    file: null,
  });
  const [resellerError, setResellerError] = useState(null);
  const [customerError, setCustomerError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [lState, setLState] = useState(null);
  const fileRef = useRef();

  const handleReseller = (id) => {
    setUploadApkData((prevState) => ({
      ...prevState,
      resellerId: id === "all" ? "all" : parseInt(id),
      customerId: "all",
    }));
    setResellerError(id === "all" ? "Please select a reseller" : null);
  };

  const handleCustomer = (id) => {
    setUploadApkData((prevState) => ({
      ...prevState,
      customerId: id === "all" ? "all" : parseInt(id),
    }));
    setCustomerError(id === "all" ? "Please select a customer" : null);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file") {
      if (files[0].type !== "application/vnd.android.package-archive") {
        setErrorAlert(true);
        setDescription("File format is incorrect (Please upload an .apk file)");
        setUploadApkData((prevState) => ({
          ...prevState,
          file: null,
        }));
        fileRef.current.value = "";
      } else {
        setUploadApkData((prevState) => ({
          ...prevState,
          [name]: files[0],
        }));
      }
    } else {
      setUploadApkData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleValidation = () => {
    if (uploadApkData.resellerId === "all") {
      setResellerError("Please select a reseller");
      return false;
    } else if (uploadApkData.customerId === "all") {
      setCustomerError("Please select a customer");
      return false;
    } else if (!uploadApkData.file) {
      setLState(false);
      setDescription("Please select a file");
      setErrorAlert(true);
      return false;
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    const formData = new FormData();
    formData.append("file", uploadApkData.file);
    formData.append("app_name", uploadApkData.app_name);
    formData.append("customer_id", uploadApkData.customerId);
    formData.append("uploaded_by", getCurrentUser().name);

    axios
      .post(`${REACT_APP_URL}/api/auth/uploadApk`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type":
            "multipart/form-data; charset=utf-8; boundary=" +
            Math.random().toString().substr(2),
        },
        onUploadProgress: (e) => {
          const { loaded, total } = e;
          let percent = Math.floor((loaded * 100) / total);
          setLState(true);
          setLoading(percent);
        },
      })
      .then((res) => {
        if (res.data.error) {
          setErrorAlert(true);
          setDescription(res.data.error);
          setLState(false);
        } else {
          toggleModal();
          setSuccessAlert(true);
          setDescription("Apk file sucessfully uploaded");
        }
      });
  };

  return (
    <>
      <AvForm onValidSubmit={handleValidation}>
        {getCurrentUser().role_id === 3 ? (
          <SelectReseller
            className=""
            fieldError={resellerError}
            resellerId={uploadApkData.resellerId}
            handleReseller={handleReseller}
          />
        ) : (
          ""
        )}

        {getCurrentUser().role_id !== 2 ? (
          <SelectCustomer
            className=""
            fieldError={customerError}
            resellerId={uploadApkData.resellerId}
            customerId={uploadApkData.customerId}
            handleCustomer={handleCustomer}
          />
        ) : (
          ""
        )}

        <AvField
          name="app_name"
          label="Application Name"
          placeholder="Enter application name"
          type="text"
          value={uploadApkData.app_name}
          onChange={handleChange}
          errorMessage="Enter application name"
          validate={{ required: { value: true } }}
        />

        <FormGroup>
          <Label>Select Apk File</Label>
          <br></br>
          <input
            type="file"
            name="file"
            onChange={handleChange}
            className="filestyle"
            data-buttonname="btn-secondary"
            ref={fileRef}
          />
        </FormGroup>

        {lState ? (
          <div className="">
            <div className="mb-4">
              <Progress color="primary" value={loading}>
                {loading}
              </Progress>
            </div>
          </div>
        ) : (
          ""
        )}

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default CreateUploadApk;
