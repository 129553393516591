import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getRoles() {
  return axios.get(`/api/auth/getRoles`, getHeader());
}

export function getRolesForCustomer() {
  return axios.get(`/api/auth/getRolesForCustomer`, getHeader());
}

export function getPermissions() {
  return axios.get(`/api/auth/getPermissions`, getHeader());
}

export function getRolePermissions(id) {
  return axios.get(`/api/auth/getRolePermissions/${id}`, getHeader());
}

export function storeRole(data) {
  return axios.post("/api/auth/storeRole", data, getHeader());
}

export function editRole(id) {
  return axios.get(`/api/auth/editRole/${id}`, getHeader());
}

export function updateRole(data) {
  return axios.post(`/api/auth/updateRole`, data, getHeader());
}

export function deleteRole(id) {
  return axios.get(`/api/auth/deleteRole/${id}`, getHeader());
}
