import React, { useState, useEffect } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { mimoLoader } from "../../store/actions";
import { FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import {
  editFirmHardware,
  updateFirmHardware,
} from "./redux/firmwareImagesApi";
import {getCurrentUser} from "../../services/authService";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomers } from "./SelectCustomersInput";

const EditFirmHardware = ({
  hardwareId,
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
  customer,
}) => {
  const dispatch = useDispatch();
  const [hardwareData, setHardwareData] = useState({});
  const [resellerId, setResellerId] = useState("all");
  const [customerId, setCustomerId] = useState(customer);

  const handleReseller = (id) => {
    setResellerId(id);
    setCustomerId("MIMO");
  };

  const handleCustomer = (id) => {
    setCustomerId(id === "MIMO" ? "MIMO" : id);
  };

  const handleCustomerSelect = (id) => {
    setCustomerId(id === "MIMO" ? "MIMO" : id);
  };


  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const { data: hardwareData } = await editFirmHardware(hardwareId);
        // setCustomerId(hardwareData.customer)
        setHardwareData(hardwareData);
      } catch (ex) {
        console.error(ex);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setHardwareData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    hardwareData.customer = customerId;
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const {
          data: { message },
        } = await updateFirmHardware(hardwareData);

        toggleModal();
        setSuccessAlert(true);
        setDescription(message);
      } catch (ex) {
        console.error(ex);
        setErrorAlert(true);
        setDescription("Failed to edit hardware");
      }
      dispatch(mimoLoader(false));
    })();
  };

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        <AvField
          style={{ fontWeight: "bold" }}
          name="product"
          label="Product Name"
          placeholder="Type Text"
          type="text"
          disabled={true}
          value={hardwareData.product}
        />

        <AvField
          name="hw"
          label="HW"
          placeholder="Type Text"
          type="text"
          value={hardwareData.hw || ""}
          onChange={handleChange}
          errorMessage="Enter HW"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="wifi"
          label="Wifi"
          placeholder="Type text"
          type="text"
          value={hardwareData.wifi || ""}
          onChange={handleChange}
          errorMessage="Enter Wifi Field"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="sensor"
          label="Sensor"
          placeholder="Type text"
          type="text"
          value={hardwareData.sensor || ""}
          onChange={handleChange}
          errorMessage="Enter Sensor Field"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="display_interface"
          label="Display Interface"
          placeholder="Type text"
          type="text"
          value={hardwareData.display_interface || ""}
          onChange={handleChange}
          errorMessage="Enter Display Interface Field"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="resolution"
          label="Resolution"
          placeholder="Type text"
          type="text"
          value={hardwareData.resolution || ""}
          onChange={handleChange}
          errorMessage="Enter Resolution Field"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="lcd_type"
          label="LCD Type"
          placeholder="Type text"
          type="text"
          value={hardwareData.lcd_type || ""}
          onChange={handleChange}
          errorMessage="Enter LCD Type Field"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="orientation"
          label="Orientation"
          placeholder="Type text"
          type="text"
          value={hardwareData.orientation || ""}
          onChange={handleChange}
          errorMessage="Enter Orientation Field"
          validate={{ required: { value: true } }}
        />

        {getCurrentUser().role_id === 3 ? (
                      <SelectReseller
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        handleReseller={handleReseller}
                      />
                  ) : (
                    ""
      )}

      {getCurrentUser().role_id === 3 ? (
                     <SelectCustomers
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        customerId={customerId}
                        handleCustomer={handleCustomer}
                      />
                  ) : (
                    ""
                  )}

     {getCurrentUser().role_id !== 3?(
                        <SelectCustomers
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        customerId={customerId}
                        handleCustomer={handleCustomerSelect}
                      />)
        :
        ("")}

        <AvField
          name="sn"
          label="Serial Number"
          placeholder="Type text"
          type="text"
          value={hardwareData.sn || ""}
          onChange={handleChange}
          errorMessage="Enter Serial Number Field"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="country"
          label="Country"
          placeholder="Type text"
          type="text"
          value={hardwareData.country || ""}
          onChange={handleChange}
          errorMessage="Enter Country Field"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="language"
          label="Language"
          placeholder="Type text"
          type="text"
          value={hardwareData.language || ""}
          onChange={handleChange}
          errorMessage="Enter Language Field"
          validate={{ required: { value: true } }}
        />

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default EditFirmHardware;
