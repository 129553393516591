import React, { useState, useEffect } from "react";
import { setBreadcrumbItems } from "../../store/actions";
import { Col, Row, Card, CardBody } from "reactstrap";
import { getAuditTrail } from "./redux/AuditTrialApi";
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import "./datatables.scss";

const DownloadAuditTrail = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "#" },
    { title: "Download Audit Trail", link: "/audit-trail" },
  ]);
  const [auditTrialdata, setAuditTrialdata] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState("");

  useEffect(() => {
    (async () => {
      props.setBreadcrumbItems("Download Audit Trail", breadcrumbItems, true);

      try {
        const { data: auditTrialdata } = await getAuditTrail();

        setAuditTrialdata(auditTrialdata);
      } catch (error) {
        console.error(error);
      }

      props.setBreadcrumbItems("Download Audit Trail", breadcrumbItems, false);
    })();
  }, []);

  const tableData = {
    columns: [
      {
        label: "Activation Key",
        field: "activation_key",
        sort: "asc",
        width: 150,
      },
      {
        label: "Application Name",
        field: "app_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Serial Number",
        field: "serial_number",
        sort: "asc",
        width: 200,
      },
      {
        label: "Mac Address Ethernet",
        field: "mac_address_ethernet",
        sort: "asc",
        width: 200,
      },

      {
        label: "IP Address",
        field: "ip_address",
        sort: "asc",
        width: 200,
      },
      {
        label: "Version Name",
        field: "version_number",
        sort: "asc",
        width: 200,
      },
      {
        label: "Andorid OS",
        field: "androidOS",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
      {
        label: "Firmware",
        field: "firmware",
        sort: "asc",
        width: 200,
      },
      {
        label: "Downloaded Version Number",
        field: "downloaded_version_number",
        sort: "asc",
        width: 200,
      },

      {
        label: "Timestamp",
        field: "created_at",
        sort: "asc",
        width: 200,
      },
    ],
    rows: auditTrialdata,
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}

      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <MDBDataTable responsive bordered striped data={tableData} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(DownloadAuditTrail);
