import React, { useState, useEffect } from "react";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { activationKeyReport } from "./redux/activationKeyApi";
import { Col, Row, Card, CardBody, Label } from "reactstrap";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import { AvForm } from "availity-reactstrap-validation";
import secureLocalStorage from "react-secure-storage";
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";

const ActivationKeyReport = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Activation Key", link: "/activation" },
    { title: "Report", link: "#" },
  ]);
  const [reportData, setReportData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [printLimit, setPrintLimit] = useState(true);
  const [keyFilter, setKeyFilter] = useState("all");
  const [keyType, setKeyType] = useState("all");
  const [resellerId, setResellerId] = useState("all");
  const [customerId, setCustomerId] = useState("all");
  const todayDate = new Date();
  const currentDate =
    todayDate.getMonth() +
    1 +
    "-" +
    todayDate.getDate() +
    "-" +
    todayDate.getFullYear();

  useEffect(() => {
    (async () => {
      props.setBreadcrumbItems("Report", breadcrumbItems, true);

      try {
        const { data: reportData } = await activationKeyReport(customerId);

        setReportData(reportData);
        setFilteredData(reportData);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Report", breadcrumbItems, false);
    })();

    props.setBreadcrumbItems("Report", breadcrumbItems, true);

    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    axios
      .get(
        `${REACT_APP_URL}/api/auth/activationKeyReport/${props.match.params.id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setReportData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    props.setBreadcrumbItems("Report", breadcrumbItems, false);
  }, []);

  useEffect(() => {
    !printLimit && window.print();
    setPrintLimit(true);
  }, [printLimit]);

  const handleReseller = (id) => {
    setResellerId(id);
    setCustomerId("all");
    setFilteredData(
      reportData
        .filter((item) => (id === "all" ? item : item.resellerId == id))
        .filter((item) =>
          keyType === "all"
            ? item
            : keyType === "activated"
            ? item.active === 1
            : item.active === 0
        )
    );
  };

  const handleCustomer = (id) => {
    setCustomerId(id);
    setFilteredData(
      reportData
        .filter((item) =>
          resellerId === "all" ? item : item.resellerId == resellerId
        )
        .filter((item) => (id === "all" ? item : item.customerId == id))
        .filter((item) =>
          keyType === "all"
            ? item
            : keyType === "activated"
            ? item.active === 1
            : item.active === 0
        )
    );
  };

  const handleKeyType = (e) => {
    const { value } = e.target;
    setKeyType(value);
    setFilteredData(
      reportData
        .filter((item) =>
          resellerId === "all" ? item : item.resellerId == resellerId
        )
        .filter((item) =>
          customerId === "all" ? item : item.customerId == customerId
        )
        .filter((item) =>
          value === "all"
            ? item
            : value === "activated"
            ? item.active === 1
            : item.active === 0
        )
    );
  };

  return (
    <>
      {printLimit ? (
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <AvForm>
                  <Col xs="12" className="row">
                    {getCurrentUser().role_id === 3 ? (
                      <Col xs="3">
                        <SelectReseller
                          className=""
                          fieldError={null}
                          resellerId={resellerId}
                          handleReseller={handleReseller}
                        />
                      </Col>
                    ) : (
                      ""
                    )}

                    <Col xs="3">
                      <SelectCustomer
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        customerId={customerId}
                        handleCustomer={handleCustomer}
                      />
                    </Col>

                    <Col xs="3">
                      <Label>Select Key Type</Label>
                      <br></br>
                      <select
                        style={{ marginLeft: "0px" }}
                        required
                        onChange={handleKeyType}
                        name="keyFilter"
                        className="custom-select"
                      >
                        <option defaultValue value="all">
                          All
                        </option>
                        <option value="expired">Expired</option>
                        <option value="expireSoon">Expire Soon</option>
                      </select>
                    </Col>
                  </Col>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Row>
                <Col xs="12">
                  <div className="invoice-title">
                    <h4 className="float-right font-size-16">
                      <strong>
                        {printLimit ? (
                          <Link
                            to="#"
                            onClick={() => setPrintLimit(false)}
                            className="btn btn-success waves-effect waves-light mr-3"
                          >
                            <i className="fa fa-print"></i>
                          </Link>
                        ) : (
                          `Report#${Math.random().toString().substr(13)}`
                        )}
                      </strong>
                    </h4>
                    <h3 className="mt-0">
                      <img src={logo} alt="logo" height="24" />
                    </h3>
                  </div>
                  <hr />
                  <Row>
                    <Col xs="6">
                      {props.match.params.id != "all" ? (
                        <address>
                          <strong>Customer Details:</strong>
                          <br />
                          {filteredData[0] ? filteredData[0].name : ""}
                          <br />
                          {filteredData[0] ? filteredData[0].email : ""}
                          <br />
                          {filteredData[0] ? filteredData[0].phone : ""}
                          <br />
                          {filteredData[0] ? filteredData[0].address : ""}
                        </address>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs="6" className="text-right">
                      <address>
                        <strong>Date:</strong>
                        <br />
                        {currentDate}
                        <br />
                        <br />
                      </address>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xs="12">
                  <div>
                    <div className="p-2">
                      <h3 className="font-size-16">
                        <strong>Activation Keys Summary</strong>
                      </h3>
                    </div>
                    <div className="">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              {props.match.params.id == "all" ? (
                                <td>
                                  <strong>Customer Name</strong>
                                </td>
                              ) : (
                                ""
                              )}
                              <td>
                                <strong>Application Name</strong>
                              </td>
                              <td className="text-center">
                                <strong>Activation Key</strong>
                              </td>
                              <td className="text-center">
                                <strong>Key Type</strong>
                              </td>
                              <td className="text-center">
                                <strong>Device Quantity</strong>
                              </td>
                              <td className="text-center">
                                <strong>Activation Status</strong>
                              </td>
                              <td className="text-right">
                                <strong>Expiration Date</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData
                              .filter((item) =>
                                keyFilter != "all"
                                  ? keyFilter == "expireSoon"
                                    ? item.expire_check_soon != 1 &&
                                      item.expire_check_soon != "" &&
                                      item.expire_check != 0
                                    : item.expire_check != 1 &&
                                      item.expire_check != ""
                                  : true
                              )
                              .map((item, index) => (
                                <tr key={index}>
                                  {props.match.params.id == "all" ? (
                                    <td>{item.customer_name}</td>
                                  ) : (
                                    ""
                                  )}
                                  <td>{item.app_name}</td>
                                  <td className="text-center">{item.key}</td>
                                  <td className="text-center">
                                    {item.key_type}
                                  </td>
                                  <td className="text-center">
                                    {item.device_quantity}
                                  </td>
                                  <td className="text-center">
                                    {item.active == 0 ? (
                                      <h5>
                                        <span className="badge badge-danger">
                                          Not Activated
                                        </span>
                                      </h5>
                                    ) : (
                                      <h5>
                                        <span className="badge badge-success">
                                          Activated
                                        </span>
                                      </h5>
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {item.expire_check_soon != 1 &&
                                    item.expire_check_soon != "" &&
                                    item.expire_check != 0 ? (
                                      <h5>
                                        <span className="badge badge-primary">
                                          {item.valid_date}
                                          <br></br>
                                          <br></br>
                                          (Expire Soon)
                                        </span>
                                      </h5>
                                    ) : item.expire_check != 1 &&
                                      item.expire_check != "" ? (
                                      <h5>
                                        <span className="badge badge-danger">
                                          {item.valid_date}
                                          <br></br>
                                          <br></br>
                                          (Expired)
                                        </span>
                                      </h5>
                                    ) : (
                                      item.valid_date
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="d-print-none">
                        <div className="float-right">
                          <Link
                            to="#"
                            onClick={() => setPrintLimit(false)}
                            className="btn btn-success waves-effect waves-light mr-3"
                          >
                            <i className="fa fa-print"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(ActivationKeyReport);
