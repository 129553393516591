import React, { useState, useEffect } from "react";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { deleteRole, getRoles } from "./redux/RolePermissionApi";
import { Col, Card, CardBody, Row, Button } from "reactstrap";
import { getCurrentUser } from "../../services/authService";
import { Permissions } from "./../../services/permissions";
import { setBreadcrumbItems } from "../../store/actions";
import { AvForm } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import EditRoleModal from "./EditRoleModal";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import "./datatables.scss";

const UserRoles = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "User Roles", link: "#" },
  ]);
  const [roleData, setRoleData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const [editModalShow, setEditModalShow] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState(null);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [resellerId, setResellerId] = useState("all");
  const [customerId, setCustomerId] = useState("all");

  useEffect(() => {
    if (editModalShow) return;
    (async () => {
      props.setBreadcrumbItems("User Roles", breadcrumbItems, true);

      try {
        const {
          data: { data: roleData },
        } = await getRoles();

        const finalRoleData = roleData.filter((item) =>
          getCurrentUser().role_id === 2 && item.customer_id
            ? item.customer_id === getCurrentUser().customer_id
            : item
        );
        setRoleData(finalRoleData);
        setFilteredData(finalRoleData);
      } catch (error) {
        console.error(error);
      }

      props.setBreadcrumbItems("User Roles", breadcrumbItems, false);
    })();
  }, [editModalShow]);

  const handleReseller = (id) => {
    setResellerId(id);
    setCustomerId("all");
    setFilteredData(
      roleData.filter((item) => (id === "all" ? item : item.resellerId == id))
    );
  };

  const handleCustomer = (id) => {
    setCustomerId(id === "all" ? "all" : parseInt(id));
    setFilteredData(
      roleData
        .filter((item) => (id === "all" ? item : item.customer_id == id))
        .filter((item) =>
          resellerId === "all" ? item : item.resellerId == resellerId
        )
    );
  };

  const handleDelete = async () => {
    props.setBreadcrumbItems("User Role", breadcrumbItems, true);

    try {
      const { data: response } = await deleteRole(roleId);
      const currentData = roleData.filter((item) => item.id !== roleId);

      setSuccessAlert(true);
      setDescription(response.message);
      setRoleData(currentData);
      setFilteredData(currentData);
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("User Role", breadcrumbItems, false);
  };

  const toggleEditModal = () => {
    setEditModalShow(!editModalShow);
  };

  const rolesTable = {
    columns: [
      {
        label: "Company",
        field: "customerName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Description",
        field: "description",
        sort: "asc",
        width: 270,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 200,
      },
    ],
    rows: filteredData.map((obj) => ({
      ...obj,

      customerName: obj.customerName ? obj.customerName : "-",

      actions: (
        <>
          {Permissions().editRolePermi ? (
            <i
              className="mdi mdi-square-edit-outline mr-1 iconHover"
              style={{ color: "#20C0ED", fontSize: "23px" }}
              role="button"
              onClick={() => {
                setRoleId(obj.id);
                toggleEditModal();
              }}
            ></i>
          ) : (
            ""
          )}

          {Permissions().deleteRolePermi && obj.name !== "Super User Role" ? (
            <i
              className="mdi mdi mdi-delete  mr-1 iconHover"
              style={{
                color: "#DB4C3F",
                fontSize: "23px",
              }}
              role="button"
              onClick={() => {
                setConfirmAlert(true);
                setRoleId(obj.id);
              }}
            ></i>
          ) : (
            ""
          )}
        </>
      ),
    })),
  };

  return (
    <>
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}

      <Col xs="12">
        <Card>
          <CardBody>
            <AvForm>
              <Col xs="12">
                <Row className="align-items-center mb-3">
                  {getCurrentUser().role_id === 3 ? (
                    <Col xs="3">
                      <SelectReseller
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        handleReseller={handleReseller}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {getCurrentUser().role_id !== 2 ? (
                    <Col xs="3">
                      <SelectCustomer
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        customerId={customerId}
                        handleCustomer={handleCustomer}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {Permissions().createRolePermi ? (
                    <Col xs="2">
                      <Button
                        style={{ marginTop: "10px" }}
                        onClick={() => {
                          props.history.replace(`/create-role`);
                        }}
                      >
                        Create Role
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </AvForm>

            <MDBDataTable responsive bordered striped data={rolesTable} />
          </CardBody>
        </Card>
      </Col>

      {editModalShow ? (
        <EditRoleModal
          toggleEdit={toggleEditModal}
          isOpenEdit={editModalShow}
          roleId={roleId}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(UserRoles);
