import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getHelpCategory } from "./redux/helpGuideApi";
import secureLocalStorage from "react-secure-storage";
import { mimoLoader } from "../../store/actions";
import { useDispatch } from "react-redux";
import axios from "axios";

const CreateHelp = ({
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [helpData, setHelpData] = useState({
    existCategory: "",
    category: "",
    fileName: "",
    file: null,
  });
  const [categoryData, setCategoryData] = useState([]);
  const [categoryError, setCategoryError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [loadingBar, setLoadingBar] = useState(null);
  const [source, setSource] = useState(axios.CancelToken.source());

  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const { data: helpCategory } = await getHelpCategory();
        setCategoryData(helpCategory);
      } catch (ex) {
        console.error(ex);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file") {
      if (files[0]?.size > 100000000) {
        value = null;
        setErrorAlert(true);
        setDescription("File Size is larger than 100MB");
      } else {
        setHelpData((prevState) => ({
          ...prevState,
          [name]: files[0],
        }));
      }
    } else {
      setHelpData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleValidation = () => {
    if (!helpData.file) {
      setErrorAlert(true);
      setDescription("Upload file is not selected");
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    const formData = new FormData();
    formData.append("existCategory", helpData.existCategory);
    formData.append("category", helpData.category);
    formData.append("fileName", helpData.fileName);
    formData.append("file", helpData.file);

    axios
      .post(`${REACT_APP_URL}/api/auth/create-help`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type":
            "multipart/form-data; charset=utf-8; boundary=" +
            Math.random().toString().substr(2),
        },
        onUploadProgress: (e) => {
          const { loaded, total } = e;
          let percent = Math.floor((loaded * 100) / total);
          setLoadingBar(true);
          setLoading(percent);
        },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res.data.error) {
          setErrorAlert(true);
          setDescription(res.data.error);
          setLoadingBar(false);
        } else {
          toggleModal();
          setSuccessAlert(true);
          setDescription("Help has been successfully created");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <AvForm onValidSubmit={handleValidation}>
        <AvField
          type="select"
          name="existCategory"
          label="Select Help Category"
          helpMessage={
            categoryError ? (
              <span className="text-danger h6">{categoryError}</span>
            ) : (
              ""
            )
          }
          onChange={(e) => {
            setHelpData((prevState) => ({
              ...prevState,
              existCategory: e.target.value,
            }));
          }}
        >
          <option value=""> Select Category</option>
          {categoryData.map((item) => (
            <option key={item.id} value={item.id}>
              {item.category}
            </option>
          ))}
        </AvField>

        {helpData.existCategory === "" ? (
          <>
            <AvField
              name="category"
              id="category"
              label="Category Name"
              placeholder="Category Name"
              type="text"
              value={helpData.category || ""}
              onChange={handleChange}
              errorMessage="Enter Category Name"
              validate={{ required: { value: true } }}
            />
            <UncontrolledTooltip placement="top" target="category">
              Enter Category Name (MLock Help Guides)
            </UncontrolledTooltip>
          </>
        ) : (
          ""
        )}

        <AvField
          name="fileName"
          id="fileName"
          label="File Name"
          placeholder="File Name"
          type="text"
          value={helpData.fileName || ""}
          onChange={handleChange}
          errorMessage="Enter File Name"
          validate={{ required: { value: true } }}
        />
        <UncontrolledTooltip placement="top" target="category">
          Enter File Name (MLock User Guide v3.1.7)
        </UncontrolledTooltip>

        <FormGroup>
          <Label>
            Upload File{" "}
            <i
              style={{
                fontSize: "18px",
                cursor: "pointer",
                color: "#2E79BA",
              }}
              className="mdi mdi-help-circle-outline"
              id="fileRule"
            ></i>
            <UncontrolledPopover target="fileRule" placement="right">
              <PopoverBody
                style={{
                  fontSize: "12px",
                }}
              >
                <ul>
                  <li>File format must be 'PDF' or 'DOCS'</li>
                </ul>
              </PopoverBody>
            </UncontrolledPopover>
          </Label>
          <br></br>

          <input
            type="file"
            name="file"
            onChange={handleChange}
            className="filestyle size"
            data-buttonname="btn-secondary"
          />
        </FormGroup>

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default CreateHelp;
