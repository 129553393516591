import React, { useState, useEffect } from "react";
import { editProduct, updateProduct } from "./redux/firmwareImagesApi";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { mimoLoader } from "../../store/actions";
import { FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";

const EditProduct = ({
  firmwareProId,
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [firmwareProData, setFirmwareProData] = useState({
    id: firmwareProId,
  });

  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const { data: firmwareProData } = await editProduct(firmwareProId);

        setFirmwareProData(firmwareProData);
      } catch (ex) {
        console.error(ex);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFirmwareProData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        await updateProduct(firmwareProData);

        toggleModal();
        setSuccessAlert(true);
        setDescription("Firmware product has been successfully updated");
      } catch (ex) {
        console.error(ex);
      }
      dispatch(mimoLoader(false));
    })();
  };

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        <AvField
          name="product"
          label="Product Name"
          placeholder="Enter name"
          type="text"
          value={firmwareProData.product}
          onChange={handleChange}
          errorMessage="Enter Product Name"
          validate={{ required: { value: true } }}
        />

        <FormGroup className="mb-0">
          <div>
            <Button
              type="submit"
              color="primary"
              className="waves-effect waves-light mr-1"
              id="sa-title"
            >
              Submit
            </Button>

            <Button
              type="reset"
              onClick={toggleModal}
              color="secondary"
              className="waves-effect"
              id="e1"
            >
              Cancel
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default EditProduct;
