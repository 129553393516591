import React, { useState, useEffect } from "react";
import { deleteFirmwarePro, getFirmProduct } from "./redux/firmwareImagesApi";
import { SelectReseller } from "../../component/Common/SelectReseller";
import ModalLayout from "../../component/Common/ModalLayout";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import { AvForm } from "availity-reactstrap-validation";
import { Col, Row, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import CreateFirmProduct from "./CreateFirmProduct";
import EditFirmProduct from "./EditFirmProduct";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import "./datatables.scss";

const FirmwareProduct = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Firmware Products", link: "#" },
  ]);
  const [firmwareProData, setFirmwareProData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [firmwareProId, setFirmwareProId] = useState(null);
  const [resellerId, setResellerId] = useState("all");
  const [modalShow, setModalShow] = useState(false);
  const [modalShowCreate, setModalShowCreate] = useState(false);

  useEffect(() => {
    if (modalShow || modalShowCreate) return;
    (async () => {
      props.setBreadcrumbItems("Firmware Products", breadcrumbItems, true);

      try {
        const {
          data: { data: firmwareProData },
        } = await getFirmProduct("all");

        setFirmwareProData(firmwareProData);
        setFilteredData(firmwareProData);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Firmware Products", breadcrumbItems, false);
    })();
  }, [modalShow, modalShowCreate]);

  const handleReseller = (id) => {
    setResellerId(id);
    setFilteredData(
      firmwareProData.filter((item) =>
        id === "all" ? item : item.reseller_id == id
      )
    );
  };

  const handleDelete = () => {
    (async () => {
      props.setBreadcrumbItems("Firmware Products", breadcrumbItems, true);

      try {
        const {
          data: { message: message },
        } = await deleteFirmwarePro(firmwareProId);
        const currentData = firmwareProData.filter(
          (item) => item.id !== firmwareProId
        );

        setSuccessAlert(true);
        setDescription(message);
        setFirmwareProData(currentData);
        setFilteredData(currentData);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Firmware Products", breadcrumbItems, false);
    })();
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  const dataTable = {
    columns: [
      {
        label: "Product",
        field: "product",
        sort: "asc",
        width: 150,
      },

      {
        label: "Timestamp",
        field: "created_at",
        sort: "asc",
        width: 200,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: filteredData.map((obj) => ({
      ...obj,

      actions: (
        <>
          <i
            className="mdi mdi-file-eye-outline iconHover mr-1"
            style={{ color: "#408EBA", fontSize: "23px" }}
            role="button"
            onClick={() => {
              props.history.replace("/hardware-profile/" + obj.id);
            }}
          ></i>

          <i
            className="mdi mdi-square-edit-outline mr-1 iconHover"
            style={{ color: "#20C0ED", fontSize: "23px" }}
            role="button"
            onClick={() => {
              setFirmwareProId(obj.id);
              toggleModal();
            }}
          ></i>

          <i
            className="mdi mdi mdi-delete  mr-1 iconHover"
            style={{
              color: "#DB4C3F",
              fontSize: "23px",
            }}
            role="button"
            onClick={() => {
              setConfirmAlert(true);
              setFirmwareProId(obj.id);
            }}
          ></i>
        </>
      ),
    })),
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      <Col xs="12">
        <Card>
          <CardBody>
            <AvForm>
              <Col xs="12">
                <Row className="align-items-center mb-3">
                  {getCurrentUser().role_id === 3 ? (
                    <Col xs="3">
                      <SelectReseller
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        handleReseller={handleReseller}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col xs="2">
                    <Button
                      style={{ marginTop: "10px" }}
                      onClick={toggleModalCreate}
                    >
                      Create Product
                    </Button>
                  </Col>
                </Row>
              </Col>
            </AvForm>

            <MDBDataTable responsive bordered striped data={dataTable} />
          </CardBody>
        </Card>
      </Col>

      {modalShow ? (
        <ModalLayout
          toggleModal={toggleModal}
          isOpen={modalShow}
          title="Edit Product"
          size="lg"
        >
          <EditFirmProduct
            firmwareProId={firmwareProId}
            toggleModal={toggleModal}
            setSuccessAlert={setSuccessAlert}
            setDescription={setDescription}
            setErrorAlert={setErrorAlert}
          />
        </ModalLayout>
      ) : (
        ""
      )}

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Create Product"
          size="lg"
        >
          <CreateFirmProduct
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(FirmwareProduct);
