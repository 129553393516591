import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CHECK_LOGIN } from "./actionTypes";
import { apiLoginError, loginUserSuccessful } from "./actions";

// AUTH related methods
import {
  setLoggeedInUser,
  postLogin,
  setPermissions,
} from "../../../helpers/authUtils";

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { username, password, history } }) {
  try {
    const { REACT_APP_URL } = process.env;
    const response = yield call(postLogin, `${REACT_APP_URL}/api/auth/login`, {
      email: username,
      password: password,
    });
    if (response.message) {
      yield put(apiLoginError(response.message));
    } else {
      setLoggeedInUser(response);
      setPermissions(response.permissions);
      yield put(loginUserSuccessful(response));
      {
        response.user?.role_id === 2 &&
        !response.user?.two_factor_verified_at &&
        response.user?.two_factor_status === "enable"
          ? history.push("/check-two-factor")
          : history.push("/dashboard");
      }
    }
  } catch (error) {
    yield put(apiLoginError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(CHECK_LOGIN, loginUser);
}

function* loginSaga() {
  yield all([fork(watchUserLogin)]);
}

export default loginSaga;
