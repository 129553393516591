import React, { Component } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Label,
  FormGroup,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

class ModalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal_data: this.props.data,
      editBox: "",
      data: {},
      command: "",
      header: {
        userName: "Admin",
        password: "0000",
      },
      success_alert: false,
      success_message: "",
      user: JSON.parse(secureLocalStorage.getItem("user")),
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      modal_data: this.props.data,
    });
  };

  handleChange = (event) => {
    event.preventDefault();
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleSubmit = async () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    this.setState(
      {
        submitted_data: {
          data: this.state.data,
          command: this.state.command,
          macAddress: [this.state.modal_data.mac_address],
          header: this.state.header,
        },
      },
      () => {
        axios
          .post(
            `${REACT_APP_URL}/api/auth/remoteDevice`,
            this.state.submitted_data,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.error) {
              this.setState({
                error: res.data.error,
              });
            } else {
              this.setState(
                {
                  success_message: "Job Schedule Successfully Updated",
                  success_alert: true,
                  editBox: "",
                  modal: false,
                },
                this.props.alertModel()
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  };

  render() {
    const reboot_time = JSON.parse(this.state.modal_data.reboot_time);
    const sleep_on = JSON.parse(this.state.modal_data.sleep_on_time);
    const sleep_off = JSON.parse(this.state.modal_data.sleep_off_time);

    return (
      <>
        <i
          className="mdi mdi-clock-check-outline iconHover"
          style={{ color: "#20C0ED", fontSize: "21px" }}
          role="button"
          onClick={(e) => {
            this.toggle();
          }}
          id={`schedule_events_${this.state.modal_data.id}`}
        ></i>
        <UncontrolledTooltip
          placement="top"
          target={`schedule_events_${this.state.modal_data.id}`}
        >
          Scheduled Events
        </UncontrolledTooltip>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          autoFocus={true}
          centered={true}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>Schedule Events</ModalHeader>
          <ModalBody>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <Table className="table table-bordered mb-0">
                        <tbody>
                          <tr>
                            <th>Reboot Time</th>
                            <td>
                              {reboot_time ? (
                                this.state.editBox == "editRebootTime" ? (
                                  <div>
                                    <FormGroup className="mb-0">
                                      <Label>Repeat</Label>
                                      <br></br>
                                      <div>
                                        <Table className="table-centered table-vertical table-nowrap">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <label>Monday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          monday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          monday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={reboot_time[
                                                    "Job Days"
                                                  ].includes("Mon")}
                                                  name="monday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Tuesday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          tuesday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          tuesday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={reboot_time[
                                                    "Job Days"
                                                  ].includes("Tue")}
                                                  name="tuesday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Wednesday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          wednesday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          wednesday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={reboot_time[
                                                    "Job Days"
                                                  ].includes("Wed")}
                                                  name="wednesday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Thursday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          thursday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          thursday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={reboot_time[
                                                    "Job Days"
                                                  ].includes("Thr")}
                                                  name="thursday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Friday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          friday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          friday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={reboot_time[
                                                    "Job Days"
                                                  ].includes("Fri")}
                                                  name="friday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Saturday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          saturday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          saturday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={reboot_time[
                                                    "Job Days"
                                                  ].includes("Sat")}
                                                  name="saturday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Sunday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          sunday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          sunday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={reboot_time[
                                                    "Job Days"
                                                  ].includes("Sun")}
                                                  name="sunday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </div>

                                      <Label>Time</Label>
                                      <br></br>
                                      <Input
                                        className="form-control"
                                        name="time"
                                        type="time"
                                        required
                                        id="example-time-input"
                                        defaultValue={reboot_time["Job Time"]}
                                        onChange={this.handleChange}
                                        // errorMessage="Select date and time"
                                        validate={{ required: { value: true } }}
                                      />
                                      <br></br>
                                    </FormGroup>
                                  </div>
                                ) : (
                                  <>
                                    <b>Job Days:</b> {reboot_time["Job Days"]}
                                    <br></br>
                                    <b>Job Time:</b> {reboot_time["Job Time"]}
                                  </>
                                )
                              ) : (
                                "Not Schedule"
                              )}

                              {reboot_time ? (
                                this.state.editBox == "editRebootTime" ? (
                                  <>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="waves-effect waves-light mr-1"
                                      id="sa-title"
                                      onClick={this.handleSubmit}
                                    >
                                      Submit
                                    </Button>

                                    <Button
                                      type="submit"
                                      color="danger"
                                      className="waves-effect waves-light mr-1"
                                      id="sa-title"
                                      onClick={(e) => {
                                        this.setState({
                                          command: "",
                                          editBox: "",
                                          data: {},
                                        });
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <i
                                    className="mdi mdi-square-edit-outline mr-1 iconHover"
                                    style={{
                                      float: "right",
                                      marginTop: "-17px",
                                      color: "#20C0ED",
                                      fontSize: "23px",
                                    }}
                                    role="button"
                                    onClick={() => {
                                      this.setState({
                                        editBox: "editRebootTime",
                                        command: "jobSchedule",
                                        data: {
                                          ...this.state.data,
                                          jobName: "Reboot Device",
                                          time: reboot_time["Job Time"],
                                          monday: reboot_time[
                                            "Job Days"
                                          ].includes("Mon")
                                            ? "true"
                                            : "false",
                                          tuesday: reboot_time[
                                            "Job Days"
                                          ].includes("Tue")
                                            ? "true"
                                            : "false",
                                          wednesday: reboot_time[
                                            "Job Days"
                                          ].includes("Wed")
                                            ? "true"
                                            : "false",
                                          thursday: reboot_time[
                                            "Job Days"
                                          ].includes("Thr")
                                            ? "true"
                                            : "false",
                                          friday: reboot_time[
                                            "Job Days"
                                          ].includes("Fri")
                                            ? "true"
                                            : "false",
                                          saturday: reboot_time[
                                            "Job Days"
                                          ].includes("Sat")
                                            ? "true"
                                            : "false",
                                          sunday: reboot_time[
                                            "Job Days"
                                          ].includes("Sun")
                                            ? "true"
                                            : "false",
                                        },
                                      });
                                    }}
                                  ></i>
                                )
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Sleep On</th>
                            <td>
                              {sleep_on ? (
                                this.state.editBox == "editSleepOn" ? (
                                  <div>
                                    <FormGroup className="mb-0">
                                      <Label>Repeat</Label>
                                      <br></br>
                                      <div>
                                        <Table className="table-centered table-vertical table-nowrap">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <label>Monday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          monday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          monday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_on[
                                                    "Job Days"
                                                  ].includes("Mon")}
                                                  name="monday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Tuesday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          tuesday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          tuesday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_on[
                                                    "Job Days"
                                                  ].includes("Tue")}
                                                  name="tuesday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Wednesday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          wednesday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          wednesday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_on[
                                                    "Job Days"
                                                  ].includes("Wed")}
                                                  name="wednesday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Thursday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          thursday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          thursday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_on[
                                                    "Job Days"
                                                  ].includes("Thr")}
                                                  name="thursday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Friday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          friday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          friday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_on[
                                                    "Job Days"
                                                  ].includes("Fri")}
                                                  name="friday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Saturday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          saturday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          saturday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_on[
                                                    "Job Days"
                                                  ].includes("Sat")}
                                                  name="saturday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Sunday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          sunday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          sunday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_on[
                                                    "Job Days"
                                                  ].includes("Sun")}
                                                  name="sunday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </div>

                                      <Label>Time</Label>
                                      <br></br>
                                      <Input
                                        className="form-control"
                                        name="time"
                                        type="time"
                                        required
                                        id="example-time-input"
                                        defaultValue={sleep_on["Job Time"]}
                                        onChange={this.handleChange}
                                        // errorMessage="Select date and time"
                                        validate={{ required: { value: true } }}
                                      />
                                      <br></br>
                                    </FormGroup>
                                  </div>
                                ) : (
                                  <>
                                    <b>Job Days:</b> {sleep_on["Job Days"]}
                                    <br></br>
                                    <b>Job Time:</b> {sleep_on["Job Time"]}
                                  </>
                                )
                              ) : (
                                "Not Schedule"
                              )}

                              {sleep_on ? (
                                this.state.editBox == "editSleepOn" ? (
                                  <>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="waves-effect waves-light mr-1"
                                      id="sa-title"
                                      onClick={this.handleSubmit}
                                    >
                                      Submit
                                    </Button>

                                    <Button
                                      type="submit"
                                      color="danger"
                                      className="waves-effect waves-light mr-1"
                                      id="sa-title"
                                      onClick={(e) => {
                                        this.setState({
                                          command: "",
                                          editBox: "",
                                          data: {},
                                        });
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <i
                                    className="mdi mdi-square-edit-outline mr-1 iconHover"
                                    style={{
                                      float: "right",
                                      marginTop: "-17px",
                                      color: "#20C0ED",
                                      fontSize: "23px",
                                    }}
                                    role="button"
                                    onClick={() => {
                                      this.setState({
                                        editBox: "editSleepOn",
                                        command: "jobSchedule",
                                        data: {
                                          ...this.state.data,
                                          jobName: "Sleep On",
                                          time: sleep_on["Job Time"],
                                          monday: sleep_on["Job Days"].includes(
                                            "Mon"
                                          )
                                            ? "true"
                                            : "false",
                                          tuesday: sleep_on[
                                            "Job Days"
                                          ].includes("Tue")
                                            ? "true"
                                            : "false",
                                          wednesday: sleep_on[
                                            "Job Days"
                                          ].includes("Wed")
                                            ? "true"
                                            : "false",
                                          thursday: sleep_on[
                                            "Job Days"
                                          ].includes("Thr")
                                            ? "true"
                                            : "false",
                                          friday: sleep_on["Job Days"].includes(
                                            "Fri"
                                          )
                                            ? "true"
                                            : "false",
                                          saturday: sleep_on[
                                            "Job Days"
                                          ].includes("Sat")
                                            ? "true"
                                            : "false",
                                          sunday: sleep_on["Job Days"].includes(
                                            "Sun"
                                          )
                                            ? "true"
                                            : "false",
                                        },
                                      });
                                    }}
                                  ></i>
                                )
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>

                          <tr>
                            <th>Sleep Off</th>

                            <td>
                              {sleep_off ? (
                                this.state.editBox == "editSleepOff" ? (
                                  <div>
                                    <FormGroup className="mb-0">
                                      <Label>Repeat</Label>
                                      <br></br>
                                      <div>
                                        <Table className="table-centered table-vertical table-nowrap">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <label>Monday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          monday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          monday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_off[
                                                    "Job Days"
                                                  ].includes("Mon")}
                                                  name="monday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Tuesday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          tuesday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          tuesday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_off[
                                                    "Job Days"
                                                  ].includes("Tue")}
                                                  name="tuesday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Wednesday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          wednesday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          wednesday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_off[
                                                    "Job Days"
                                                  ].includes("Wed")}
                                                  name="wednesday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Thursday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          thursday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          thursday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_off[
                                                    "Job Days"
                                                  ].includes("Thr")}
                                                  name="thursday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Friday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          friday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          friday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_off[
                                                    "Job Days"
                                                  ].includes("Fri")}
                                                  name="friday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Saturday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          saturday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          saturday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_off[
                                                    "Job Days"
                                                  ].includes("Sat")}
                                                  name="saturday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>
                                                <label>Sunday</label>
                                              </td>
                                              <td>
                                                <input
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          sunday: "true",
                                                        },
                                                      });
                                                    } else {
                                                      this.setState({
                                                        data: {
                                                          ...this.state.data,
                                                          sunday: "false",
                                                        },
                                                      });
                                                    }
                                                  }}
                                                  defaultChecked={sleep_off[
                                                    "Job Days"
                                                  ].includes("Sun")}
                                                  name="sunday"
                                                  type="checkbox"
                                                  className="m-0 form-control-sm ml-5"
                                                />
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </div>

                                      <Label>Time</Label>
                                      <br></br>
                                      <Input
                                        className="form-control"
                                        name="time"
                                        type="time"
                                        required
                                        id="example-time-input"
                                        defaultValue={sleep_off["Job Time"]}
                                        onChange={this.handleChange}
                                        // errorMessage="Select date and time"
                                        validate={{ required: { value: true } }}
                                      />
                                      <br></br>
                                    </FormGroup>
                                  </div>
                                ) : (
                                  <>
                                    <b>Job Days:</b> {sleep_off["Job Days"]}
                                    <br></br>
                                    <b>Job Time:</b> {sleep_off["Job Time"]}
                                  </>
                                )
                              ) : (
                                "Not Schedule"
                              )}

                              {sleep_off ? (
                                this.state.editBox == "editSleepOff" ? (
                                  <>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="waves-effect waves-light mr-1"
                                      id="sa-title"
                                      onClick={this.handleSubmit}
                                    >
                                      Submit
                                    </Button>

                                    <Button
                                      type="submit"
                                      color="danger"
                                      className="waves-effect waves-light mr-1"
                                      id="sa-title"
                                      onClick={(e) => {
                                        this.setState({
                                          command: "",
                                          editBox: "",
                                          data: {},
                                        });
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </>
                                ) : (
                                  <i
                                    className="mdi mdi-square-edit-outline mr-1 iconHover"
                                    style={{
                                      float: "right",
                                      marginTop: "-17px",
                                      color: "#20C0ED",
                                      fontSize: "23px",
                                    }}
                                    role="button"
                                    onClick={() => {
                                      this.setState({
                                        editBox: "editSleepOff",
                                        command: "jobSchedule",
                                        data: {
                                          ...this.state.data,
                                          jobName: "Sleep Off",
                                          time: sleep_off["Job Time"],
                                          monday: sleep_off[
                                            "Job Days"
                                          ].includes("Mon")
                                            ? "true"
                                            : "false",
                                          tuesday: sleep_off[
                                            "Job Days"
                                          ].includes("Tue")
                                            ? "true"
                                            : "false",
                                          wednesday: sleep_off[
                                            "Job Days"
                                          ].includes("Wed")
                                            ? "true"
                                            : "false",
                                          thursday: sleep_off[
                                            "Job Days"
                                          ].includes("Thr")
                                            ? "true"
                                            : "false",
                                          friday: sleep_off[
                                            "Job Days"
                                          ].includes("Fri")
                                            ? "true"
                                            : "false",
                                          saturday: sleep_off[
                                            "Job Days"
                                          ].includes("Sat")
                                            ? "true"
                                            : "false",
                                          sunday: sleep_off[
                                            "Job Days"
                                          ].includes("Sun")
                                            ? "true"
                                            : "false",
                                        },
                                      });
                                    }}
                                  ></i>
                                )
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default ModalPage;
