import firebase from "firebase";
var firebaseConfig = {
  apiKey: "AIzaSyCNZdEtwA2MbKx-GgbQKRt6W2KQy1Zgias",
  authDomain: "mcloud-staging-11cb1.firebaseapp.com",
  projectId: "mcloud-staging-11cb1",
  storageBucket: "mcloud-staging-11cb1.appspot.com",
  messagingSenderId: "864624440788",
  appId: "1:864624440788:web:ee5b68908a729a88c59241",
  measurementId: "G-LZFY1494SY"
};
 
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
 
 
export default db;