import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getUserData(id) {
  return axios.get(`/api/auth/user/${id}`, getHeader());
}

export function storeUser(data) {
  return axios.post("/api/auth/user/create", data, getHeader());
}

export function editUser(id) {
  return axios.get(`/api/auth/user/edit/${id}`, getHeader());
}

export function updateUser(data) {
  return axios.post(`/api/auth/user/update`, data, getHeader());
}

export function deleteUser(id) {
  return axios.get(`/api/auth/deleteUser/${id}`, getHeader());
}
