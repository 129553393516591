import React, { useState, useEffect } from "react";
import { setBreadcrumbItems } from "../../store/actions";
import DeviceForm from "./deviceForm";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import Response from "./Response";
import Address from "./address";

const ActivateDevice = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Manage Devices", link: "#" },
  ]);
  const [data, setData] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [invalidMac, setInvalidMac] = useState([]);

  useEffect(() => {
    props.setBreadcrumbItems("Manage Devices", breadcrumbItems, true);
  }, []);

  const handleCallback = (childData) => {
    setData(childData);
  };

  const invalidMacCallback = (invalidMac) => {
    setInvalidMac(invalidMac);
  };

  const handleResponse = () => {
    props.setBreadcrumbItems("Manage Devices", breadcrumbItems, false);
  };

  const handleCustomer = (childData) => {
    setCustomer(childData);
  };

  const handleCustomerData = (childData) => {
    setCustomerData(childData);
  };

  return (
    <>
      <Row>
        <Col lg="3">
          <Address
            macAddress={props?.location.aboutProps}
            parentCallback={handleCallback}
            invalidMacCallback={invalidMacCallback}
            customerCallback={handleCustomer}
            customerDataCallback={handleCustomerData}
          />
        </Col>

        <Col lg="3">
          <DeviceForm
            parentData={data}
            invalidMac={invalidMac}
            customer={customer}
          />
        </Col>

        <Col lg="6">
          <Response
            parentCallback={handleResponse}
            customerData={customerData}
          />
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(ActivateDevice);
