import axios from "axios";
import { getToken } from "./../services/authService";
import secureLocalStorage from "react-secure-storage";

//Set the logged in user data in local session
const setLoggeedInUser = (response) => {
  secureLocalStorage.setItem("user", JSON.stringify(response.user));
  secureLocalStorage.setItem("auth", JSON.stringify(response.access_token));
};

const getLoggedInUser = () => {
  if (!secureLocalStorage.getItem("user")) return null;
  return JSON.parse(secureLocalStorage.getItem("user"));
};

const setPermissions = (permisisons) => {
  secureLocalStorage.setItem("permissions", JSON.stringify(permisisons));
};
const getPermissions = () => {
  if (!secureLocalStorage.getItem("permissions")) return [];
  return JSON.parse(secureLocalStorage.getItem("permissions"));
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const updateLoggedInUser = (user) => {
  secureLocalStorage.setItem("twoFactorStatus", true);
  secureLocalStorage.setItem("user", JSON.stringify(user));
};

//is user is logged in
const isTwoFactorAuth = () => {
  if (!secureLocalStorage.getItem("twoFactorStatus")) return null;
  return true;
};

//is user is logged in
const isUserTwoFactorAuth = () => {
  return isTwoFactorAuth() != null;
};

// Register Method
const postRegister = (url, data) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        response.json().then((json) => {
          resolve(json);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Login Method
const postLogin = (url, data) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        response.json().then((json) => {
          resolve(json);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

//validateTwoFA
const validateTwoFA = (url, data) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        response.json().then((json) => {
          resolve(json);
        });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// postForgetPwd
const postForgetPwd = (url, data) => {
  return axios
    .post(url, data)
    .then((response) => {
      if (response.status === 400 || response.status === 500)
        throw response.data;
      return response.data;
    })
    .catch((err) => {
      throw err[1];
    });
};

export {
  setLoggeedInUser,
  updateLoggedInUser,
  getLoggedInUser,
  isUserAuthenticated,
  postRegister,
  postLogin,
  validateTwoFA,
  postForgetPwd,
  isTwoFactorAuth,
  isUserTwoFactorAuth,
  setPermissions,
  getPermissions,
};
