import React, { useState, useEffect } from "react";
import ModalLayout from "../../component/Common/ModalLayout";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import { Button, UncontrolledTooltip } from "reactstrap";
import secureLocalStorage from "react-secure-storage";
import { Col, Row, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteMimoApk } from "./redux/mimoApkApi";
import EditMimoApk from "./EditMimoApk";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import MimoCreate from "./MimoCreate";
import "./datatables.scss";
import axios from "axios";

const MimoApks = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Mimo Apks", link: "/mimo-apks" },
  ]);
  const [mimoApkData, setMimoApkData] = useState([]);
  const [mimoApkId, setMimoApkId] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [user] = useState(JSON.parse(secureLocalStorage.getItem("user")));
  const [modalShow, setModalShow] = useState(false);
  const [modalShowCreate, setModalShowCreate] = useState(false);

  useEffect(() => {
    if (modalShow || modalShowCreate) return;
    props.setBreadcrumbItems("Mimo Apks", breadcrumbItems, true);

    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    axios
      .get(
        `${REACT_APP_URL}/api/auth/mimoapk`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        data
      )
      .then((res) => {
        setMimoApkData(res.data);
      })
      .catch((error) => {
        console.error(error);
      });

    props.setBreadcrumbItems("Mimo Apks", breadcrumbItems, false);
  }, [modalShow, modalShowCreate]);

  const handleDelete = async () => {
    props.setBreadcrumbItems("Mimo Apks", breadcrumbItems, true);

    try {
      await deleteMimoApk(mimoApkId);

      const currentData = mimoApkData.filter((item) => item.id !== mimoApkId);
      setSuccessAlert(true);
      setDescription("Data deleted successfully.");
      setMimoApkData(currentData);
      props.history.replace("/mimo-apks");
    } catch (ex) {
      console.error(ex);
      if (ex.response) {
        setErrorAlert(true);
        setDescription(ex.response.data.message);
      }
    }

    props.setBreadcrumbItems("Mimo Apks", breadcrumbItems, false);
  };

  const copyToClipboard = (obj) => {
    const { REACT_APP_URL } = process.env;
    var textArea = document.createElement("textarea");
    textArea.value = REACT_APP_URL + "/" + obj.app_name + "/" + obj.file_name;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  const tableData = {
    columns: [
      {
        label: "Application Name",
        field: "app_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Version Name",
        field: "version_name",
        sort: "asc",
        width: 270,
      },

      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 270,
      },

      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 200,
      },
    ],
    rows: mimoApkData.map((obj) => ({
      ...obj,
      actions: (
        <>
          {getCurrentUser().role_id === 3 ? (
            <>
              <i
                className="mdi mdi-content-copy  mr-1 iconHover"
                style={{
                  color: "#7EB69C",
                  fontSize: "23px",
                }}
                role="button"
                id="copyURL"
                onClick={() => {
                  copyToClipboard(obj);
                }}
              ></i>
              <UncontrolledTooltip placement="top" target="copyURL">
                Copy URL
              </UncontrolledTooltip>

              <i
                className="mdi mdi-square-edit-outline mr-1 iconHover"
                style={{ color: "#20C0ED", fontSize: "23px" }}
                role="button"
                onClick={() => {
                  setMimoApkId(obj.id);
                  toggleModal();
                }}
              ></i>

              <i
                className="mdi mdi mdi-delete  mr-1 iconHover"
                style={{
                  color: "#DB4C3F",
                  fontSize: "23px",
                }}
                role="button"
                onClick={() => {
                  setConfirmAlert(true);
                  setMimoApkId(obj.id);
                }}
              ></i>
            </>
          ) : (
            <>
              <i
                className="mdi mdi-content-copy  mr-1 iconHover"
                style={{
                  color: "#7EB69C",
                  fontSize: "23px",
                }}
                role="button"
                id="copyURL"
                onClick={() => copyToClipboard(obj)}
              ></i>
              <UncontrolledTooltip placement="top" target="copyURL">
                Copy URL
              </UncontrolledTooltip>
            </>
          )}
        </>
      ),
    })),
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              {user.role_id === 3 ? (
                <Button
                  onClick={toggleModalCreate}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                >
                  Upload Mimo Apk
                </Button>
              ) : (
                ""
              )}

              <MDBDataTable responsive bordered striped data={tableData} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modalShow ? (
        <ModalLayout
          toggleModal={toggleModal}
          isOpen={modalShow}
          title="Edit Mimo Apk"
          size="lg"
        >
          <EditMimoApk
            mimoApkId={mimoApkId}
            toggleModal={toggleModal}
            setSuccessAlert={setSuccessAlert}
            setDescription={setDescription}
            setErrorAlert={setErrorAlert}
          />
        </ModalLayout>
      ) : (
        ""
      )}

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Upload Mimo Apk"
          size="lg"
        >
          <MimoCreate
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(MimoApks);
