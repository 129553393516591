import React, { useState, useEffect } from "react";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import ModalLayout from "../../component/Common/ModalLayout";
import { deleteGroup, getGroupData } from "./redux/GroupApi";
import { getCurrentUser } from "../../services/authService";
import { Permissions } from "../../services/permissions";
import { setBreadcrumbItems } from "../../store/actions";
import { AvForm } from "availity-reactstrap-validation";
import { Col, Card, CardBody, Row } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import ProfileModal from "./ProfileModal";
import { MDBDataTable } from "mdbreact";
import CreateGroup from "./CreateGroup";
import { connect } from "react-redux";
import ViewModal from "./ViewModal";
import { Button } from "reactstrap";
import EditGroup from "./EditGroup";
import "./datatables.scss";

const Group = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Device Groups", link: "/group" },
  ]);
  const [groupData, setGroupData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [resellerId, setResellerId] = useState("all");
  const [customerId, setCustomerId] = useState("all");
  const [groupId, setGroupId] = useState(null);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [groupObject, setGroupObject] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalShowCreate, setModalShowCreate] = useState(false);

  useEffect(() => {
    if (modalShow || modalShowCreate) return;
    (async () => {
      props.setBreadcrumbItems("Device Groups", breadcrumbItems, true);

      try {
        const { data: groupData } = await getGroupData(
          getCurrentUser().role_id === 2
            ? getCurrentUser().customer_id
            : customerId
        );

        setGroupData(groupData);
        setFilteredData(groupData);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Device Groups", breadcrumbItems, false);
    })();
  }, [modalShow, modalShowCreate]);

  const handleReseller = (id) => {
    setResellerId(id);
    setCustomerId("all");
    setFilteredData(
      groupData.filter((item) => (id === "all" ? item : item.resellerId == id))
    );
  };

  const handleCustomer = (id) => {
    setCustomerId(id === "all" ? "all" : parseInt(id));
    setFilteredData(
      groupData
        .filter((item) => (id === "all" ? item : item.customer_id == id))
        .filter((item) =>
          resellerId === "all" ? item : item.resellerId == resellerId
        )
    );
  };

  const handleDelete = async () => {
    props.setBreadcrumbItems("Device Groups", breadcrumbItems, true);

    try {
      await deleteGroup(groupId);
      const currentData = groupData.filter((item) => item.id !== groupId);

      setGroupData(currentData);
      setFilteredData(
        currentData
          .filter((item) =>
            resellerId === "all" ? item : item.resellerId == resellerId
          )
          .filter((item) =>
            customerId === "all" ? item : item.customer_id == customerId
          )
      );
      setSuccessAlert(true);
      setDescription("Group has been successfully deleted");
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("Device Groups", breadcrumbItems, false);
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  const dataTable = {
    columns: [
      {
        label: "Group Name",
        field: "group_name",
        sort: "asc",
      },

      {
        label: "Wifi Mac Address",
        field: "macAddress",
        sort: "asc",
      },

      {
        label: "Actions",
        field: "actions",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: filteredData.map((obj) => ({
      ...obj,

      macAddress: <ViewModal key={obj.id} data={obj} />,

      actions: (
        <>
          {Permissions().editGroupPermi ? (
            <i
              className="mdi mdi-square-edit-outline mr-1 iconHover"
              style={{ color: "#20C0ED", fontSize: "23px" }}
              role="button"
              onClick={() => {
                setGroupObject(obj);
                toggleModal();
              }}
            ></i>
          ) : (
            ""
          )}

          {Permissions().deleteGroupPermi ? (
            <i
              className="mdi mdi mdi-delete  mr-1 iconHover"
              style={{
                color: "#DB4C3F",
                fontSize: "23px",
              }}
              role="button"
              onClick={() => {
                setGroupId(obj.id);
                setConfirmAlert(true);
              }}
            ></i>
          ) : (
            ""
          )}
        </>
      ),

    })),
  };

  return (
    <>
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}

      <Col xs="12">
        <Card>
          <CardBody>
            <AvForm>
              <Col xs="12">
                <Row className="align-items-center mb-3">
                  {getCurrentUser().role_id === 3 ? (
                    <Col xs="3">
                      <SelectReseller
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        handleReseller={handleReseller}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {getCurrentUser().role_id !== 2 ? (
                    <Col xs="3">
                      <SelectCustomer
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        customerId={customerId}
                        handleCustomer={handleCustomer}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {Permissions().createProfilePermi ? (
                    <Col xs="2">
                      <Button
                        style={{ marginTop: "10px" }}
                        onClick={toggleModalCreate}
                      >
                        Create Group
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </AvForm>

            <MDBDataTable
              className="custom-css"
              responsive
              bordered
              striped
              entries={100}
              data={dataTable}
            />
          </CardBody>
        </Card>
      </Col>

      {modalShow ? (
        <ModalLayout
          toggleModal={toggleModal}
          isOpen={modalShow}
          title="Edit Group"
          size="1500px"
        >
          <EditGroup
            groupObject={groupObject}
            toggleModal={toggleModal}
            setSuccessAlert={setSuccessAlert}
            setDescription={setDescription}
            setErrorAlert={setErrorAlert}
          />
        </ModalLayout>
      ) : (
        ""
      )}

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Create Group"
          size="1500px"
        >
          <CreateGroup
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(Group);
