import React from "react";
import PolarChart from "../AllCharts/chartjs/polarchart";
import { Col, Row, Card, CardBody } from "reactstrap";

const MserviceVersion = ({ reports }) => {
  return (
    <>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">MService Versions</h4>
              <PolarChart reports={reports} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MserviceVersion;
