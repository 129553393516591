import React, { useState, useEffect } from "react";
import {
  deleteFirmware,
  getFirmHardware,
  deleteFirmHardware,
  getFirmwareDetail,
} from "./redux/firmwareImagesApi";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Table,
  UncontrolledPopover,
  PopoverBody,
} from "reactstrap";
import ModalLayout from "../../component/Common/ModalLayout";
import { setBreadcrumbItems } from "../../store/actions";
import CreateFirmHardware from "./CreateFirmHardware";
import SweetAlert from "react-bootstrap-sweetalert";
import CreateFirmVersion from "./CreateFirmVersion";
import EditFirmHardware from "./EditFirmHardware";
import EditFirmVersion from "./EditFirmVersion";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";

const FirmHardwareProfile = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Products", link: "/firmware-product" },
    { title: "Hardware Profiles", link: "#" },
  ]);
  const [hardwareData, setHardwareData] = useState([]);
  const [hardwareId, setHardwareId] = useState(null);
  const [firmwareData, setFirmwareData] = useState([]);
  const [firmwareId, setFirmwareId] = useState(null);
  const [hardwareDetail, setHardwareDetail] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [confirmAlertFirmware, setConfirmAlertFirmware] = useState(false);
  const [highLightId, setHighLightId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowFirmware, setmodalShowFirmware] = useState(false);
  const [modalShowCreate, setModalShowCreate] = useState(false);
  const [modalShowCreateFirm, setModalShowCreateFirm] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (modalShow || modalShowCreate) {
      return;
    }  
    (async () => {
      props.setBreadcrumbItems("Hardware Profiles", breadcrumbItems, true);
      try {
        const {
          data: { data: hardwareData },
        } = await getFirmHardware(props.match.params.id);
        setHardwareData(hardwareData);
     } catch (ex) {
        console.error(ex);
      }
      props.setBreadcrumbItems("Hardware Profiles", breadcrumbItems, false);
    })();
  }, [modalShow, modalShowCreate]);

  useEffect(() => {
    if (modalShowCreateFirm) {
      setUploaded(false);
      return;
    }
    if (modalShowFirmware) {
      setUpdate(false);
      return;
    }
    (async () => {
      props.setBreadcrumbItems("Hardware Profiles", breadcrumbItems, true);
      try {
      if(uploaded || update)
      {
        const { data: firmwareData } = await getFirmwareDetail(hardwareDetail.id);
        setFirmwareData(firmwareData);
        if(update)setUpdate(false);
        if(uploaded)setUploaded(false);
      }
     } catch (ex) {
        console.error(ex);
      }
      props.setBreadcrumbItems("Hardware Profiles", breadcrumbItems, false);
    })();
  }, [modalShowCreateFirm, modalShowFirmware]);

  const handleFirmware = (id,called) => {
    (async () => {
      try {
        if(id!==null)
        {
        const { data: firmwareData } = await getFirmwareDetail(id);
        setFirmwareData(firmwareData);
      }
     }
      catch (ex) {
        console.error(ex);
      }
    })();
  };

  const hardwareDelete = () => {
    (async () => {
      props.setBreadcrumbItems("Hardware Profiles", breadcrumbItems, true);

      try {
        const {
          data: { message: message },
        } = await deleteFirmHardware(hardwareId);

        const currentData = hardwareData.filter(
          (item) => item.id !== hardwareId
        );
        setSuccessAlert(true);
        setDescription(message);
        setHardwareData(currentData);
        setHighLightId(null);
        setHardwareDetail(null);
        setFirmwareData([]);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Hardware Profiles", breadcrumbItems, false);
    })();
  };

  const firmwareDelete = (obj) => {
    (async () => {
      props.setBreadcrumbItems("Hardware Profiles", breadcrumbItems, true);

      try {
        const {
          data: { message: message },
        } = await deleteFirmware(firmwareId);

        const currentData = firmwareData.filter(
          (item) => item.id !== firmwareId
        );
        setSuccessAlert(true);
        setDescription(message);
        setFirmwareData(currentData);
        setHighLightId(null);
        setHardwareDetail(null);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Hardware Profiles", breadcrumbItems, false);
    })();
  };

  const handleRowClicked = (key) => {
    let column = document.getElementById(key);
    let row = column.parentElement.parentElement;
    row.classList.add("highLight");

    if (highLightId != null && highLightId != key) {
      let column = document.getElementById(highLightId);
      let row = column.parentElement.parentElement;
      row.classList.remove("highLight");
    }

    setHighLightId(key);
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const toggleModalFirmware = () => {
    setmodalShowFirmware(!modalShowFirmware);
    setFirmwareData(firmwareData);
    handleFirmware(hardwareId);
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  const toggleModalCreateFirm = () => {
    setModalShowCreateFirm(!modalShowCreateFirm);
  };

  const hardwareTable = {
    columns: [
      {
        label: "Customer",
        field: "customer",
        sort: "asc",
        width: 270,
      },
      {
        label: "HW",
        field: "hw",
        sort: "asc",
        width: 200,
      },
      {
        label: "Display Interface",
        field: "display_interface",
        sort: "asc",
        width: 200,
      },
      {
        label: "Language",
        field: "language",
        sort: "asc",
        width: 200,
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
        width: 200,
      },
      {
        label: "Orientation",
        field: "orientation",
        sort: "asc",
        width: 200,
      },

      {
        label: "Resolution",
        field: "resolution",
        sort: "asc",
        width: 200,
      },

      {
        label: "Sensor",
        field: "sensor",
        sort: "asc",
        width: 200,
      },

      {
        label: "Serial Number",
        field: "sn",
        sort: "asc",
        width: 200,
      },
      {
        label: "Wifi",
        field: "wifi",
        sort: "asc",
        width: 200,
      },
      {
        label: "LCD Type",
        field: "lcd_type",
        sort: "asc",
        width: 200,
      },
      {
        label: "Timestamp",
        field: "created_at",
        sort: "asc",
        width: 200,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: hardwareData.map((obj, key) => ({
      ...obj,
      actions: (
        <>
          <i
            className="mr-1 mdi mdi-file-eye-outline iconHover"
            style={{ color: "#408EBA", fontSize: "23px" }}
            role="button"
            id={key + 1}
            onClick={() => {
              handleFirmware(obj.id);
              setHardwareDetail(obj);
              handleRowClicked(key + 1);
            }}
          ></i>

          <i
            className="mdi mdi-square-edit-outline mr-1 iconHover"
            style={{ color: "#20C0ED", fontSize: "23px" }}
            role="button"
            onClick={() => {
              toggleModal();
            }}
          ></i>

          <i
            className="mdi mdi mdi-delete  mr-1 iconHover"
            style={{
              color: "#DB4C3F",
              fontSize: "23px",
            }}
            role="button"
            onClick={() => {
              setConfirmAlert(true);
              setHardwareId(obj.id);
            }}
          ></i>
        </>
      ),

      clickEvent: () => [
        handleFirmware(obj.id),
        setHardwareDetail(obj),
        handleRowClicked(key + 1),
      ],
    })),
  };

  const firmwareTable = {
    columns: [
      {
        label: "Version",
        field: "version",
        sort: "asc",
        width: 150,
      },
      {
        label: "Target Version",
        field: "targetVersion",
        sort: "asc",
        width: 270,
      },
      {
        label: "Android OS",
        field: "androidOs",
        sort: "asc",
        width: 270,
      },
      {
        label: "Build Number",
        field: "buildNumber",
        sort: "asc",
        width: 270,
      },
      {
        label: "File",
        field: "url",
        sort: "asc",
        width: 270,
      },
      {
        label: "Comments",
        field: "comments",
        sort: "asc",
        width: 270,
      },
      {
        label: "Release Notes",
        field: "release",
        sort: "asc",
        width: 270,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "disabled",
        width: 200,
      },
    ],
    rows: firmwareData.map((obj, index) => ({
      version: (
        <>
          <i
            id={`versions_${index}`}
            style={{
              cursor: "pointer",
              backgroundColor: "#D0D0D0",
              padding: "0px 5px 0px 5px",
              borderRadius: "5px",
            }}
          >
            <b>{obj.version[0]}</b>
          </i>
          <UncontrolledPopover target={`versions_${index}`} placement="right">
            <PopoverBody>
              {obj.version.map((item, index) => {
                return (
                  <ul className="list-group" key={index}>
                    <li
                      className="list-group-item"
                      style={{ fontSize: "13px" }}
                    >
                      <b>{item}</b>
                    </li>
                  </ul>
                );
              })}
            </PopoverBody>
          </UncontrolledPopover>
        </>
      ),

      targetVersion: obj.targetVersion ? obj.targetVersion : "",

      androidOs: obj.androidOs ? obj.androidOs : "",

      buildNumber: obj.buildNumber ? obj.buildNumber : "",

      url: (
        <a
          className="btn btn-success waves-effect waves-light mr-3"
          href={process.env.REACT_APP_URL + "/" + obj.url}
        >
          <i className="fas fa-download fa-1.2x"></i> Download ({obj.fileSize}
          MB)
        </a>
      ),

      comments: (
        <>
          <i
            id={`comments_${index}`}
            style={{
              fontSize: "20px",
              cursor: "pointer",
            }}
            className="mdi mdi-comment-text md-18 center"
          ></i>
          <UncontrolledPopover target={`comments_${index}`} placement="left">
            <PopoverBody>{obj.comments}</PopoverBody>
          </UncontrolledPopover>
        </>
      ),

      release: obj.release ? (
        <a
          className="btn btn-success waves-effect waves-light mr-3"
          href={process.env.REACT_APP_URL + "/" + obj.release}
        >
          <i className="fas fa-download fa-1.2x"></i> Download
        </a>
      ) : (
        ""
      ),

      actions: (
        <>
          <i
            className="mdi mdi-square-edit-outline mr-1 iconHover"
            style={{ color: "#20C0ED", fontSize: "23px" }}
            role="button"
            onClick={() => {
              setFirmwareId(obj.id);
              toggleModalFirmware();
            }}
          ></i>

          <i
            className="mdi mdi mdi-delete  mr-1 iconHover"
            style={{
              color: "#DB4C3F",
              fontSize: "23px",
            }}
            role="button"
            onClick={() => {
              setConfirmAlertFirmware(true);
              setFirmwareId(obj.id);
            }}
          ></i>
        </>
      ),
    })),
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            hardwareDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {confirmAlertFirmware ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlertFirmware(false);
            firmwareDelete();
          }}
          onCancel={() => setConfirmAlertFirmware(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      {/* Hardware */}
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Button
                style={{ marginRight: "10px", marginBottom: "10px" }}
                onClick={toggleModalCreate}
              >
                Create Hardware
              </Button>

              <MDBDataTable responsive bordered striped data={hardwareTable} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Firmware */}
      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              {hardwareDetail ? (
                <>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Hardware Information</h4>

                      <Row>
                        <Col style={{ fontSize: "14px" }}>
                          <div className="table-responsive">
                            <Table className="table-centered table-vertical table-nowrap">
                              <tbody>
                                <tr>
                                  <td>
                                    <p>
                                      <strong>Customer</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{hardwareDetail.customer}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      <strong>HW</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{hardwareDetail.hw}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      <strong>Wifi</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{hardwareDetail.wifi}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      <strong>Sensor</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{hardwareDetail.sensor}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      <strong>Display Interface</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{hardwareDetail.display_interface}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      <strong>Timestamp</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{hardwareDetail.created_at}</p>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                        <Col style={{ fontSize: "14px" }}>
                          <div className="table-responsive">
                            <Table className="table-centered table-vertical table-nowrap">
                              <tbody>
                                <tr>
                                  <td>
                                    <p>
                                      <strong>Serial Number</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>
                                      {hardwareDetail.sn
                                        ? hardwareDetail.sn
                                        : "-"}
                                    </p>
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    <p>
                                      <strong>Resolution</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{hardwareDetail.resolution}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      <strong>Orientation</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{hardwareDetail.orientation}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      <strong>LCD Type</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{hardwareDetail.lcd_type}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      <strong>Country</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{hardwareDetail.country}</p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p>
                                      <strong>Language</strong>
                                    </p>
                                  </td>
                                  <td>
                                    <p>{hardwareDetail.language}</p>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Button
                    style={{ marginRight: "10px", marginBottom: "10px" }}
                    onClick={toggleModalCreateFirm}
                  >
                    Upload Firmware
                  </Button>
                </>
              ) : (
                ""
              )}

              <MDBDataTable responsive bordered striped data={firmwareTable} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modalShow ? (
        <ModalLayout
          toggleModal={toggleModal}
          isOpen={modalShow}
          title="Edit Hardware"
          size="lg"
        >
          <EditFirmHardware
            hardwareId={hardwareDetail.id}
            customer={hardwareDetail.customer}
            toggleModal={toggleModal}
            setSuccessAlert={setSuccessAlert}
            setDescription={setDescription}
            setErrorAlert={setErrorAlert}
          />
        </ModalLayout>
      ) : (
        ""
      )}

      {modalShowFirmware ? (
        <ModalLayout
          toggleModal={toggleModalFirmware}
          isOpen={modalShowFirmware}
          title="Edit Firmware"
          size="lg"
        >
          <EditFirmVersion
            firmwareId={firmwareId}
            setUpdate={setUpdate}
            toggleModal={toggleModalFirmware}
            setSuccessAlert={setSuccessAlert}
            setDescription={setDescription}
            setErrorAlert={setErrorAlert}
          />
        </ModalLayout>
      ) : (
        ""
      )}

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Create Hardware"
          size="lg"
        >
          <CreateFirmHardware
            productId={props.match.params.id}
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}

      {modalShowCreateFirm ? (
        <ModalLayout
          toggleModal={toggleModalCreateFirm}
          isOpen={modalShowCreateFirm}
          title="Upload Firmware"
          size="lg"
        >
          <CreateFirmVersion
            setUploaded={setUploaded}
            hardwareId={hardwareDetail.id}
            toggleModal={toggleModalCreateFirm}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(FirmHardwareProfile);
