import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { VALIDATE_2FA } from "./actionTypes";
import { updateLoggedInUser } from "../../../helpers/authUtils";
import { validateTwoFA, getLoggedInUser } from "../../../helpers/authUtils";
import { twoFASuccessful, apiError } from "./actions";

//If user is 2FA then dispatch redux action's are directly from here.
function* twoFactorAuth({ payload: { code, history } }) {
  try {
    const { REACT_APP_URL } = process.env;
    const response = yield call(
      validateTwoFA,
      `${REACT_APP_URL}/api/auth/validateTwoFA`,
      {
        code: code,
      }
    );

    if (response.error) {
      yield put(apiError(response.error));
    } else {
      const userData = getLoggedInUser();
      Object.keys(userData).forEach(() => {
        userData["two_factor_verified_at"] = response.date;
      });
      updateLoggedInUser(userData);
      yield put(twoFASuccessful(true));
      {
        history.push("/dashboard");
      }
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchTwoFactorAuth() {
  yield takeEvery(VALIDATE_2FA, twoFactorAuth);
}

function* twoFactorAuthSaga() {
  yield all([fork(watchTwoFactorAuth)]);
}

export default twoFactorAuthSaga;
