import React, { useState, useEffect } from "react";
import { FormGroup, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { getRoles } from "../RolePermission/redux/RolePermissionApi";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { setBreadcrumbItems } from "../../store/actions";
import { editUser, updateUser } from "./redux/userApi";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";

const EditUser = ({ isOpenEdit, toggleEdit, userId }) => {
  const [userData, setUserData] = useState({
    name: null,
    email: null,
    phone: null,
    user_role_id: null,
  });
  const [roleData, setRoleData] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState(null);
  const [fieldError, setFieldError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data: userData },
        } = await editUser(userId);

        const {
          data: { data: roleData },
        } = await getRoles();

        setUserData(userData);
        setRoleData(roleData);
      } catch (ex) {
        console.error(ex);
      }
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    let parsedValue = value;
    if (name === "user_role_id") {
      parsedValue = parseInt(value);
    }
    setUserData((prevState) => ({
      ...prevState,
      [name]: parsedValue,
    }));
  };

  const validateForm = () => {
    if (!userData.user_role_id) {
      setFieldError("Please select a user role");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const { data: response } = await updateUser(userData);

      setSuccessAlert(true);
      setDescription(response.message);
    } catch (ex) {
      console.error(ex);
      if (ex.response) {
        setErrorAlert(true);
        setDescription(ex.response.data.message);
      }
    }
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          title="Success"
          success
          onConfirm={() => {
            setSuccessAlert(false);
            toggleEdit();
          }}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          title="Failed"
          error
          onConfirm={() => {
            setErrorAlert(false);
          }}
        >
          {description}
        </SweetAlert>
      ) : null}

      <Modal isOpen={isOpenEdit} toggle={toggleEdit} autoFocus={true} size="lg">
        <ModalHeader toggle={toggleEdit}>Edit User</ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleSubmit}>
            <FormGroup>
              <AvField
                name="name"
                label="Name"
                placeholder="Enter user name"
                type="text"
                value={userData.name}
                onChange={handleChange}
                errorMessage="Enter user Name"
                validate={{
                  required: { value: true },
                }}
              />
              <AvField
                name="email"
                label="Email"
                type="email"
                value={userData.email}
                disabled
                style={{ backgroundColor: "#EEEEEF" }}
              />
              <AvField
                name="phone"
                label="Phone Number"
                placeholder="Enter phone number"
                type="number"
                onChange={handleChange}
                value={userData.phone}
                validate={{
                  pattern: {
                    value: "^[0-9]+$",
                    errorMessage: "Enter only numbers",
                  },
                }}
              />

              <AvField
                type="select"
                name="user_role_id"
                label="Assign Role"
                value={userData.user_role_id}
                onChange={handleChange}
                helpMessage={
                  fieldError ? (
                    <span className="text-danger h6">{fieldError}</span>
                  ) : (
                    ""
                  )
                }
              >
                <option value="">-- Select Role --</option>
                {roleData.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </AvField>
            </FormGroup>

            <FormGroup>
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary mr-1"
              >
                Submit
              </Button>

              <Button
                type="reset"
                onClick={toggleEdit}
                color="secondary"
                className="btn btn-secondary ml-1"
              >
                Cancel
              </Button>
            </FormGroup>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(EditUser);
