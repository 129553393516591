import React, { useState } from "react";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Label, Button, Input } from "reactstrap";
import { getCurrentUser } from "../../services/authService";
import { mimoLoader } from "../../store/actions";
import { storeAdmin } from "./redux/adminApi";
import { useDispatch } from "react-redux";

const CreateAdmin = ({
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [adminData, setAdminData] = useState({
    resellerId:
      getCurrentUser().role_id !== 3 ? getCurrentUser().reseller_id : "all",
    role_id: 1,
    name: "",
    email: "",
    phone: "",
    twoFAStatus: "disable",
  });
  const [fieldError, setFieldError] = useState(null);

  const handleReseller = (id) => {
    setAdminData((prevState) => ({
      ...prevState,
      resellerId: id,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdminData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (adminData.resellerId === "all") {
      setFieldError("Please select a reseller");
      return;
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        await storeAdmin(adminData);

        toggleModal();
        setSuccessAlert(true);
        setDescription("Admin has been created successfully");
      } catch (ex) {
        setErrorAlert(true);
        setDescription(ex.response.data.message);
      }
      dispatch(mimoLoader(false));
    })();
  };

  return (
    <>
      <AvForm onValidSubmit={validateForm}>
        {getCurrentUser().role_id === 3 ? (
          <SelectReseller
            className=""
            fieldError={fieldError}
            resellerId={adminData.resellerId}
            handleReseller={handleReseller}
          />
        ) : (
          ""
        )}

        <AvField
          name="name"
          label="Name"
          placeholder="Type Something"
          type="text"
          value={adminData.name}
          onChange={handleChange}
          errorMessage="Enter Name"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="email"
          label="E-Mail  "
          placeholder="Enter Valid Email"
          type="email"
          onChange={handleChange}
          value={adminData.email}
          errorMessage="Invalid Email"
          validate={{
            required: { value: true },
            email: { value: true },
          }}
        />

        <AvField
          name="phone"
          label="Phone Number  "
          placeholder="Enter Phone Number"
          type="number"
          onChange={handleChange}
          value={adminData.phone}
          errorMessage="Enter Only Number"
          validate={{
            required: { value: true },
            pattern: {
              value: "^[0-9]+$",
              errorMessage: "Only Numbers",
            },
          }}
        />

        <FormGroup>
          <Label>Two Factor Authentication</Label>
          <Input type="select" name="twoFAStatus" onChange={handleChange}>
            <option value="disable">Disable</option>
            <option value="enable">Enable</option>
          </Input>
        </FormGroup>

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default CreateAdmin;
