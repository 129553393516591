import React, { useState, useEffect } from "react";
import { getReseller } from "../../pages/ResellerAccounts/redux/resellerApi";
import { AvField } from "availity-reactstrap-validation";

export const SelectReseller = ({
  className,
  fieldError,
  resellerId,
  handleReseller,
}) => {
  const [resellerData, setResellerData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data: resellerData },
        } = await getReseller();

        setResellerData(resellerData);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleChange = (e) => {
    const { value } = e.target;
    handleReseller(value);
  };

  return (
    <AvField
      type="select"
      name="resellerId"
      label="Select Reseller"
      helpMessage={
        fieldError ? <span className="text-danger h6">{fieldError}</span> : ""
      }
      className={className}
      value={resellerId}
      onChange={handleChange}
    >
      <option value="all">All</option>
      {resellerData.map((item) => (
        <option key={item.id} value={item.id}>
          {item.name}
        </option>
      ))}
    </AvField>
  );
};
