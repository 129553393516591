import { VALIDATE_2FA, TWO_FA_SUCCESSFUL, API_FAILED } from "./actionTypes";

export const validate2FA = (code, history) => {
  return {
    type: VALIDATE_2FA,
    payload: { code, history },
  };
};

export const twoFASuccessful = (check2FA) => {
  return {
    type: TWO_FA_SUCCESSFUL,
    payload: check2FA,
  };
};

export const apiError = (apiError) => {
  return {
    type: API_FAILED,
    payload: apiError,
  };
};
