import React, { useState, useEffect } from "react";
import { editReseller, updateReseller } from "./redux/resellerApi";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { mimoLoader } from "../../store/actions";
import { FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";

const EditReseller = ({
  resellerId,
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [resellerData, setResellerData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const {
          data: { data: resellerData },
        } = await editReseller(resellerId);

        setResellerData(resellerData);
      } catch (ex) {
        console.error(ex);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResellerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    dispatch(mimoLoader(true));
    try {
      const { data: response } = await updateReseller(resellerData);

      toggleModal();
      setSuccessAlert(true);
      setDescription(response.message);
    } catch (ex) {
      if (ex.response) {
        console.error(ex.response.data.message);
        setErrorAlert(true);
        setDescription(ex.response.data.message);
      }
    }
    dispatch(mimoLoader(false));
  };

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        <AvField
          name="name"
          label="Name"
          placeholder="Type Something"
          type="text"
          value={resellerData.name}
          onChange={handleChange}
          errorMessage="Enter reseller name"
          validate={{
            required: { value: true },
          }}
        />

        <AvField
          name="email"
          label="E-Mail  "
          placeholder="Enter Valid Email"
          type="email"
          value={resellerData.email}
          disabled={true}
        />

        <AvField
          name="phone"
          label="Phone"
          placeholder="Enter phone number"
          type="number"
          onChange={handleChange}
          value={resellerData.phone}
        />

        <AvField
          name="address"
          label="Address"
          placeholder="Enter address"
          type="text"
          onChange={handleChange}
          value={resellerData.address}
        />

        <FormGroup className="mb-0">
          <div>
            <Button
              type="submit"
              color="primary"
              className="waves-effect waves-light mr-1"
              id="sa-title"
            >
              Submit
            </Button>

            <Button
              type="reset"
              onClick={toggleModal}
              color="secondary"
              className="waves-effect"
              id="e1"
            >
              Cancel
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default EditReseller;
