import React, { Component } from "react";
import {
  Table,
  Card,
  CardBody,
  Label,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import { AvForm } from "availity-reactstrap-validation";
import secureLocalStorage from "react-secure-storage";
import { MDBDataTable, MDBInput } from "mdbreact";
import { connect } from "react-redux";
import axios from "axios";

class address extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      breadcrumbItems: [
        { title: "Mimo", link: "/" },
        { title: "Manage Devices", link: "#" },
      ],

      user: JSON.parse(secureLocalStorage.getItem("user")),
      transactions: [],
      data: [],
      invalidMac: [],

      deviceViewModal: false,
      vmData: {},

      radioCheck: "customerList",

      resellerId: "all",
      customerId: "all",
      filteredData: [],
      customer_data: [],

      activePage: "",

      groupData: [],
      filterGroupData: [],
      groupDeviceCheck: false,
      groupDevices: [],
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidMount() {
    this.props.customerCallback(this.state.customerId);
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    if (this.props?.macAddress) {
      this.setState({
        radioCheck: "customerList",
        customerId: this.props.macAddress.customer_id,
      });
    }

    if (getCurrentUser().role_id === 1) {
      axios
        .get(`${REACT_APP_URL}/api/auth/customer`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((res) => {
          this.setState({ customer_data: res.data }, () => {
            this.props.customerDataCallback(res.data);
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    axios
      .get(
        getCurrentUser().role_id === 1 || getCurrentUser().role_id === 3
          ? `${REACT_APP_URL}/api/auth/getGroups/all`
          : `${REACT_APP_URL}/api/auth/getGroups/${
              getCurrentUser().customer_id
            }`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        this.setState({
          groupData: res.data,
          filterGroupData: res.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(
        getCurrentUser().role_id === 1 || getCurrentUser().role_id === 3
          ? `${REACT_APP_URL}/api/auth/device/all`
          : `${REACT_APP_URL}/api/auth/device/${getCurrentUser().customer_id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
        data
      )
      .then((res) => {
        this.setState(
          {
            transactions: res.data,
            filteredData: this.props?.macAddress
              ? res.data
                  .filter((item) =>
                    item.customer_id === this.props.macAddress.customer_id
                      ? this.props.macAddress.customer_id
                      : getCurrentUser().customer_id
                  )
                  .map((item) => {
                    if (
                      item.mac_address === this.props.macAddress.mac_address
                    ) {
                      return {
                        ...item,
                        isChecked: true,
                      };
                    } else {
                      return item;
                    }
                  })
              : res.data,
          },
          () => {
            this.props.parentCallback(
              this.props?.macAddress ? [this.props.macAddress.mac_address] : []
            );
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleReseller = (id) => {
    this.setState({
      resellerId: id === "all" ? "all" : parseInt(id),
      customerId: "all",
      groupDeviceCheck: false,
      groupDevices: [],
      data: [],
      filterGroupData: this.state.groupData.filter((item) =>
        id == "all" ? item : item.resellerId == id
      ),
      filteredData: this.state.transactions.filter((item) =>
        id == "all" ? item : item.resellerId == id
      ),
    });
  };

  handleCustomer = (id) => {
    this.setState(
      {
        customerId: id === "all" ? "all" : parseInt(id),
        groupDeviceCheck: false,
        groupDevices: [],
        data: [],
        filteredData: this.state.transactions
          .filter((item) =>
            this.state.resellerId === "all"
              ? item
              : item.resellerId === this.state.resellerId
          )
          .filter((item) => (id === "all" ? item : item.customer_id == id)),

        filterGroupData: this.state.groupData
          .filter((item) =>
            this.state.resellerId == "all"
              ? item
              : item.resellerId == this.state.resellerId
          )
          .filter((item) => (id == "all" ? item : item.customer_id == id)),
      },
      () => {
        this.props.parentCallback([]);
        this.props.customerCallback(id);
      }
    );
  };

  // Handling Radio Button
  handleRadioCheck = (e) => {
    this.setState(
      {
        data: [],
        radioCheck: e.target.name,
        filteredData: this.state.transactions.filter((item) =>
          this.state.customerId === "all"
            ? item
            : item.customer_id == this.state.customerId
        ),

        groupDeviceCheck: false,
        groupDevices: [],
      },
      () => {
        this.props.parentCallback([]);
      }
    );
  };

  // Handling Group List
  handleGroup = (e) => {
    if (e.target.value) {
      const macParse = JSON.parse(e.target.value);
      this.setState(
        {
          data: macParse.filter(
            (item) =>
              item !== "00:00:00:00:00:00" && item !== "02:00:00:00:00:00"
          ),
          groupDeviceCheck: true,
          groupDevices: this.state.transactions.filter((item) =>
            macParse.includes(item.mac_address)
          ),
        },
        () => {
          this.props.parentCallback(this.state.data);
        }
      );
    } else {
      this.setState({
        groupDevices: [],
      });
      this.props.parentCallback([]);
    }
  };

  // Handling Checkboxes
  toggleCheckbox = (obj) => {
    this.setState(
      {
        activePage: this.ref.current.state.activePage,
        filteredData: this.state.filteredData.map((item) => {
          if (item.id === obj.id) {
            return {
              ...item,
              isChecked: !item.isChecked,
            };
          } else {
            return { ...item };
          }
        }),
      },
      () => {
        this.ref.current.changeActivePage(this.state.activePage);

        let arr = [];
        let serNum = [];
        this.state.filteredData.map((item) => {
          if (
            item.isChecked &&
            item.isChecked === true &&
            item.mac_address !== "00:00:00:00:00:00" &&
            item.mac_address !== "02:00:00:00:00:00"
          ) {
            arr.push(item.mac_address);
            this.setState({ data: arr }, () => {
              this.props.parentCallback(this.state.data);
            });
          } else if (
            item.isChecked &&
            item.isChecked === true &&
            item.mac_address === "00:00:00:00:00:00" &&
            item.mac_address === "02:00:00:00:00:00"
          ) {
            serNum.push(item.serial_number);
            this.setState({ invalidMac: serNum }, () => {
              this.props.invalidMacCallback(this.state.invalidMac);
            });
          } else {
            this.setState({ invalidMac: serNum, data: arr }, () => {
              this.props.parentCallback(this.state.data);
              this.props.invalidMacCallback(this.state.invalidMac);
            });
          }
        });
      }
    );
  };

  changePage = (activePage) => {
    this.handleTopCheckbox(activePage - 1);
  };

  handleTopCheckbox = (activePage) => {
    let activeItem = this.ref.current.state.pages[activePage];
    let topCheckBox = document.getElementsByClassName("header-checkbox");
    let isActive = activeItem.every((item) => {
      return item.isChecked === true;
    });
    topCheckBox[0].checked = isActive;
    topCheckBox[1].checked = isActive;
  };

  handleAllChecked = (e) => {
    const checkFlag = e.target.checked;
    this.setState({
      activePage: this.ref.current.state.activePage,
    });

    let listOfItems = this.ref.current.state.pages[
      this.ref.current.state.activePage
    ].map((item) => item.id);

    this.setState(
      {
        filteredData: this.state.filteredData.map((item) => {
          if (
            listOfItems.some((obj) => {
              return obj === item.id;
            })
          ) {
            return {
              ...item,
              isChecked: checkFlag,
            };
          } else {
            return { ...item };
          }
        }),
      },
      () => {
        this.ref.current.changeActivePage(this.state.activePage);
        let topCheckBox = document.getElementsByClassName("header-checkbox");
        topCheckBox[0].checked = checkFlag;
        topCheckBox[1].checked = checkFlag;

        let arr = [];
        let serNum = [];
        this.state.filteredData.map((item) => {
          if (
            item.isChecked &&
            item.isChecked === true &&
            item.mac_address !== "00:00:00:00:00:00" &&
            item.mac_address !== "02:00:00:00:00:00"
          ) {
            arr.push(item.mac_address);
            this.setState({ data: arr }, () => {
              this.props.parentCallback(this.state.data);
            });
          } else if (
            item.isChecked &&
            item.isChecked === true &&
            item.mac_address === "00:00:00:00:00:00" &&
            item.mac_address === "02:00:00:00:00:00"
          ) {
            serNum.push(item.serial_number);
            this.setState({ invalidMac: serNum }, () => {
              this.props.invalidMacCallback(this.state.invalidMac);
            });
          } else {
            this.setState({ invalidMac: serNum, data: arr }, () => {
              this.props.parentCallback(this.state.data);
              this.props.invalidMacCallback(this.state.invalidMac);
            });
          }
        });
      }
    );
  };

  // Modal Function
  deviceViewModal = (obj) => {
    this.setState((prevState) => ({
      deviceViewModal: !prevState.deviceViewModal,
      vmData: obj,
    }));
  };

  render() {
    const deviceData = {
      columns: [
        {
          label: "Device Detail",
          field: "deviceDetail",
          sort: "desc",
          width: 200,
        },
        {
          label: (
            <MDBInput
              type="checkbox"
              defaultChecked={false}
              onChange={(e) => {
                this.handleAllChecked(e);
              }}
              className="header-checkbox"
              style={{ fontSize: "2px" }}
            />
          ),
          field: "check",
          sort: "disabled",
        },
      ],

      rows: this.state.filteredData
        ? this.state.filteredData.map((obj, key) => ({
            check: (
              <MDBInput
                key={obj.id}
                type="checkbox"
                value={obj.id}
                checked={obj.isChecked ? obj.isChecked : false}
                onChange={() => {
                  this.toggleCheckbox(obj);
                }}
                style={{ fontSize: "2px" }}
              />
            ),
            isChecked: obj.isChecked ? obj.isChecked : false,
            id: obj.id,

            deviceDetail: (
              <>
                <h5
                  style={{
                    whiteSpace: "pre-line",
                  }}
                  type="button"
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() => {
                    this.deviceViewModal(obj);
                  }}
                >
                  {obj.device_name ? obj.device_name : ""}{" "}
                  {obj.rebootStatus == 0 ? (
                    obj.status == "on" ? (
                      <>
                        <i
                          className="mdi mdi-record-circle"
                          style={{
                            color: "#32CD32",
                            fontSize: "13px",
                          }}
                          id={`online_${key}`}
                        ></i>
                        <UncontrolledTooltip
                          placement="top"
                          target={`online_${key}`}
                        >
                          Online
                        </UncontrolledTooltip>
                      </>
                    ) : obj.status == "off" ? (
                      <>
                        <i
                          className="mdi mdi-record-circle"
                          style={{
                            color: "#DB4C3F",
                            fontSize: "13px",
                          }}
                          id={`offline_${key}`}
                        ></i>
                        <UncontrolledTooltip
                          placement="top"
                          target={`offline_${key}`}
                        >
                          Offline
                        </UncontrolledTooltip>
                      </>
                    ) : (
                      <>
                        <i
                          className="mdi mdi-progress-wrench"
                          style={{ color: "#A67CE1", fontSize: "13px" }}
                          id={`maintenance_${key}`}
                        ></i>
                        <UncontrolledTooltip
                          placement="top"
                          target={`maintenance_${key}`}
                        >
                          Device in Maintenance mode
                        </UncontrolledTooltip>
                      </>
                    )
                  ) : (
                    <>
                      <i
                        className="mdi mdi-record-circle"
                        style={{
                          color: "#F4A930",
                          fontSize: "13px",
                        }}
                        id={`rebootDevice_${key}`}
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={`rebootDevice_${key}`}
                      >
                        Device Reboot
                      </UncontrolledTooltip>
                    </>
                  )}
                </h5>

                {obj.expire == "yes" ? (
                  <img width="20px" height="20px" src="expired.png"></img>
                ) : (
                  ""
                )}

                <p
                  style={{
                    marginBottom: "0.1rem",
                    lineHeight: "1.4",
                  }}
                >
                  <span style={{ color: "#ccc", fontSize: "10px" }}>
                    MAC ADDRESS{" "}
                  </span>
                  <br></br>
                  {obj.mac_address}
                </p>
                <p
                  style={{
                    marginBottom: "0.1rem",
                    lineHeight: "1.4",
                  }}
                >
                  <span style={{ color: "#ccc", fontSize: "10px" }}>
                    IP Address{" "}
                  </span>
                  <br></br>
                  {obj.ip_address}
                </p>
              </>
            ),

            activation_key: obj.activation_key,
            deviceName: obj.device_name,
            serial_number: obj.serial_number,
            mac_address_ethernet: obj.mac_address_ethernet,
            mac_address: obj.mac_address,
            version_number: obj.version_number,
            androidOS: obj.androidOS,
            model_number: obj.model_number,
            running_mimo_apps: obj.running_mimo_apps,
            public_ip: obj.public_ip,
            mlock_version: obj.mlock_version,
            webViewVersion: obj.webViewVersion,
            ip_addres: obj.ip_address,
            firmware: obj.firmware,
            location: obj.location,
            tags: obj.tags,
          }))
        : [],
    };

    const groupDevices = {
      columns: [
        {
          label: "Group Devices",
          field: "macAddress",
          sort: "desc",
          width: 200,
        },
      ],

      rows: this.state.groupDevices
        ? this.state.groupDevices.map((obj, key) => ({
            macAddress: (
              <>
                <h5
                  style={{
                    whiteSpace: "pre-line",
                  }}
                  type="button"
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() => {
                    this.deviceViewModal(obj);
                  }}
                >
                  {obj.device_name ? obj.device_name : ""}{" "}
                  {obj.rebootStatus == 0 ? (
                    obj.status == "on" ? (
                      <>
                        <i
                          className="mdi mdi-record-circle"
                          style={{
                            color: "#32CD32",
                            fontSize: "13px",
                          }}
                          id={`online_${key}`}
                        ></i>
                        <UncontrolledTooltip
                          placement="top"
                          target={`online_${key}`}
                        >
                          Online
                        </UncontrolledTooltip>
                      </>
                    ) : (
                      <>
                        <i
                          className="mdi mdi-record-circle"
                          style={{
                            color: "#DB4C3F",
                            fontSize: "13px",
                          }}
                          id={`offline_${key}`}
                        ></i>
                        <UncontrolledTooltip
                          placement="top"
                          target={`offline_${key}`}
                        >
                          Offline
                        </UncontrolledTooltip>
                      </>
                    )
                  ) : (
                    <>
                      <i
                        className="mdi mdi-record-circle"
                        style={{
                          color: "#F4A930",
                          fontSize: "13px",
                        }}
                        id={`rebootDevice_${key}`}
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={`rebootDevice_${key}`}
                      >
                        Device Reboot
                      </UncontrolledTooltip>
                    </>
                  )}
                </h5>

                {obj.expire == "yes" ? (
                  <img width="20px" height="20px" src="expired.png"></img>
                ) : (
                  ""
                )}

                <p
                  style={{
                    marginBottom: "0.1rem",
                    lineHeight: "1.4",
                  }}
                >
                  <span style={{ color: "#ccc", fontSize: "10px" }}>
                    MAC ADDRESS{" "}
                  </span>
                  <br></br>
                  {obj.mac_address}
                </p>

                <p
                  style={{
                    marginBottom: "0.1rem",
                    lineHeight: "1.4",
                  }}
                >
                  <span style={{ color: "#ccc", fontSize: "10px" }}>
                    IP Address{" "}
                  </span>
                  <br></br>
                  {obj.ip_address}
                </p>
              </>
            ),
          }))
        : [],
    };

    return (
      <>
        <Card>
          <CardBody
            style={{
              overflowY: "auto",
              overflowX: "auto",
              maxHeight: "680px",
            }}
          >
            <h4 className="card-title mb-3">Devices</h4>

            {getCurrentUser().role_id === 3 ? (
              <AvForm>
                <SelectReseller
                  className=""
                  fieldError={null}
                  resellerId={this.state.resellerId}
                  handleReseller={this.handleReseller}
                />
              </AvForm>
            ) : (
              ""
            )}

            {getCurrentUser().role_id !== 2 ? (
              <AvForm>
                <SelectCustomer
                  className=""
                  fieldError={null}
                  resellerId={this.state.resellerId}
                  customerId={this.state.customerId}
                  handleCustomer={this.handleCustomer}
                />
              </AvForm>
            ) : (
              ""
            )}

            <Table className="table-centered table-vertical table-nowrap">
              <tbody>
                <tr>
                  <td>
                    <p style={{ marginBottom: "0.1rem" }}>
                      <strong>Device Groups</strong>
                    </p>
                    <h6>
                      {this.state.groupDevices.length
                        ? `Total Devices (${this.state.groupDevices.length})`
                        : `Total Groups (${this.state.filterGroupData.length})`}
                    </h6>
                  </td>
                  <td>
                    <input
                      onChange={this.handleRadioCheck}
                      name="groupList"
                      type="radio"
                      className=" form-control-md check"
                      checked={
                        this.state.radioCheck === "groupList" ? true : false
                      }
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <p style={{ marginBottom: "0.1rem" }}>
                      <strong>Devices</strong>
                    </p>
                    <h6>Total Devices ({this.state.filteredData.length})</h6>
                  </td>
                  <td>
                    <input
                      onChange={this.handleRadioCheck}
                      name="customerList"
                      type="radio"
                      className=" form-control-md check"
                      checked={
                        this.state.radioCheck === "customerList" ? true : false
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </Table>

            {this.state.radioCheck === "groupList" ? (
              <>
                <hr></hr>
                <h5>Group List</h5>
                <div>
                  <Label>Select Group List</Label>
                  <br></br>
                  <select
                    style={{ marginLeft: "0px", width: "100%" }}
                    required
                    name="list"
                    onChange={this.handleGroup}
                    className="custom-select"
                  >
                    <option defaultValue value="">
                      Select Group
                    </option>

                    {this.state.filterGroupData.map((data) => {
                      return (
                        <option key={data.id} value={data.mac_address}>
                          {data.group_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <hr></hr>
              </>
            ) : (
              ""
            )}

            {this.state.groupDeviceCheck ? (
              <div className="table-responsive">
                <>
                  <MDBDataTable
                    responsive
                    data={groupDevices}
                    displayEntries={false}
                    searching={false}
                    pagesAmount={3}
                    paginationLabel={["<<", ">>"]}
                  />
                </>
              </div>
            ) : (
              ""
            )}

            {this.state.radioCheck === "customerList" ? (
              <div className="table-responsive">
                <>
                  <MDBDataTable
                    responsive
                    bordered
                    striped
                    data={deviceData}
                    ref={this.ref}
                    pagesAmount={3}
                    paginationLabel={["<<", ">>"]}
                    onPageChange={({ activePage }) => {
                      this.changePage(activePage);
                    }}
                  />
                </>
              </div>
            ) : (
              ""
            )}

            <Modal
              isOpen={this.state.deviceViewModal}
              toggle={this.deviceViewModal}
              autoFocus={true}
              size="lg"
            >
              <ModalHeader toggle={this.deviceViewModal}>
                Device Details
                {this.state.vmData.device_name
                  ? "(" + this.state.vmData.device_name + ")"
                  : ""}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <Table className="table table-bordered mb-0">
                            <tbody>
                              <tr>
                                <th>Customer</th>
                                <td>
                                  {this.state.vmData.customer
                                    ? this.state.vmData.customer
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Activation Key</th>
                                <td>
                                  {this.state.vmData.activation_key
                                    ? this.state.vmData.activation_key
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Serial Number</th>
                                <td>
                                  {this.state.vmData.serial_number
                                    ? this.state.vmData.serial_number
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Wifi Mac Address</th>
                                <td>
                                  {this.state.vmData.mac_address
                                    ? this.state.vmData.mac_address
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Ethernet Mac Address</th>
                                <td>
                                  {this.state.vmData.mac_address_ethernet
                                    ? this.state.vmData.mac_address_ethernet
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>IP Address</th>
                                <td>
                                  {this.state.vmData.ip_address
                                    ? this.state.vmData.ip_address
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>MService Version</th>
                                <td>
                                  {this.state.vmData.version_number
                                    ? this.state.vmData.version_number
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>MLock Version</th>
                                <td>
                                  {this.state.vmData.mlock_version
                                    ? this.state.vmData.mlock_version
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>WebView Version</th>
                                <td>
                                  {this.state.vmData.webViewVersion
                                    ? this.state.vmData.webViewVersion
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Running Mimo Apps</th>
                                <td>
                                  {this.state.vmData.running_mimo_apps
                                    ? this.state.vmData.running_mimo_apps
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Android OS</th>
                                <td>
                                  {this.state.vmData.androidOS
                                    ? this.state.vmData.androidOS
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Firmware</th>
                                <td>
                                  {this.state.vmData.firmware
                                    ? this.state.vmData.firmware
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Status</th>
                                <td>
                                  {this.state.vmData.status
                                    ? this.state.vmData.status
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Model Number</th>
                                <td>
                                  {this.state.vmData.model_number
                                    ? this.state.vmData.model_number
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Expire</th>
                                <td>
                                  {this.state.vmData.expire
                                    ? this.state.vmData.expire
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Location</th>
                                <td>
                                  {this.state.vmData.location
                                    ? this.state.vmData.location
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Created At</th>
                                <td>
                                  {this.state.vmData.created_at
                                    ? new Date(
                                        this.state.vmData.created_at
                                      ).toString()
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Last Online</th>
                                <td>
                                  {this.state.vmData?.updated_at
                                    ? new Date(
                                        this.state.vmData?.updated_at
                                      ).toString()
                                    : "-"}
                                </td>
                              </tr>
                              <tr>
                                <th>Public IP</th>
                                <td>
                                  {this.state.vmData.public_ip ? (
                                    <a
                                      href={`https://iplocation.io/ip/${this.state.vmData.public_ip}`}
                                      target="_blank"
                                    >
                                      {this.state.vmData.public_ip}
                                    </a>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>Tags</th>
                                <td>
                                  {this.state.vmData.tags
                                    ? this.state.vmData?.tags
                                    : "-"}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default connect(null, { setBreadcrumbItems })(address);
