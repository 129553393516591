import React from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import PieChart from "../AllCharts/chartjs/piechart";

const AndroidVersions = ({ reports }) => {
  return (
    <>
      <Row>
        <Col lg="12">
          <Card style={{ height: 383, textAlign: "-webkit-center" }}>
            <CardBody className="pie-div">
              <h4 className="card-title mb-4">Android OS Versions</h4>
              <PieChart reports={reports} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AndroidVersions;
