import secureLocalStorage from "react-secure-storage";

export function getToken() {
  let user = secureLocalStorage.getItem("auth");
  return user.replace(/['"]+/g, "");
}

export function getCurrentUser() {
  return JSON.parse(secureLocalStorage.getItem("user"));
}

export function getPermissions() {
  return JSON.parse(secureLocalStorage.getItem("permissions"));
}

export function getHeader() {
  return {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
  };
}
