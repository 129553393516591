import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getSuperAdminDashboard() {
  return axios.get(`/api/auth/superAdminDashboard`, getHeader());
}

export function getResellerDashboard() {
  return axios.get(`/api/auth/resellerDashboard`, getHeader());
}

export function getUserDashboard(customerId) {
  return axios.get(`/api/auth/userDashboard/${customerId}`, getHeader());
}
