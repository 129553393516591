import { getHeader } from "../../../services/authService";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getDevices(customerID) {
  return axios.get(`/api/auth/device/${customerID}`, getHeader());
}

export function storeDevice(data) {
  return axios.post(`/api/auth/device-create`, data, getHeader());
}

export function getLocation(id) {
  return axios.get(`/api/auth/locationStore/${id}`, getHeader());
}

export function selectDeleteDevice(data) {
  return axios.post(`/api/auth/selectDeleteDevice/${data}`, getHeader());
}

export function emailNotiAdmin(data) {
  return axios.post(`/api/auth/emailNotificationAdmin`, data, getHeader());
}

export function emailNotiUser(data) {
  return axios.post(`/api/auth/emailNotificationUser`, data, getHeader());
}

export function saveMaintenanceMode(data) {
  return axios.post(`/api/auth/saveMaintenanceMode`, data, getHeader());
}

export function saveMultiMaintenanceMode(data) {
  return axios.post(`/api/auth/saveMultiMaintenanceMode`, data, getHeader());
}

export function deviceEdit(id) {
  return axios.get(`/api/auth/device/edit/${id}`, getHeader());
}

// View Modal
export function storeTags(data) {
  return axios.post(`/api/auth/saveTags`, data, getHeader());
}

// Device Updates
export function getDeviceUpdate(id) {
  return axios.get(`/api/auth/deviceUpdate/${id}`, getHeader());
}

// Set Direct Commands
export function setDirectCommand(data) {
  return axios.post(`/api/auth/setDirectCommand`, data, getHeader());
}
