import React, { Component } from "react";
import { Pie } from "react-chartjs-2";
import { Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap";

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_large: false,
    };

    this.tog_large = this.tog_large.bind(this);
  }

  tog_large() {
    this.setState((prevState) => ({
      modal_large: !prevState.modal_large,
    }));
  }

  handlePie(res) {
    console.log("Cal", res);
  }

  render() {
    const data = {
      labels: ["Android 5", "Android 6", "Android 8"],
      datasets: [
        {
          data: this.props.reports.androidVersion
            ? this.props.reports.androidVersion
            : [],
          backgroundColor: ["#3C4CCF", "#02A499", "#28bbe3"],
          hoverBackgroundColor: ["#3C4CCF", "#02A499", "#28bbe3"],
          hoverBorderColor: "#fff",
        },
      ],
    };

    return (
      <React.Fragment>
        <Pie
          width={140}
          height={300}
          style={{ maxHeight: 350 }}
          data={data}
          onElementsClick={(res) => {
            this.handlePie(res);
          }}
        />

        {/* <Col sm="6" md="3" className="mt-4">
          <div className="text-center">
            <Button
              type="button"
              color="primary"
              className="waves-effect waves-light"
              onClick={this.tog_large}
            >
              Large modal
            </Button>
          </div>

          <Modal
            isOpen={this.state.modal_large}
            toggle={this.tog_large}
            autoFocus={true}
            size="lg"
          >
            <ModalHeader toggle={this.tog_large}>Android 5</ModalHeader>

            <ModalBody>
              <p className="mb-0">
                Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                cursus magna, vel scelerisque nisl consectetur et. Donec sed
                odio dui. Donec ullamcorper nulla non metus auctor fringilla.
              </p>
            </ModalBody>
          </Modal>
        </Col> */}
      </React.Fragment>
    );
  }
}

export default PieChart;
