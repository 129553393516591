import React from "react";
import ReactApexChart from "react-apexcharts";
import { Card, CardBody } from "reactstrap";

const DeviceModels = ({ reports }) => {
  const pieModel = {
    options: {
      colors: ["#02A499", "#F0F1F4"],
      chart: {
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          columnWidth: "70%",
        },
      },
      grid: {
        borderColor: "#3C4CCF",
        row: {
          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },

      xaxis: {
        categories:
          reports.deviceModels !== undefined
            ? reports.deviceModels.map((item) => {
                return item.modelName;
              })
            : [],

        labels: {
          formatter: function (val) {
            return val;
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },
      fill: {
        opacity: 1,
      },

      legend: {
        show: false,
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
    series: [
      {
        name: "Series A",
        data: [20, 20, 20, 20],
      },
    ],
  };

  const barChartData = {
    series: [
      {
        name: "Total",
        data:
          reports.deviceModels !== undefined
            ? reports.deviceModels.map((item) => {
                return item.total;
              })
            : [],
      },
    ],
  };

  return (
    <>
      <Card style={{ height: "383px" }}>
        <CardBody>
          <h4 className="card-title mb-4">Device Models</h4>

          <div
            id="morris-bar-stacked"
            className="morris-charts morris-charts-height"
            dir="ltr"
          >
            <ReactApexChart
              options={pieModel.options}
              series={barChartData.series}
              type="bar"
              height="290"
            />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default DeviceModels;
