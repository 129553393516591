import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getCustomerUser(id) {
  return axios.get(`/api/auth/getCustomerUser/${id}`, getHeader());
}

export function storeCustomerUser(data) {
  return axios.post("/api/auth/storeCustomerUser", data, getHeader());
}

export function editCustomerUser(id) {
  return axios.get(`/api/auth/editCustomerUser/${id}`, getHeader());
}

export function updateCustomerUser(data) {
  return axios.post(`/api/auth/updateCustomerUser`, data, getHeader());
}

export function deleteCustomerUser(id) {
  return axios.get(`/api/auth/deleteCustomerUser/${id}`, getHeader());
}
