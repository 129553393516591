import React, { Component } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { SetDateAndTime } from "./components/SetDateAndTime";
import { OverlayScreen } from "./components/OverlayScreen";
import { setBreadcrumbItems } from "../../store/actions";
import secureLocalStorage from "react-secure-storage";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import Switch from "react-switch";
import Select from "react-select";
import {
  Table,
  Card,
  CardBody,
  Button,
  FormGroup,
  Input,
  Label,
  UncontrolledTooltip,
  InputGroup,
} from "reactstrap";
import axios from "axios";

const languageOptions = [
  {
    options: [
      { label: "French", value: "fr" },
      { label: "Spanish", value: "es" },
      { label: "German", value: "de" },
      { label: "Japanese", value: "ja" },
      { label: "Italian", value: "it" },
      { label: "Chinese (Simplifies)", value: "zh_CN" },
      { label: "Dutch", value: "nl" },
      { label: "Portuguese", value: "pt" },
      { label: "Russian", value: "ru" },
      { label: "Korean", value: "ko" },
      { label: "Swedish", value: "sv" },
      { label: "Chinese (Traditional)", value: "zh_TW" },
      { label: "English", value: "en" },
      { label: "Norwegian Bokmål", value: "nb" },
      { label: "Arabic", value: "ar" },
      { label: "Polish", value: "pl" },
      { label: "Danish", value: "da" },
      { label: "Turkish", value: "tr" },
      { label: "Portuguese", value: "pt" },
      { label: "Hebrew", value: "iw" },
      { label: "Greek", value: "el" },
      { label: "Finnish", value: "fi" },
      { label: "Ukrainian", value: "uk" },
      { label: "Thai", value: "th" },
      { label: "Czech", value: "cs" },
      { label: "Romanian", value: "ro" },
      { label: "Indonesian", value: "in" },
      { label: "Croatian", value: "hr" },
      { label: "Serbian", value: "sr" },
    ],
  },
];

export const commandsGroup = [
  {
    label: "Activate Software",
    options: [
      { label: "Activate MService", value: "activateMService" },
      { label: "Activate MTemp", value: "activateMTemp" },
    ],
  },

  {
    label: "LEDs",
    options: [
      { label: "Get LED Type", value: "getLedType" },
      { label: "Get LED Color", value: "getLedColor" },
      { label: "Setting LED Colors", value: "light" },
    ],
  },

  {
    label: "System",
    options: [
      { label: "Factory Reset", value: "factoryResetDevice" },
      { label: "Reboot Device", value: "reboot_device" },
      { label: "Switch Off Device", value: "switchOffDevice" },
      { label: "Track Memory Stats", value: "trackMemoryStats" },
      // { label: "Get MService Logs", value: "getMserviceLogs" },
    ],
  },

  {
    label: "Launch/Exit Apps",
    options: [
      { label: "Launch App", value: "launchApp" },
      { label: "Exit and Open Settings", value: "exitAppOpenSettings" },
      { label: "Exit App", value: "exitApp" },
    ],
  },

  {
    label: "Device Settings",
    options: [
      { label: "Set Screen Brightness", value: "setBrightness" },
      { label: "Set System Volume", value: "adjustSystemVolume" },
      { label: "Set Media Volume", value: "adjustMediaVolume" },
      { label: "Set Date & Time", value: "setDateAndTime" },
      { label: "Set System Language", value: "setSystemLanguage" },
      {
        label: "Enable/Disable Physical Buttons",
        value: "enablePhysicalButtons",
      },
      {
        label: "Show/Hide Status Bar",
        value: "showHideStatusBar",
      },
    ],
  },

  {
    label: "Install and Update Software",
    options: [
      { label: "Install System App", value: "installSystemApp" },
      { label: "Install User App", value: "installUserApp" },
      { label: "Update MService", value: "updateMIMOService" },
      { label: "Update WebView", value: "updateWebView" },
      { label: "Update MLock", value: "updateMLock" },
    ],
  },

  {
    label: "Uninstall Software",
    options: [
      { label: "Uninstall System App", value: "uninstallSystemApp" },
      { label: "Uninstall User App", value: "uninstallUserApp" },
    ],
  },

  {
    label: "Internet Configuration",
    options: [
      { label: "Update WIFI Configs", value: "setIPForWifi" },
      { label: "Update Ethernet Configs", value: "setIPForEthernet" },
      { label: "Get IP Configuration", value: "getIpConfigs" },
      { label: "Assign SSID Profile", value: "assignSSIDProfile" },
    ],
  },

  {
    label: "Get Information/Data",
    options: [
      { label: "Get Device Information", value: "getDeviceInfo" },
      { label: "Get Screenshot", value: "getScreenshot" },
      { label: "Get Bug Report", value: "getBugReport" },
      { label: "Get Version Number", value: "version_list" },
      {
        label: "Get Device Temperature (Android 6 & 8 Only)",
        value: "getDeviceTemperature",
      },
      { label: "Get Screen Brightness", value: "getBrightness" },
      { label: "Get Installed Apps List", value: "getInstalledAppsList" },

      { label: "Get Cookie List", value: "getCookieList" },
    ],
  },

  {
    label: "MService",
    options: [
      {
        label: "Update MService Configurations",
        value: "updateMServiceConfigurations",
      },
    ],
  },

  {
    label: "MLock",
    options: [
      { label: "Update MLock Configurations", value: "updateMLockSettings" },
      {
        label: "Reset MLock Configurations to Default",
        value: "resetMLockSettings",
      },
      { label: "Upload Content for MLock", value: "uploadVideoContent" },
    ],
  },

  {
    label: "MTemp",
    options: [
      { label: "Update MTemp Configurations", value: "updateMTempSettings" },
    ],
  },

  {
    label: "MLauncher",
    options: [
      {
        label: "Update MLauncher Configurations",
        value: "updateMLauncherSettings",
      },
    ],
  },

  {
    label: "Firmware",
    options: [
      // {label: "Check for OTA Firmware Update (Rooted)",value: "checkFirmwareUpdateRooted"},
      { label: "Check for OTA Firmware Update", value: "checkFirmwareUpdate" },
    ],
  },

  {
    label: "Profiles",
    options: [{ label: "Use Profiles", value: "profiles" }],
  },

  {
    label: "Job Schedule",
    options: [
      { label: "Schedule a Job", value: "jobSchedule" },
      { label: "Unschedule All Events", value: "unScheduleEvents" },
      { label: "Schedule Future Events", value: "scheduleFutureEvents" },
    ],
  },

  {
    label: "Push Content",
    options: [{ label: "Push File", value: "pushFile" }],
  },
];

class deviceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Mimo", link: "/" },
        { title: "Manage Devices", link: "#" },
      ],
      user: JSON.parse(secureLocalStorage.getItem("user")),

      data: {},
      errors:{},
      command: "",
      hSetting: false,
      title: "",
      description: "",
      confirmAlert: false,
      sSetting: false,
      cState: false,
      aState: false,
      errorDialog: false,
      mState: false,
      submittedData: {},
      videoData: [],
      profileData: [],
      apkData: [],
      mimoApkData: [],
      updateApkData: [],
      mlockApkData: [],
      header: {
        userName: "Admin",
        password: "0000",
      },
      selectedGroup: null,
      selectedCommand: null,
      SSIDRadioButton: "existSSIDProfile",
      passwordEyeIcon: true,
      passwordInputType: "password",

      isOpenOverlay: false,
      overlayData: {
        enableScreenPin: false,
        overlayNewPin: "",
        overlayWebURL: "",
        enableOverlayWebview: false,
        lockScreenTime: 10,
      },

      setDateAndTimeData: {
        automaticDateTime: false,
        automaticTimezone: false,
        date: "",
        time: "",
        timeZone: "",
        use24HourFormat: false,
      },
    };
  }

  handleSelectGroup = (selectedGroup) => {
    this.setState({ selectedGroup });
  };

  handleSelectCommand = (selectedCommand) => {
    this.setState({ selectedCommand });
  };

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customer !== this.props.customer) {
      this.componentDidMount();
    }
  }

  componentDidMount() {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    if (this.state.user.role_id == 2) {
      axios
        .get(
          `${REACT_APP_URL}/api/auth/profiles/${this.state.user.customer_id}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          data
        )
        .then((res) => {
          this.setState({ profileData: res.data });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${REACT_APP_URL}/api/auth/videoUrl/${this.state.user.customer_id}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          data
        )
        .then((res) => {
          this.setState({ videoData: res.data });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${REACT_APP_URL}/api/auth/apkUrl/${this.state.user.customer_id}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          data
        )
        .then((res) => {
          this.setState({ apkData: res.data });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${REACT_APP_URL}/api/auth/mimoApkUrl/${this.state.user.customer_id}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          data
        )
        .then((res) => {
          this.setState({ mimoApkData: res.data });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${REACT_APP_URL}/api/auth/updateApkData/MService`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          data
        )
        .then((res) => {
          this.setState({ updateApkData: res.data });
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(
          `${REACT_APP_URL}/api/auth/updateApkData/MLock`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
          data
        )
        .then((res) => {
          this.setState({ mlockApkData: res.data });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      if (this.props.customer == "all") {
        axios
          .get(
            `${REACT_APP_URL}/api/auth/profiles/all`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ profileData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `${REACT_APP_URL}/api/auth/videoUrl/all`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ videoData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `${REACT_APP_URL}/api/auth/apkUrl/all`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ apkData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `${REACT_APP_URL}/api/auth/mimoApkUrl/all`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ mimoApkData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `${REACT_APP_URL}/api/auth/updateApkData/MService`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ updateApkData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `${REACT_APP_URL}/api/auth/updateApkData/MLock`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ mlockApkData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        axios
          .get(
            `${REACT_APP_URL}/api/auth/profiles/${this.props.customer}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ profileData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `${REACT_APP_URL}/api/auth/videoUrl/${this.props.customer}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ videoData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `${REACT_APP_URL}/api/auth/apkUrl/${this.props.customer}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ apkData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `${REACT_APP_URL}/api/auth/mimoApkUrl/${this.props.customer}`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ mimoApkData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `${REACT_APP_URL}/api/auth/updateApkData/MService`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ updateApkData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });

        axios
          .get(
            `${REACT_APP_URL}/api/auth/updateApkData/MLock`,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
            data
          )
          .then((res) => {
            this.setState({ mlockApkData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (this.props.customer == "") {
        this.setState({
          videoData: [],
          apkData: [],
          mimoApkData: [],
          updateApkData: [],
          profileData: [],
        });
      }
    }
  }

  handleCommandRules = () => {
    this.setState({
        setDateAndTimeData: {
          automaticDateTime: false,
          automaticTimezone: false,
          date: "",
          time: "",
          timeZone: "",
          use24HourFormat: false,
        },
      });
    if (this.state.command == "jobSchedule") {
      this.setState({
        data: {
          monday: "false",
          tuesday: "false",
          wednesday: "false",
          thursday: "false",
          friday: "false",
          saturday: "false",
          sunday: "false",
        },
      });
    } else if (this.state.command === "resetMLockSettings") {
      this.setState({
        data: {
          configration: '{"resetMLock":"true"}',
        },
      });
    } else if (this.state.command == "updateMIMOService") {
      this.setState({
        data: {
          apk_name: "MService.apk",
          mState: false,
        },
      });
    } else if (this.state.command == "updateMLock") {
      this.setState({
        data: {
          apk_name: "MLock.apk",
          mState: false,
        },
      });
    } else if (this.state.command == "updateMServiceConfigurations") {
      this.setState({
        data: {
          startOnReboot: "false",
          autoUpdateMService: "false",
          accessAlowed: "false",
          immediateAutomaticReboot: "false",
          addSSLCertificate: false,
          updateMServicePassword: "",
          whiteListedIps: "",
          newPassword:"",
        },
      });
    } else if (this.state.command == "updateWebView") {
      this.setState({
        data: {
          apk_name: "GoogleWebView.apk",
        },
      });
    } else if (this.state.command == "pushFile") {
      this.setState({
        data: {
          reboot_device: "false",
        },
      });
    } else if (this.state.command == "trackMemoryStats") {
      this.setState({
        data: {
          trackMemoryStats: "true",
        },
      });
    } else if (this.state.command == "enablePhysicalButtons") {
      this.setState({
        data: {
          enablePhysicalButtons: "enabled",
        },
      });
    } else if (this.state.command == "showHideStatusBar") {
      this.setState({
        data: {
          showHideStatusBar: "false",
        },
      });
    } else if (this.state.command === "assignSSIDProfile") {
      this.setState({
        data: {
          SSIDRadioButton: "existSSIDProfile",
          profileName: null,
        },
      });
    } else if (
      this.state.command == "launchApp" ||
      this.state.command == "exitAppOpenSettings" ||
      this.state.command == "exitApp" ||
      this.state.command == "uninstallUserApp"
    ) {
      this.setState({
        data: {
          app_name: this.state.data.app_name ? this.state.data.app_name : "",
        },
      });
    }
    else if (this.state.command === "setDateAndTime") {
      this.setState({
        setDateAndTimeData: {
          automaticDateTime: false,
          automaticTimezone: false,
          date: "",
          time: "",
          timeZone: "",
          use24HourFormat: false,
        },
      });
    }
    else {
      this.setState({
        data: {},
        errors:{},
      });
    }
  };

  handleChange = (event) => {
    if (event.target.name == "mfile") {
      this.setState({
        data: {
          ...this.state.data,
          [event.target.name]: event.target.files[0],
        },
      });
    } else {
      this.setState(
        {
          data: {
            ...this.state.data,
            [event.target.name]: event.target.value,
          },
        },
        () => {
          if (this.state.data == "") {
            this.setState({
              data: [],
            });
          } else {
          }
        }
      );
    }
  };

  setErrorState = (description) => {
    this.setState({
      errorDialog: true,
      title: "Failed",
      description: description,
    });
  };

  handleValidation = () => {
    const { command, data, SSIDRadioButton } = this.state;
    const { parentData, invalidMac } = this.props;

    if (!parentData.length) {
      this.setErrorState("Device is not selected");
    } else if (
      command === "assignSSIDProfile" &&
      SSIDRadioButton === "existSSIDProfile" &&
      data.profileName === null
    ) {
      this.setErrorState("Profile is not selected");
    } else if (
      command === "assignSSIDProfile" &&
      SSIDRadioButton === "newSSIDProfile" &&
      !data.password
    ) {
      this.setErrorState("Profile is not selected");
    } else if (command === "scheduleFutureEvents" && !data.date) {
      this.setErrorState("Profile is not selected");
    }
     else 
     {
      let submittedData = {
        data: data,
        command: command,
        macAddress: parentData,
        invalidMac: invalidMac,
        header: this.state.header,
      };
      if (command === "updateMLockSettings" && this.state.data.mode ==="web") {
        submittedData.data = { ...data, ...this.state.overlayData };
      }
      if(this.state.command === "updateMIMOService" || this.state.command === "updateMLock" || this.state.command === "updateWebView" || this.state.command === "installUserApp" || this.state.command === "installSystemApp") {
        if(this.state.command === "installUserApp" || this.state.command === "installSystemApp"){
          if(!this.state.data.apk_name){
            this.setState({
              errors:{
                apk_name: true
              }
            });
          return;
          }
        }
          if(!this.state.data.apk_url){
            this.setState({
              errors:{
                apk_url: true
              }
            });
          return;
          }
        }
      if(this.state.command === "jobSchedule") {
        if(this.state.data.monday==="false"&&this.state.data.tuesday==="false"&&this.state.data.wednesday==="false"&&this.state.data.thursday==="false"&&this.state.data.friday==="false"&&this.state.data.saturday==="false"&&this.state.data.sunday==="false")
          {
            this.setState({
              errors:{
                day: true
              }
            });
          return;
          }
          if(!this.state.data.jobName){
            this.setState({
              errors:{
                jobName: true
              }
            });
          return;
          }
          if(!this.state.data.time){
            this.setState({
              errors:{
                time: true
              }
            });
          return;
          }
        }
      if(this.state.command === "setIPForEthernet" || this.state.command === "setIPForWifi"){
          if(!this.state.data.ip_mode){
            this.setState({
              errors:{
                ip_mode: true
          }
        });
        return;
          }
      }
      if(this.state.command === "pushFile"){
          const regex = /\.[^/.]+$/;
          if(this.state.data.fileName && !regex.test(this.state.data.fileName)){
            this.setState({
              errors:{
                fileName: true
          }
        });
        return;
          }
      }
      if(this.state.command==="updateMTempSettings"){
      if(!this.state.data.mtemp_mode){
        this.setState({
          errors:{
            mtemp_mode: true
      }
    });
    return;
  }
      }
      if(this.state.command==="setSystemLanguage"){
  if(!this.state.data.language){
    this.setState({
      errors:{
        language: true
      }
  });
  return;
}
      }
      if (command === "setDateAndTime") {
        if(!this.state.setDateAndTimeData.automaticDateTime)
        {
          if(!this.state.setDateAndTimeData.date){
    this.setState({
      errors:{
        date: true
      }
  });
  return;
          }  
          if(!this.state.setDateAndTimeData.time){
    this.setState({
      errors:{
        time: true
      }
  });
  return;
          }  
        }
        if(!this.state.setDateAndTimeData.automaticTimezone)
        {
          if(!this.state.setDateAndTimeData.timeZone){
    this.setState({
      errors:{
        timeZone: true
      }
  });
  return;
          } 
        }
        submittedData.data = { ...data, ...this.state.setDateAndTimeData }; 
      }    
      if(this.state.command==="uploadVideoContent"){
        if(!this.state.data.videoUrl){
          this.setState({
            errors:{
              videoUrl: true
            }
          });
        return;
        }
      }
      this.setState({ submittedData }, this.handleSubmit);
      return;
    }
    this.handleSubmit();
  };

  handleSubmit = async () => {
    const { REACT_APP_URL } = process.env;

    if(this.state.command === "updateMServiceConfigurations"){
      if(!this.state.submittedData.data.addSSLCertificate || this.state.submittedData.data.addSSLCertificate === "false"){
        delete this.state.submittedData.data.sslCertificatePath;
        delete this.state.submittedData.data.sslCertificatePassword;
      }
    }

    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");
    this.props.setBreadcrumbItems(
      "Manage Devices",
      this.state.breadcrumbItems,
      true
    );

    if (this.state.command === "uploadVideoContent") {
      let formData = new FormData();
      formData.append("command", this.state.command);
      formData.append("macAddress", JSON.stringify(this.props.parentData));
      formData.append("header", JSON.stringify(this.state.header));
      for (var key in this.state.data) {
        formData.append(key, this.state.data[key]);
      }
      axios
        .post(`${REACT_APP_URL}/api/auth/fileUpload`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.error) {
            this.setState(
              {
                error: res.data.error,
              },
              () => {
                this.props.setBreadcrumbItems(
                  "Manage Devices",
                  this.state.breadcrumbItems,
                  false
                );
              }
            );
          } else {
            this.setState(
              {
                success: res.data.message,
              },
              () => {
                this.props.setBreadcrumbItems(
                  "Manage Devices",
                  this.state.breadcrumbItems,
                  false
                );
              }
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .post(
          `${REACT_APP_URL}/api/auth/remoteDevice`,
          this.state.submittedData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.error) {
            this.setState(
              {
                error: res.data.error,
              },
              () => {
                this.props.setBreadcrumbItems(
                  "Manage Devices",
                  this.state.breadcrumbItems,
                  false
                );
              }
            );
          } else {
            this.setState(
              {
                success: res.data.message,
              },
              () => {
                this.props.setBreadcrumbItems(
                  "Manage Devices",
                  this.state.breadcrumbItems,
                  false
                );
              }
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  handleConfirm = () => {
    this.setState({
      confirmAlert: true,
      dynamicDescription:
        this.state.command === "switchOffDevice"
          ? "This command will turn the device off and it CANNOT be turned on remotely"
          : "This command will erase all data and applications",
    });
  };

  // Show Password
  handleShowPassword(object) {
    this.setState({
      passwordEyeIcon: object ? true : false,
      passwordInputType: object ? "password" : "text",
    });
  }

  toggleOverlay = () => {
    this.setState(
      {
        isOpenOverlay: !this.state.isOpenOverlay,
      },
      () => {
        if (!this.state.isOpenOverlay) {
          this.setState({
            overlayData: {
              enableScreenPin: false,
              overlayNewPin: "",
              overlayWebURL: "",
              enableOverlayWebview: false,
              lockScreenTime: 10,
            },
          });
        }
      }
    );
  };

  handleOverlayScreen = (data) => {
    this.setState({
      isOpenOverlay: !this.state.isOpenOverlay,
      overlayData: data,
    });
  };

  handleDateAndTime = (name, value) => {
    if(name=='date'|| name =='time'|| name=='timeZone')
    {
      this.setState({
      setDateAndTimeData: {
        ...this.state.setDateAndTimeData,
        [name]: value,
      },
      errors:{
        ...this.state.errors,
        [name]: false
      }
    });
    }
    else if(name=='automaticDateTime'){
    this.setState({
      setDateAndTimeData: {
        ...this.state.setDateAndTimeData,
        [name]: value,
        date:"",
        time:""
      }
    });
  }
    else if(name=='automaticTimezone'){
    this.setState({
      setDateAndTimeData: {
        ...this.state.setDateAndTimeData,
        [name]: value,
        timeZone:"",
      }
    });
  }
    else{
    this.setState({
      setDateAndTimeData: {
        ...this.state.setDateAndTimeData,
        [name]: value,
      }
    });
  }
  };

  render() {
    const {
      command,
      aState,
      mState,
      cState,
      videoData,
      updateApkData,
      apkData,
      mimoApkData,
      mlockApkData,
      profileData,
      header,
      SSIDRadioButton,
      userId,
      sSetting,
      hSetting,
      passwordInputType,
      passwordEyeIcon,
      selectedGroup,
      selectedCommand,
      success,
      confirmAlert,
      description,
      title,
      errorDialog,
      error,
    } = this.state;

    // Assign SSID Profile Command
    function Offsymbol(text) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2,
          }}
        >
          {" "}
          {text}
        </div>
      );
    }
    function OnSymbol(text) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            fontSize: 12,
            color: "#fff",
            paddingRight: 2,
          }}
        >
          {" "}
          {text}
        </div>
      );
    }

    const currentDate = new Date();
    const fullCurrentDate =
      currentDate.getFullYear() +
      "-" +
      (currentDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      currentDate.getDate().toString().padStart(2, "0");

    return (
      <>
        {success ? (
          <SweetAlert
            title="Success"
            success
            onConfirm={() => {
              this.setState({ success: null });
            }}
          >
            {success}
            <br></br>
            <br></br>
            {this.props.invalidMac?.length ? (
              <>
                <h5>
                  Wifi Mac Address not found. Commands can not be issued with
                  the following Serial Number:
                </h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Serial Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.invalidMac?.map((item, index) => (
                      <tr key={index}>
                        <td>{item}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              ""
            )}
          </SweetAlert>
        ) : null}
        {error ? (
          <SweetAlert
            title="Failed"
            error
            onConfirm={() => {
              this.setState({ error: null });
            }}
          >
            {error}
            <br></br>
            <br></br>
            {this.props.invalidMac?.length ? (
              <>
                <h5>
                  Wifi Mac Address not found. Commands can not be issued with
                  the following Serial Number:
                </h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Serial Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.invalidMac?.map((item, index) => (
                      <tr key={index}>
                        <td>{item}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              ""
            )}
          </SweetAlert>
        ) : null}
        {confirmAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              this.setState({ confirmAlert: false }, this.handleValidation)
            }
            onCancel={() =>
              this.setState({
                confirmAlert: false,
                errorDialog: true,
                title: "Cancelled",
                deleted_data: {},
                description: "Command has been cancel",
              })
            }
          >
            {description}
          </SweetAlert>
        ) : null}
        {errorDialog ? (
          <SweetAlert
            error
            title={title}
            onConfirm={() => this.setState({ errorDialog: false })}
          >
            {description}
          </SweetAlert>
        ) : null}

        <Card>
          <CardBody style={{ overflowY: "auto", maxHeight: "680px" }}>
            <FormGroup>
              <div>
                <b>
                  <h5>
                    Selected Devices{" "}
                    {this.props.parentData
                      ? "(" + this.props.parentData.length + ")"
                      : "(" + 0 + ")"}
                  </h5>
                </b>
                <Label>Select Command</Label>
                <br></br>

                <Select
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 0,
                      position: "relative",
                    }),
                  }}
                  required
                  value={selectedCommand}
                  options={commandsGroup}
                  className="select2"
                  autoComplete="nope"
                  name="command"
                  onChange={(e) => {
                    this.setState(
                      {
                        command: e.value,
                        selectedGroup: null,
                        data:{},
                        errors:{}
                      },
                      () => {
                        this.handleSelectCommand(e);
                        this.handleCommandRules();
                      }
                    );
                  }}
                />
              </div>
            </FormGroup>

            <AvForm
              onValidSubmit={
                command == "switchOffDevice" || command == "factoryResetDevice"
                  ? this.handleConfirm
                  : this.handleValidation
              }
            >
              {command == "allIPs" ||
              command == "isFirmwareFileDownloaded" ||
              command == "factoryResetDevice" ||
              command == "getDeviceInfo" ||
              command == "getDeviceTemperature" ||
              command == "getIpConfigs" ||
              command == "getLedType" ||
              command == "getBrightness" ||
              command == "getWhitelist" ||
              command == "version_list" ||
              command == "reboot_device" ||
              command == "switchOffDevice" ||
              command == "updateFirmware" ||
              command == "checkFirmwareUpdateRooted" ||
              command == "checkFirmwareUpdate" ||
              command == "getScreenshot" ||
              command == "getBugReport" ||
              command == "getMserviceLogs" ||
              command == "getInstalledAppsList" ? (
                <div></div>
              ) : (
                ""
              )}
              {/* ===================== Activate MService ===================== */}
              {command == "activateMService" ? (
                <div>
                  <FormGroup className="mb-0">
                    <div>
                      <AvField
                        name="mservice_key"
                        label="MService Activation Key"
                        placeholder="MService activation key"
                        type="text"
                        autoComplete="off"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Please enter a valid activation key for MService issued by MIMO Monitors."
                        onChange={this.handleChange}
                        defaultValue=""
                        errorMessage="Enter Key"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Activate MTemp ===================== */}
              {command == "activateMTemp" ? (
                <div>
                  {/* <h4 className="card-title">Enter Information</h4> */}

                  <FormGroup className="mb-0">
                    <div>
                      <AvField
                        name="mtemp_key"
                        label="MTemp Activation Key"
                        placeholder="MTemp activation key"
                        type="text"
                        autoComplete="off"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Please enter a valid activation key for MService issued by MIMO Monitors."
                        onChange={this.handleChange}
                        defaultValue=""
                        errorMessage="Enter Key"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Download Firmware ===================== */}
              {command == "downloadFirmware" ? (
                <div>
                  {/* <h4 className="card-title">Enter Information</h4> */}

                  <FormGroup className="mb-0">
                    <div>
                      <AvField
                        name="firmware_url"
                        label="Firmware Url"
                        placeholder="Type Something"
                        type="text"
                        autoComplete="off"
                        onChange={this.handleChange}
                        errorMessage="Enter Url"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Install System App ===================== */}
              {command == "installSystemApp" || command == "installUserApp" ? (
                <div>
                  <FormGroup className="mb-0">
                    <div>
                      <AvField
                        name="apk_name"
                        label="Apk Name"
                        placeholder=".apk extension i.e mservice.apk"
                        type="text"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Enter the name(without spaces) you want to give to the downloaded apk file. It should include .apk extension i.e msevice.apk"
                        autoComplete="nope"
                        value={this.state.data.apk_name || ""}
                        onChange={(e) => {
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    apk_name: e.target.value,
                                  },
                                  errors:{
                                    ...this.state.errors,
                                    apk_name: false
                                  }
                                });
                              }}
                      />
                      {this.state.errors.apk_name && <span style={{color:'red'}}>Enter APK Name</span>}


                      {aState == true ? (
                        <AvField
                          name="apk_url"
                          label=" Custom Apk Url"
                          placeholder="Type URL"
                          type="text"
                          autoComplete="off"
                          value={this.state.data.apk_url}
                          onChange={this.handleChange}
                          errorMessage="Enter URL"
                        />
                      ) : (
                        ""
                      )}

                      {aState == false ? (
                        <FormGroup>
                          <div>
                            <Label>Apk URL</Label>
                            <br></br>

                            <select
                              style={{
                                marginLeft: "0px",
                                width: "100%",
                                maxHeight: "50%",
                              }}
                              required
                              onChange={(e) => {
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    apk_url: e.target.value,
                                  },
                                  errors:{
                                    ...this.state.errors,
                                    apk_url: false
                                  }
                                });
                              }}
                              name="apk_url"
                              value={this.state.data.apk_url || ""}
                              className="custom-select"
                            >
                              <option defaultValue value="">
                                Select Apk
                              </option>
                              {apkData.map((key, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={
                                      process.env.REACT_APP_URL + "/" + key.url
                                    }
                                  >
                                    {key.url}
                                  </option>
                                );
                              })}

                              {mimoApkData.map((key, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={
                                      process.env.REACT_APP_URL +
                                      "/" +
                                      key.app_name +
                                      "/" +
                                      key.file_name
                                    }
                                  >
                                    {key.app_name + "/" + key.file_name}
                                  </option>
                                );
                              })}
                            </select>
                            {this.state.errors.apk_url && <span style={{color:'red'}}>Select APK URL</span>}
                          </div>
                        </FormGroup>
                      ) : (
                        ""
                      )}

                      <Table className="table-centered table-vertical table-nowrap">
                        <tbody>
                          <tr>
                            <td>
                              <label>Customize Apk URL</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      aState: true,
                                      data: {
                                        ...this.state.data,
                                        apk_url: "",
                                      },
                                    });
                                  } else {
                                    this.setState({
                                      aState: false,
                                      data: {
                                        ...this.state.data,
                                        apk_url: "",
                                      },
                                    });
                                  }
                                }}
                                name="custom_url"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <hr></hr>
                    </div>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Set Brightness ===================== */}
              {command == "setBrightness" ? (
                <div>
                  <FormGroup className="mb-0">
                    <div>
                      <AvField
                        name="brightness"
                        label="Range Value"
                        placeholder="Number between 0 - 255"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Brightness can be any number between 0 to 255. It sets the screen Brightness of the targeted device."
                        min={0}
                        onChange={this.handleChange}
                        value={this.state.data.brightness}
                        type="number"
                        errorMessage="This value should be between 0 and 255."
                        validate={{
                          required: { value: true },
                          min: { value: 0 },
                          max: { value: 255 },
                        }}
                      />

                      <Button
                        color="success"
                        onClick={() => {
                          this.setState({
                            data: { ...this.state.data, brightness: "255" },
                          });
                        }}
                        className="waves-effect waves-light mr-1"
                        id="sa-title"
                      >
                        Max
                      </Button>
                    </div>
                    <br></br>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Set System Volume ===================== */}
              {command == "adjustSystemVolume" ? (
                <div>
                  <FormGroup className="mb-0">
                    <div>
                      <AvField
                        name="system_volume"
                        label="Range Value"
                        placeholder="Number between 0 - 7"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Volume can be adjusted to any level from 0 to 7. It sets the system volume on the device."
                        min={0}
                        onChange={this.handleChange}
                        value={this.state.data.system_volume}
                        type="number"
                        errorMessage="This value should be between 0 and 7."
                        validate={{
                          required: { value: true },
                          min: { value: 0 },
                          max: { value: 7 },
                        }}
                      />

                      <Button
                        color="success"
                        onClick={() => {
                          this.setState({
                            data: { ...this.state.data, system_volume: "7" },
                          });
                        }}
                        className="waves-effect waves-light mr-1"
                        id="sa-title"
                      >
                        Max
                      </Button>
                    </div>
                  </FormGroup>
                  <br></br>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Set Media Volume ===================== */}
              {command == "adjustMediaVolume" ? (
                <div>
                  <FormGroup className="mb-0">
                    <div>
                      <AvField
                        name="media_volume"
                        label="Range Value"
                        placeholder="Number between 0 - 15"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Volume can be adjusted to any level from 0 to 15.  It sets the system media volume of the device."
                        min={0}
                        onChange={this.handleChange}
                        value={this.state.data.media_volume}
                        type="number"
                        errorMessage="This value should be between 0 and 15."
                        validate={{
                          required: { value: true },
                          min: { value: 0 },
                          max: { value: 15 },
                        }}
                      />

                      <Button
                        color="success"
                        onClick={() => {
                          this.setState({
                            data: { ...this.state.data, media_volume: "15" },
                          });
                        }}
                        className="waves-effect waves-light mr-1"
                        id="sa-title"
                      >
                        Max
                      </Button>
                    </div>
                  </FormGroup>
                  <br></br>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Setting LED Color ===================== */}
              {command == "light" ? (
                <div>
                  <FormGroup className="mb-0">
                    <div>
                      <AvField
                        name="light"
                        label="Enter LED Color"
                        placeholder="LED Color in HEX CODE e.g 00ff00"
                        type="text"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="LED color can be any Hex color code. It turns on the device internal/external LEDs and controls the color of these LEDs."
                        autoComplete="off"
                        value={this.state.data.light}
                        onChange={this.handleChange}
                        errorMessage="Hex Value"
                        validate={{
                          required: { value: true },
                        }}
                      />
                    </div>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Set Device Timezone ===================== */}
              {command == "setDateAndTime" ? (
                <SetDateAndTime
                  setDateAndTimeData={this.state.setDateAndTimeData}
                  errorState = {this.state.errors}
                  handleDateAndTime={this.handleDateAndTime}
                  handleSelectGroup={this.handleSelectGroup}
                />
              ) : (
                ""
              )}

              {/* ===================== Set Device Language ===================== */}
              {command == "setSystemLanguage" ? (
                <div>
                  <FormGroup className="mb-0">
                    <div>
                      <Select
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 0,
                            position: "relative",
                          }),
                        }}
                        required
                        value={selectedGroup}
                        options={languageOptions}
                        className="select2"
                        name="language"
                        autoComplete="nope"
                        onChange={(e) => {
                          this.handleSelectGroup(e);
                          this.setState({
                            data: {
                              language: e.value,
                            },
                            errors:{
                                ...this.state.errors,
                                language: false
                              }
                          });
                        }}
                      />
                      {this.state.errors.language && <span style={{color:'red'}}>Select Language</span>}
                    </div>
                  </FormGroup>
                  <br></br>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Job Schedule ===================== */}
              {command == "jobSchedule" ? (
                <div>
                  <FormGroup className="mb-0">
                    <Label>Repeat</Label>
                    <br></br>
                    <div>
                      <Table className="table-centered table-vertical table-nowrap">
                        <tbody>
                          <tr>
                            <td>
                              <label>Monday</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        monday: "true",
                                      },
                                      errors:{
                                    ...this.state.errors,
                                    day: false
                                  }
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        monday: "false",
                                      },
                                    });
                                  }
                                }}
                                name="monday"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Tuesday</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        tuesday: "true",
                                      },
                                      errors:{
                                    ...this.state.errors,
                                    day: false
                                  }
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        tuesday: "false",
                                      },
                                    });
                                  }
                                }}
                                name="tuesday"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Wednesday</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        wednesday: "true",
                                      },
                                      errors:{
                                    ...this.state.errors,
                                    day: false
                                  }
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        wednesday: "false",
                                      },
                                    });
                                  }
                                }}
                                name="wednesday"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Thursday</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        thursday: "true",
                                      },
                                      errors:{
                                    ...this.state.errors,
                                    day: false
                                  }
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        thursday: "false",
                                      },
                                    });
                                  }
                                }}
                                name="thursday"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Friday</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        friday: "true",
                                      },
                                      errors:{
                                    ...this.state.errors,
                                    day: false
                                  }
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        friday: "false",
                                      },
                                    });
                                  }
                                }}
                                name="friday"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Saturday</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        saturday: "true",
                                      },
                                      errors:{
                                    ...this.state.errors,
                                    day: false
                                  }
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        saturday: "false",
                                      },
                                    });
                                  }
                                }}
                                name="saturday"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>Sunday</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        sunday: "true",
                                      },
                                      errors:{
                                    ...this.state.errors,
                                    day: false
                                  }
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        sunday: "false",
                                      },
                                    });
                                  }
                                }}
                                name="sunday"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      {this.state.errors.day && <span style={{color:'red'}}>Select Day</span>}
                    </div>

                    <Label>Job Name</Label>
                    <br></br>
                    <div>
                      <select
                        style={{
                          marginLeft: "0px",
                          width: "100%",
                          maxHeight: "50%",
                          marginBottom: "5%",
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Select Job"
                        required
                        onChange={(e) => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              jobName: e.target.value,
                            },
                            errors:{
                                    ...this.state.errors,
                                    jobName: false
                                  }
                          });
                        }}
                        name="jobName"
                        className="custom-select"
                      >
                        <option defaultValue value="">
                          Select Job
                        </option>
                        <option value="Reboot Device">Reboot Device</option>
                        <option value="Sleep On">Sleep On</option>
                        <option value="Sleep Off">Sleep Off</option>
                        <option value="minBrightness">Min. Brightness</option>
                        <option value="maxBrightness">Max. Brightness</option>
                      </select>
                      {this.state.errors.jobName && <span style={{color:'red'}}>Select Job</span>}
                    </div>

                    <Label>Time</Label>
                    <br></br>
                    <Input
                      className="form-control"
                      name="time"
                      type="time"
                      required
                      // defaultValue="12:00:00"
                      id="example-time-input"
                      value={this.state.data.time}
                       onChange={(e) => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              time: e.target.value,
                            },
                            errors:{
                                    ...this.state.errors,
                                    time: false
                                  }
                          });
                        }}
                      errorMessage="Select date and time"
                      validate={{ required: { value: true } }}
                    />
                    {this.state.errors.time && <span style={{color:'red'}}>Select Time</span>}
                    <br></br>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Update Service Configurations ===================== */}
              {command == "updateMServiceConfigurations" ? (
                <div>
                  <FormGroup className="mb-0">
                    {/* <Label>Repeat</Label> */}
                    <br></br>
                    <div>
                      <Table className="table-centered table-vertical table-nowrap">
                        <tbody>
                          <tr>
                            <td>
                              <label>Start On Reboot</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        startOnReboot: "true",
                                      },
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        startOnReboot: "false",
                                      },
                                    });
                                  }
                                }}
                                name="startOnReboot"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>
                                Auto Update MService <br></br> B/W 12PM to 6PM
                              </label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        autoUpdateMService: "true",
                                      },
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        autoUpdateMService: "false",
                                      },
                                    });
                                  }
                                }}
                                name="autoUpdateMService"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>
                                Access Alowed To<br></br> Localhost Only
                              </label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        accessAlowed: "true",
                                      },
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        accessAlowed: "false",
                                      },
                                    });
                                  }
                                }}
                                name="accessAlowed"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <label>
                                Allow Immediate Automatic<br></br> Reboot After
                                Intalling APKs
                              </label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        immediateAutomaticReboot: "true",
                                      },
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        immediateAutomaticReboot: "false",
                                      },
                                    });
                                  }
                                }}
                                name="immediateAutomaticReboot"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <label>Add SSL Certificate</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        addSSLCertificate: true,
                                        sslCertificatePath: "",
                                        sslCertificatePassword: "",
                                      },
                                    });
                                  } else {
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        addSSLCertificate: false,
                                      },
                                    });
                                  }
                                }}
                                name="addSSLCertificate"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>

                          {this.state.data.addSSLCertificate && (
                            <>
                              <div className="form-group">
                                <label htmlFor="sslCerificatePath">
                                  {" "}
                                  SSL Certificate Path
                                </label>
                                <AvField
                                  required
                                  onChange={(e) =>
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        sslCertificatePath: e.target.value,
                                      },
                                    })
                                  }
                                  name="sslCertificatePath"
                                  type="text"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  value={this.state.data.sslCertificatePath}
                                  placeholder="Enter ssl certificate path"
                                />
                              </div>

                              <div className="form-group">
                                <label htmlFor="sslCertificatePassword">
                                  SSL Certificate Password
                                </label>
                                <AvField
                                  required
                                  onChange={this.handleChange}
                                  name="sslCertificatePassword"
                                  type="password"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  value={this.state.data.sslCertificatePassword}
                                  placeholder="Enter ssl certificate password"
                                />
                              </div>
                            </>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <div className="form-group">
                      <label htmlFor="updateMServicePassword">MService Password</label>
                      <AvField
                        required
                        onChange={this.handleChange}
                        name="updateMServicePassword"
                        type="password"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        value={this.state.data.updateMServicePassword || ""}
                        placeholder="Enter Password"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="whiteListedIps">
                        Add Whitelisted IPs
                      </label>
                      <AvField
                        onChange={(e) =>
                          this.setState({
                            data: {
                              ...this.state.data,
                              whiteListedIps: e.target.value,
                            },
                          })
                        }
                        name="whiteListedIps"
                        type="text"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        value={this.state.data.whiteListedIps}
                        placeholder="Enter Whitelisted Ips"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="updateMServicePassword">
                        New Password
                      </label>
                      <AvField
                        onChange={this.handleChange}
                        name="newPassword"
                        type="password"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        value={this.state.data.newPassword || ""}
                        placeholder="Password"
                        
                      />
                    </div>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Uninstall System App ===================== */}
              {command == "uninstallSystemApp" ? (
                <div>
                  <FormGroup className="mb-0">
                    <div>
                      <AvField
                        name="un_apk_name"
                        label="Apk Name"
                        placeholder=".apk extension i.e mservice.apk"
                        type="text"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Enter the name of the apk file you want to delete from system/priv-app folder. Wich will eventually uninstall the app. "
                        autoComplete="off"
                        value={this.state.data.un_apk_name}
                        onChange={this.handleChange}
                        errorMessage="Enter Name"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Launch App & Exit app & Exit and Open Setting & uninstall User App ===================== */}
              {command == "launchApp" ||
              command == "exitAppOpenSettings" ||
              command == "exitApp" ||
              command == "uninstallUserApp" ? (
                <div>
                  <FormGroup className="mb-0">
                    <div>
                      <AvField
                        name="app_name"
                        label="Application Name"
                        placeholder="App display name i.e MLock"
                        type="text"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={
                          command == "launchApp"
                            ? "Enter the home screen display name of the application you want to launch on tablets. i.e MLock"
                            : command == "exitAppOpenSettings"
                            ? "Enter the home screen display name of the application you want to exit and open settings on tablets. i.e MLock"
                            : command == "uninstallUserApp"
                            ? "Enter the home screen display name of the application you want to launch on tablets. i.e MLock"
                            : "Enter the home screen display name of the application you want to exit from the tablets. i.e MLock"
                        }
                        autoComplete="off"
                        onChange={this.handleChange}
                        errorMessage="Enter Name"
                        validate={{ required: { value: true } }}
                        value={this.state.data.app_name}
                      />
                    </div>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Update Ethernet Config ===================== */}
              {command == "setIPForEthernet" || command == "setIPForWifi" ? (
                <div>
                  <FormGroup>
                    <div>
                      <Label>IP Mode</Label>
                      <br></br>

                      <select
                        style={{
                          marginLeft: "0px",
                          width: "100%",
                          maxHeight: "50%",
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Select IP Mode"
                        required
                        onChange={(e) => {
                          this.setState({
                            data: {
                              ip_mode: e.target.value,
                            },
                            errors:{
                              ...this.state.errors,
                              ip_mode: false
                            }
                          });
                        }}
                        name="ip_mode"
                        className="custom-select"
                       
                      >
                        <option defaultValue value="">
                          Select IP Mode
                        </option>
                        <option value="static">Static</option>
                        <option value="DHCP">DHCP</option>
                      </select>
                      {this.state.errors.ip_mode && <span style={{color:'red'}}>Select IP Mode</span>}

                    </div>
                  </FormGroup>

                  {this.state.data.ip_mode == "static" ? (
                    <FormGroup className="mb-0">
                      <div>
                        <AvField
                          name="ip_address"
                          label="IP Address"
                          placeholder="192.168.1.1"
                          type="text"
                          value={this.state.data.ip_address}
                          onChange={this.handleChange}
                          errorMessage="Enter IP"
                          validate={{ required: { value: true } }}
                        />
                        <AvField
                          name="gateway"
                          label="Gateway"
                          placeholder="192.168.1.1"
                          type="text"
                          value={this.state.data.gateway}
                          onChange={this.handleChange}
                          errorMessage="Enter Gateway"
                          validate={{ required: { value: true } }}
                        />
                        <AvField
                          name="dns1"
                          label="DNS1"
                          placeholder="8.8.8.8"
                          type="text"
                          value={this.state.data.dns1}
                          onChange={this.handleChange}
                          errorMessage="Enter DNS"
                          validate={{ required: { value: true } }}
                        />
                        <AvField
                          name="dns2"
                          label="DNS2"
                          placeholder="8.8.8.8"
                          type="text"
                          value={this.state.data.dns2}
                          onChange={this.handleChange}
                          errorMessage="Enter DNS"
                          validate={{ required: { value: true } }}
                        />
                        <AvField
                          name="prefix_length"
                          label="Prefix Length"
                          placeholder="Number between 0 - 25"
                          min={0}
                          onChange={this.handleChange}
                          value={this.state.data.prefix_length}
                          type="number"
                          errorMessage="This value should be between 0 and 25."
                          validate={{
                            required: { value: true },
                            min: { value: 0 },
                            max: { value: 25 },
                          }}
                        />
                      </div>
                    </FormGroup>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {/* ===================== Upload Video Content ===================== */}
              {command == "uploadVideoContent" ? (
                <div>
                  <AvField
                    name="videoName"
                    label="File Name"
                    placeholder="Enter the file name with extension i.e vid1.mp4"
                    type="text"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Enter the file name with extension by which file will be created on the device. i.e vid1.mp4"
                    value={this.state.data.videoName}
                    onChange={this.handleChange}
                    errorMessage="Enter Name"
                    validate={{ required: { value: true } }}
                  />

                  {cState == true ? (
                    <AvField
                      name="videoUrl"
                      label=" Custom File Url"
                      placeholder="Enter a valid URL of file"
                      type="text"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Enter a valid URL which device can use to download the file on device."
                      value={this.state.data.videoUrl}
                      onChange={this.handleChange}
                      errorMessage="Enter URL"
                      validate={{ required: { value: true } }}
                    />
                  ) : (
                    ""
                  )}

                  {cState == false ? (
                    <FormGroup>
                      <div>
                        <Label>File URL</Label>
                        <br></br>

                        <select
                          style={{
                            marginLeft: "0px",
                            width: "100%",
                            maxHeight: "50%",
                          }}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Select File"
                          required
                          onChange={(e) => {
                            this.setState({
                              data: {
                                ...this.state.data,
                                videoUrl: e.target.value,
                              },
                              errors:{
                                ...this.state.errors,
                                videoUrl: false
                              }
                            });
                          }}
                          name="videoUrl"
                          className="custom-select"
                        >
                          <option defaultValue value="">
                            Select File
                          </option>
                          {videoData.map((key, index) => {
                            return (
                              <option
                                key={index}
                                value={
                                  process.env.REACT_APP_URL + "/" + key.url
                                }
                              >
                                {key.url}
                              </option>
                            );
                          })}
                        </select>
                        {this.state.errors.videoUrl && <span style={{color:'red'}}>Select File URL</span>}
                      </div>
                    </FormGroup>
                  ) : (
                    ""
                  )}

                  <Table className="table-centered table-vertical table-nowrap">
                    <tbody>
                      <tr>
                        <td>
                          <label>Customize File URL</label>
                        </td>
                        <td>
                          <input
                            onChange={(e) => {
                              if (e.target.checked == true) {
                                this.setState({
                                  cState: true,
                                  data: {
                                    ...this.state.data,
                                    videoUrl: "",
                                  },
                                });
                              } else {
                                this.setState({
                                  cState: false,
                                  data: {
                                    ...this.state.data,
                                    videoUrl: "",
                                  },
                                });
                              }
                            }}
                            name="custom_video"
                            type="checkbox"
                            className="m-0 form-control-sm ml-5"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr></hr>

                  <div className="form-group">
                    <label htmlFor="userpassword">Password</label>
                    <AvField
                      required
                      onChange={this.handleChange}
                      name="userpassword"
                      type="password"
                      autoComplete="new-password"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Enter password"
                      value={this.state.data.userpassword}
                      placeholder="Enter password"
                    />
                  </div>
                  <FormGroup className="mb-0">
                    <div></div>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Update MLock Setting ===================== */}
              {command == "updateMLockSettings" ? (
                <>
                  <FormGroup>
                    <div>
                      <Label>Select Mode</Label>
                      <br></br>
                      <select
                        style={{
                          marginLeft: "0px",
                          width: "100%",
                          maxHeight: "50%",
                        }}
                        required
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Select Mode"
                        onChange={(e) => {
                          this.setState(
                            {
                              data: {
                                mode: e.target.value,
                              },
                            },
                            () => {
                              this.setState({
                                hSetting: false,
                                sSetting: false,
                              });

                              if (this.state.data.mode == "video") {
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    videoRepeatMode: "one",
                                    contentUpdateAllowed: "true",
                                    allowGuestUser: "true",
                                    enableScrollText: "true",
                                    updateMlockPassword: "",

                                    autoStart: "false",
                                    rebootDevice: "false",
                                    refreshScreen: "false",
                                    disableUSBPort: "false",

                                    appLaunchDelayTime: 0,
                                  },
                                });
                              }

                              if (this.state.data.mode == "web") {
                                this.setState({
                                  data: {
                                    ...this.state.data,

                                    allowGuestUser: "true",
                                    enableScrollText: "true",
                                    disableCookies: "false",
                                    enableAutoRefreshWebpage: "true",
                                    disableFormData: "true",
                                    enableAutoRefreshWebsiteAfterFixedInterval:
                                      "false",
                                    updateMlockPassword: "",

                                    autoStart: "false",
                                    rebootDevice: "false",
                                    refreshScreen: "false",
                                    loadFileWebview: "false",
                                    enableAudio: "false",
                                    disableUSBPort: "false",
                                    setCookie: "false",

                                    appLaunchDelayTime: 0,
                                  },
                                });
                              }

                              if (this.state.data.mode == "app") {
                                this.setState({
                                  data: {
                                    ...this.state.data,

                                    autoStart: "true",
                                    allowGuestUser: "true",
                                    enableScrollText: "true",
                                    updateMlockPassword: "",

                                    rebootDevice: "false",
                                    refreshScreen: "false",
                                    disableUSBPort: "false",
                                    isAppRelaunch: "false",
                                    appLaunchDelayTime: 0,
                                  },
                                });
                              }
                            }
                          );
                        }}
                        name="mode"
                        className="custom-select"
                      >
                        <option defaultValue value="">
                          Select Mode
                        </option>
                        <option value="video">Video</option>
                        <option value="web">Web</option>
                        <option value="app">App</option>
                        <option value="exitMlock">Exit MLock</option>
                      </select>
                    </div>
                  </FormGroup>

                  {this.state.data.mode == "video" ? (
                    <FormGroup className="mb-0">
                      <div>
                        <Table className="table-centered table-vertical table-nowrap">
                          <tbody>
                            <tr>
                              <td>
                                <label>Disable USB Port</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          disableUSBPort: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          disableUSBPort: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="disableUSBPort"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label>Auto Start</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          autoStart: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          autoStart: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="autoStart"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label>Reboot Device</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          rebootDevice: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          rebootDevice: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="rebootDevice"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label>Refresh PlayScreen</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refreshScreen: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refreshScreen: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="refreshScreen"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>

                        <div className="mb-3">
                          <Label>Loop Videos</Label>
                          <br></br>

                          <select
                            style={{
                              marginLeft: "0px",
                              width: "100%",
                              maxHeight: "50%",
                            }}
                            required
                            onChange={(e) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  videoRepeatMode: e.target.value,
                                },
                              });
                            }}
                            name="videoRepeatMode"
                            className="custom-select"
                          >
                            <option defaultValue value="one">
                              One
                            </option>
                            <option value="all">All</option>
                          </select>
                        </div>

                        <div className="mb-3">
                          <Label>Enable Content Update</Label>
                          <br></br>

                          <select
                            style={{
                              marginLeft: "0px",
                              width: "100%",
                              maxHeight: "50%",
                            }}
                            required
                            onChange={(e) => {
                              this.setState({
                                data: {
                                  ...this.state.data,
                                  contentUpdateAllowed: e.target.value,
                                },
                              });
                            }}
                            name="contentUpdateAllowed"
                            className="custom-select"
                          >
                            <option defaultValue value="true">
                              True
                            </option>
                            <option value="false">False</option>
                          </select>
                        </div>

                        <AvField
                          name="videoContentPath"
                          label="Video Path"
                          placeholder="/storage/emulated/0/Download"
                          type="text"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Any valid directory path on the device i.e /storage/emulated/0/Download"
                          value={this.state.data.videoContentPath}
                          onChange={this.handleChange}
                          errorMessage="Enter Path"
                          autoComplete="off"
                        />
                      </div>
                    </FormGroup>
                  ) : (
                    ""
                  )}

                  {/* ------------------ For Web Mode ------------- */}
                  {this.state.data.mode == "web" ? (
                    <FormGroup className="mb-0">
                      <div>
                        <Table className="table-centered table-vertical table-nowrap">
                          <tbody>
                            <tr>
                              <td>
                                <label>Disable USB Port</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          disableUSBPort: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          disableUSBPort: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="disableUSBPort"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label>Auto Start</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          autoStart: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          autoStart: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="autoStart"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label>Reboot Device</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          rebootDevice: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          rebootDevice: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="rebootDevice"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label>Refresh PlayScreen</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refreshScreen: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refreshScreen: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="refreshScreen"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label>Load File</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          loadFileWebview: "true",
                                          loadFileWebviewPathName:"",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          loadFileWebview: "false",
                                          loadFileWebviewPathName: "",
                                        },
                                      });
                                    }
                                  }}
                                  name="loadFileWebview"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label>Enable Audio</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          enableAudio: "true",
                                          audioPath: "",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          enableAudio: "false",
                                          audioPath: "",
                                        },
                                      });
                                    }
                                  }}
                                  name="enableAudio"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label>Set Cookie</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          setCookie: "true",
                                          cookieName: "",
                                          cookieValue: "",
                                          cookiePath: "",
                                          cookieBaseURL: "",
                                          expireDate: "",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          setCookie: "false",
                                          cookieName: "",
                                          cookieValue: "",
                                          cookiePath: "",
                                          cookieBaseURL: "",
                                          expireDate: "",
                                        },
                                      });
                                    }
                                  }}
                                  name="setCookie"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>

                        {/* {(this.state.hSetting==false)&& (this.state.sSetting==false)?<> */}

                        {this.state.data.enableAudio === "true" ? (
                          <>
                            <AvField
                              name="audioPath"
                              id="audioPath"
                              label="Audio Path"
                              placeholder="Enter the URL / full address of audio file"
                              type="text"
                              autoComplete="off"
                              value={this.state.data.audioPath}
                              onChange={this.handleChange}
                              required
                              errorMessage="Enter Audio Path"
                            />

                            <UncontrolledTooltip
                              placement="top"
                              target="audioPath"
                            >
                              Enter the URL or full address on off audio file
                              saved on the device. This audio will play in the
                              background when MLock is in web mode.
                            </UncontrolledTooltip>
                          </>
                        ) : (
                          ""
                        )}

                        {this.state.data.setCookie === "true" ? (
                          <>
                            <Card className="mini-stat bg-primary">
                              <CardBody className="mini-stat-img">
                                <div className="text-white">
                                  <AvField
                                    name="cookieBaseURL"
                                    id="cookieBaseURL"
                                    label="Base URL"
                                    placeholder="Enter the base URL"
                                    type="text"
                                    autoComplete="off"
                                    value={this.state.data.cookieBaseURL}
                                    onChange={this.handleChange}
                                    required
                                    errorMessage="Enter Base URL"
                                  />
                                  <AvField
                                    name="cookieName"
                                    id="cookieName"
                                    label="Name of Cookie"
                                    placeholder="Enter the name of cookie"
                                    type="text"
                                    autoComplete="off"
                                    value={this.state.data.cookieName}
                                    onChange={this.handleChange}
                                    required
                                    errorMessage="Enter Name of Cookie"
                                  />
                                  <AvField
                                    name="cookieValue"
                                    id="cookieValue"
                                    label="Value of Cookie"
                                    placeholder="Enter the value of cookie"
                                    type="text"
                                    autoComplete="off"
                                    value={this.state.data.cookieValue}
                                    onChange={this.handleChange}
                                    required
                                    errorMessage="Enter Value of Cookie"
                                  />
                                  <AvField
                                    name="cookiePath"
                                    id="cookiePath"
                                    label="Path"
                                    placeholder="Enter the path"
                                    type="text"
                                    autoComplete="off"
                                    value={this.state.data.cookiePath}
                                    onChange={this.handleChange}
                                    required
                                    errorMessage="Enter Path"
                                  />

                                  <Label>Expire Date</Label>
                                  <Input
                                    className="form-control"
                                    name="expireDate"
                                    type="date"
                                    min={fullCurrentDate}
                                    value={this.state.data.expireDate}
                                    onChange={this.handleChange}
                                    errorMessage="Select date"
                                    validate={{ required: { value: true } }}
                                  />
                                </div>
                              </CardBody>
                            </Card>
                          </>
                        ) : (
                          ""
                        )}

                        {this.state.data.loadFileWebview == "false" ||
                        this.state.data?.loadFileWebview == null ? (
                          <>
                            <div className="mb-3">
                              <Label>Enable Auto Refresh Website</Label>
                              <br></br>

                              <select
                                style={{
                                  marginLeft: "0px",
                                  width: "100%",
                                  maxHeight: "50%",
                                }}
                                required
                                onChange={(e) => {
                                  this.setState({
                                    data: {
                                      ...this.state.data,
                                      enableAutoRefreshWebsiteAfterFixedInterval:
                                        e.target.value,
                                    },
                                  });
                                }}
                                name="enableAutoRefreshWebsiteAfterFixedInterval"
                                className="custom-select"
                              >
                                <option defaultValue value="false">
                                  False
                                </option>
                                <option value="true">True</option>
                              </select>
                            </div>

                            {this.state.data
                              .enableAutoRefreshWebsiteAfterFixedInterval ===
                            "true" ? (
                              <div className="mb-3">
                                <AvField
                                  name="autorefreshTimeInMinutes"
                                  placeholder="Value between (1-90)"
                                  type="number"
                                  value={
                                    this.state.data.autorefreshTimeInMinutes
                                  }
                                  onChange={this.handleChange}
                                  autoComplete="off"
                                  min={1}
                                  max={90}
                                  required
                                />
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="mb-3">
                              <Label>Disable Cookies</Label>
                              <br></br>

                              <select
                                style={{
                                  marginLeft: "0px",
                                  width: "100%",
                                  maxHeight: "50%",
                                }}
                                required
                                onChange={(e) => {
                                  this.setState({
                                    data: {
                                      ...this.state.data,
                                      disableCookies: e.target.value,
                                    },
                                  });
                                }}
                                name="disableCookies"
                                className="custom-select"
                              >
                                <option defaultValue value="false">
                                  False
                                </option>
                                <option value="true">True</option>
                              </select>
                            </div>

                            <div className="mb-3">
                              <Label>Disable Saving Form Data</Label>
                              <br></br>

                              <select
                                style={{
                                  marginLeft: "0px",
                                  width: "100%",
                                  maxHeight: "50%",
                                }}
                                required
                                onChange={(e) => {
                                  this.setState({
                                    data: {
                                      ...this.state.data,
                                      disableFormData: e.target.value,
                                    },
                                  });
                                }}
                                name="disableFormData"
                                className="custom-select"
                              >
                                <option defaultValue value="true">
                                  True
                                </option>
                                <option value="false">False</option>
                              </select>
                            </div>

                            <div className="mb-3">
                              <Label>
                                Will auto-refresh broken URLs every 10 seconds
                              </Label>
                              <br></br>

                              <select
                                style={{
                                  marginLeft: "0px",
                                  width: "100%",
                                  maxHeight: "50%",
                                }}
                                required
                                onChange={(e) => {
                                  this.setState({
                                    data: {
                                      ...this.state.data,
                                      enableAutoRefreshWebpage: e.target.value,
                                    },
                                  });
                                }}
                                name="enableAutoRefreshWebpage"
                                className="custom-select"
                              >
                                <option defaultValue value="true">
                                  True
                                </option>
                                <option value="false">False</option>
                              </select>
                            </div>

                            <AvField
                              name="websiteURL"
                              label="Website URL"
                              placeholder="https://www.example.com"
                              type="text"
                              autoComplete="off"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Enter any valid URL with http or https which will open when MLock will be set to web mode."
                              value={this.state.data.websiteURL}
                              onChange={this.handleChange}
                              errorMessage="Enter Url"
                            />
                          </>
                        ) : (
                          <>
                          <AvField
                            name="loadFileWebviewPathName"
                            id="loadFileWebviewPathName"
                            label="File name with address"
                            placeholder=" i.e storage/emulated/0/Download/myweb.html"
                            type="text"
                            autoComplete="off"
                            value={this.state.data.loadFileWebviewPathName}
                            onChange={this.handleChange}
                            required
                            errorMessage="Enter Url"
                          />
                          <UncontrolledTooltip
                              placement="top"
                              target="loadFileWebviewPathName"
                            >
                              Enter the URL.
                            </UncontrolledTooltip>
                            </>
                        )}
                      </div>

                      <Button
                        className="mb-2"
                        style={{
                          width: "100%",
                          backgroundImage:
                            "linear-gradient(to right, #BF0E6F, #D3233A)",
                        }}
                        onClick={() => {
                          this.toggleOverlay();
                        }}
                      >
                        Edit Overlay Screen
                      </Button>
                    </FormGroup>
                  ) : (
                    ""
                  )}

                  {/* -------------------------------- For App Mode --------------------------------------------- */}

                  {this.state.data.mode == "app" ? (
                    <FormGroup className="mb-0">
                      <div>
                        <Table className="table-centered table-vertical table-nowrap">
                          <tbody>
                            <tr>
                              <td>
                                <label>Disable USB Port</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          disableUSBPort: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          disableUSBPort: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="disableUSBPort"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label>Reboot Device</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          rebootDevice: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          rebootDevice: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="rebootDevice"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <label>Refresh PlayScreen</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refreshScreen: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refreshScreen: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="refreshScreen"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>

                        <AvField
                          name="defaultApp"
                          label="Default App"
                          placeholder="App Display Name"
                          type="text"
                          autoComplete="off"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Enterd the app's display name on home screen you want to auto-start on reboot."
                          value={this.state.data.defaultApp}
                          onChange={this.handleChange}
                          errorMessage="Enter App Name"
                          // validate={{ required: { value: true } }}
                        />
                        <div className="mb-3">
                              <Label>Relaunch App</Label>
                              <br></br>

                              <select
                                style={{
                                  marginLeft: "0px",
                                  width: "100%",
                                  maxHeight: "50%",
                                }}
                                required
                                onChange={(e) => {
                                  this.setState({
                                    data: {
                                      ...this.state.data,
                                      isAppRelaunch: e.target.value,
                                    },
                                  });
                                }}
                                name="isAppRelaunch"
                                className="custom-select"
                              >
                                <option defaultValue value="false">
                                  False
                                </option>
                                <option value="true">True</option>
                              </select>
                            </div>
                      </div>
                    </FormGroup>
                  ) : (
                    ""
                  )}

                  {/* ---------------------------------------------------------------------------------------------- */}

                  {this.state.data?.mode == "web" ||
                  this.state.data?.mode == "video" ||
                  this.state.data?.mode == "app" ||
                  this.state.data?.mode == "exitMlock" ? (
                    <div className="form-group">
                      <label htmlFor="password">Admin Password</label>
                      <AvField
                        required
                        onChange={this.handleChange}
                        id="p1"
                        name="updateMlockPassword"
                        type="password"
                        autoComplete="new-password"
                        value={this.state.data.updateMlockPassword || ""}
                        placeholder="Enter password"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.data?.mode == "web" ||
                  this.state.data?.mode == "video" ||
                  this.state.data?.mode == "app" ||
                  this.state.data?.mode == "exitMlock" ? (
                    <div className="form-group">
                        <label htmlFor="adminpassword">New Admin Password</label>
                        <AvField
                          onChange={this.handleChange}
                          name="newPassword"
                          type="password"
                          autoComplete="new-password"
                          value={this.state.data.newPassword}
                          placeholder="Enter Admin password"
                        />
                      </div>
                  ) : (
                    ""
                  )}   

                  {this.state.data?.mode == "web" ||
                  this.state.data?.mode == "video" ||
                  this.state.data?.mode == "app" ? (
                    <>
                      <Label className="control-label">
                        Auto start delay(S)
                      </Label>
                      <InputGroup className="mb-3">
                        <div
                          className="input-group-append"
                          onClick={() =>
                            this.setState({
                              data: {
                                ...this.state.data,
                                appLaunchDelayTime:
                                  this.state.data.appLaunchDelayTime != 0
                                    ? this.state.data.appLaunchDelayTime - 1
                                    : 0,
                              },
                            })
                          }
                        >
                          <Button type="button" color="primary">
                            <i className="mdi mdi-minus"></i>
                          </Button>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          value={
                            this.state.data.appLaunchDelayTime
                              ? this.state.data.appLaunchDelayTime
                              : 0
                          }
                          readOnly
                        />
                        <div
                          className="input-group-append"
                          onClick={() =>
                            this.setState({
                              data: {
                                ...this.state.data,
                                appLaunchDelayTime:
                                  this.state.data.appLaunchDelayTime != 30
                                    ? this.state.data.appLaunchDelayTime + 1
                                    : 30,
                              },
                            })
                          }
                        >
                          <Button type="button" color="primary">
                            <i className="mdi mdi-plus"></i>
                          </Button>
                        </div>
                      </InputGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {hSetting == true ? (
                    <>
                      <div className="mb-3">
                        <Label>Allow Guest User</Label>
                        <br></br>

                        <select
                          style={{
                            marginLeft: "0px",
                            width: "100%",
                            maxHeight: "50%",
                          }}
                          required
                          onChange={(e) => {
                            this.setState({
                              data: {
                                ...this.state.data,
                                allowGuestUser: e.target.value,
                              },
                            });
                          }}
                          name="allowGuestUser"
                          className="custom-select"
                        >
                          <option defaultValue value="true">
                            True
                          </option>
                          <option value="false">False</option>
                        </select>
                      </div>


                      <div className="form-group">
                        <label htmlFor="guestPassword">New Guest Password</label>
                        <AvField
                          onChange={this.handleChange}
                          name="guestPassword"
                          type="password"
                          autoComplete="new-password"
                          value={this.state.data.guestPassword}
                          placeholder="Enter Guest password"
                        />
                        <Table>
                          <tbody>
                            <tr>
                              <td>
                                <label>Change Guest User Rights</label>
                              </td>
                              <td>
                                <Switch
                                  uncheckedIcon={Offsymbol("No")}
                                  checkedIcon={OnSymbol("Yes")}
                                  onColor="#626ed4"
                                  checked={
                                    this.state.data.changeGuestUserRights ===
                                    "true"
                                  }
                                  onChange={() => {
                                    if (
                                      this.state.data.changeGuestUserRights &&
                                      this.state.data.changeGuestUserRights ===
                                        "true"
                                    ) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          changeGuestUserRights: "false",
                                          allowToAccessHelp: "false",
                                          allowToChangeMlockMode: "false",
                                          allowToConfigureAutoStart: "false",
                                          allowToConfigureScrollingText:
                                            "false",
                                          allowToExitMlock: "false",
                                          allowToLoadConfigurationFile: "false",
                                          allowToRebootDevice: "false",
                                          allowToSaveConfigurationFile: "false",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          changeGuestUserRights: "true",
                                          allowToChangeMlockMode: "false",
                                          allowToAccessHelp: "false",
                                          allowToConfigureAutoStart: "false",
                                          allowToConfigureScrollingText:
                                            "false",
                                          allowToExitMlock: "false",
                                          allowToLoadConfigurationFile: "false",
                                          allowToRebootDevice: "false",
                                          allowToSaveConfigurationFile: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="changeGuestUserRights"
                                  className="mr-1"
                                />
                                <br></br>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                        {this.state.data.changeGuestUserRights === "true" && (
                          <Table className="table-centered table-vertical table-nowrap">
                            <tbody>
                              <tr>
                                <td>
                                  <label>Allow to change MLock mode</label>
                                </td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToChangeMlockMode: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToChangeMlockMode: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="allowTochangeMlockMode"
                                  type="checkbox"
                                  checked={
                                    this.state.data.allowToChangeMlockMode ===
                                    "true"
                                  }
                                  className="m-0 form-control-sm ml-5"
                                />
                              </tr>
                              <tr>
                                <td>
                                  <label>Allow to configure Auto-start</label>
                                </td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToConfigureAutoStart: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToConfigureAutoStart: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="allowTochangeMlockMode"
                                  type="checkbox"
                                  checked={
                                    this.state.data
                                      .allowToConfigureAutoStart === "true"
                                  }
                                  className="m-0 form-control-sm ml-5"
                                />
                              </tr>
                              <tr>
                                <td>
                                  <label>
                                    Allow to save configuration file
                                  </label>
                                </td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToSaveConfigurationFile: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToSaveConfigurationFile: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="allowTochangeMlockMode"
                                  type="checkbox"
                                  checked={
                                    this.state.data
                                      .allowToSaveConfigurationFile === "true"
                                  }
                                  className="m-0 form-control-sm ml-5"
                                />
                              </tr>
                              <tr>
                                <td>
                                  <label>
                                    Allow to load configuration file
                                  </label>
                                </td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToLoadConfigurationFile: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToLoadConfigurationFile: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="allowTochangeMlockMode"
                                  type="checkbox"
                                  checked={
                                    this.state.data
                                      .allowToLoadConfigurationFile === "true"
                                  }
                                  className="m-0 form-control-sm ml-5"
                                />
                              </tr>
                              <tr>
                                <td>
                                  <label>
                                    Allow to configure scrolling text
                                  </label>
                                </td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToConfigureScrollingText: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToConfigureScrollingText:
                                            "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="allowTochangeMlockMode"
                                  type="checkbox"
                                  checked={
                                    this.state.data
                                      .allowToConfigureScrollingText === "true"
                                  }
                                  className="m-0 form-control-sm ml-5"
                                />
                              </tr>
                              <tr>
                                <td>
                                  <label>Allow to access Help</label>
                                </td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToAccessHelp: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToAccessHelp: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="allowTochangeMlockMode"
                                  type="checkbox"
                                  checked={
                                    this.state.data.allowToAccessHelp === "true"
                                  }
                                  className="m-0 form-control-sm ml-5"
                                />
                              </tr>
                              <tr>
                                <td>
                                  <label>Allow to exit MLock</label>
                                </td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToExitMlock: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToExitMlock: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="allowTochangeMlockMode"
                                  type="checkbox"
                                  checked={
                                    this.state.data.allowToExitMlock === "true"
                                  }
                                  className="m-0 form-control-sm ml-5"
                                />
                              </tr>
                              <tr>
                                <td>
                                  <label>Allow to reboot device</label>
                                </td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToRebootDevice: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          allowToRebootDevice: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="allowTochangeMlockMode"
                                  type="checkbox"
                                  checked={
                                    this.state.data.allowToRebootDevice ===
                                    "true"
                                  }
                                  className="m-0 form-control-sm ml-5"
                                />
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {sSetting == true ? (
                    <>
                      <div className="mb-3">
                        <Label>Enable Scroll Text</Label>
                        <br></br>

                        <select
                          style={{
                            marginLeft: "0px",
                            width: "100%",
                            maxHeight: "50%",
                          }}
                          required
                          onChange={(e) => {
                            this.setState({
                              data: {
                                ...this.state.data,
                                enableScrollText: e.target.value,
                              },
                            });
                          }}
                          name="enableScrollText"
                          className="custom-select"
                        >
                          <option defaultValue value="true">
                            True
                          </option>
                          <option value="false">False</option>
                        </select>
                      </div>

                      <AvField
                        name="scrollingText"
                        label="Scrolling Text"
                        placeholder="Type something"
                        type="text"
                        autoComplete="off"
                        value={this.state.data.scrollingText}
                        onChange={this.handleChange}
                        errorMessage="Enter text"
                      />

                      <AvField
                        name="scrollingTextSpeed"
                        label="Scrolling Speed"
                        placeholder="Number between 5 - 100"
                        min={5}
                        onChange={this.handleChange}
                        value={this.state.data.scrollingTextSpeed}
                        type="number"
                        errorMessage="This value should be between 5 and 100."
                        validate={{
                          min: { value: 5 },
                          max: { value: 100 },
                        }}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  {this.state.data.mode !== "exitMlock" &&
                  this.state.data.mode ? (
                    <>
                      <Button
                        onClick={() => {
                          this.setState({
                            hSetting: true,
                            sSetting: false,
                          });
                        }}
                        color="success"
                        className="waves-effect waves-light  mb-1 mr-1"
                        id="sa-title"
                      >
                        Guest Setting
                      </Button>

                      <Button
                        onClick={() => {
                          this.setState({
                            sSetting: true,
                            hSetting: false,
                          });
                        }}
                        color="danger"
                        className="waves-effect waves-light mb-1 mr-1"
                        id="sa-title"
                      >
                        Scrolling Text
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}

              {/* ===================== End Mtemp Setting ===================== */}
              {command == "updateMTempSettings" ? (
                <div>
                  <FormGroup>
                    <div>
                      <Label>Select Mode</Label>
                      <br></br>

                      <select
                        style={{
                          marginLeft: "0px",
                          width: "100%",
                          maxHeight: "50%",
                        }}
                        required
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Select Mode"
                        onChange={(e) => {
                          this.setState(
                            {
                              data: {
                                mtemp_mode: e.target.value,
                              },
                               errors:{
                                ...this.state.errors,
                                mtemp_mode: false
                              }
                            },
                            () => {
                              if (this.state.data.mtemp_mode == "internal") {
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    failing_temp: "100.4",
                                    temp_scale: "F",
                                    refresh_mtemp_screen: "false",
                                  },
                                });
                              }

                              if (this.state.data.mtemp_mode == "forehead") {
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    failing_temp: "96.8",
                                    temp_scale: "F",
                                    refresh_mtemp_screen: "false",
                                  },
                                });
                              }

                              if (this.state.data.mtemp_mode == "wrist") {
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    failing_temp: "95.72",
                                    temp_scale: "F",
                                    refresh_mtemp_screen: "false",
                                  },
                                });
                              }
                            }
                          );
                        }}
                        name="mtemp_mode"
                        className="custom-select"
                      >
                        <option defaultValue value="">
                          Select Mode
                        </option>
                        <option value="internal">Internal</option>
                        <option value="forehead">Forehead</option>
                        <option value="wrist">Wrist</option>
                      </select>
                      {this.state.errors.mtemp_mode && <span style={{color:'red'}}>Select Mode</span>}

                    </div>
                  </FormGroup>

                  {this.state.data.mtemp_mode == "internal" ? (
                    <FormGroup className="mb-0">
                      <div>
                        <Table className="table-centered table-vertical table-nowrap">
                          <tbody>
                            <tr>
                              <td>
                                <label>Refresh MTemp Screen</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refresh_mtemp_screen: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refresh_mtemp_screen: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="refresh_mtemp_screen"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>

                        <div className="mb-3">
                          <Label>Temparature Scale</Label>
                          <br></br>

                          <select
                            style={{
                              marginLeft: "0px",
                              width: "100%",
                              maxHeight: "50%",
                            }}
                            required
                            onChange={(e) => {
                              this.setState(
                                {
                                  data: {
                                    ...this.state.data,
                                    temp_scale: e.target.value,
                                  },
                                },
                                () => {
                                  if (this.state.data.temp_scale == "C") {
                                    var faren = Number(
                                      this.state.data.failing_temp
                                    );
                                    var convert = ((faren - 32) * 5) / 9;
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        failing_temp: convert,
                                      },
                                    });
                                  }

                                  if (this.state.data.temp_scale == "F") {
                                    var cel = Number(
                                      this.state.data.failing_temp
                                    );
                                    var convert = (cel * 9) / 5 + 32;
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        failing_temp: convert,
                                      },
                                    });
                                  }
                                }
                              );
                            }}
                            name="temp_scale"
                            className="custom-select"
                          >
                            <option defaultValue value="F">
                              Fahrenheit
                            </option>
                            <option value="C">Celsius</option>
                          </select>
                        </div>

                        <AvField
                          name="failing_temp"
                          label="Falling Temperature"
                          type="text"
                          autoComplete="off"
                          value={this.state.data.failing_temp}
                          onChange={this.handleChange}
                          errorMessage="Enter temp"
                          validate={{ required: { value: true } }}
                        />
                      </div>
                    </FormGroup>
                  ) : (
                    ""
                  )}

                  {/* ------------------Forehead Mode------------- */}
                  {this.state.data.mtemp_mode == "forehead" ? (
                    <FormGroup className="mb-0">
                      <div>
                        <Table className="table-centered table-vertical table-nowrap">
                          <tbody>
                            <tr>
                              <td>
                                <label>Refresh MTemp Screen</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refresh_mtemp_screen: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refresh_mtemp_screen: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="refresh_mtemp_screen"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>

                        <div className="mb-3">
                          <Label>Temparature Scale</Label>
                          <br></br>

                          <select
                            style={{
                              marginLeft: "0px",
                              width: "100%",
                              maxHeight: "50%",
                            }}
                            required
                            onChange={(e) => {
                              this.setState(
                                {
                                  data: {
                                    ...this.state.data,
                                    temp_scale: e.target.value,
                                  },
                                },
                                () => {
                                  if (this.state.data.temp_scale == "C") {
                                    var faren = Number(
                                      this.state.data.failing_temp
                                    );
                                    var convert = ((faren - 32) * 5) / 9;
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        failing_temp: convert,
                                      },
                                    });
                                  }

                                  if (this.state.data.temp_scale == "F") {
                                    var cel = Number(
                                      this.state.data.failing_temp
                                    );
                                    var convert = (cel * 9) / 5 + 32;
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        failing_temp: convert,
                                      },
                                    });
                                  }
                                }
                              );
                            }}
                            name="temp_scale"
                            className="custom-select"
                          >
                            <option defaultValue value="F">
                              Fahrenheit
                            </option>
                            <option value="C">Celsius</option>
                          </select>
                        </div>

                        <AvField
                          name="failing_temp"
                          label="Falling Temperature"
                          type="text"
                          autoComplete="off"
                          value={this.state.data.failing_temp}
                          onChange={this.handleChange}
                          errorMessage="Enter temp"
                          validate={{ required: { value: true } }}
                        />
                      </div>
                    </FormGroup>
                  ) : (
                    ""
                  )}

                  {/* --------------------------------For App Mode--------------------------------------------- */}

                  {this.state.data.mtemp_mode == "wrist" ? (
                    <FormGroup className="mb-0">
                      <div>
                        <Table className="table-centered table-vertical table-nowrap">
                          <tbody>
                            <tr>
                              <td>
                                <label>Refresh MTemp Screen</label>
                              </td>
                              <td>
                                <input
                                  onChange={(e) => {
                                    if (e.target.checked == true) {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refresh_mtemp_screen: "true",
                                        },
                                      });
                                    } else {
                                      this.setState({
                                        data: {
                                          ...this.state.data,
                                          refresh_mtemp_screen: "false",
                                        },
                                      });
                                    }
                                  }}
                                  name="refresh_mtemp_screen"
                                  type="checkbox"
                                  className="m-0 form-control-sm ml-5"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>

                        <div className="mb-3">
                          <Label>Temparature Scale</Label>
                          <br></br>

                          <select
                            style={{
                              marginLeft: "0px",
                              width: "100%",
                              maxHeight: "50%",
                            }}
                            required
                            onChange={(e) => {
                              this.setState(
                                {
                                  data: {
                                    ...this.state.data,
                                    temp_scale: e.target.value,
                                  },
                                },
                                () => {
                                  if (this.state.data.temp_scale == "C") {
                                    var faren = Number(
                                      this.state.data.failing_temp
                                    );
                                    var convert = ((faren - 32) * 5) / 9;
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        failing_temp: convert,
                                      },
                                    });
                                  }

                                  if (this.state.data.temp_scale == "F") {
                                    var cel = Number(
                                      this.state.data.failing_temp
                                    );
                                    var convert = (cel * 9) / 5 + 32;
                                    this.setState({
                                      data: {
                                        ...this.state.data,
                                        failing_temp: convert,
                                      },
                                    });
                                  }
                                }
                              );
                            }}
                            name="temp_scale"
                            className="custom-select"
                          >
                            <option defaultValue value="F">
                              Fahrenheit
                            </option>
                            <option value="C">Celsius</option>
                          </select>
                        </div>

                        <AvField
                          name="failing_temp"
                          label="Falling Temperature"
                          type="text"
                          autoComplete="off"
                          value={this.state.data.failing_temp}
                          onChange={this.handleChange}
                          errorMessage="Enter temp"
                          validate={{ required: { value: true } }}
                        />
                      </div>
                    </FormGroup>
                  ) : (
                    ""
                  )}

                  {/* ---------------------------------------------------------------------------------------------- */}

                  {this.state.data?.mtemp_mode ? (
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <AvField
                        required
                        onChange={this.handleChange}
                        name="mtempPassword"
                        type="password"
                        autoComplete="new-password"
                        value={this.state.data.mtempPassword || ""}
                        placeholder="Enter password"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              {/* ===================== Update MLauncher Configration  ===================== */}
              {command == "updateMLauncherSettings" ? (
                <>
                  <div>
                    <FormGroup>
                      <div>
                        <Label>Select Mode</Label>
                        <br></br>
                        <select
                          style={{
                            marginLeft: "0px",
                            width: "100%",
                            maxHeight: "50%",
                          }}
                          required
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Select Mode"
                          onChange={(e) => {
                            this.setState({
                              data: {
                                mode: e.target.value,
                                newPassword: "",
                                adminPassword: "",
                                reboot_device:
                                  this.state.data.reboot_device == "true"
                                    ? "true"
                                    : "false",
                              },
                            });
                          }}
                          name="mode"
                          className="custom-select"
                        >
                          <option defaultValue value="">
                            Select Mode
                          </option>
                          <option value="mLauncherWeb">Web</option>
                          <option value="mLauncherApp">App</option>
                        </select>
                      </div>
                    </FormGroup>

                    {/* ===================== For MLauncher Web Mode ===================== */}
                    {this.state.data.mode == "mLauncherWeb" ? (
                      <AvField
                        name="websiteURL"
                        label="Website URL"
                        placeholder="https://www.example.com"
                        type="text"
                        autoComplete="off"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Enter any valid URL with http or https which will open when MLock will be set to web mode."
                        value={this.state.data.websiteURL}
                        onChange={this.handleChange}
                        errorMessage="Enter Url"
                      />
                    ) : (
                      ""
                    )}

                    {/* ===================== For MLauncher App Mode ===================== */}
                    {this.state.data.mode == "mLauncherApp" ? (
                      <AvField
                        name="defaultApp"
                        label="Default Application"
                        placeholder="App Display Name"
                        type="text"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Enterd the app's display name on home screen you want to set for auto-launch."
                        autoComplete="off"
                        onChange={this.handleChange}
                        errorMessage="Enter App"
                      />
                    ) : (
                      ""
                    )}

                    <div className="form-group">
                      <label htmlFor="newPassword"> New Password</label>
                      <AvField
                        onChange={this.handleChange}
                        name="newPassword"
                        type="password"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="This field is optional. If you want to change the password of MLauncher 2020 enter the new password here."
                        autoComplete="new-password"
                        value={this.state.data.newPassword}
                        placeholder="Enter new password"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="userpassword">Password</label>
                      <AvField
                        required
                        onChange={this.handleChange}
                        name="adminPassword"
                        type="password"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="MLauncher Admin password"
                        autoComplete="new-password"
                        value={this.state.data.adminPassword || ""}
                        placeholder="MLauncher Admin password"
                      />
                    </div>
                  </div>

                  <Table className="table-centered table-vertical table-nowrap">
                    <tbody>
                      <tr>
                        <td>
                          <label>Reboot Device</label>
                        </td>
                        <td>
                          <input
                            onChange={(e) => {
                              if (e.target.checked == true) {
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    reboot_device: "true",
                                  },
                                });
                              } else {
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    reboot_device: "false",
                                  },
                                });
                              }
                            }}
                            name=" reboot_device"
                            type="checkbox"
                            className="m-0 form-control-sm ml-5"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              ) : (
                ""
              )}

              {/* ===================== Reset MLock ===================== */}
              {command == "resetMLockSettings" ? (
                <div>
                  <div className="form-group">
                    <label htmlFor="userpassword">Password</label>
                    <AvField
                      required
                      onChange={this.handleChange}
                      name="resetPassword"
                      type="password"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="MLock Admin password"
                      autoComplete="new-password"
                      value={this.state.data.resetPassword || ""}
                      placeholder="MLock Admin password"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Update Mimo Service ===================== */}
              {command == "updateMIMOService" ? (
                <div>
                  {mState == true ? (
                    <AvField
                      name="apk_url"
                      label=" Custom Apk Url"
                      placeholder="Type URL"
                      type="text"
                      autoComplete="off"
                      value={this.state.data.apk_url || ""}
                      onChange={this.handleChange}
                      errorMessage="Enter URL"
                      validate={{ required: { value: true } }}
                    />
                  ) : (
                    ""
                  )}

                  {mState == false ? (
                    <FormGroup>
                      <div>
                        <Label>Apk URL</Label>
                        <br></br>

                        <select
                          style={{
                            marginLeft: "0px",
                            width: "100%",
                            maxHeight: "50%",
                          }}
                          required
                          onChange={(e) => {
                            this.setState({
                              data: {
                                ...this.state.data,
                                apk_url: e.target.value,
                              },
                              errors:{
                                ...this.state.errors,
                                apk_url: false
                              }
                            });
                          }}
                          name="apk_url"
                          value={this.state.data.apk_url || ""}
                          className="custom-select"
                        >
                          <option defaultValue value="">
                            Select Apk
                          </option>

                          {updateApkData.map((key, index) => {
                            return (
                              <option
                                key={index}
                                value={
                                  process.env.REACT_APP_URL +
                                  "/" +
                                  key.app_name +
                                  "/" +
                                  key.file_name
                                }
                              >
                                {key.app_name + "/" + key.file_name}
                              </option>
                            );
                          })}
                        </select>
                        {this.state.errors.apk_url && <span style={{color:'red'}}>Select APK URL</span>}

                      </div>
                    </FormGroup>
                  ) : (
                    ""
                  )}

                  <Table className="table-centered table-vertical table-nowrap">
                    <tbody>
                      <tr>
                        <td>
                          <label>Customize Apk URL</label>
                        </td>
                        <td>
                          <input
                            onChange={(e) => {
                              if (e.target.checked == true) {
                                this.setState({
                                  mState: true,
                                  data: {
                                    ...this.state.data,
                                    mState: true,
                                    apk_url: "",
                                  },
                                });
                              } else {
                                this.setState({
                                  mState: false,
                                  data: {
                                    ...this.state.data,
                                    mState: false,
                                    apk_url: "",
                                  },
                                });
                              }
                            }}
                            name="custom_url"
                            type="checkbox"
                            className="m-0 form-control-sm ml-5"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr></hr>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Update WebView ===================== */}
              {command == "updateWebView" ? (
                <div>
                  <FormGroup className="mb-0">
                    <div>
                      {aState == true ? (
                        <AvField
                          name="apk_url"
                          label=" Custom Apk Url"
                          placeholder="Type URL"
                          type="text"
                          autoComplete="off"
                          value={this.state.data.apk_url}
                          onChange={this.handleChange}
                          errorMessage="Enter URL"
                          validate={{ required: { value: true } }}
                        />
                      ) : (
                        ""
                      )}

                      {aState == false ? (
                        <FormGroup>
                          <div>
                            <Label>Apk URL</Label>
                            <br></br>

                            <select
                              style={{
                                marginLeft: "0px",
                                width: "100%",
                                maxHeight: "50%",
                              }}
                              required
                              onChange={(e) => {
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    apk_url: e.target.value,
                                  },
                                  errors:{
                                    ...this.state.errors,
                                    apk_url: false
                                  }
                                });
                              }}
                              name="apk_url"
                              value={this.state.data.apk_url || ""}
                              className="custom-select"
                            >
                              <option defaultValue value="">
                                Select Apk
                              </option>
                              {apkData.map((key, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={
                                      process.env.REACT_APP_URL + "/" + key.url
                                    }
                                  >
                                    {key.url}
                                  </option>
                                );
                              })}

                              {mimoApkData.map((key, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={
                                      process.env.REACT_APP_URL +
                                      "/" +
                                      key.app_name +
                                      "/" +
                                      key.file_name
                                    }
                                  >
                                    {key.app_name + "/" + key.file_name}
                                  </option>
                                );
                              })}
                            </select>
                            {this.state.errors.apk_url && <span style={{color:'red'}}>Select APK URL</span>}

                          </div>
                        </FormGroup>
                      ) : (
                        ""
                      )}

                      <Table className="table-centered table-vertical table-nowrap">
                        <tbody>
                          <tr>
                            <td>
                              <label>Customize Apk URL</label>
                            </td>
                            <td>
                              <input
                                onChange={(e) => {
                                  if (e.target.checked == true) {
                                    this.setState({
                                      aState: true,
                                      data: {
                                        ...this.state.data,
                                        apk_url: "",
                                      },
                                    });
                                  } else {
                                    this.setState({
                                      aState: false,
                                      data: {
                                        ...this.state.data,
                                        apk_url: "",
                                      },
                                    });
                                  }
                                }}
                                name="custom_url"
                                type="checkbox"
                                className="m-0 form-control-sm ml-5"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <hr></hr>
                    </div>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Update MLock ===================== */}
              {command == "updateMLock" ? (
                <div>
                  {mState == true ? (
                    <AvField
                      name="apk_url"
                      label=" Custom Apk Url"
                      placeholder="Type URL"
                      type="text"
                      autoComplete="off"
                      value={this.state.data.apk_url || ""}
                      onChange={this.handleChange}
                      errorMessage="Enter URL"
                      validate={{ required: { value: true } }}
                    />
                  ) : (
                    ""
                  )}

                  {mState == false ? (
                    <FormGroup>
                      <div>
                        <Label>Apk URL</Label>
                        <br></br>

                        <select
                          style={{
                            marginLeft: "0px",
                            width: "100%",
                            maxHeight: "50%",
                          }}
                          required
                          onChange={(e) => {
                            this.setState({
                              data: {
                                ...this.state.data,
                                apk_url: e.target.value,
                              },
                              errors:{
                                ...this.state.errors,
                                apk_url: false
                              }
                            });
                          }}
                          name="apk_url"
                          value={this.state.data.apk_url || ""}
                          className="custom-select"
                        >
                          <option defaultValue value="">
                            Select Apk
                          </option>

                          {mlockApkData.map((key, index) => {
                            return (
                              <option
                                key={index}
                                value={
                                  process.env.REACT_APP_URL +
                                  "/" +
                                  key.app_name +
                                  "/" +
                                  key.file_name
                                }
                              >
                                {key.app_name + "/" + key.file_name}
                              </option>
                            );
                          })}
                        </select>
                        {this.state.errors.apk_url && <span style={{color:'red'}}>Select APK URL</span>}

                      </div>
                    </FormGroup>
                  ) : (
                    ""
                  )}

                  <Table className="table-centered table-vertical table-nowrap">
                    <tbody>
                      <tr>
                        <td>
                          <label>Customize Apk URL</label>
                        </td>
                        <td>
                          <input
                            onChange={(e) => {
                              if (e.target.checked == true) {
                                this.setState({
                                  mState: true,
                                  data: {
                                    ...this.state.data,
                                    apk_url: "",
                                  },
                                });
                              } else {
                                this.setState({
                                  mState: false,
                                  data: {
                                    ...this.state.data,
                                    apk_url: "",
                                  },
                                });
                              }
                            }}
                            name="custom_url"
                            type="checkbox"
                            className="m-0 form-control-sm ml-5"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <hr></hr>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Update White List ===================== */}
              {command == "updateWhitelist" ? (
                <div>
                  <AvField
                    name="whitelist_ip"
                    label="Whitelist IPs List"
                    placeholder="192.168.10.1.1,192.168.10.1.2"
                    type="text"
                    autoComplete="off"
                    onChange={this.handleChange}
                    errorMessage="Enter IP"
                    validate={{ required: { value: true } }}
                  />
                </div>
              ) : (
                ""
              )}

              {/* ===================== Profiles ===================== */}
              {command == "profiles" ? (
                <div>
                  <FormGroup>
                    <div>
                      <Label>Profiles</Label>
                      <br></br>

                      <select
                        style={{
                          marginLeft: "0px",
                          width: "100%",
                          maxHeight: "50%",
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Select profile"
                        required
                        onChange={(e) => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              profileName: e.target.value,
                            },
                          });
                        }}
                        name="profileName"
                        className="custom-select"
                      >
                        <option defaultValue value="">
                          Select Profile
                        </option>
                        {profileData.map((key, index) => {
                          return (
                            <option key={index} value={key.id}>
                              {key.profile_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </FormGroup>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Request Headers ===================== */}
              {command ? (
                <>
                  <div style={{ display: "none" }}>
                    <hr></hr>
                    <h3 className="mb-0">Request Headers </h3>
                    <hr></hr>
                    <AvField
                      name="header_username"
                      value="Admin"
                      label="User name"
                      placeholder="Enter username"
                      type="text"
                      autoComplete="off"
                      onChange={(e) => {
                        this.setState({
                          header: {
                            ...header,
                            userName: e.target.value,
                          },
                        });
                      }}
                      errorMessage="Enter name"
                      validate={{ required: { value: true } }}
                    />

                    <div className="form-group">
                      <label htmlFor="header_password">Password</label>
                      <AvField
                        required
                        onChange={(e) => {
                          this.setState({
                            header: {
                              ...header,
                              password: e.target.value,
                            },
                          });
                        }}
                        autoComplete="new-password"
                        name="header_password"
                        type="password"
                        value="0000"
                        placeholder="MService password"
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {/* ===================== Push File ===================== */}
              {command == "pushFile" ? (
                <div>
                  <AvField
                    name="fileName"
                    label="File Name"
                    placeholder="File Name"
                    type="text"
                    required
                    autoComplete="off"
                    onChange={(e) => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              fileName: e.target.value,
                            },
                            errors:{
                                    ...this.state.errors,
                                    fileName: false
                                  }
                          });
                        }}
                  />
                   {this.state.errors.fileName && <span style={{color:'red'}}>File name with an extension is required (.img, .png etc)</span>}

                  <AvField
                    name="fileURL"
                    label="File URL"
                    placeholder="File URL"
                    type="text"
                    required
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                  <AvField
                    name="copyAddress"
                    label="Copy Address"
                    placeholder="Copy Address"
                    type="text"
                    required
                    autoComplete="off"
                    onChange={this.handleChange}
                  />

                  <Table className="table-centered table-vertical table-nowrap">
                    <tbody>
                      <tr>
                        <td>
                          <AvField
                            name="reboot_device"
                            label="Reboot Device"
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked == true) {
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    reboot_device: "true",
                                  },
                                });
                              } else {
                                this.setState({
                                  data: {
                                    ...this.state.data,
                                    reboot_device: "false",
                                  },
                                });
                              }
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ) : (
                ""
              )}

              {/* ===================== Track Memory Stats ===================== */}
              {command == "trackMemoryStats" ? (
                <>
                  <select
                    style={{
                      marginLeft: "0px",
                      width: "100%",
                      maxHeight: "50%",
                      marginBottom: "5%",
                    }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Select Job"
                    onChange={(e) => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          trackMemoryStats: e.target.value,
                        },
                      });
                    }}
                    name="trackMemoryStats"
                    className="custom-select"
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </>
              ) : (
                ""
              )}

              {/* ===================== Enable/Disable Physical Buttons ===================== */}
              {command == "enablePhysicalButtons" ? (
                <>
                  <select
                    style={{
                      marginLeft: "0px",
                      width: "100%",
                      maxHeight: "50%",
                      marginBottom: "5%",
                    }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Select Job"
                    onChange={(e) => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          enablePhysicalButtons: e.target.value,
                        },
                      });
                    }}
                    name="enablePhysicalButtons"
                    className="custom-select"
                  >
                    <option value="enabled">Enable</option>
                    <option value="disabled">Disable</option>
                  </select>
                </>
              ) : (
                ""
              )}

              {/* ===================== Enable/Disable Physical Buttons ===================== */}
              {command == "showHideStatusBar" ? (
                <>
                  <select
                    style={{
                      marginLeft: "0px",
                      width: "100%",
                      maxHeight: "50%",
                      marginBottom: "5%",
                    }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Select Job"
                    onChange={(e) => {
                      this.setState({
                        data: {
                          ...this.state.data,
                          showHideStatusBar: e.target.value,
                        },
                      });
                    }}
                    name="showHideStatusBar"
                    className="custom-select"
                  >
                    <option value="false">Show</option>
                    <option value="true">Hide</option>
                  </select>
                </>
              ) : (
                ""
              )}

              {/* ===================== WiFi Profile ===================== */}
              {command == "assignSSIDProfile" ? (
                <>
                  <FormGroup>
                    <div className="row">
                      <div className="form-check">
                        <input
                          className="mr-3"
                          type="radio"
                          name="existSSIDProfile"
                          checked={
                            SSIDRadioButton === "existSSIDProfile"
                              ? true
                              : false
                          }
                          onChange={() =>
                            this.setState({
                              SSIDRadioButton: "existSSIDProfile",
                              data: {
                                SSIDRadioButton: "existSSIDProfile",
                                profileName: null,
                              },
                            })
                          }
                        />
                        <Label>Existing SSID Profiles</Label>
                      </div>

                      <div className="form-check">
                        <input
                          className="mr-3"
                          type="radio"
                          name="newSSIDProfile"
                          checked={
                            SSIDRadioButton === "newSSIDProfile" ? true : false
                          }
                          onChange={() =>
                            this.setState({
                              SSIDRadioButton: "newSSIDProfile",
                              data: {
                                SSIDRadioButton: "newSSIDProfile",
                                securityType: "none",
                                proxyType: "none",
                                hidden: true,
                              },
                            })
                          }
                        />
                        <Label>Assign New SSID Profile</Label>
                      </div>
                    </div>
                  </FormGroup>

                  {SSIDRadioButton === "existSSIDProfile" ? (
                    <FormGroup>
                      <div>
                        <Label>Select SSID Profile</Label>
                        <br></br>

                        <select
                          style={{
                            marginLeft: "0px",
                            width: "100%",
                            maxHeight: "50%",
                          }}
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Select profile"
                          required
                          onChange={(e) => {
                            this.setState({
                              data: {
                                ...this.state.data,
                                profileName: e.target.value,
                              },
                            });
                          }}
                          name="profileName"
                          className="custom-select"
                        >
                          <option defaultValue value="">
                            Select SSID Profile
                          </option>
                          {profileData.map((key, index) => {
                            if (key.profile_type === "assignSSIDProfile") {
                              return (
                                <option key={index} value={key.id}>
                                  {key.profile_name}
                                </option>
                              );
                            }
                          })}
                        </select>
                      </div>
                    </FormGroup>
                  ) : (
                    <>
                      <AvField
                        name="profileName"
                        label="Profile Name"
                        placeholder="Example Wi-Fi"
                        type="text"
                        autoComplete="off"
                        onChange={this.handleChange}
                        errorMessage="Enter profile name"
                        validate={{ required: { value: true } }}
                      />

                      <AvField
                        name="ssid"
                        label="SSID"
                        placeholder="ExampleMobility"
                        type="text"
                        autoComplete="off"
                        onChange={this.handleChange}
                        errorMessage="Enter SSID"
                        validate={{ required: { value: true } }}
                      />

                      <Label>Security Type</Label>
                      <br></br>
                      <select
                        style={{
                          marginLeft: "0px",
                          width: "100%",
                          maxHeight: "50%",
                          marginBottom: "3%",
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Select Job"
                        onChange={(e) => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              securityType: e.target.value,
                              userId: "",
                            },
                          });
                        }}
                        name="securityType"
                        className="custom-select"
                      >
                        <option value="none">None</option>
                        <option value="wep">WEP</option>
                        <option value="wpa/wpa2">WPA/WPA2</option>
                        <option value="wpaEnterprise">
                          WPA/WPA2 Enterprise
                        </option>
                      </select>

                      {this.state.data.securityType === "wpaEnterprise" ? (
                        <AvField
                          name="userId"
                          label="User ID"
                          placeholder="Enter user id"
                          type={userId}
                          autoComplete="off"
                          onChange={this.handleChange}
                          errorMessage="Enter the user id"
                          validate={{ required: { value: true } }}
                        />
                      ) : (
                        ""
                      )}

                      <label>Password</label>
                      <div class="input-group mb-3">
                        <input
                          className="form-control"
                          name="password"
                          placeholder="Enter the network password"
                          type={passwordInputType}
                          onChange={this.handleChange}
                        />
                        <div class="input-group-append">
                          <span class="input-group-text">
                            {passwordEyeIcon ? (
                              <i
                                className="fa fa-eye-slash"
                                aria-hidden="true"
                                role="button"
                                onClick={() => {
                                  this.handleShowPassword(false);
                                }}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-eye"
                                aria-hidden="true"
                                role="button"
                                onClick={() => {
                                  this.handleShowPassword(true);
                                }}
                              ></i>
                            )}
                          </span>
                        </div>
                      </div>

                      <Label>Hidden</Label>
                      <br></br>
                      <Switch
                        uncheckedIcon={Offsymbol("No")}
                        checkedIcon={OnSymbol("Yes")}
                        onColor="#626ed4"
                        onChange={(e) => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              hidden: !this.state.data.hidden,
                            },
                          });
                        }}
                        checked={this.state.data.hidden}
                        className="mr-1"
                      />
                      <br></br>
                      <br></br>

                      <Label>Proxy Type</Label>
                      <br></br>
                      <select
                        style={{
                          marginLeft: "0px",
                          width: "100%",
                          maxHeight: "50%",
                          marginBottom: "5%",
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Select Job"
                        onChange={(e) => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              proxyType: e.target.value,
                              ip: "",
                              port: "",
                            },
                          });
                        }}
                        name="proxyType"
                        className="custom-select"
                      >
                        <option value="none">None</option>
                        <option value="automatic">Automatic</option>
                        <option value="manual">Manual</option>
                      </select>

                      {this.state.data.proxyType === "manual" ? (
                        <>
                          <AvField
                            name="ip"
                            label="Ip"
                            placeholder="Enter ip"
                            type="text"
                            autoComplete="off"
                            onChange={this.handleChange}
                            errorMessage="Enter the ip"
                            validate={{ required: { value: true } }}
                          />

                          <AvField
                            name="port"
                            label="Port"
                            placeholder="Enter port"
                            type="text"
                            autoComplete="off"
                            onChange={this.handleChange}
                            errorMessage="Enter the port"
                            validate={{ required: { value: true } }}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              ) : (
                ""
              )}

              {command === "scheduleFutureEvents" ? (
                <>
                  <FormGroup>
                    <Label>Date</Label>
                    <Input
                      className="form-control"
                      name="date"
                      type="date"
                      min={fullCurrentDate}
                      value={this.state.data.date}
                      onChange={this.handleChange}
                      errorMessage="Select date"
                      validate={{ required: { value: true } }}
                    />
                    <br></br>

                    <Label>Time</Label>
                    <Input
                      className="form-control"
                      name="time"
                      type="time"
                      required
                      // defaultValue="12:00:00"
                      id="example-time-input"
                      value={this.state.data.time}
                      onChange={this.handleChange}
                      errorMessage="Select date and time"
                      validate={{ required: { value: true } }}
                    />
                    <br></br>

                    <Label>Job Name</Label>
                    <br></br>
                    <div>
                      <select
                        style={{
                          marginLeft: "0px",
                          width: "100%",
                          maxHeight: "50%",
                          marginBottom: "5%",
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Select Job"
                        required
                        onChange={(e) => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              jobName: e.target.value,
                            },
                          });
                        }}
                        name="jobName"
                        className="custom-select"
                      >
                        <option defaultValue value="">
                          Select Job
                        </option>
                        <option value="checkForOtaUpdate">
                          Check for OTA Update
                        </option>
                      </select>
                    </div>

                    <Label>Repeat</Label>
                    <br></br>
                    <div>
                      <select
                        style={{
                          marginLeft: "0px",
                          width: "100%",
                          maxHeight: "50%",
                          marginBottom: "5%",
                        }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Select Job"
                        required
                        onChange={(e) => {
                          this.setState({
                            data: {
                              ...this.state.data,
                              repeat: e.target.value,
                            },
                          });
                        }}
                        name="repeat"
                        className="custom-select"
                      >
                        <option defaultValue value="">
                          Select Job
                        </option>
                        <option value="oneTime">One Time</option>
                        <option value="repeatYearly">Repeat Yearly</option>
                        <option value="repeatMonthly">Repeat Monthly</option>
                      </select>
                    </div>
                  </FormGroup>
                </>
              ) : (
                ""
              )}

              {command === "getCookieList" ? (
                <>
                  <FormGroup>
                    <div>
                      <AvField
                        name="baseURL"
                        label="Base URL"
                        placeholder="Type Something"
                        type="text"
                        autoComplete="off"
                        onChange={this.handleChange}
                        errorMessage="Enter base URL"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </FormGroup>
                </>
              ) : (
                ""
              )}

              <FormGroup className="mb-0">
                <div>
                  {command != "" ? (
                    <>
                      <Button
                        type="submit"
                        color="primary"
                        className="waves-effect waves-light mr-1"
                        id="sa-title"
                      >
                        Submit
                      </Button>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </FormGroup>
            </AvForm>
          </CardBody>
        </Card>

        {this.state.isOpenOverlay ? (
          <OverlayScreen
            toggleOverlay={this.toggleOverlay}
            isOpenOverlay={this.state.isOpenOverlay}
            overlayData={this.state.overlayData}
            handleOverlayScreen={this.handleOverlayScreen}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default connect(null, { setBreadcrumbItems })(deviceForm);
