import React, { Component } from "react";
import {
  toggleSidebar,
  hideRightSidebar,
  changeTopbarTheme,
  changeLayout,
  changeSidebarTheme,
  changeLayoutWidth,
  changeSidebarType,
  changePreloader,
} from "../../../store/actions";
import { getHeader } from "../../../services/authService";
import secureLocalStorage from "react-secure-storage";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "metismenujs";

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_toggle: false,
      layoutType: this.props.layoutType,
      topbarTheme: this.props.topbarTheme,
      leftSideBarTheme: this.props.leftSideBarTheme,
      layoutWidth: this.props.layoutWidth,
      sidebarType: this.props.leftSideBarType,
      isPreloader: this.props.isPreloader,
      data: JSON.parse(secureLocalStorage.getItem("user")),
    };
  }

  componentDidMount() {
    document.body.setAttribute("data-sidebar", "dark");
    this.initMenu();
  }

  //update local state after changing layout
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        layoutType: this.props.layoutType,
        topbarTheme: this.props.topbarTheme,
        leftSideBarTheme: this.props.leftSideBarTheme,
        layoutWidth: this.props.layoutWidth,
        sidebarType: this.props.leftSideBarType,
        isPreloader: this.props.isPreloader,
      });
    }

    if (this.props.leftSideBarType !== prevProps.leftSideBarType) {
      this.initMenu();
    }
  }

  initMenu() {
    new MetisMenu("#side-menu");
    var matchingMenuItem = null;
    var ul = document.getElementById("side-menu");
    var items = ul.getElementsByTagName("a");
    var splitLink = document.location.pathname.split("-")[0];

    for (var i = 0; i < items.length; ++i) {
      if (
        this.props.location.pathname === items[i].pathname ||
        splitLink === items[i].pathname
      ) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");
        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };
  //change layput type and dispatch action
  changeLayout = () => {
    this.props.changeLayout("horizontal");
  };

  //theme preloader
  changeThemePreloader = (value) => {
    this.props.changePreloader(!this.props.isPreloader);
  };

  //change left sidebar theme
  changeLeftSidebarTheme = (value) => {
    this.props.changeSidebarTheme(value);
  };

  //change layout width
  changeLayoutWidth = (value) => {
    if (this.state.layoutWidth === "boxed")
      this.props.changeLayoutWidth("fluid", this.state.layoutType);
    else this.props.changeLayoutWidth("boxed", this.state.layoutType);
  };

  //change topbar theme and dispatch action
  changeTopbarTheme = (value) => {
    this.props.changeTopbarTheme(value);
  };

  //change sidebar type
  changeLeftSidebarType = (value) => {
    this.props.changeSidebarType(value);
  };

  render() {
    return (
      <>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title" style={{ fontSize: "15px" }}>
              Main Menu
            </li>

            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="mdi mdi-view-dashboard"></i>
                <span>Dashboard</span>
              </Link>
            </li>

            {this.state.data.role_id === 1 ? (
              // Admin List
              <>
                <li>
                  <Link to="/user-roles" className=" waves-effect">
                    <i className="mdi mdi-account-multiple"></i>
                    <span>User Roles</span>
                  </Link>
                </li>

                <li>
                  <Link to="/company" className=" waves-effect">
                    <i className="mdi mdi-account-multiple"></i>
                    <span>Companies</span>
                  </Link>
                </li>

                <li>
                  <Link to="/activation" className=" waves-effect">
                    <i className="mdi mdi-key-star"></i>
                    <span>Activation Key</span>
                  </Link>
                </li>

                <li>
                  <Link to="/admin" className=" waves-effect">
                    <i className="mdi mdi-account-key"></i>
                    <span>Admins</span>
                  </Link>
                </li>

                <li>
                  <Link to="/user" className=" waves-effect">
                    <i className="mdi mdi-account-plus-outline"></i>
                    <span>User</span>
                  </Link>
                </li>
                <li>
                  <Link to="/device" className=" waves-effect">
                    <i className="mdi mdi-monitor-clean"></i>
                    <span>Devices</span>
                  </Link>
                </li>
                <li>
                  <Link to="/activated-device" className=" waves-effect">
                    <i className="mdi mdi-devices"></i>
                    <span>Manage Devices</span>
                  </Link>
                </li>
                <li>
                  <Link to="/profiles" className=" waves-effect">
                    <i className="mdi mdi-folder-multiple"></i>
                    <span>Profiles</span>
                  </Link>
                </li>
                <li>
                  <Link to="/group" className=" waves-effect">
                    <i className="mdi mdi-monitor-multiple"></i>
                    <span>Device Groups</span>
                  </Link>
                </li>
                <li>
                  <Link to="/show-upload-apk" className=" waves-effect">
                    <i className="mdi mdi-folder-upload-outline"></i>
                    <span>Upload Apks</span>
                  </Link>
                </li>

                <li>
                  <Link to="/show-upload-file" className=" waves-effect">
                    <i className="mdi mdi-folder-upload-outline"></i>
                    <span>Upload File</span>
                  </Link>
                </li>

                <li>
                  <Link to="/show-upload-document" className=" waves-effect">
                    <i className="mdi mdi-file-document-box-plus-outline"></i>
                    <span>Documents</span>
                  </Link>
                </li>

                <li>
                  <Link to="/firmware-product" className=" waves-effect">
                    <i className="mdi mdi-zip-box"></i>
                    <span>Firmware Images</span>
                  </Link>
                </li>

                <li>
                  <Link to="/enable-two-factor" className=" waves-effect">
                    <i className="mdi mdi-file-lock-outline"></i>
                    <span>Two Factor Authentication</span>
                  </Link>
                </li>
                <li>
                  <Link to="/Logs" className=" waves-effect">
                    <i className="mdi mdi-zip-box"></i>
                    <span>Logs</span>
                  </Link>
                </li>
              </>
            ) : this.state.data.role_id === 2 ? (
              //Customer List
              <>
                {" "}
                <li>
                  <Link to="/mimo-apks" className=" waves-effect">
                    <i className="mdi mdi-file-key"></i>
                    <span>Mimo Apks</span>
                  </Link>
                </li>
                <li>
                  <Link to="/user-roles" className=" waves-effect">
                    <i className="mdi mdi-account-multiple"></i>
                    <span>User Roles</span>
                  </Link>
                </li>
                <li>
                  <Link to="/user" className=" waves-effect">
                    <i className="mdi mdi-account-plus-outline"></i>
                    <span>User</span>
                  </Link>
                </li>
                <li>
                  <Link to="/device" className=" waves-effect">
                    <i className="mdi mdi-monitor-clean"></i>
                    <span>Devices</span>
                  </Link>
                </li>
                <li>
                  <Link to="/remote-device" className=" waves-effect">
                    <i className="mdi mdi-devices"></i>
                    <span>Manage Devices</span>
                  </Link>
                </li>
                <li>
                  <Link to="/profiles" className=" waves-effect">
                    <i className="mdi mdi-folder-multiple"></i>
                    <span>Profiles</span>
                  </Link>
                </li>
                <li>
                  <Link to="/group" className=" waves-effect">
                    <i className="mdi mdi-monitor-multiple"></i>
                    <span>Device Groups</span>
                  </Link>
                </li>
                <li>
                  <Link to="/show-upload-apk" className=" waves-effect">
                    <i className="mdi mdi-folder-upload-outline"></i>
                    <span>Upload Apks</span>
                  </Link>
                </li>
                <li>
                  <Link to="/show-upload-file" className=" waves-effect">
                    <i className="mdi mdi-folder-upload-outline"></i>
                    <span>Upload File</span>
                  </Link>
                </li>
                <li>
                  <Link to="/show-upload-document" className=" waves-effect">
                    <i className="mdi mdi-file-document-box-plus-outline"></i>
                    <span>Documents</span>
                  </Link>
                </li>
              </>
            ) : (
              //Super Admin List
              <>
                <li>
                  <Link to="/reseller-accounts" className=" waves-effect">
                    <i className="mdi mdi-file-document-box-plus-outline"></i>
                    <span>Reseller Accounts</span>
                  </Link>
                </li>
                <li>
                  <Link to="/user-roles" className=" waves-effect">
                    <i className="mdi mdi-account-multiple"></i>
                    <span>User Roles</span>
                  </Link>
                </li>
                <li>
                  <Link to="/company" className=" waves-effect">
                    <i className="mdi mdi-account-multiple"></i>
                    <span>Companies</span>
                  </Link>
                </li>
                <li>
                  <Link to="/activation" className=" waves-effect">
                    <i className="mdi mdi-key-star"></i>
                    <span>Activation Key</span>
                  </Link>
                </li>
                <li>
                  <Link to="/admin" className=" waves-effect">
                    <i className="mdi mdi-account-key"></i>
                    <span>Admins</span>
                  </Link>
                </li>
                <li>
                  <Link to="/user" className=" waves-effect">
                    <i className="mdi mdi-account-plus-outline"></i>
                    <span>User</span>
                  </Link>
                </li>
                <li>
                  <Link to="/device" className=" waves-effect">
                    <i className="mdi mdi-monitor-clean"></i>
                    <span>Devices</span>
                  </Link>
                </li>
                <li>
                  <Link to="/activated-device" className=" waves-effect">
                    <i className="mdi mdi-devices"></i>
                    <span>Manage Devices</span>
                  </Link>
                </li>
                <li>
                  <Link to="/profiles" className=" waves-effect">
                    <i className="mdi mdi-folder-multiple"></i>
                    <span>Profiles</span>
                  </Link>
                </li>
                <li>
                  <Link to="/group" className=" waves-effect">
                    <i className="mdi mdi-monitor-multiple"></i>
                    <span>Device Groups</span>
                  </Link>
                </li>
                <li>
                  <Link to="/mimo-apks" className=" waves-effect">
                    <i className="mdi mdi-file-key"></i>
                    <span>Mimo Apks</span>
                  </Link>
                </li>
                <li>
                  <Link to="/show-upload-apk" className=" waves-effect">
                    <i className="mdi mdi-folder-upload-outline"></i>
                    <span>Upload Apks</span>
                  </Link>
                </li>

                <li>
                  <Link to="/show-upload-file" className=" waves-effect">
                    <i className="mdi mdi-folder-upload-outline"></i>
                    <span>Upload File</span>
                  </Link>
                </li>

                <li>
                  <Link to="/show-upload-document" className=" waves-effect">
                    <i className="mdi mdi-file-document-box-plus-outline"></i>
                    <span>Documents</span>
                  </Link>
                </li>

                <li>
                  <Link to="/firmware-product" className=" waves-effect">
                    <i className="mdi mdi-zip-box"></i>
                    <span>Firmware Images</span>
                  </Link>
                </li>
                <li>
                  <Link to="/audit-trail" className=" waves-effect">
                    <i className="mdi mdi-file-key"></i>
                    <span>Download Audit Trail</span>
                  </Link>
                </li>
                <li>
                  <Link to="/enable-two-factor" className=" waves-effect">
                    <i className="mdi mdi-file-lock-outline"></i>
                    <span>Two Factor Authentication</span>
                  </Link>
                </li>
                <li>
                  <Link to="/help-guide" className=" waves-effect">
                    <i className="mdi mdi-zip-box"></i>
                    <span>Help Guides</span>
                  </Link>
                </li>
                <li>
                  <Link to="/Logs" className=" waves-effect">
                    <i className="mdi mdi-zip-box"></i>
                    <span>Logs</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    is_toggle,
    leftSideBarType,
    layoutType,
    leftSideBarTheme,
    layoutWidth,
    topbarTheme,
    isPreloader,
  } = state.Layout;
  return {
    is_toggle,
    leftSideBarType,
    layoutType,
    leftSideBarTheme,
    layoutWidth,
    topbarTheme,
    isPreloader,
  };
};

export default withRouter(
  connect(mapStatetoProps, {
    toggleSidebar,
    hideRightSidebar,
    changeLayout,
    changeTopbarTheme,
    changeSidebarTheme,
    changeLayoutWidth,
    changeSidebarType,
    changePreloader,
  })(SidebarContent)
);
