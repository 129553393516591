import React, { useState, useEffect } from "react";
import {
  Col,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Row,
} from "reactstrap";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { deleteApk, getUploadFiles } from "./redux/UploadFileApi";
import ModalLayout from "../../component/Common/ModalLayout";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import { Permissions } from "../../services/permissions";
import { AvForm } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import CreateUploadFile from "./CreateUploadFile";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import "./datatables.scss";

const ShowUploadFile = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Upload Files ", link: "/show-upload-file" },
  ]);
  const [fileData, setFileData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [resellerId, setResellerId] = useState("all");
  const [customerId, setCustomerId] = useState("all");
  const [fileId, setFileId] = useState({});
  const [modalShowCreate, setModalShowCreate] = useState(false);

  useEffect(() => {
    if (modalShowCreate) return;
    (async () => {
      props.setBreadcrumbItems("Upload File", breadcrumbItems, true);

      try {
        const { data: fileData } = await getUploadFiles(
          getCurrentUser().role_id === 2
            ? getCurrentUser().customer_id
            : customerId
        );

        setFileData(fileData);
        setFilteredData(fileData);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Upload File", breadcrumbItems, false);
    })();
  }, [modalShowCreate]);

  const handleReseller = (id) => {
    setResellerId(id);
    setCustomerId("all");
    setFilteredData(
      fileData.filter((item) => (id === "all" ? item : item.resellerId == id))
    );
  };

  const handleCustomer = (id) => {
    setCustomerId(id === "all" ? "all" : parseInt(id));
    setFilteredData(
      fileData.filter((item) => (id === "all" ? item : item.customerId == id))
    );
  };

  const handleDelete = async () => {
    props.setBreadcrumbItems("Upload File", breadcrumbItems, true);

    try {
      await deleteApk(fileId);
      const currentData = fileData.filter((item) => item.id !== fileId);

      setFileData(currentData);
      setFilteredData(
        currentData.filter((item) =>
          customerId === "all" ? item : item.customerId == customerId
        )
      );
      setSuccessAlert(true);
      setDescription("File has been successfully deleted");
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("Upload File", breadcrumbItems, false);
  };

  const copyToClipboard = (obj) => {
    const { REACT_APP_URL } = process.env;
    var textArea = document.createElement("textarea");
    textArea.value = REACT_APP_URL + "/" + obj.url;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      console.log("Fallback: Copying text command was " + msg);
    } catch (err) {
      console.error("Fallback: Oops, unable to copy", err);
    }

    document.body.removeChild(textArea);
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  const dataTable = {
    columns: [
      {
        label: "App Name",
        field: "appName",
        sort: "asc",
        width: 150,
      },
      {
        label: "File Name",
        field: "fileName",
        sort: "asc",
        width: 150,
      },
      {
        label: "File",
        field: "file",
        sort: "disabled",
        width: 270,
      },

      {
        label: "Uploaded by",
        field: "uploadedBy",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: filteredData.map((obj) => ({
      ...obj,

      uploadedBy: obj.uploadedBy,
      file: (
        <a
          className="btn btn-success waves-effect waves-light mr-3"
          href={process.env.REACT_APP_URL + "/" + obj.url}
        >
          <i className="fas fa-download fa-1.2x"></i> Download ({obj.fileSize}
          MB)
        </a>
      ),

      action: (
        <>
          <i
            className="mdi mdi-content-copy  mr-1 iconHover"
            style={{
              color: "#7EB69C",
              fontSize: "23px",
            }}
            role="button"
            id="copyURL"
            onClick={() => {
              copyToClipboard(obj);
            }}
          ></i>
          <UncontrolledTooltip placement="top" target="copyURL">
            Copy URL
          </UncontrolledTooltip>

          {Permissions().deleteFilePermi ? (
            <i
              className="mdi mdi mdi-delete  mr-1 iconHover"
              style={{
                color: "#DB4C3F",
                fontSize: "23px",
              }}
              role="button"
              onClick={() => {
                setFileId(obj.id);
                setConfirmAlert(true);
              }}
            ></i>
          ) : (
            ""
          )}
        </>
      ),
    })),
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={(e) => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      <Col xs="12">
        <Card>
          <CardBody>
            <AvForm>
              <Col xs="12">
                <Row className="align-items-center mb-3">
                  {getCurrentUser().role_id === 3 ? (
                    <Col xs="3">
                      <SelectReseller
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        handleReseller={handleReseller}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {getCurrentUser().role_id !== 2 ? (
                    <Col xs="3">
                      <SelectCustomer
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        customerId={customerId}
                        handleCustomer={handleCustomer}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {Permissions().uploadFilePermi ? (
                    <Col xs="2">
                      <Button
                        style={{ marginTop: "10px" }}
                        onClick={toggleModalCreate}
                      >
                        Upload File
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </AvForm>

            <MDBDataTable
              responsive
              bordered
              striped
              entries={100}
              data={dataTable}
            />
          </CardBody>
        </Card>
      </Col>

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Upload File"
          size="lg"
        >
          <CreateUploadFile
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(ShowUploadFile);
