import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Alert, Button } from "reactstrap";
import { checkLogin, clearErrorLogin, clearError } from "../../store/actions";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { isUserAuthenticated } from "../../helpers/authUtils";
import { Link, withRouter } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

const Login = (props) => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    props.clearErrorLogin();
    props.clearError();
  }, []);

  const handleSubmit = (e, value) => {
    const { email, password } = value;
    props.checkLogin(email, password, props.history);
  };

  if (isUserAuthenticated()) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <>
      <Container className="account-pages my-5 pt-sm-5">
        <Row className="justify-content-center">
          <Col md="8" lg="6" xl="5">
            <Card className="overflow-hidden">
              <CardBody className="pt-0">
                <h3 className="text-center mt-4">
                  <Link to="\" className="logo logo-admin">
                    <img src={logo} height="80" alt="logo" />
                  </Link>
                </h3>
                <div className="p-3">
                  <h4 className="text-muted font-size-18 mb-1 text-center">
                    Welcome Back !
                  </h4>
                  <p className="text-muted text-center">Sign in to continue.</p>
                  {props.user && (
                    <Alert color="success">Your Login is successfull.</Alert>
                  )}

                  {props.loginError && (
                    <Alert color="danger">{props.loginError}</Alert>
                  )}
                  <AvForm
                    className="form-horizontal mt-4"
                    onValidSubmit={handleSubmit}
                  >
                    <AvField
                      label="Email"
                      type="email"
                      name="email"
                      placeholder="Enter Email"
                      value={loginData.email}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter an email",
                        },
                        pattern: {
                          value: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                          errorMessage:
                            "You have entered an invalid email address!",
                        },
                      }}
                    />

                    <AvField
                      label="Password"
                      type="password"
                      name="password"
                      value={loginData.password}
                      placeholder="Enter password"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: "Please enter a password",
                        },
                      }}
                    />

                    <div className="form-group row mt-4">
                      <Col xs="6">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customControlInline"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customControlInline"
                          >
                            Remember me
                          </label>
                        </div>
                      </Col>
                      <Col xs="6" className="text-right">
                        <Button
                          color="primary"
                          className="w-md waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </Button>
                      </Col>
                    </div>
                    <div className="form-group row">
                      <Col xs="12" className="mt-4">
                        <Link to="/forget-password">
                          <i className="mdi mdi-lock"></i> Forgot your password?
                        </Link>
                      </Col>
                    </div>
                  </AvForm>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStatetoProps = (state) => {
  const { user, loginError, loading } = state.Login;
  return { user, loginError, loading };
};

export default withRouter(
  connect(mapStatetoProps, { checkLogin, clearErrorLogin, clearError })(Login)
);
