import React, { useState, useEffect } from "react";
import { getCustomerUser, deleteCustomerUser } from "./redux/userApi";
import { Col, Row, Card, CardBody, Button } from "reactstrap";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import { Permissions } from "../../services/permissions";
import SweetAlert from "react-bootstrap-sweetalert";
import EditUserModal from "./EditUserModal";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import "./datatables.scss";

export const CustomerUser = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "User", link: "#" },
  ]);
  const [userData, setUserData] = useState([]);
  const [userId, setUserId] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [editModalShow, setEditModalShow] = useState(false);

  useEffect(() => {
    if (editModalShow) return;
    (async () => {
      props.setBreadcrumbItems("User", breadcrumbItems, true);

      try {
        const {
          data: { data: userData },
        } = await getCustomerUser(getCurrentUser().customer_id);

        setUserData(userData);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("User", breadcrumbItems, false);
    })();
  }, [editModalShow]);

  const handleDelete = async () => {
    props.setBreadcrumbItems("User", breadcrumbItems, true);

    const { data: response } = await deleteCustomerUser(userId);
    const currentData = userData.filter((item) => item.id !== userId);

    try {
      setSuccessAlert(true);
      setDescription(response.message);
      setUserData(currentData);
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("User", breadcrumbItems, false);
  };

  const toggleEditModal = () => {
    setEditModalShow(!editModalShow);
  };

  const adminTable = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 270,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Two Factor Auth Status",
        field: "TowFaStatus",
        sort: "asc",
        width: 200,
      },
      {
        label: "User Role",
        field: "roleName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 200,
      },
    ],
    rows: userData.map((obj, key) => ({
      ...obj,

      TowFaStatus:
        obj.two_factor_status === "enable" ? (
          <h5>
            <span className="badge badge-success">Enabled</span>
          </h5>
        ) : (
          <h5>
            <span className="badge badge-danger">Disabled</span>
          </h5>
        ),

      actions: (
        <>
          {Permissions().editUserPermi ? (
            <i
              className="mdi mdi-square-edit-outline mr-1 iconHover"
              style={{ color: "#20C0ED", fontSize: "23px" }}
              role="button"
              onClick={() => {
                setUserId(obj.id);
                toggleEditModal();
              }}
            ></i>
          ) : (
            ""
          )}

          {Permissions().deleteUserPermi &&
          getCurrentUser().id !== obj.id &&
          obj.roleName !== "Super User Role" ? (
            <i
              className="mdi mdi mdi-delete  mr-1 iconHover"
              style={{
                color: "#DB4C3F",
                fontSize: "23px",
              }}
              role="button"
              onClick={() => {
                setConfirmAlert(true);
                setUserId(obj.id);
              }}
            ></i>
          ) : (
            ""
          )}
        </>
      ),
    })),
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}

      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              {Permissions().createUserPermi ? (
                <Button
                  onClick={() => {
                    props.history.replace(
                      `/create-user/${getCurrentUser().customer_id}`
                    );
                  }}
                  style={{ marginRight: "10px", marginBottom: "10px" }}
                >
                  Create User
                </Button>
              ) : (
                ""
              )}

              <MDBDataTable responsive bordered striped data={adminTable} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {editModalShow ? (
        <EditUserModal
          toggleEdit={toggleEditModal}
          isOpenEdit={editModalShow}
          userId={userId}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(CustomerUser);
