import React from 'react';
import { CardBody, Table } from 'reactstrap';
import './datatables.scss';

const ShowViewData = ({ responseData }) => {
  const { command } = responseData;
  let hasStatus = responseData.hasOwnProperty('Status');
  let hasMessage = responseData.hasOwnProperty('Message');
  let status;
  let message;
  let messageCommand = (command === 'getInstalledAppsList' ||
    command === 'Get Installed Apps List');

  if (hasStatus)
    status = responseData['Status'];
  if (hasMessage)
    message = responseData['Message'];

  let tableData = [];
  if (
    (responseData.hasOwnProperty('Response') ||
      responseData.hasOwnProperty('Message')) &&
    (command === 'getInstalledAppsList' ||
      command === 'Get Installed Apps List')
  ) {
    let items;
    if (responseData.hasOwnProperty('Response')) {
      items = responseData['Response'].split(', ');
    } else {
      items = responseData['Message'].split(', ');
    }
    tableData = items
      .map((item) => {
        const match = item
          .replace(/\n|\]/g, '')
          .match(/AppName: ([^\x00-\x1F]+) Version: ([^,]+)/);
        if (!match) return null;
        const [_, appName, version] = match;
        const AppName = appName.replace(/\u2026/g, '');
        return { AppName, version };
      })
      .filter((item) => item !== null);
  }

  if (hasMessage && messageCommand) {
    message = (
      <Table className='table table-bordered mb-0'>
        <thead>
          <tr>
            <th>App Name</th>
            <th>Version</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={index}>
              <td>{item.AppName}</td>
              <td>{item.version}</td>
            </tr>
          ))}
        </tbody>
      </Table>)
  }

  return (
    <>
      <CardBody className='cardBody'>
        <Table className='table table-bordered mb-0 table-responsive'>
          <tbody>
            <tr>
              <th>User</th>
              <th>Timestamp</th>
              <th>Command</th>
              <th>Mac Address</th>
              {Object.keys(responseData ? responseData : '').map(
                (key, index) => {
                  if (
                    key !== 'jsonData' &&
                    key !== 'command' &&
                    key !== 'deviceName' &&
                    key !== 'timestamp' &&
                    key !== 'user' &&
                    key !== 'macAddress' &&
                    key !== 'customerId' &&
                    key !== 'resellerId' &&
                    key !== 'Status' &&
                    key !== 'Message'
                  ) {
                    return (
                      <th
                        key={index}
                        style={{ textTransform: 'capitalize' }}
                      >{`${key}`}</th>
                    );
                  }
                }
              )}
              {hasStatus && (
                <th style={{ textTransform: 'capitalize' }}>{`Status`}</th>
              )}
              {hasMessage && (
                <th style={{ textTransform: 'capitalize' }}>{`Message`}</th>
              )}
            </tr>
            <tr>
              <td>{responseData ? responseData['user'] : ''}</td>
              <td>{responseData ? responseData['timestamp'] : ''}</td>
              <td>{responseData ? responseData['command'] : ''}</td>
              <td> {responseData ? responseData['macAddress'] : ''}</td>
              {Object.keys(responseData ? responseData : '').map(
                (key, index) => {
                  if (
                    key !== 'jsonData' &&
                    key !== 'command' &&
                    key !== 'deviceName' &&
                    key !== 'timestamp' &&
                    key !== 'user' &&
                    key !== 'macAddress' &&
                    key !== 'customerId' &&
                    key !== 'resellerId' &&
                    key !== 'Status' &&
                    key !== 'Message'
                  ) {
                    return (
                      <React.Fragment key={index}>
                        {key === 'Screenshot URL' ||
                          key === 'Bug Report URL' ? (
                          <td>
                            <a
                              className='btn btn-success waves-effect waves-light mr-3'
                              target='_blank'
                              href={`${responseData ? responseData[key] : ''}`}
                            >
                              <i className='fas fa-link fa-1.2x'></i> Open
                            </a>
                          </td>
                        ) : (key === 'Response') &&
                          (command === 'getInstalledAppsList' ||
                            command === 'Get Installed Apps List') ? (
                          <td>
                            <Table className='table table-bordered mb-0'>
                              <thead>
                                <tr>
                                  <th>App Name</th>
                                  <th>Version</th>
                                </tr>
                              </thead>
                              <tbody>
                                {tableData.map((item, index) => (
                                  <tr key={index}>
                                    <td>{item.AppName}</td>
                                    <td>{item.version}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </td>
                        ) : key === 'Response' ? (
                          <td>{`${responseData ? responseData[key] : ''}`}</td>
                        ) : (
                          <td>{`${responseData ? responseData[key] : ''}`}</td>
                        )}
                      </React.Fragment>
                    );
                  }
                }
              )}
              {hasStatus && <td>{status}</td>}
              {hasMessage && <td>{message}</td>}
            </tr>
          </tbody>
        </Table>
      </CardBody>
    </>
  );
};

export default ShowViewData;
