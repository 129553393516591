import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, FormGroup, Button } from "reactstrap";
import { deleteReseller, getReseller } from "./redux/resellerApi";
import ModalLayout from "../../component/Common/ModalLayout";
import { setBreadcrumbItems } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import CreateReseller from "./CreateReseller";
import EditReseller from "./EditReseller";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import "./datatables.scss";

const ResellerAccounts = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Reseller Accounts", link: "#" },
  ]);
  const [resellerData, setResellerData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [resellerId, setResellerId] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState(null);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowCreate, setModalShowCreate] = useState(false);

  useEffect(() => {
    if ((modalShow, modalShowCreate)) return;
    (async () => {
      props.setBreadcrumbItems("Reseller Accounts", breadcrumbItems, true);

      try {
        const {
          data: { data: resellerData },
        } = await getReseller();

        setResellerData(resellerData);
        setFilteredData(resellerData);
      } catch (error) {
        console.error(error);
      }

      props.setBreadcrumbItems("Reseller Accounts", breadcrumbItems, false);
    })();
  }, [modalShow, modalShowCreate]);

  const handleDelete = async () => {
    props.setBreadcrumbItems("Reseller Accounts", breadcrumbItems, true);

    try {
      const { data: response } = await deleteReseller(resellerId);
      const currentData = resellerData.filter((item) => item.id !== resellerId);

      setSuccessAlert(true);
      setDescription(response.message);
      setResellerData(currentData);
      setFilteredData(currentData);
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("Reseller Accounts", breadcrumbItems, false);
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  const adminTable = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 270,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Address",
        field: "address",
        sort: "asc",
        width: 200,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 200,
      },
    ],
    rows: filteredData.map((obj) => ({
      ...obj,

      actions: (
        <>
          <i
            className="mdi mdi-square-edit-outline mr-1 iconHover"
            style={{ color: "#20C0ED", fontSize: "23px" }}
            role="button"
            onClick={() => {
              setResellerId(obj.id);
              toggleModal();
            }}
          ></i>
          <i
            className="mdi mdi mdi-delete  mr-1 iconHover"
            style={{
              color: "#DB4C3F",
              fontSize: "23px",
            }}
            role="button"
            onClick={() => {
              setConfirmAlert(true);
              setResellerId(obj.id);
            }}
          ></i>
        </>
      ),
    })),
  };

  return (
    <>
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}

      <Col xs="12">
        <Card>
          <CardBody>
            <FormGroup>
              <Col xs="12" className="row">
                <Col xs="2 col-2 mb-2">
                  <Button onClick={toggleModalCreate}>Create Reseller</Button>
                </Col>
              </Col>
            </FormGroup>

            <div className="table-rep-plugin">
              <div className="table-responsive mb-0">
                <MDBDataTable responsive bordered striped data={adminTable} />
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>

      {modalShow ? (
        <ModalLayout
          toggleModal={toggleModal}
          isOpen={modalShow}
          title="Edit Reseller"
          size="lg"
        >
          <EditReseller
            resellerId={resellerId}
            toggleModal={toggleModal}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Create Reseller"
          size="lg"
        >
          <CreateReseller
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(ResellerAccounts);
