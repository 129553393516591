import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import {
  editRole,
  getPermissions,
  updateRole,
} from "./redux/RolePermissionApi";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";

const EditRole = ({ isOpenEdit, toggleEdit, roleId, ...props }) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "User Roles", link: "#" },
  ]);
  const [roleData, setRoleData] = useState({
    customer_id: "all",
    name: "",
    description: "",
    permissions: [],
  });
  const [existPermissions, setExistPermissions] = useState([]);
  const [permissionData, setPermissionData] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState(null);
  const [loginUserRoleId, setLoginUserRoleId] = useState(
    getCurrentUser().role_id
  );

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { data: roleData, permissions: existPermissions },
        } = await editRole(roleId);

        const {
          data: { data: permissionData },
        } = await getPermissions();

        setRoleData(roleData);
        setExistPermissions(existPermissions);
        setPermissionData(permissionData);
      } catch (ex) {
        console.error(ex);
      }
    })();
  }, []);

  const handleCustomer = (value) => {
    setRoleData((prevState) => ({
      ...prevState,
      customer_id: value === "all" ? "all" : parseInt(value),
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, dataset } = e.target;

    if (type === "checkbox") {
      if (existPermissions.includes(parseInt(dataset.id))) {
        setExistPermissions(
          existPermissions.filter((item) => item !== parseInt(dataset.id))
        );
      } else {
        setExistPermissions([...existPermissions, parseInt(dataset.id)]);
      }
    } else {
      setRoleData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    if (roleData.customer_id === "all") {
      setErrorAlert(true);
      setDescription("Please select a customer");
      return false;
    } else if (!roleData.permissions.length) {
      setErrorAlert(true);
      setDescription("Please select a permission");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      const { data: response } = await updateRole(roleData);

      setSuccessAlert(true);
      setDescription(response.message);
    } catch (ex) {
      console.error(ex);
      if (ex.response) {
        setErrorAlert(true);
        setDescription(ex.response.data.message);
      }
    }
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          title="Success"
          success
          onConfirm={() => {
            setSuccessAlert(false);
            toggleEdit();
          }}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          title="Failed"
          error
          onConfirm={() => {
            setErrorAlert(false);
          }}
        >
          {description}
        </SweetAlert>
      ) : null}

      <Modal isOpen={isOpenEdit} toggle={toggleEdit} autoFocus={true} size="lg">
        <ModalHeader toggle={toggleEdit}>Edit Role</ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleSubmit}>
            <FormGroup>
              {roleData.name !== "Super User Role" &&
              getCurrentUser().role_id !== 2 ? (
                <SelectCustomer
                  className=""
                  handleCustomer={handleCustomer}
                  fieldError={null}
                  customerId={roleData.customer_id}
                />
              ) : (
                ""
              )}

              <AvField
                name="name"
                label="Name"
                placeholder="Enter role name"
                type="text"
                disabled={roleData.name === "Super User Role" ? true : false}
                value={roleData.name}
                onChange={handleChange}
                errorMessage="Enter role Name"
                validate={{
                  required: { value: true },
                }}
              />

              <AvField
                name="description"
                label="Description"
                placeholder="Enter description"
                type="text"
                onChange={handleChange}
                value={roleData.description}
              />
            </FormGroup>

            <hr />
            <FormGroup>
              <Col lg="12">
                <Row>
                  <Label style={{ fontWeight: "500" }}>PERMISSIONS</Label>
                </Row>
              </Col>
            </FormGroup>

            <FormGroup>
              <Col lg="12">
                <Row>
                  <Col lg="3">
                    <h6 className="font-weight-bold">User Roles</h6>
                    {permissionData
                      .filter((mod) => mod.module_name === "user_roles")
                      .map((item, key) => {
                        return (
                          <div key={key} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={item.name}
                              defaultChecked={existPermissions.includes(
                                item.id
                              )}
                              onChange={handleChange}
                              data-id={item.id}
                              disabled={
                                loginUserRoleId === 2 &&
                                roleData.name === "Super User Role"
                                  ? true
                                  : false
                              }
                            />

                            <Label
                              className="form-check-label"
                              check
                              htmlFor={item.name}
                            >
                              {item.display_name}
                            </Label>
                          </div>
                        );
                      })}
                  </Col>

                  <Col lg="3">
                    <h6 className="font-weight-bold">Users</h6>
                    {permissionData
                      .filter((mod) => mod.module_name === "users")
                      .map((item, key) => {
                        return (
                          <div key={key} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={item.name}
                              defaultChecked={existPermissions.includes(
                                item.id
                              )}
                              onChange={handleChange}
                              data-id={item.id}
                              disabled={
                                loginUserRoleId === 2 &&
                                roleData.name === "Super User Role"
                                  ? true
                                  : false
                              }
                            />

                            <Label
                              className="form-check-label"
                              check
                              htmlFor={item.name}
                            >
                              {item.display_name}
                            </Label>
                          </div>
                        );
                      })}
                  </Col>

                  <Col lg="3">
                    <h6 className="font-weight-bold">Devices</h6>
                    {permissionData
                      .filter((mod) => mod.module_name === "devices")
                      .map((item, key) => {
                        return (
                          <div key={key} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={item.name}
                              defaultChecked={existPermissions.includes(
                                item.id
                              )}
                              onChange={handleChange}
                              data-id={item.id}
                              disabled={
                                loginUserRoleId === 2 &&
                                roleData.name === "Super User Role"
                                  ? true
                                  : false
                              }
                            />

                            <Label
                              className="form-check-label"
                              check
                              htmlFor={item.name}
                            >
                              {item.display_name}
                            </Label>
                          </div>
                        );
                      })}
                  </Col>

                  <Col lg="3">
                    <h6 className="font-weight-bold">Manage Devices</h6>
                    {permissionData
                      .filter((mod) => mod.module_name === "manage_devices")
                      .map((item, key) => {
                        return (
                          <div key={key} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={item.name}
                              defaultChecked={existPermissions.includes(
                                item.id
                              )}
                              onChange={handleChange}
                              data-id={item.id}
                              disabled={
                                loginUserRoleId === 2 &&
                                roleData.name === "Super User Role"
                                  ? true
                                  : false
                              }
                            />

                            <Label
                              className="form-check-label"
                              check
                              htmlFor={item.name}
                            >
                              {item.display_name}
                            </Label>
                          </div>
                        );
                      })}
                  </Col>
                </Row>
              </Col>
            </FormGroup>

            <FormGroup>
              <Col lg="12">
                <Row>
                  <Col lg="3">
                    <h6 className="font-weight-bold">Profiles</h6>
                    {permissionData
                      .filter((mod) => mod.module_name === "profiles")
                      .map((item, key) => {
                        return (
                          <div key={key} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={item.name}
                              defaultChecked={existPermissions.includes(
                                item.id
                              )}
                              onChange={handleChange}
                              data-id={item.id}
                              disabled={
                                loginUserRoleId === 2 &&
                                roleData.name === "Super User Role"
                                  ? true
                                  : false
                              }
                            />

                            <Label
                              className="form-check-label"
                              check
                              htmlFor={item.name}
                            >
                              {item.display_name}
                            </Label>
                          </div>
                        );
                      })}
                  </Col>

                  <Col lg="3">
                    <h6 className="font-weight-bold">Device Groups</h6>
                    {permissionData
                      .filter((mod) => mod.module_name === "device_groups")
                      .map((item, key) => {
                        return (
                          <div key={key} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={item.name}
                              defaultChecked={existPermissions.includes(
                                item.id
                              )}
                              onChange={handleChange}
                              data-id={item.id}
                              disabled={
                                loginUserRoleId === 2 &&
                                roleData.name === "Super User Role"
                                  ? true
                                  : false
                              }
                            />

                            <Label
                              className="form-check-label"
                              check
                              htmlFor={item.name}
                            >
                              {item.display_name}
                            </Label>
                          </div>
                        );
                      })}
                  </Col>

                  <Col lg="3">
                    <h6 className="font-weight-bold">Upload Apks</h6>
                    {permissionData
                      .filter((mod) => mod.module_name === "upload_apk")
                      .map((item, key) => {
                        return (
                          <div key={key} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={item.name}
                              defaultChecked={existPermissions.includes(
                                item.id
                              )}
                              onChange={handleChange}
                              data-id={item.id}
                              disabled={
                                loginUserRoleId === 2 &&
                                roleData.name === "Super User Role"
                                  ? true
                                  : false
                              }
                            />

                            <Label
                              className="form-check-label"
                              check
                              htmlFor={item.name}
                            >
                              {item.display_name}
                            </Label>
                          </div>
                        );
                      })}
                  </Col>

                  <Col lg="3">
                    <h6 className="font-weight-bold">Upload File</h6>
                    {permissionData
                      .filter((mod) => mod.module_name === "upload_file")
                      .map((item, key) => {
                        return (
                          <div key={key} className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name={item.name}
                              defaultChecked={existPermissions.includes(
                                item.id
                              )}
                              onChange={handleChange}
                              data-id={item.id}
                              disabled={
                                loginUserRoleId === 2 &&
                                roleData.name === "Super User Role"
                                  ? true
                                  : false
                              }
                            />

                            <Label
                              className="form-check-label"
                              check
                              htmlFor={item.name}
                            >
                              {item.display_name}
                            </Label>
                          </div>
                        );
                      })}
                  </Col>
                </Row>
              </Col>
            </FormGroup>

            <FormGroup>
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary mr-1"
                onClick={() => {
                  setRoleData((prevState) => ({
                    ...prevState,
                    permissions: existPermissions,
                  }));
                }}
              >
                Submit
              </Button>

              <Button
                type="reset"
                onClick={toggleEdit}
                color="secondary"
                className="btn btn-secondary ml-1"
              >
                Cancel
              </Button>
            </FormGroup>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(EditRole);
