import React, { useState, useEffect } from "react";
import { Col, Row, Card, CardBody, FormGroup, Button, Label } from "reactstrap";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { getPermissions, storeRole } from "./redux/RolePermissionApi";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { setBreadcrumbItems } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import { getCurrentUser } from "../../services/authService";
import { SelectReseller } from "../../component/Common/SelectReseller";

const CreateRole = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "User Roles", link: "/user-roles" },
    { title: "Create Role", link: "#" },
  ]);
  const [permissionData, setPermissionData] = useState([]);
  const [roleData, setRoleData] = useState({
    resellerId:
      getCurrentUser().role_id !== 3 ? getCurrentUser().reseller_id : "all",
    customerId:
      getCurrentUser().role_id === 2 ? getCurrentUser().customer_id : "all",
    name: "",
    description: "",
    permissions: [],
  });
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState(null);
  const [customerError, setCustomerError] = useState(null);

  useEffect(() => {
    (async () => {
      props.setBreadcrumbItems("Create Role", breadcrumbItems, true);

      try {
        const {
          data: { data: permissionData },
        } = await getPermissions();

        setPermissionData(permissionData);
      } catch (error) {
        console.error(error);
      }

      props.setBreadcrumbItems("Create Role", breadcrumbItems, false);
    })();
  }, []);

  const handleReseller = (id) => {
    setRoleData((prevState) => ({
      ...prevState,
      resellerId: id === "all" ? "all" : parseInt(id),
      customerId: "all",
    }));
  };

  const handleCustomer = (value) => {
    setRoleData((prevState) => ({
      ...prevState,
      customerId: value === "all" ? "all" : parseInt(value),
    }));
  };

  const handleChange = (e) => {
    const { name, value, checked, type, dataset } = e.target;

    if (type === "checkbox") {
      setRoleData((prevState) => ({
        ...prevState,
        permissions: checked
          ? [...roleData.permissions, dataset.id]
          : [...roleData.permissions.filter((item) => item !== dataset.id)],
      }));
    } else {
      setRoleData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    if (roleData.customerId === "all") {
      setCustomerError("Please select a customer");
      return false;
    } else if (!roleData.permissions.length) {
      setErrorAlert(true);
      setDescription("Please select a permission");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    props.setBreadcrumbItems("Create Role", breadcrumbItems, true);

    try {
      const { data: response } = await storeRole(roleData);

      setSuccessAlert(true);
      setDescription(response.message);
    } catch (ex) {
      console.error(ex);
      if (ex.response) {
        setErrorAlert(true);
        setDescription(ex.response.data.message);
      }
    }

    props.setBreadcrumbItems("Create Reseller", breadcrumbItems, false);
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          title="Success"
          success
          onConfirm={() => {
            setSuccessAlert(false);
            props.history.replace("/user-roles");
          }}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          error
          title="Failed"
          onConfirm={() => {
            setErrorAlert(false);
          }}
        >
          {description}
        </SweetAlert>
      ) : null}

      <Row>
        <Col lg="10">
          <AvForm onValidSubmit={handleSubmit}>
            <Card>
              <CardBody>
                {getCurrentUser().role_id === 3 ? (
                  <SelectReseller
                    className="col-7"
                    fieldError={null}
                    resellerId={roleData.resellerId}
                    handleReseller={handleReseller}
                  />
                ) : (
                  ""
                )}

                {getCurrentUser().role_id !== 2 ? (
                  <SelectCustomer
                    className="col-7"
                    fieldError={customerError}
                    resellerId={roleData.resellerId}
                    customerId={roleData.customerId}
                    handleCustomer={handleCustomer}
                  />
                ) : (
                  ""
                )}

                <AvField
                  className="col-7"
                  name="name"
                  label="Name"
                  placeholder="Enter role name"
                  type="text"
                  value={roleData.name}
                  onChange={handleChange}
                  errorMessage="Enter role Name"
                  validate={{
                    required: { value: true },
                  }}
                />

                <AvField
                  className="col-7"
                  name="description"
                  label="Description"
                  placeholder="Enter description"
                  type="text"
                  onChange={handleChange}
                  value={roleData.description}
                />
              </CardBody>
              <hr />
              <CardBody>
                <Card>
                  <CardBody>
                    <Col lg="12">
                      <Row>
                        <Col lg="2">
                          <h5>
                            <Label style={{ fontWeight: "500" }}>
                              PERMISSIONS
                            </Label>
                          </h5>
                        </Col>

                        <Col lg="2">
                          <h6 className="font-weight-bold">User Roles</h6>
                          {permissionData
                            .filter((mod) => mod.module_name === "user_roles")
                            .map((item, key) => {
                              return (
                                <AvField
                                  key={key}
                                  label={item.display_name}
                                  name={item.name}
                                  data-id={item.id}
                                  type="checkbox"
                                  onChange={handleChange}
                                />
                              );
                            })}
                        </Col>

                        <Col lg="2">
                          <h6 className="font-weight-bold">Users</h6>
                          {permissionData
                            .filter((mod) => mod.module_name === "users")
                            .map((item, key) => {
                              return (
                                <AvField
                                  key={key}
                                  label={item.display_name}
                                  name={item.name}
                                  data-id={item.id}
                                  type="checkbox"
                                  onChange={handleChange}
                                />
                              );
                            })}
                        </Col>

                        <Col lg="2">
                          <h6 className="font-weight-bold">Devices</h6>
                          {permissionData
                            .filter((mod) => mod.module_name === "devices")
                            .map((item, key) => {
                              return (
                                <AvField
                                  key={key}
                                  label={item.display_name}
                                  name={item.name}
                                  data-id={item.id}
                                  type="checkbox"
                                  onChange={handleChange}
                                />
                              );
                            })}
                        </Col>

                        <Col lg="2">
                          <h6 className="font-weight-bold">Manage Devices</h6>
                          {permissionData
                            .filter(
                              (mod) => mod.module_name === "manage_devices"
                            )
                            .map((item, key) => {
                              return (
                                <AvField
                                  key={key}
                                  label={item.display_name}
                                  name={item.name}
                                  data-id={item.id}
                                  type="checkbox"
                                  onChange={handleChange}
                                />
                              );
                            })}
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>

                  <CardBody>
                    <Col lg="12">
                      <Row>
                        <Col lg="2">
                          <h5></h5>
                        </Col>

                        <Col lg="2">
                          <h6 className="font-weight-bold">Profiles</h6>
                          {permissionData
                            .filter((mod) => mod.module_name === "profiles")
                            .map((item, key) => {
                              return (
                                <AvField
                                  key={key}
                                  label={item.display_name}
                                  name={item.name}
                                  data-id={item.id}
                                  type="checkbox"
                                  onChange={handleChange}
                                />
                              );
                            })}
                        </Col>

                        <Col lg="2">
                          <h6 className="font-weight-bold">Device Groups</h6>
                          {permissionData
                            .filter(
                              (mod) => mod.module_name === "device_groups"
                            )
                            .map((item, key) => {
                              return (
                                <AvField
                                  key={key}
                                  name={item.name}
                                  label={item.display_name}
                                  data-id={item.id}
                                  type="checkbox"
                                  onChange={handleChange}
                                />
                              );
                            })}
                        </Col>

                        <Col lg="2">
                          <h6 className="font-weight-bold">Upload Apks</h6>
                          {permissionData
                            .filter((mod) => mod.module_name === "upload_apk")
                            .map((item, key) => {
                              return (
                                <AvField
                                  key={key}
                                  name={item.name}
                                  label={item.display_name}
                                  data-id={item.id}
                                  type="checkbox"
                                  onChange={handleChange}
                                />
                              );
                            })}
                        </Col>

                        <Col lg="2">
                          <h6 className="font-weight-bold">Upload File</h6>
                          {permissionData
                            .filter((mod) => mod.module_name === "upload_file")
                            .map((item, key) => {
                              return (
                                <AvField
                                  key={key}
                                  name={item.name}
                                  label={item.display_name}
                                  data-id={item.id}
                                  type="checkbox"
                                  onChange={handleChange}
                                />
                              );
                            })}
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>

                <FormGroup className="mb-0">
                  <div>
                    <Button
                      type="submit"
                      color="primary"
                      className="waves-effect waves-light mr-1"
                      id="sa-title"
                    >
                      Submit
                    </Button>

                    <Button
                      type="reset"
                      onClick={() => {
                        props.history.replace("/user-roles");
                      }}
                      color="secondary"
                      className="waves-effect"
                      id="e1"
                    >
                      Cancel
                    </Button>
                  </div>
                </FormGroup>
              </CardBody>
            </Card>
          </AvForm>
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(CreateRole);
