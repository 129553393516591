import React, { useState } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { storeReseller } from "./redux/resellerApi";
import { mimoLoader } from "../../store/actions";
import { FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";

const CreateReseller = ({
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [resellerData, setResellerData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    role_id: 1,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResellerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    dispatch(mimoLoader(true));
    try {
      const { data: response } = await storeReseller(resellerData);

      toggleModal();
      setSuccessAlert(true);
      setDescription(response.message);
    } catch (ex) {
      console.error(ex);
      if (ex.response) {
        setErrorAlert(true);
        setDescription(ex.response.data.message);
      }
    }
    dispatch(mimoLoader(true));
  };

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        <AvField
          name="name"
          label="Name"
          placeholder="Enter name"
          type="text"
          value={resellerData.name}
          onChange={handleChange}
          errorMessage="Enter Name"
          validate={{
            required: { value: true },
          }}
        />

        <AvField
          name="email"
          label="E-Mail  "
          placeholder="Enter email"
          type="email"
          onChange={handleChange}
          value={resellerData.email}
          errorMessage="Invalid Email"
          validate={{
            required: { value: true },
            email: { value: true },
          }}
        />

        <AvField
          name="phone"
          label="Phone Number"
          placeholder="Enter phone number"
          type="number"
          onChange={handleChange}
          value={resellerData.phone}
        />

        <AvField
          name="address"
          label="Address"
          placeholder="Enter address"
          type="text"
          onChange={handleChange}
          value={resellerData.address}
        />

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default CreateReseller;
