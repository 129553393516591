import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

// Products
export function getFirmProduct(id) {
  return axios.get(`/api/auth/getFirmProduct/${id}`, getHeader());
}

export function createProduct(data) {
  return axios.post(`/api/auth/createProduct`, data, getHeader());
}

export function editProduct(id) {
  return axios.get(`/api/auth/editProduct/${id}`, getHeader());
}

export function updateProduct(data) {
  return axios.post(`/api/auth/updateProduct`, data, getHeader());
}

export function deleteFirmwarePro(id) {
  return axios.get(`/api/auth/deleteFirmwarePro/${id}`, getHeader());
}

// Hardware
export function getFirmHardware(id) {
  return axios.get(`/api/auth/getFirmHardware/${id}`, getHeader());
}

export function createFirmHardware(data) {
  return axios.post(`/api/auth/createFirmHardware`, data, getHeader());
}

export function editFirmHardware(id) {
  return axios.get(`/api/auth/editFirmHardware/${id}`, getHeader());
}

export function updateFirmHardware(data) {
  return axios.post(`/api/auth/updateFirmHardware`, data, getHeader());
}

export function deleteFirmHardware(id) {
  return axios.get(`/api/auth/deleteFirmHardware/${id}`, getHeader());
}

// Firmware
export function getFirmwareDetail(id) {
  return axios.get(`/api/auth/versionDetail/${id}`, getHeader());
}

export function deleteFirmware(id) {
  return axios.get(`/api/auth/deleteFirmware/${id}`, getHeader());
}
