import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getProfiles(id) {
  return axios.get(`/api/auth/profiles/${id}`, getHeader());
}

export function createProfile(data) {
  return axios.post(`/api/auth/createProfile`, data, getHeader());
}

export function deleteProfile(id) {
  return axios.get(`/api/auth/deleteProfile/${id}`, getHeader());
}

export function getUpdateApkData(type) {
  return axios.get(`/api/auth/updateApkData/${type}`, getHeader());
}

export function getApkUrl(id) {
  return axios.get(`/api/auth/apkUrl/${id}`, getHeader());
}
