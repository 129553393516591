import React, { useState, useEffect } from "react";
import {
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Col,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Switch from "react-switch";

export const OverlayScreen = ({
  isOpenOverlay,
  toggleOverlay,
  handleOverlayScreen,
  overlayData,
}) => {
  const [data, setData] = useState({
    enableScreenPin: overlayData.enableScreenPin,
    overlayNewPin: overlayData.overlayNewPin,
    overlayWebURL: overlayData.overlayWebURL,
    enableOverlayWebview: overlayData.enableOverlayWebview,
    lockScreenTime: overlayData.lockScreenTime,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (data.enableOverlayWebview === true && data.overlayWebURL === "") {
      return;
    }
    handleOverlayScreen(data);
  };

  function Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  }
  function OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        {text}
      </div>
    );
  }

  return (
    <>
      <Modal
        isOpen={isOpenOverlay}
        toggle={toggleOverlay}
        autoFocus={true}
        size="lg"
      >
        <ModalHeader toggle={toggleOverlay}>
          Lock Screen
          <Switch
            className="ml-3"
            uncheckedIcon={Offsymbol("Off")}
            checkedIcon={OnSymbol("On")}
            onColor="#626ed4"
            onChange={() =>
              setData((prevState) => ({
                ...prevState,
                enableScreenPin: !data.enableScreenPin,
              }))
            }
            checked={data.enableScreenPin}
          />
        </ModalHeader>
        <ModalBody>
          <AvForm>
            <FormGroup row>
              <Label className="col-sm-2 col-form-label">New Pin</Label>
              <Col sm="10">
                <AvField
                  name="overlayNewPin"
                  placeholder="Enter new pin"
                  type="password"
                  value={overlayData.overlayNewPin}
                  onChange={handleChange}
                />
              </Col>
            </FormGroup>

            <FormGroup className="text-center">
              <div className="align-items-center">
                <Label className="mr-4 font-weight-bold">
                  Lock Screen Time (s)
                </Label>
                <button
                  className="btn btn-outline-secondary"
                  style={{ backgroundColor: "#f2f2f2", color: "black" }}
                  onClick={() => {
                    setData((prevState) => ({
                      ...prevState,
                      lockScreenTime: Math.max(
                        10,
                        Math.min(60, prevState.lockScreenTime - 1)
                      ),
                    }));
                  }}
                >
                  -
                </button>
                <span className="mx-2 input-text qty text font-weight-bold">
                  {data.lockScreenTime}
                </span>
                <button
                  className="btn btn-outline-secondary"
                  style={{ backgroundColor: "#f2f2f2", color: "black" }}
                  onClick={() => {
                    setData((prevState) => ({
                      ...prevState,
                      lockScreenTime: Math.max(
                        10,
                        Math.min(60, prevState.lockScreenTime + 1)
                      ),
                    }));
                  }}
                >
                  +
                </button>
              </div>
            </FormGroup>

            <FormGroup row>
              <Label className="col-sm-3 col-form-label">
                <b>Use Overlay Webview</b>
              </Label>
              <Switch
                uncheckedIcon={Offsymbol("Off")}
                checkedIcon={OnSymbol("On")}
                onColor="#626ed4"
                onChange={() =>
                  setData((prevState) => ({
                    ...prevState,
                    enableOverlayWebview: !data.enableOverlayWebview,
                  }))
                }
                checked={data.enableOverlayWebview}
              />
            </FormGroup>

            <FormGroup row>
              <Label className="col-sm-2 col-form-label">Web URL</Label>
              <Col sm="10">
                <AvField
                  name="overlayWebURL"
                  placeholder="https://wwww.chess.com"
                  type="text"
                  value={overlayData.overlayWebURL}
                  onChange={handleChange}
                  errorMessage="Enter a valid URL"
                  validate={{
                    required: {
                      value: data.enableOverlayWebview ? true : false,
                    },
                    pattern: {
                      value:
                        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                      errorMessage: "Enter a valid URL",
                    },
                  }}
                />
              </Col>
            </FormGroup>

            <FormGroup className="text-right">
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary mr-1"
                onClick={handleSave}
              >
                Save
              </Button>

              <Button
                type="reset"
                onClick={toggleOverlay}
                color="secondary"
                className="btn btn-secondary ml-1"
              >
                Cancel
              </Button>
            </FormGroup>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
};
