import { getCurrentUser, getPermissions } from "./authService";

export function Permissions() {
  const PERMISSIONS = {
    create_role: "createRolePermi",
    edit_role: "editRolePermi",
    delete_role: "deleteRolePermi",

    create_user: "createUserPermi",
    edit_user: "editUserPermi",
    delete_user: "deleteUserPermi",

    create_device: "createDevicePermi",
    edit_device: "editDevicePermi",
    delete_device: "deleteDevicePermi",

    manage_devices: "manageDevicesPermi",
    clear_response: "clearResponsePermi",

    create_profile: "createProfilePermi",
    edit_profile: "editProfilePermi",
    delete_profile: "deleteProfilePermi",

    create_group: "createGroupPermi",
    edit_group: "editGroupPermi",
    delete_group: "deleteGroupPermi",

    upload_apk: "uploadApkPermi",
    edit_apk: "editApkPermi",
    delete_apk: "deleteApkPermi",

    upload_file: "uploadFilePermi",
    edit_file: "editFilePermi",
    delete_file: "deleteFilePermi",
  };

  const currentUserRole = getCurrentUser()?.role_id;

  if (currentUserRole !== 1 && currentUserRole !== 3) {
    const permis = getPermissions();
    return Object.fromEntries(
      Object.entries(PERMISSIONS).map(([key, value]) => [
        value,
        permis.some((item) => item.name === key),
      ])
    );
  } else {
    return Object.fromEntries(
      Object.entries(PERMISSIONS).map(([key, value]) => [value, true])
    );
  }
}
