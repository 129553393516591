import React, { useState, useEffect } from "react";
import { SelectReseller } from "../../../component/Common/SelectReseller";
import { getCustomerData } from "../../Company/redux/customerApi";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../../services/authService";
import { Col, Card, CardBody, Button, Row } from "reactstrap";
import { setBreadcrumbItems } from "../../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { enableTwoFA } from "../redux/twoFactorApi";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import "./datatables.scss";

const EnableTwoFactor = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo Monitors", link: "/" },
    { title: "Two Factor Authentication", link: "#" },
  ]);

  const [customerData, setCustomerData] = useState([]);
  const [customerObject, setCustomerObject] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successAlert, setSuccessAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [companyStatus, setCompanyStatus] = useState("all");
  const [resellerId, setResellerId] = useState("all");

  useEffect(() => {
    (async () => {
      props.setBreadcrumbItems(
        "Two Factor Authentication",
        breadcrumbItems,
        true
      );

      try {
        const { data: customerData } = await getCustomerData();

        setCustomerData(customerData);
        setFilteredData(customerData);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems(
        "Two Factor Authentication",
        breadcrumbItems,
        false
      );
    })();
  }, []);

  const handleReseller = (id) => {
    setResellerId(id);
    setFilteredData(
      customerData.filter((item) =>
        companyStatus === "all"
          ? id === "all"
            ? item
            : item.reseller_id == id
          : id === "all"
          ? item.two_factor_status === companyStatus
          : item.reseller_id == id && item.two_factor_status === companyStatus
      )
    );
  };

  const handleStatus = (e) => {
    const { value } = e.target;
    setCompanyStatus(value);
    setFilteredData(
      customerData.filter((item) =>
        value === "all"
          ? resellerId === "all"
            ? item
            : item.reseller_id == resellerId
          : resellerId === "all"
          ? item.two_factor_status === value
          : item.reseller_id == resellerId && item.two_factor_status === value
      )
    );
  };

  const handleSubmit = async () => {
    try {
      props.setBreadcrumbItems(
        "Two Factor Authentication",
        breadcrumbItems,
        true
      );

      const { data: data } = await enableTwoFA(customerObject.id);

      customerObject.two_factor_status = data.status;
      const currentData = [...filteredData];
      const index = currentData.indexOf(customerObject);
      currentData[index] = { ...customerObject };

      setConfirmAlert(false);
      setSuccessAlert(true);
      setDescription(data.message);
      setFilteredData(currentData);
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems(
      "Two Factor Authentication",
      breadcrumbItems,
      false
    );
  };

  const customerTable = {
    columns: [
      {
        label: "Company",
        field: "customer",
        sort: "desc",
        width: 200,
      },
      {
        label: "Company Email",
        field: "customerEmail",
        sort: "desc",
        width: 200,
      },
      {
        label: "Two Factor Auth Status",
        field: "action",
        sort: "desc",
        width: 200,
      },
    ],
    rows: filteredData.map((obj, key) => ({
      customer: obj.name,
      customerEmail: obj.email,

      action: (
        <>
          <Button
            color={obj.two_factor_status === "disable" ? "danger" : "primary"}
            className="w-md waves-effect waves-light text-center"
            onClick={() => {
              setCustomerObject(obj);
              setConfirmAlert(true);
              setDescription(
                obj.two_factor_status === "enable"
                  ? "Do you want to disable Two Factor Authentication"
                  : "Do you want to enable Two Factor Authentication"
              );
            }}
          >
            {obj.two_factor_status === "disable" ? "Disabled" : "Enabled"}
          </Button>
        </>
      ),
    })),
  };

  return (
    <>
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleSubmit();
          }}
          onCancel={() => {
            setConfirmAlert(false);
          }}
        >
          {description}
        </SweetAlert>
      ) : (
        ""
      )}
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : (
        ""
      )}

      <Col xs="12">
        <Card>
          <CardBody>
            <Col xs="12">
              <AvForm>
                <Row className="align-items-center mb-3">
                  {getCurrentUser().role_id === 3 ? (
                    <Col xs="3">
                      <SelectReseller
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        handleReseller={handleReseller}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col xs="3">
                    <AvField
                      label="Company Status"
                      type="select"
                      name="customer"
                      onChange={handleStatus}
                    >
                      <option value="all">All</option>
                      <option value="enable">Enabled</option>
                      <option value="disable">Disabled</option>
                    </AvField>
                  </Col>
                </Row>
              </AvForm>
            </Col>

            <MDBDataTable
              responsive
              bordered
              striped
              entries={100}
              data={customerTable}
              className="d1"
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(EnableTwoFactor);
