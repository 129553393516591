import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip } from "reactstrap";
import { setBreadcrumbItems } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from "react-redux";
import axios from "axios";

import ReactApexChart from "react-apexcharts";
import "c3/c3.css";
import secureLocalStorage from "react-secure-storage";

class StatPssModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Mimo", link: "/" },
        { title: "Devices", link: "/device" },
        { title: "all", link: "#" },
      ],

      statModal: false,
      statData: [],
      time: [],
      freeRam: [],
      data: {
        id: this.props.deviceId,
      },
      confirmNotify: false,
      errorDialog: false,
      successConfirm: false,
      alertTitle: "",
      alertDescription: "",
      mimoLoader: false,
    };
  }

  statToggle = () => {
    this.setState(
      {
        statModal: !this.state.statModal,
      },
      () => {
        if (this.state.statModal == false) {
          this.setState({
            statData: [],
          });
        }
      }
    );
  };

  memoryStat = () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");
    axios
      .post(`${REACT_APP_URL}/api/auth/memoryStats`, this.state.data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.setState({
          statData: res.data.data,
          time: res.data.time.map((item) => {
            const strToDate = new Date(item);
            return new Date(strToDate + "UTC").toLocaleString([], {
              dateStyle: "short",
              timeStyle: "short",
            });
          }),
          freeRam: res.data.freeRam,
          mimoLoader: false,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  resetStats = () => {
    this.props.setBreadcrumbItems("Devices", this.state.breadcrumbItems, true);
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");
    axios
      .post(`${REACT_APP_URL}/api/auth/resetStats`, this.state.data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.setState(
          {
            successConfirm: true,
            alertTitle: "Success",
            alertDescription: res.data.message,
            statModal: !this.state.statModal,
          },
          () => {
            this.props.setBreadcrumbItems(
              "Devices",
              this.state.breadcrumbItems,
              false
            );
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    const options = {
      chart: {
        zoom: {
          enabled: true,
        },
        toolbar: {
          show: false,
        },
      },
      legend: {
        show: true,
      },

      stroke: {
        curve: "smooth",
        width: 2,
      },
      grid: {
        borderColor: "#f8f8fa",
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: this.state.time ? this.state.time : [],
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
      },
    };

    const series = this.state.statData.map((item) => {
      return {
        name: [item.processName],
        data: item.pss,
      };
    });
    series.push({
      name: ["Free Ram"],
      data: this.state.freeRam,
    });

    return (
      <>
        {this.state.confirmNotify ? (
          <SweetAlert
            title={this.state.alertTitle}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              this.setState(
                {
                  confirmNotify: false,
                },
                () => {
                  this.resetStats();
                }
              );
            }}
            onCancel={() =>
              this.setState({
                confirmNotify: false,
                errorDialog: true,
                alertTitle: "Cancelled",
                alertDescription: "Action has been cancelled",
              })
            }
          >
            {this.state.alertDescription}
          </SweetAlert>
        ) : null}
        {this.state.errorDialog ? (
          <SweetAlert
            error
            title={this.state.alertTitle}
            onConfirm={() => this.setState({ errorDialog: false })}
          >
            {this.state.alertDescription}
          </SweetAlert>
        ) : null}
        {this.state.successConfirm ? (
          <SweetAlert
            success
            title={this.state.alertTitle}
            onConfirm={() => this.setState({ successConfirm: false })}
          >
            {this.state.alertDescription}
          </SweetAlert>
        ) : null}

        {/* BUTTON */}
        <i
          style={{ color: "#E99401", fontSize: "21px" }}
          className="mdi mdi-chart-line iconHover"
          tabIndex="0"
          role="button"
          id={`statPssModal`}
          onClick={() => {
            this.statToggle();
            this.memoryStat();
          }}
        ></i>
        <UncontrolledTooltip placement="top" target={`statPssModal`}>
          Memory Stats Pss
        </UncontrolledTooltip>

        <Modal
          isOpen={this.state.statModal}
          toggle={this.statToggle}
          autoFocus={true}
          size="xl"
        >
          <ModalHeader toggle={this.statToggle}>
            Memory Stats Pss{" "}
            <i
              style={{ color: "#DB4C3F", fontSize: "21px" }}
              className="mdi mdi-delete iconHover"
              tabIndex="0"
              role="button"
              onClick={() =>
                this.setState({
                  confirmNotify: true,
                  alertTitle: "Are you sure?",
                  alertDescription: "Do you want to reset memory stats?",
                })
              }
            ></i>
            <i
              style={{ color: "#30B8A1", fontSize: "22px" }}
              className="mdi mdi-refresh iconHover"
              tabIndex="0"
              role="button"
              onClick={() =>
                this.setState(
                  {
                    mimoLoader: true,
                  },
                  () => {
                    this.memoryStat();
                  }
                )
              }
            ></i>
            {/* {this.state.mimoLoader === true ? (
              <div
                style={{ width: "33px", height: "33px" }}
                className="mimoLoader ml-2"
              ></div>
            ) : (
              ""
            )} */}
          </ModalHeader>
          <ModalBody>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height="700"
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default connect(null, { setBreadcrumbItems })(StatPssModal);
