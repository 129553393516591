import React, { Component } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { getCurrentUser, getHeader } from "../../services/authService";
import { updateApkData } from "../MimoApks/redux/mimoApkApi";
import { getProfiles } from "../Profile/redux/profileApi";
import { setBreadcrumbItems } from "../../store/actions";
import { Permissions } from "../../services/permissions";
import { AvForm } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable, MDBInput } from "mdbreact";
import { CSVLink } from "react-csv";
import EditDeviceModal from "./EditDeviceModal";
import * as deviceApi from "./redux/deviceApi";
import StatUssModal from "./StatUssModal";
import StatPssModal from "./StatPssModal";
import { Link } from "react-router-dom";
import EventsModal from "./eventModal";
import { connect } from "react-redux";
import GroupModal from "./groupModal";
import ViewModal from "./ViewModal";
import "./datatables.scss";
import axios from "axios";

class Devices extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      breadcrumbItems: [
        { title: "Mimo", link: "/" },
        { title: "Devices", link: "#" },
      ],
      data: [],
      confirm_both: false,
      success_dlg: false,
      error_dlg: false,
      event_alert: false,
      deletedData: {},
      customerUsers: [],
      customerEmails: [],
      title: "",
      description: "",

      modal_data: {},
      modal_center: false,
      check: true,
      filteredData: [],
      checkedBoxes: [],
      confirmAlert: false,
      isAllChecked: false,
      updateApkData: [],
      mlockApkData: [],
      macAddress: [],
      profileData: [],
      updateMServiceAlert: false,
      updateMLockAlert: false,
      maintenanceAlert: false,
      multiMaintenanceAlert: false,
      successAlert: false,

      activePage: "",
      status: "all",

      statToggle: false,
      notificationData: {
        deviceId: null,
      },
      confirmNotify: false,
      multiMaintenanceMode: true,
      confirmNotifyUser: false,
      maintenanceRadioButton: true,

      resellerId: "all",
      customerId: this.props.match.params.customerId
        ? this.props.match.params.customerId
        : "all",
      deviceId: null,
      editModalShow: false,

      setDirectCommand: false,
    };
  }

  async componentDidMount() {
    const { breadcrumbItems, customer } = this.state;
    this.props.setBreadcrumbItems("Devices", breadcrumbItems, true);

    // Get Devices
    try {
      const { data: deviceData } = await deviceApi.getDevices(
        getCurrentUser().role_id === 1 || getCurrentUser().role_id === 3
          ? "all"
          : getCurrentUser().customer_id
      );

      this.setState({
        data: deviceData,
        filteredData:
          this.props.location.deviceLast48Hours ||
          this.props.location.activeDevices ||
          this.props.location.onlineDevices
            ? this.props.location.deviceLast48Hours ||
              this.props.location.activeDevices ||
              this.props.location.onlineDevices
            : deviceData,
      });
    } catch (ex) {
      console.error(ex);
    }

    // Get APK Data
    try {
      const { data: mserviceData } = await updateApkData("MService");
      const { data: mlockData } = await updateApkData("MLock");

      this.setState({
        updateApkData:
          process.env.REACT_APP_URL +
          "/" +
          mserviceData[0].app_name +
          "/" +
          mserviceData[0].file_name,

        mlockApkData:
          process.env.REACT_APP_URL +
          "/" +
          mlockData[0].app_name +
          "/" +
          mlockData[0].file_name,
      });
    } catch (ex) {
      console.error(ex);
    }

    // Get Profile Data
    try {
      const { data: profileData } = await getProfiles(
        getCurrentUser().role_id === 1 || getCurrentUser().role_id === 3
          ? "all"
          : getCurrentUser().customer_id
      );

      this.setState({
        profileData: profileData,
      });
    } catch (ex) {
      console.error(ex);
    }

    if (getCurrentUser().role_id === 2) {
      axios
        .get(`/api/auth/userShow/${getCurrentUser().id}`, getHeader())
        .then((res) => {
          this.setState({
            customerUsers: res.data,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    this.props.setBreadcrumbItems("Devices", breadcrumbItems, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.editModalShow &&
      this.state.editModalShow !== prevState.editModalShow
    ) {
      this.handleRefresh();
    }
  }

  handleEdit = (id) => {
    this.props.history.replace(`/device-edit/${id}`);
  };

  handleDownloadCSV = () => {
    this.csvLink.link.click();
  };

  toggleEditModal = () => {
    this.setState({
      editModalShow: !this.state.editModalShow,
    });
  };

  handleAllChecked = (e) => {
    const checkFlag = e.target.checked;
    this.setState({
      activePage: this.ref.current.state.activePage,
    });

    let listOfItems = this.ref.current.state.pages[
      this.ref.current.state.activePage
    ].map((item) => item.id);

    this.setState(
      {
        filteredData: this.state.filteredData.map((item) => {
          if (
            listOfItems.some((obj) => {
              return obj === item.id;
            })
          ) {
            return { ...item, isChecked: checkFlag };
          } else {
            return { ...item };
          }
        }),
      },
      () => {
        this.ref.current.changeActivePage(this.state.activePage);

        let topCheckBox = document.getElementsByClassName("header-checkbox");
        topCheckBox[0].checked = checkFlag;
        topCheckBox[1].checked = checkFlag;
      }
    );
  };

  toggleCheckbox = (e, obj) => {
    let checkFlag = true;
    this.setState(
      {
        activePage: this.ref.current.state.activePage,
        filteredData: this.state.filteredData.map((item) => {
          if (item.id === obj.id) {
            if(item.isChecked){
              checkFlag = false;
            }
            return { ...item, isChecked: !item.isChecked };
          } else {
            if(!item.isChecked){
              checkFlag = false;
            }
            return { ...item };
          }
        }),
      },
      () => {
        this.ref.current.changeActivePage(this.state.activePage);
        let topCheckBox = document.getElementsByClassName("header-checkbox");
        topCheckBox[0].checked = checkFlag;
        topCheckBox[1].checked = checkFlag;
      }
    );
  };

  changePage = (activePage) => {
    this.handleTopCheckbox(activePage - 1);
  };

  handleTopCheckbox = (activePage) => {
    let activeItem = this.ref.current.state.pages[activePage];
    let topCheckBox = document.getElementsByClassName("header-checkbox");
    let isActive = activeItem.every((item) => {
      return item.isChecked === true;
    });
    topCheckBox[0].checked = isActive;
    topCheckBox[1].checked = isActive;
  };

  handleModelAlert = () => {
    this.setState({
      event_alert: true,
    });
  };

  handleUpdateMservice = () => {
    this.props.setBreadcrumbItems("Devices", this.state.breadcrumbItems, true);

    this.setState(
      {
        updateMServiceAlert: false,
        submitted_data: {
          command: "updateMIMOService",
          macAddress: this.state.macAddress,
          data: {
            apk_name: "MService.apk",
            apk_url: this.state.updateApkData,
            mState: false,
          },
          header: {
            userName: "Admin",
            password: "0000",
          },
        },
      },
      () => {
        axios
          .post(
            `/api/auth/remoteDevice`,
            this.state.submitted_data,
            getHeader()
          )
          .then((res) => {
            if (res.data.error) {
              this.setState(
                {
                  error: res.data.error,
                },
                () => {
                  this.props.setBreadcrumbItems(
                    "Devices",
                    this.state.breadcrumbItems,
                    false
                  );
                }
              );
            } else {
              this.setState(
                {
                  successAlert: true,
                  updateMServiceAlert: false,
                  description: "Command sent - Check response for status",
                },
                () => {
                  this.props.setBreadcrumbItems(
                    "Devices",
                    this.state.breadcrumbItems,
                    false
                  );
                }
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  };

  handleUpdateMLock = () => {
    this.props.setBreadcrumbItems("Devices", this.state.breadcrumbItems, true);

    this.setState(
      {
        updateMLockAlert: false,
        submitted_data: {
          command: "updateMLock",
          macAddress: this.state.macAddress,
          data: {
            apk_name: "MLock.apk",
            apk_url: this.state.mlockApkData,
            mState: false,
          },
          header: {
            userName: "Admin",
            password: "0000",
          },
        },
      },
      () => {
        axios
          .post(
            `/api/auth/remoteDevice`,
            this.state.submitted_data,
            getHeader()
          )
          .then((res) => {
            if (res.data.error) {
              this.setState(
                {
                  error: res.data.error,
                },
                () => {
                  this.props.setBreadcrumbItems(
                    "Devices",
                    this.state.breadcrumbItems,
                    false
                  );
                }
              );
            } else {
              this.setState(
                {
                  successAlert: true,
                  updateMLockAlert: false,
                  description: "Command sent - Check response for status",
                },
                () => {
                  this.props.setBreadcrumbItems(
                    "Devices",
                    this.state.breadcrumbItems,
                    false
                  );
                }
              );
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    );
  };

  // Maintenance Mode
  handleMaintenanceMode = async () => {
    const { breadcrumbItems } = this.state;
    this.props.setBreadcrumbItems("Devices", breadcrumbItems, true);

    try {
      const { data: response } = await deviceApi.saveMaintenanceMode(
        this.state.submitted_data
      );

      this.setState({
        successAlert: true,
        description: response.message,
      });
    } catch (ex) {
      console.error(ex);
    }

    this.props.setBreadcrumbItems("Devices", breadcrumbItems, false);
  };

  handleMultiMaintenance = () => {
    this.state.filteredData.map((item) => {
      if (item.isChecked && item.isChecked === true) {
        let arr = this.state.checkedBoxes;
        arr.push(item.id);
        this.setState({
          checkedBoxes: arr,
        });
      }
    });

    this.setState(
      {
        submitted_data: {
          maintenanceRadioButton: this.state.maintenanceRadioButton,
          checkedBoxes: this.state.checkedBoxes,
        },
      },
      async () => {
        const { breadcrumbItems } = this.state;
        this.props.setBreadcrumbItems("Devices", breadcrumbItems, true);

        try {
          const { data: response } = await deviceApi.saveMultiMaintenanceMode(
            this.state.submitted_data
          );

          this.setState({
            successAlert: true,
            description: response.message,
            maintenanceRadioButton: true,
            checkedBoxes: [],
          });
        } catch (ex) {
          console.error(ex);
          this.setState({
            checkedBoxes: [],
          });
        }
      }
    );
  };

  handleRadioButton = (e) => {
    this.setState({
      maintenanceRadioButton:
        e.target.name === "maintenanceEnable" ? true : false,
    });
  };

  // Delete Functions
  handleDelete = () => {
    this.props.setBreadcrumbItems("Devices", this.state.breadcrumbItems, true);

    axios
      .post(
        `/api/auth/deleteDevice/${this.state.deletedData.id}`,
        this.state.deletedData,
        getHeader()
      )
      .then((res) => {
        this.setState(
          {
            successAlert: true,
            title: "Success",
            description: "Your data has been deleted.",

            data: this.state.data.filter(
              (item) => item.id != this.state.deletedData.id
            ),

            filteredData: this.state.filteredData.filter(
              (item) => item.id != this.state.deletedData.id
            ),
          },
          () => {
            this.props.setBreadcrumbItems(
              "Devices",
              this.state.breadcrumbItems,
              false
            );
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  deleteDevices = async () => {
    const { breadcrumbItems } = this.state;
    this.props.setBreadcrumbItems("Devices", breadcrumbItems, true);

    this.state.filteredData.map((item) => {
      if (item.isChecked && item.isChecked === true) {
        let arr = this.state.checkedBoxes;
        arr.push(item.id);
        this.setState({
          checkedBoxes: arr,
        });
      }
    });

    try {
      await deviceApi.selectDeleteDevice(
        JSON.stringify(this.state.checkedBoxes)
      );

      this.setState(
        {
          success_dlg: true,
          title: "Success",
          description: "Devices has been deleted.",

          data: this.state.data.filter(
            (item) => !this.state.checkedBoxes.includes(item.id)
          ),

          filteredData: this.state.filteredData.filter(
            (item) => !this.state.checkedBoxes.includes(item.id)
          ),

          checkedBoxes: [],
        },
        () => {
          let topCheckBox = document.getElementsByClassName("header-checkbox");
          topCheckBox[0].checked = false;
          topCheckBox[1].checked = false;
        }
      );
    } catch (ex) {
      console.error(ex);
    }

    this.props.setBreadcrumbItems("Devices", breadcrumbItems, false);
  };

  // Filterts
  handleReseller = (id) => {
    this.setState({
      resellerId: id === "all" ? "all" : parseInt(id),
      customer: "all",
      status: "all",
      filteredData: this.state.data.filter((item) =>
        id === "all" ? item : item.resellerId == id
      ),
    });
  };

  handleCustomer = (id) => {
    this.setState(
      {
        customerId: id === "all" ? "all" : parseInt(id),
        status: "all",
        filteredData: this.state.data
          .filter((item) =>
            this.state.resellerId === "all"
              ? item
              : item.resellerId == this.state.resellerId
          )
          .filter((item) => (id === "all" ? item : item.customer_id == id)),
      },
      () => {
        axios
          .get(`/api/auth/profiles/${id}`, getHeader())
          .then((res) => {
            this.setState({ profileData: res.data });
          })
          .catch((error) => {
            console.error(error);
          });

        if (id != "all") {
          axios
            .get(`/api/auth/customer-users/${id}`, getHeader())
            .then((res) => {
              this.setState({
                customerUsers: res.data.data.map((item) => ({
                  ...item,
                  emailChecked: item.enable_notify == "on",
                })),
              });
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    );
  };

  handleStatus = (e) => {
    this.setState({
      status: e.target.value,
      filteredData: this.state.data
        .filter((item) =>
          this.state.resellerId === "all"
            ? item
            : item.resellerId == this.state.resellerId
        )
        .filter((item) =>
          this.state.customerId === "all"
            ? item
            : item.customer_id == this.state.customerId
        )
        .filter((item) =>
          e.target.value === "all"
            ? item
            : e.target.value === "unnamed"
            ? item.device_name === null
            : item.status == e.target.value
        ),
    });
  };

  handleRefresh = async () => {
    const { breadcrumbItems } = this.state;
    this.props.setBreadcrumbItems("Devices", breadcrumbItems, true);

    try {
      const { data: deviceData } = await deviceApi.getDevices(
        getCurrentUser().role_id === 1 || getCurrentUser().role_id === 3
          ? "all"
          : getCurrentUser().customer_id
      );

      this.setState({
        data: deviceData,
        filteredData: deviceData.filter((item) =>
          this.state.customerId == "all"
            ? this.state.status == "all"
              ? item
              : this.state.status == "online"
              ? item.status == "on"
              : this.state.status == "offline"
              ? item.status == "off"
              : item.status == "maintenance"
            : this.state.status == "all"
            ? item.customer_id == this.state.customerId
            : this.state.status == "online"
            ? item.status == "on" && item.customer_id == this.state.customerId
            : this.state.status == "offline"
            ? item.status == "off" && item.customer_id == this.state.customerId
            : item.status == "maintenance" &&
              item.customer_id == this.state.customerId
        ),
      });
    } catch (ex) {
      console.error(ex);
    }

    if (
      (getCurrentUser().role_id === 1 || getCurrentUser().role_id === 3) &&
      this.state.customerId != "all"
    ) {
      axios
        .get(`/api/auth/customer-users/${this.state.customerId}`, getHeader())
        .then((res) => {
          this.setState({
            customerUsers: res.data.data.map((item) => ({
              ...item,
              emailChecked: item.enable_notify == "on",
            })),
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (getCurrentUser().role_id === 2) {
      axios
        .get(`/api/auth/userShow/${getCurrentUser().id}`, getHeader())
        .then((res) => {
          this.setState({
            customerUsers: res.data,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    this.props.setBreadcrumbItems("Devices", breadcrumbItems, false);
  };

  // Notification
  checkboxUsers = (object) => {
    this.setState({
      customerUsers: this.state.customerUsers.map((item) => {
        if (item.email === object.email) {
          return { ...item, emailChecked: !item.emailChecked };
        } else {
          return { ...item };
        }
      }),
    });
  };

  handleEmailNotiAdmin = async () => {
    const { breadcrumbItems } = this.state;
    this.props.setBreadcrumbItems("Devices", breadcrumbItems, true);

    try {
      const { data: response } = await deviceApi.emailNotiAdmin(
        this.state.notificationData
      );

      this.setState({
        successAlert: true,
        description: response.message,
      });
    } catch (ex) {
      console.error(ex);
    }

    this.props.setBreadcrumbItems("Devices", breadcrumbItems, false);
  };

  handleEmailNotiUser = async () => {
    const { breadcrumbItems } = this.state;
    this.props.setBreadcrumbItems("Devices", breadcrumbItems, true);

    try {
      const { data: response } = await deviceApi.emailNotiUser(
        this.state.notificationData
      );

      this.setState({
        successAlert: true,
        description: response.message,
      });
    } catch (ex) {
      console.error(ex);
    }

    this.props.setBreadcrumbItems("Devices", breadcrumbItems, false);
  };

  //Location
  handleLocation = async (device) => {
    try {
      const { data: location } = await deviceApi.getLocation(device.id);
      device.location = location.location;
      const filteredData = [...this.state.filteredData];
      const index = filteredData.indexOf(device);
      filteredData[index] = { ...device };
      this.setState({
        filteredData: filteredData,
      });
    } catch (ex) {
      console.error(ex);
    }
  };

  handleDirectCommands = async () => {
    const { breadcrumbItems } = this.state;
    this.props.setBreadcrumbItems("Devices", breadcrumbItems, true);

    this.state.filteredData.map((item) => {
      if (item.isChecked && item.isChecked === true) {
        let arr = this.state.checkedBoxes;
        arr.push(item.mac_address);
        this.setState({
          checkedBoxes: arr,
        });
      }
    });

    try {
      await deviceApi.setDirectCommand({
        macAddresses: this.state.checkedBoxes,
      });

      this.setState(
        {
          successAlert: true,
          description: "Reboot command issued",
          checkedBoxes: [],
        },
        () => {
          let topCheckBox = document.getElementsByClassName("header-checkbox");
          topCheckBox[0].checked = false;
          topCheckBox[1].checked = false;
        }
      );
    } catch (ex) {
      console.error(ex);
    }

    this.props.setBreadcrumbItems("Devices", breadcrumbItems, false);
  };

  render() {
    const {
      successAlert,
      event_alert,
      success_dlg,
      success_dlg1,
      confirm_both,
      success_confirm,
      confirmAlert,
      error_dlg,
      updateMServiceAlert,
      updateMLockAlert,
      confirmNotify,
      confirmNotifyUser,
      maintenanceAlert,
      multiMaintenanceAlert,
      title,
      description,
      customerId,
      filteredData,
      check,
      customerUsers,
      status,
      notificationData,
      submitted_data,
      maintenanceRadioButton,
      profileData,
      setDirectCommand,
    } = this.state;

    function getCSVData(deviceData) {
      const csvData = [];
      csvData.push(["Device Name", "IP Address", "Ethernet Mac Address", "Wifi Mac Address", "Status", "MService Version"]);
      deviceData.forEach((deviceItem) => {
        if (deviceItem.isChecked) {
          csvData.push([deviceItem.device_name, deviceItem.ip_address, deviceItem.mac_address_ethernet, deviceItem.mac_address, deviceItem.status, deviceItem.version_number]);
        }
      });
      return csvData;
    }

    const data = {
      columns: [
        {
          label: (
            <MDBInput
              type="checkbox"
              defaultChecked={false}
              onChange={(e) => {
                this.handleAllChecked(e);
              }}
              className="header-checkbox"
              style={{ fontSize: "2px" }}
            />
          ),
          field: "check",
          sort: "disabled",
          width: 200,
        },

        {
          label: "Device Name",
          field: "device_name",
          sort: "desc",
          width: 200,
        },

        {
          label: "IP Address",
          field: "ip_address",
          sort: "desc",
          width: 200,
        },

        {
          label: "Ethernet Mac Address",
          field: "mac_address_ethernet",
          sort: "desc",
          width: 200,
        },

        {
          label: "Wifi Mac Address",
          field: "mac_address",
          sort: "desc",
          width: 200,
        },

        {
          label: "Status",
          field: "status",
          sort: "desc",
          width: 200,
        },

        {
          label: "Location",
          field: "location",
          sort: "desc",
          width: 200,
        },

        {
          label: "Action",
          field: "action",
          sort: "disabled",
          width: 200,
        },
      ],
      rows:
        check == true
          ? filteredData.map((obj, key) => ({
              check: (
                <MDBInput
                  key={obj.id}
                  type="checkbox"
                  value={obj.id}
                  checked={obj.isChecked ? obj.isChecked : false}
                  onChange={(e) => {
                    this.toggleCheckbox(e, obj);
                  }}
                  style={{ fontSize: "2px" }}
                />
              ),
              id: obj.id,
              isChecked: obj.isChecked ? obj.isChecked : false,

              device_name: obj.device_name,
              customer: obj.customer,
              mac_address: obj.mac_address,
              status:
                obj.rebootStatus === 0 ? (
                  obj.status == "on" ? (
                    <>
                      <i
                        className="mdi mdi-record-circle-outline"
                        style={{ color: "#32CD32", fontSize: "23px" }}
                        id={`online_${obj.id}`}
                        role="button"
                        onClick={() => {
                          this.setState({
                            maintenanceAlert: true,
                            submitted_data: {
                              maintenanceMode: true,
                              macAddress: [obj.mac_address],
                            },
                          });
                        }}
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={`online_${obj.id}`}
                      >
                        Online
                      </UncontrolledTooltip>
                    </>
                  ) : obj.status == "off" ? (
                    <>
                      <i
                        className="mdi mdi-record-circle-outline"
                        style={{ color: "#DB4C3F", fontSize: "23px" }}
                        id={`offline_${obj.id}`}
                        role="button"
                        onClick={() => {
                          this.setState({
                            maintenanceAlert: true,
                            submitted_data: {
                              maintenanceMode: true,
                              macAddress: [obj.mac_address],
                            },
                          });
                        }}
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={`offline_${obj.id}`}
                      >
                        Offline
                      </UncontrolledTooltip>
                    </>
                  ) : obj.status == "maintenance" ? (
                    <>
                      <i
                        className="mdi mdi-progress-wrench"
                        style={{ color: "#A67CE1", fontSize: "23px" }}
                        id={`maintenance_${obj.id}`}
                        role="button"
                        onClick={() => {
                          this.setState({
                            maintenanceAlert: true,
                            submitted_data: {
                              maintenanceMode: false,
                              macAddress: [obj.mac_address],
                            },
                          });
                        }}
                      ></i>
                      <UncontrolledTooltip
                        placement="top"
                        target={`maintenance_${obj.id}`}
                      >
                        Device in Maintenance mode
                      </UncontrolledTooltip>
                    </>
                  ) : (
                    <>
                      <i
                        className="mdi mdi-progress-wrench"
                        style={{ color: "#A67CE1", fontSize: "23px" }}
                        role="button"
                        onClick={() => {
                          this.setState({
                            maintenanceAlert: true,
                            submitted_data: {
                              maintenanceMode: true,
                              macAddress: [obj.mac_address],
                            },
                          });
                        }}
                      ></i>
                    </>
                  )
                ) : (
                  <>
                    <i
                      className="mdi mdi-record-circle-outline"
                      style={{ color: "#F4A930", fontSize: "23px" }}
                      id={`rebootDevice_${key}`}
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={`rebootDevice_${key}`}
                    >
                      Reboot Command Issued
                    </UncontrolledTooltip>
                  </>
                ),

              location: (
                <i
                  style={{ fontStyle: "normal", color: "#52469B" }}
                  role="button"
                  onClick={() => {
                    this.handleLocation(obj);
                  }}
                >
                  {obj.location}
                </i>
              ),

              publicIP: (
                <a
                  href={`https://iplocation.io/ip/${obj.public_ip}`}
                  target="_blank"
                >
                  {obj.public_ip}
                </a>
              ),

              ip_address: (
                <Link
                  to={{
                    pathname: "/activated-device",
                    aboutProps: {
                      mac_address: obj.mac_address,
                      customer_id: obj.customer_id,
                    },
                  }}
                >
                  {obj.ip_address}
                </Link>
              ),

              action: (
                <>
                  <ViewModal key={obj.id} data={obj} />

                  {Permissions().editDevicePermi ? (
                    <i
                      className="mdi mdi-square-edit-outline mr-1 iconHover"
                      style={{ color: "#20C0ED", fontSize: "23px" }}
                      role="button"
                      onClick={() => {
                        this.setState({
                          deviceId: obj.id,
                        });
                        this.toggleEditModal();
                      }}
                    ></i>
                  ) : (
                    ""
                  )}

                  {Permissions().deleteDevicePermi ? (
                    <i
                      className="mdi mdi mdi-delete mr-1 iconHover"
                      style={{
                        color: "#DB4C3F",
                        fontSize: "23px",
                      }}
                      role="button"
                      onClick={() =>
                        this.setState({ confirm_both: true, deletedData: obj })
                      }
                    ></i>
                  ) : (
                    ""
                  )}

                  {obj.mServiceCompare <= -1 ? (
                    <>
                      <i
                        style={{ color: "#F7B800", fontSize: "20px" }}
                        className="mr-1 mdi mdi-information iconHover"
                        tabIndex="0"
                        role="button"
                        id={`mService_${obj.id}`}
                        onClick={() => {
                          this.setState({
                            macAddress: [obj.mac_address],
                            updateMServiceAlert: true,
                          });
                        }}
                      ></i>

                      <UncontrolledTooltip
                        placement="top"
                        target={`mService_${obj.id}`}
                      >
                        New MService Version Available
                      </UncontrolledTooltip>
                    </>
                  ) : (
                    ""
                  )}

                  {obj.mLockCompare <= -1 ? (
                    <>
                      <i
                        style={{ color: "#ED7C07", fontSize: "20px" }}
                        className="mr-1 mdi mdi-information iconHover"
                        tabIndex="0"
                        role="button"
                        id={`mLock_${obj.id}`}
                        onClick={() => {
                          this.setState({
                            macAddress: [obj.mac_address],
                            updateMLockAlert: true,
                          });
                        }}
                      ></i>

                      <UncontrolledTooltip
                        placement="top"
                        target={`mLock_${obj.id}`}
                      >
                        New MLock Version Available
                      </UncontrolledTooltip>
                    </>
                  ) : (
                    ""
                  )}

                  {obj.memoryStat === 1 ? (
                    <>
                      <StatUssModal key={`Uss.${obj.id}`} deviceId={obj.id} />
                      <StatPssModal key={`Pss.${obj.id}`} deviceId={obj.id} />
                    </>
                  ) : (
                    ""
                  )}

                  {obj.version_update_flag == 1 ? (
                    <>
                      <i
                        style={{ color: "#891A3B", fontSize: "21px" }}
                        className="mdi mdi-flag-variant iconHover"
                        tabIndex="0"
                        id={`version_update_flag_${obj.id}`}
                      ></i>

                      <UncontrolledTooltip
                        placement="top"
                        target={`version_update_flag_${obj.id}`}
                      >
                        Android OS version has been changed. Serial number might
                        effected.
                      </UncontrolledTooltip>
                    </>
                  ) : (
                    ""
                  )}

                  {obj.reboot_time ||
                  obj.sleep_on_time ||
                  obj.sleep_off_time ? (
                    <>
                      <EventsModal
                        key={obj.id}
                        data={obj}
                        alertModel={this.handleModelAlert}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </>
              ),

              activation_key: obj.activation_key,
              serial_number: obj.serial_number,
              mac_address_ethernet: obj.mac_address_ethernet,
              version_number: obj.version_number,
              androidOS: obj.androidOS,
              model_number: obj.model_number,
              running_mimo_apps: obj.running_mimo_apps,
              mlock_version: obj.mlock_version,
              webViewVersion: obj.webViewVersion,
              ip_addres: obj.ip_address,
              firmware: obj.firmware,
              tags: obj.tags,
            }))
          : filteredData,
    };

    return (
      <>
        {event_alert ? (
          <SweetAlert
            success
            title="Success"
            onConfirm={() => {
              this.setState({ event_alert: false });
              this.handleRefresh();
            }}
          >
            Job Schedule Successfully Updated
          </SweetAlert>
        ) : null}
        {successAlert ? (
          <SweetAlert
            success
            title="Success"
            onConfirm={() => {
              this.setState({
                successAlert: false,
              });
              this.handleRefresh();
            }}
          >
            {description}
          </SweetAlert>
        ) : null}
        {success_dlg ? (
          <SweetAlert
            success
            title={title}
            onConfirm={() =>
              this.setState({
                success_dlg: false,
              })
            }
          >
            {description}
          </SweetAlert>
        ) : null}
        {success_confirm ? (
          <SweetAlert
            success
            title={title}
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              this.setState({
                success_confirm: false,
                alert_confirm: false,
              })
            }
          >
            {description}
          </SweetAlert>
        ) : null}
        {error_dlg ? (
          <SweetAlert
            error
            title={title}
            onConfirm={() => this.setState({ error_dlg: false })}
          >
            {description}
          </SweetAlert>
        ) : null}
        {success_dlg1 ? (
          <SweetAlert
            title="Good job!"
            success
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() =>
              this.setState({ success_dlg1: false, alert_confirm: false })
            }
          >
            You clicked the button!
          </SweetAlert>
        ) : null}
        {confirm_both ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={(e) => {
              this.setState(
                {
                  confirm_both: false,
                },
                () => {
                  this.handleDelete();
                }
              );
            }}
            onCancel={() =>
              this.setState({
                confirm_both: false,
              })
            }
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}
        {confirmAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              this.setState({
                confirmAlert: false,
              });
              this.deleteDevices();
            }}
            onCancel={() =>
              this.setState({
                confirmAlert: false,
              })
            }
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}
        {updateMServiceAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={this.handleUpdateMservice}
            onCancel={() =>
              this.setState({
                updateMServiceAlert: false,
              })
            }
          >
            You want to update MService!
          </SweetAlert>
        ) : null}
        {updateMLockAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={this.handleUpdateMLock}
            onCancel={() =>
              this.setState({
                updateMLockAlert: false,
              })
            }
          >
            You want to update MLock!
          </SweetAlert>
        ) : null}
        {confirmNotify ? (
          <SweetAlert
            title={title}
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              this.setState(
                {
                  confirmNotify: false,
                  notificationData: {
                    ...notificationData,
                    customerEmails: customerUsers
                      .filter((item) => item.emailChecked)
                      .map((item) => {
                        return item.email;
                      }),
                  },
                },
                () => {
                  this.handleEmailNotiAdmin();
                }
              );
            }}
            onCancel={() =>
              this.setState({
                confirmNotify: false,
              })
            }
          >
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">Select</th>
                </tr>
              </thead>
              <tbody>
                {customerUsers.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.email}</td>
                      <td>
                        <MDBInput
                          key={item.id}
                          type="checkbox"
                          value={item.id}
                          checked={
                            item.emailChecked ? item.emailChecked : false
                          }
                          onChange={() => {
                            this.checkboxUsers(item);
                          }}
                          style={{ fontSize: "2px" }}
                        />
                      </td>
                      {item.emailChecked ? item.emailChecked : false}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {description}
          </SweetAlert>
        ) : null}
        {confirmNotifyUser ? (
          <SweetAlert
            title={title}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              this.setState(
                {
                  confirmNotifyUser: false,
                },
                () => {
                  this.handleEmailNotiUser();
                }
              );
            }}
            onCancel={() =>
              this.setState({
                confirmNotifyUser: false,
              })
            }
          >
            {description}
          </SweetAlert>
        ) : null}
        {maintenanceAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              this.setState({
                maintenanceAlert: false,
              });
              this.handleMaintenanceMode();
            }}
            onCancel={() =>
              this.setState({
                maintenanceAlert: false,
              })
            }
          >
            {submitted_data.maintenanceMode
              ? "Do you want to enable maintenance mode"
              : "Do you want to disable maintenance mode"}
          </SweetAlert>
        ) : null}
        {multiMaintenanceAlert ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              this.setState({
                multiMaintenanceAlert: false,
              });
              this.handleMultiMaintenance();
            }}
            onCancel={() =>
              this.setState({
                multiMaintenanceAlert: false,
              })
            }
          >
            {maintenanceRadioButton
              ? "Do you want to enable maintenance mode"
              : "Do you want to disable maintenance mode"}

            <div
              className="row mt-2"
              style={{ marginLeft: "72px", fontSize: "15px" }}
            >
              <div className="form-check">
                <input
                  className="mr-3"
                  type="radio"
                  name="maintenanceEnable"
                  checked={maintenanceRadioButton ? true : false}
                  onChange={this.handleRadioButton}
                />
                <Label>Enable</Label>
              </div>

              <div className="form-check">
                <input
                  className="mr-3"
                  type="radio"
                  name="maintenanceDisable"
                  checked={!maintenanceRadioButton ? true : false}
                  onChange={this.handleRadioButton}
                />
                <Label>Disable</Label>
              </div>
            </div>
          </SweetAlert>
        ) : null}
        {setDirectCommand ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              this.setState({
                setDirectCommand: false,
              });
              this.handleDirectCommands();
            }}
            onCancel={() =>
              this.setState({
                setDirectCommand: false,
              })
            }
          >
            Do you want to reboot devices
          </SweetAlert>
        ) : null}

        <Col xs="12">
          <Card>
            <CardBody>
              <FormGroup>
                <Col xs="12" className="row">
                  <Col xs="10">
                    <Row>
                      {getCurrentUser().role_id === 3 ? (
                        <Col xs="3">
                          <AvForm>
                            <SelectReseller
                              className=""
                              fieldError={null}
                              resellerId={this.state.resellerId}
                              handleReseller={this.handleReseller}
                            />
                          </AvForm>
                        </Col>
                      ) : (
                        ""
                      )}

                      {getCurrentUser().role_id !== 2 ? (
                        <Col xs="3">
                          <AvForm>
                            <SelectCustomer
                              className=""
                              fieldError={null}
                              resellerId={this.state.resellerId}
                              customerId={customerId}
                              handleCustomer={this.handleCustomer}
                            />
                          </AvForm>
                        </Col>
                      ) : (
                        ""
                      )}

                      <Col xs="2">
                        <Label>Filter</Label>
                        <br></br>
                        <div style={{ display: "flex" }}>
                          <select
                            name="status"
                            className="custom-select"
                            value={status}
                            onChange={(e) => {
                              this.handleStatus(e);
                            }}
                          >
                            <option defaultValue value="all">
                              All
                            </option>
                            <option style={{ color: "#32CD32" }} value="on">
                              Online
                            </option>
                            <option style={{ color: "#DB4C3F" }} value="off">
                              Offline
                            </option>
                            <option
                              style={{ color: "#A67CE1" }}
                              value="maintenance"
                            >
                              Maintenance
                            </option>
                            <option value="unnamed">Unnamed</option>
                          </select>
                        </div>
                      </Col>

                      <Col xs="4">
                        <Label></Label>
                        <br></br>
                        <div style={{ display: "flex" }}>
                          {/* Refresh Device */}
                          <i
                            className="mdi mdi-reload ml-1 iconHover"
                            style={{
                              color: "#30B8A1",
                              fontSize: "35px",
                              marginTop: "-3px",
                            }}
                            id="refresh"
                            role="button"
                            onClick={this.handleRefresh}
                          ></i>
                          <UncontrolledTooltip placement="top" target="refresh">
                            Refresh Devices
                          </UncontrolledTooltip>

                          {/* Device Updates */}
                          <i
                            className="mdi mdi-update mr-1 iconHover"
                            style={{
                              color: "#2675F2",
                              fontSize: "35px",
                              marginTop: "-3px",
                            }}
                            id="deviceUpdate"
                            role="button"
                            onClick={() => {
                              this.props.history.replace(`/devices-updates`);
                            }}
                          ></i>
                          <UncontrolledTooltip
                            placement="top"
                            target="deviceUpdate"
                          >
                            Devices Updates
                          </UncontrolledTooltip>

                          {/* Email Notification */}
                          {getCurrentUser().role_id === 1 ||
                          getCurrentUser().role_id === 3 ? (
                            customerId != "all" ? (
                              <>
                                <i
                                  className="mdi mdi-email-send-outline mr-1 iconHover"
                                  style={{
                                    color: customerUsers.some(
                                      (item) => item.emailChecked
                                    )
                                      ? "#37CC00"
                                      : "#046DB9",
                                    fontSize: "35px",
                                    marginTop: "-3px",
                                  }}
                                  id="emailNoti"
                                  role="button"
                                  onClick={() =>
                                    this.setState({
                                      confirmNotify: true,
                                      notificationData: {
                                        customerId: customerId,
                                      },
                                      title:
                                        "Enable/Disable email notifications",
                                      description:
                                        "Enabling this feature will automatically send an email to customer's email if any device remains offline for more than 10 minutes",
                                    })
                                  }
                                ></i>

                                <UncontrolledTooltip
                                  placement="top"
                                  target="emailNoti"
                                >
                                  Email Notification
                                </UncontrolledTooltip>
                              </>
                            ) : (
                              ""
                            )
                          ) : (
                            <>
                              <i
                                className="mdi mdi-email-send-outline mr-1 iconHover"
                                style={{
                                  color: customerUsers.enable_notify
                                    ? "#37CC00"
                                    : "#046DB9",
                                  fontSize: "35px",
                                  marginTop: "-3px",
                                }}
                                role="button"
                                id="emailNoti"
                                onClick={() =>
                                  this.setState({
                                    confirmNotifyUser: true,
                                    notificationData: {
                                      customerId: getCurrentUser().customer_id,
                                      customerEmails: [getCurrentUser().email],
                                      enableStatus: customerUsers.enable_notify
                                        ? false
                                        : true,
                                    },

                                    title: customerUsers.enable_notify
                                      ? "Disable email notifications?"
                                      : "Enable email notifications?",
                                    description:
                                      "Enabling this feature will automatically send an email to customer's email if any device remains offline for more than 10 minutes",
                                  })
                                }
                              ></i>

                              <UncontrolledTooltip
                                placement="top"
                                target="emailNoti"
                              >
                                Email Notification
                              </UncontrolledTooltip>
                            </>
                          )}

                          {/* Select Delete */}
                          {filteredData.some(
                            (item) => item.isChecked === true
                          ) ? (
                            <>
                              <i
                                className="mdi mdi-delete iconHover"
                                style={{
                                  color: "#E8314F",
                                  fontSize: "35px",
                                  marginTop: "-3px",
                                }}
                                id="deleteSelectedDevice"
                                role="button"
                                onClick={() =>
                                  this.setState({
                                    confirmAlert: true,
                                  })
                                }
                              ></i>
                              <UncontrolledTooltip
                                placement="top"
                                target="deleteSelectedDevice"
                              >
                                Delete Selected Devices
                              </UncontrolledTooltip>

                              <GroupModal
                                data={filteredData}
                                profiles={profileData}
                                alertModel={this.handleModelAlert}
                              />

                              <i
                                className="mdi mdi-progress-wrench mr-1 iconHover"
                                style={{
                                  color: "#A67CE1",
                                  fontSize: "32px",
                                  marginTop: "-1px",
                                }}
                                id="maintenanceModeIcon"
                                role="button"
                                onClick={() => {
                                  this.setState({
                                    multiMaintenanceAlert: true,
                                  });
                                }}
                              ></i>
                              <UncontrolledTooltip
                                placement="top"
                                target="maintenanceModeIcon"
                              >
                                Maintenance Mode
                              </UncontrolledTooltip>

                              <i
                                className="mdi mdi-apple-keyboard-command mr-1 iconHover"
                                style={{
                                  color: "#F7A000",
                                  fontSize: "32px",
                                  marginTop: "-1px",
                                }}
                                id="applyCommand"
                                role="button"
                                onClick={() => {
                                  this.setState({
                                    setDirectCommand: true,
                                  });
                                }}
                              ></i>
                              <UncontrolledTooltip
                                placement="top"
                                target="applyCommand"
                              >
                                Apply Direct Commands
                              </UncontrolledTooltip>
                                 <i
                                className="mdi mdi-download mr-1 iconHover"
                                style={{
                                  color: "#2675F2",
                                  fontSize: "35px",
                                  marginTop: "-3px",
                                }}
                                id="exportCsv"
                                role="button"
                                onClick={() => {
                                  this.handleDownloadCSV()
                                }}
                              > </i>
                              <CSVLink data={getCSVData(filteredData)} filename={"devices-data.csv"}
                                ref={(csvRef) => (this.csvLink = csvRef)}

                              />
                              <UncontrolledTooltip placement="top" target="exportCsv">
                                Export CSV
                              </UncontrolledTooltip>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col xs="2 col-2 text-right mt-3">
                    {getCurrentUser().role_id === 1 ||
                    getCurrentUser().role_id === 3 ? (
                      <Button
                        onClick={() => {
                          this.props.history.replace("/device-create");
                        }}
                      >
                        Create Device
                      </Button>
                    ) : (
                      ""
                    )}
                  </Col>
                </Col>
              </FormGroup>

              <div className="table-rep-plugin">
                <div className="table-responsive mb-0">
                  <MDBDataTable
                    responsive
                    bordered
                    striped
                    entries={100}
                    data={data}
                    ref={this.ref}
                    className="d1"
                    id="selectAll"
                    onPageChange={({ activePage }) => {
                      this.changePage(activePage);
                    }}
                  />
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        {this.state.editModalShow ? (
          <EditDeviceModal
            toggleEdit={this.toggleEditModal}
            isOpenEdit={this.state.editModalShow}
            deviceId={this.state.deviceId}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default connect(null, { setBreadcrumbItems })(Devices);
