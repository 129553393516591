import React, { useState, useEffect } from "react";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { Col, Card, CardBody, Row, Button } from "reactstrap";
import ModalLayout from "../../component/Common/ModalLayout";
import { getCurrentUser } from "../../services/authService";
import { deleteUser, getUserData } from "./redux/userApi";
import { setBreadcrumbItems } from "../../store/actions";
import { Permissions } from "../../services/permissions";
import { AvForm } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import EditUserModal from "./EditUserModal";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import CreateUser from "./CreateUser";
import "./datatables.scss";

const User = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "User", link: "/user" },
  ]);
  const [userData, setUserData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [userId, setUserId] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState(null);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  const [resellerId, setResellerId] = useState("all");
  const [customerId, setCustomerId] = useState("all");
  const [modalShowCreate, setModalShowCreate] = useState(false);

  useEffect(() => {
    if ((editModalShow, modalShowCreate)) return;
    (async () => {
      props.setBreadcrumbItems("User", breadcrumbItems, true);

      try {
        const {
          data: { data: userData },
        } = await getUserData("all");

        setUserData(userData);
        setFilteredData(
          userData
            .filter((item) =>
              resellerId === "all" ? item : item.reseller_id == resellerId
            )
            .filter((item) =>
              customerId === "all" ? item : item.customer_id == customerId
            )
        );
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("User", breadcrumbItems, false);
    })();
  }, [editModalShow, modalShowCreate]);

  const handleReseller = (id) => {
    setResellerId(id);
    setCustomerId("all");
    setFilteredData(
      userData.filter((item) => (id === "all" ? item : item.reseller_id == id))
    );
  };

  const handleCustomer = (id) => {
    setCustomerId(id === "all" ? "all" : parseInt(id));
    setFilteredData(
      userData
        .filter((item) => (id === "all" ? item : item.customer_id == id))
        .filter((item) =>
          resellerId === "all" ? item : item.reseller_id == resellerId
        )
    );
  };

  const handleDelete = async () => {
    props.setBreadcrumbItems("User", breadcrumbItems, true);

    const { data: response } = await deleteUser(userId);
    const currentData = userData.filter((item) => item.id !== userId);

    try {
      setSuccessAlert(true);
      setDescription(response.message);
      setFilteredData(currentData);
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("User", breadcrumbItems, false);
  };

  const toggleEditModal = () => {
    setEditModalShow(!editModalShow);
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  const userTable = {
    columns: [
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 270,
      },
      {
        label: "Phone",
        field: "phone",
        sort: "asc",
        width: 200,
      },
      {
        label: "Two Factor Auth Status",
        field: "TowFaStatus",
        sort: "asc",
        width: 200,
      },
      {
        label: "User Role",
        field: "roleName",
        sort: "asc",
        width: 200,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 200,
      },
    ],
    rows: filteredData.map((obj) => ({
      ...obj,

      TowFaStatus:
        obj.two_factor_status === "enable" ? (
          <h5>
            <span className="badge badge-success">Enabled</span>
          </h5>
        ) : (
          <h5>
            <span className="badge badge-danger">Disabled</span>
          </h5>
        ),

      actions: (
        <>
          {Permissions().editUserPermi ? (
            <i
              className="mdi mdi-square-edit-outline mr-1 iconHover"
              style={{
                color: "#20C0ED",
                fontSize: "23px",
              }}
              role="button"
              onClick={() => {
                setUserId(obj.id);
                toggleEditModal();
              }}
            ></i>
          ) : (
            ""
          )}
          {Permissions().deleteUserPermi ? (
            <i
              className="mdi mdi mdi-delete mr-1 iconHover"
              style={{
                color: "#DB4C3F",
                fontSize: "23px",
              }}
              role="button"
              onClick={() => {
                setConfirmAlert(true);
                setUserId(obj.id);
              }}
            ></i>
          ) : (
            ""
          )}
        </>
      ),
    })),
  };

  return (
    <>
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert error title="Failed" onConfirm={() => setErrorAlert(false)}>
          {description}
        </SweetAlert>
      ) : null}

      <Col xs="12">
        <Card>
          <CardBody>
            <AvForm>
              <Col xs="12">
                <Row className="align-items-center mb-3">
                  {getCurrentUser().role_id === 3 ? (
                    <Col xs="3">
                      <SelectReseller
                        className=""
                        fieldError={null}
                        resellerId={resellerId}
                        handleReseller={handleReseller}
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col xs="3">
                    <SelectCustomer
                      className=""
                      fieldError={null}
                      resellerId={resellerId}
                      customerId={customerId}
                      handleCustomer={handleCustomer}
                    />
                  </Col>

                  {Permissions().createUserPermi ? (
                    <Col xs="2">
                      <Button
                        style={{ marginTop: "10px" }}
                        onClick={toggleModalCreate}
                      >
                        Create User
                      </Button>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              </Col>
            </AvForm>

            <MDBDataTable
              responsive
              bordered
              striped
              entries={100}
              data={userTable}
            />
          </CardBody>
        </Card>
      </Col>

      {editModalShow ? (
        <EditUserModal
          toggleEdit={toggleEditModal}
          isOpenEdit={editModalShow}
          userId={userId}
        />
      ) : (
        ""
      )}

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Create User"
          size="lg"
        >
          <CreateUser
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(User);
