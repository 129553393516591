import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function checkTwoFA() {
  return axios.get(`/api/auth/checkTwoFA`, getHeader());
}

export function enableTwoFA(customerID) {
  return axios.get(`/api/auth/enableTwoFA/${customerID}`, getHeader());
}

export function validateTwoFA(code) {
  return axios.post(
    `/api/auth/validateTwoFA`,
    {
      code: code,
    },
    getHeader()
  );
}

export function resendTwoFactor() {
  return axios.get(`/api/auth/resendTwoFA`, getHeader());
}
