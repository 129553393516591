import React, { useState, useEffect } from "react";
import { editActKey, updateActKey } from "./redux/activationKeyApi";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Label, Button, Input } from "reactstrap";
import { mimoLoader } from "../../store/actions";
import { useDispatch } from "react-redux";

const ActivationEdit = ({
  activationKeyId,
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [activationKeyData, setActivationKeyData] = useState({
    name: "",
    app_name: "",
    activation_key: "",
    device_quantity: "",
    comments: "",
  });
  const [newData, setNewData] = useState({
    valid_date: "",
  });
  const [appName, setAppName] = useState([
    { app_name: "MService" },
    { app_name: "Mtemp" },
    { app_name: "Mlock" },
  ]);
  const [keyType, setKeyType] = useState([
    { value: "Trial" },
    { value: "Annual" },
    { value: "Perpetual" },
  ]);
  const [newArr, setNewArr] = useState([]);
  const [keyArr, setKeyArr] = useState([]);

  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const { data: activationKetData } = await editActKey(activationKeyId);

        const responseData = activationKetData[0];
        setActivationKeyData(responseData);
        setNewData(responseData);

        appName.map((item) => {
          if (item.app_name !== responseData.app_name) {
            setNewArr((prevState) => [
              ...prevState,
              { app_name: item.app_name },
            ]);
          }
        });

        keyType.map((item) => {
          if (item.value !== responseData.key_type) {
            setKeyArr((prevState) => [...prevState, { value: item.value }]);
          }
        });
      } catch (ex) {
        console.error(ex);
        setErrorAlert(true);
        setDescription(ex.response.data.message);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "key_type" && value === "Trial") {
      setNewData((prevState) => ({
        ...prevState,
        [name]: value,
        valid_date: activationKeyData.valid_date,
      }));
    } else if (name === "key_type" && value === "Annual") {
      setNewData((prevState) => ({
        ...prevState,
        [name]: value,
        valid_date: activationKeyData.valid_date,
      }));
    } else if (name === "key_type" && value === "Perpetual") {
      setNewData((prevState) => ({
        ...prevState,
        [name]: value,
        valid_date: "",
      }));
    } else {
      setNewData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    dispatch(mimoLoader(true));
    try {
      await updateActKey(activationKeyId, newData);

      toggleModal();
      setSuccessAlert(true);
      setDescription("Activation key has been successfully updated");
    } catch (ex) {
      console.error(ex);
      setErrorAlert(true);
      setDescription(ex.response.data.message);
    }
    dispatch(mimoLoader(false));
  };

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        <AvField
          name="name"
          label="Customer Name"
          placeholder="Type Something"
          type="text"
          value={activationKeyData.name}
          onChange={handleChange}
          errorMessage="Enter Name"
          validate={{ required: { value: true } }}
          disabled
        />

        <FormGroup>
          <Label>Select Application Name</Label>
          <select
            required
            onChange={handleChange}
            name="app_name"
            className="custom-select"
          >
            <option defaultValue value={activationKeyData.app_name}>
              {activationKeyData.app_name}
            </option>
            {newArr.map((item, index) => {
              return (
                <option key={index} value={item.app_name}>
                  {item.app_name}
                </option>
              );
            })}
          </select>
        </FormGroup>

        <FormGroup>
          <Label>Select Key Type</Label>
          <select
            required
            onChange={handleChange}
            name="key_type"
            className="custom-select"
          >
            <option defaultValue value={activationKeyData.key_type}>
              {activationKeyData.key_type}
            </option>
            {keyArr.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.value}
                </option>
              );
            })}
          </select>
        </FormGroup>

        {newData.key_type !== "Perpetual" ? (
          <FormGroup>
            <Label>Expiration Date</Label>
            <Input
              className="form-control"
              onChange={handleChange}
              name="valid_date"
              defaultValue={newData.valid_date || ""}
              type="date"
            />
          </FormGroup>
        ) : (
          ""
        )}

        <AvField
          name="activation_key"
          label="Activation Key"
          placeholder="Type Something"
          type="text"
          value={activationKeyData.activation_key}
          disabled
        />

        <AvField
          name="device_quantity"
          label="Device Quantity"
          placeholder="Enter Device Quantity"
          type="number"
          value={activationKeyData.device_quantity}
          onChange={handleChange}
          errorMessage="Enter Device Quantity"
          validate={{ required: { value: true } }}
        />

        <FormGroup>
          <Label>Comments</Label>
          <textarea
            className="form-control"
            placeholder="Type Comments"
            rows="3"
            name="comments"
            type="text"
            defaultValue={activationKeyData.comments || ""}
            onChange={handleChange}
          ></textarea>
        </FormGroup>

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default ActivationEdit;
