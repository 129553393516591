import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getUploadFile(id) {
  return axios.get(`/api/auth/uploadDocShow/${id}`, getHeader());
}

export function deleteApk(id) {
  return axios.get(`/api/auth/removeFile/${id}`, getHeader());
}

export function deleteSelectedApk(data) {
  return axios.post(`/api/auth/selectDeleteAPK/`, data, getHeader());
}
