import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";

const ModalLayout = ({ toggleModal, isOpen, title, size, children }) => {
  const mimoLoaderState = useSelector((state) => state.MimoLoader.mimoLoader);

  return (
    <>
      <Modal
        style={{ maxWidth: size }}
        isOpen={isOpen}
        toggle={toggleModal}
        autoFocus={true}
        size={size}
      >
        <ModalHeader toggle={toggleModal}>
          <div className="d-flex align-items-center">
            {title}
            {mimoLoaderState ? (
              <div className="mimoLoaderModal ml-2"></div>
            ) : (
              ""
            )}
          </div>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </Modal>
    </>
  );
};

export default ModalLayout;
