import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { setBreadcrumbItems } from "../../../store/actions";
import { UncontrolledTooltip } from "reactstrap";
import mlock from "../../../assets/help/mlock/MLock User Guide v3.1.7.pdf";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";

class HelpButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItems: [
        { title: "Mimo", link: "/" },
        { title: "Help", link: "#" },
      ],
      helpData: {},
      // category: [],
    };
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  componentDidMount() {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");
    this.props.setBreadcrumbItems("Help", this.state.breadcrumbItems, true);

    axios
      .get(`${REACT_APP_URL}/api/auth/help`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        this.setState(
          {
            helpData: res.data,
          },
          () => {
            console.log(this.state.helpData);
            this.props.setBreadcrumbItems(
              "Help",
              this.state.breadcrumbItems,
              false
            );
          }
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          {this.state.helpData && this.state.helpData.category
            ? this.state.helpData.category.map((obj, index) => {
                return (
                  <Col key={index} xl="4" md="6">
                    <Card>
                      <CardBody>
                        <h4 className="card-title">{obj.category}</h4>
                        <ol className="activity-feed">
                          {this.state.helpData.catGuide
                            .filter((item) => item.category === obj.category)
                            .map((item, ind) => {
                              return (
                                <li key={ind} className="feed-item pb-2">
                                  <span className="date">
                                    <a
                                      target="_blank"
                                      href={
                                        process.env.REACT_APP_URL +
                                        "/" +
                                        item.location
                                      }
                                    >
                                      {item.file_name}
                                    </a>
                                  </span>
                                </li>
                              );
                            })}
                        </ol>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })
            : ""}
        </Row>
      </React.Fragment>
    );
  }
}

export default connect(null, { setBreadcrumbItems })(HelpButton);
