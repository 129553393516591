import React, { useState } from "react";
import { getLocation, storeTags } from "./redux/deviceApi";
import {
  Col,
  Row,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import "./tags.scss";

const ViewModal = (props) => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(props.data);
  const [location, setLocation] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [tags, setTags] = useState(
    props.data.tags ? JSON.parse(props.data.tags) : []
  );
  const [errorBox, setErrorBox] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setModalData(props.data);
  };

  const handleLocation = async () => {
    try {
      const { data: locationData } = await getLocation(modalData.id);

      setLocation(locationData.location);
      setLatitude(locationData.latitude);
      setLongitude(locationData.longitude);
    } catch (ex) {
      console.error(ex);
    }
  };

  const addTags = (e) => {
    const { value } = e.target;
    if (value !== "") {
      if (tags.some((item) => item.toUpperCase() === value.toUpperCase())) {
        setErrorBox(true);
      } else {
        (async () => {
          setErrorBox(false);
          setTags([...tags, value]);

          const updatedTags = tags.filter(
            (tag) => tag !== "Invalid Serial Number"
          );
          const tagsData = {
            id: modalData.id,
            tags: [...updatedTags, value],
          };

          try {
            await storeTags(tagsData);
          } catch (ex) {
            console.error(ex);
          }
        })();
      }
      e.target.value = "";
    }
  };

  const removeTags = async (val) => {
    setTags(tags.filter((_, index) => index !== val));
    const tagsData = {
      id: modalData.id,
      tags: tags
        .filter((tag) => tag !== "Invalid Serial Number")
        .filter((_, index) => index !== val),
    };

    try {
      await storeTags(tagsData);
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <>
      {/* BUTTON */}
      <i
        className="mr-1 mdi mdi-file-eye-outline iconHover"
        style={{ color: "#408EBA", fontSize: "23px" }}
        role="button"
        onClick={(e) => {
          toggle();
          handleLocation();
        }}
      ></i>

      <Modal
        isOpen={modal}
        toggle={toggle}
        autoFocus={true}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={toggle}>Device Details</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-bordered mb-0">
                      <tbody>
                        <tr>
                          <th>ID</th>
                          <td>{modalData.id}</td>
                        </tr>
                        <tr>
                          <th>Company</th>
                          <td>{modalData.customer}</td>
                        </tr>
                        <tr>
                          <th>Activation Key</th>
                          <td>{modalData.activation_key}</td>
                        </tr>
                        <tr>
                          <th>Serial Number</th>
                          <td>{modalData.serial_number}</td>
                        </tr>
                        <tr>
                          <th>Wifi Mac Address</th>
                          <td>{modalData.mac_address}</td>
                        </tr>
                        <tr>
                          <th>Ethernet Mac Address</th>
                          <td>{modalData.mac_address_ethernet}</td>
                        </tr>
                        <tr>
                          <th>IP Address</th>
                          <td>{modalData.ip_address}</td>
                        </tr>
                        <tr>
                          <th>MService Version</th>
                          <td>
                            {modalData.version_number
                              ? modalData.version_number
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>MLock Version</th>
                          <td>
                            {modalData.mlock_version
                              ? modalData.mlock_version
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>WebView Version</th>
                          <td>
                            {modalData.webViewVersion
                              ? modalData.webViewVersion
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Running Mimo Apps</th>
                          <td>
                            {modalData.running_mimo_apps
                              ? modalData.running_mimo_apps
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Android OS</th>
                          <td>{modalData.androidOS}</td>
                        </tr>
                        <tr>
                          <th>Firmware</th>
                          <td>{modalData.firmware}</td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>
                            {modalData.status === "on" ? "Online" : "Offline"}
                          </td>
                        </tr>
                        <tr>
                          <th>Model Number</th>
                          <td>
                            {modalData.model_number
                              ? modalData.model_number
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Connection Type</th>
                          <td>
                            {modalData.connectionType
                              ? modalData.connectionType
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Wifi Signal Strength</th>
                          <td>
                            {modalData.wifiSignalStrength
                              ? modalData.wifiSignalStrength
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Device Name</th>
                          <td>
                            {modalData.device_name
                              ? modalData.device_name
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Expire</th>
                          <td>{modalData.expire ? modalData.expire : "-"}</td>
                        </tr>
                        <tr>
                          <th>Location</th>
                          <td>{location ? location : "-"}</td>
                        </tr>
                        <tr>
                          <th>Latitude</th>
                          <td>{latitude ? latitude : "-"}</td>
                        </tr>
                        <tr>
                          <th>Longitude</th>
                          <td>{longitude ? longitude : "-"}</td>
                        </tr>
                        <tr>
                          <th>Created At</th>
                          <td>{new Date(modalData.created_at).toString()}</td>
                        </tr>
                        <tr>
                          <th>Last Online</th>
                          <td>
                            {new Date(modalData.updated_at + " UTC").toString()}
                            <br></br>
                          </td>
                        </tr>
                        <tr>
                          <th>Public IP</th>
                          <td>
                            <a
                              href={`https://iplocation.io/ip/${modalData.public_ip}`}
                              target="_blank"
                            >
                              {modalData.public_ip}
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <th>Groups</th>
                          <td>{modalData.groupNames}</td>
                        </tr>
                        <tr>
                          <th>Tags</th>
                          <td>
                            <div
                              style={{
                                borderColor: errorBox === true ? "#ec536c" : "",
                              }}
                              className="tags-input"
                            >
                              <ul id="tags">
                                {tags.map((tag, index) => (
                                  <li key={index} className="tag">
                                    <span className="tag-title">{tag}</span>
                                    <span
                                      className="tag-close-icon"
                                      onClick={() => removeTags(index)}
                                    >
                                      x
                                    </span>
                                  </li>
                                ))}
                              </ul>
                              <input
                                type="text"
                                onKeyUp={(event) =>
                                  event.key === "Enter" ? addTags(event) : null
                                }
                                placeholder="Press enter to add tags"
                              />
                            </div>
                            {errorBox === true ? (
                              <div className="invalid-error">
                                Tag already assigned
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ViewModal;
