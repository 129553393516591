import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getHelpGuide() {
  return axios.get(`/api/auth/helpGuide`, getHeader());
}

export function deleteHelp(id) {
  return axios.get(`/api/auth/deleteHelp/${id}`, getHeader());
}

export function getHelpCategory() {
  return axios.get(`/api/auth/helpCategory`, getHeader());
}

export function editHelp(id) {
  return axios.get(`/api/auth/editHelp/${id}`, getHeader());
}
