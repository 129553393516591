import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
} from "reactstrap";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const ViewModal = (props) => {
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(props.data);
  const [deviceData, setDeviceData] = useState([]);

  const toggle = () => {
    setModal(!modal);
    setModalData(props.data);
  };

  const showGroupDetail = () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    axios
      .get(`${REACT_APP_URL}/api/auth/showGroupDetail/${modalData.id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setDeviceData(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {/* BUTTON */}
      <i
        className="mr-1 mdi mdi-file-eye-outline iconHover"
        style={{ color: "#408EBA", fontSize: "23px" }}
        role="button"
        onClick={() => {
          toggle();
          showGroupDetail();
        }}
      ></i>

      <Modal
        isOpen={modal}
        toggle={toggle}
        autoFocus={true}
        centered={true}
        size="lg"
      >
        <ModalHeader toggle={toggle}>
          {modalData.group_name} - Devices
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Device Name</th>
                          <th>Serial Number</th>
                          <th>Mac Address</th>
                          <th>IP Address</th>
                        </tr>
                      </thead>
                      <tbody>
                        {deviceData.map((obj, index) => (
                          <tr key={index}>
                            <td>{obj["Device Name"]}</td>
                            <td>{obj["Serial Number"]}</td>
                            <td>{obj["Mac Address"]}</td>
                            <td>{obj["Ip Address"]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ViewModal;
