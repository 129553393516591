import React, { useState, useRef } from "react";
import {
  FormGroup,
  Label,
  UncontrolledTooltip,
  Button,
  Progress,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import "./tags.scss";

const CreateFirmVersion = ({
  hardwareId,
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
  setUploaded,
}) => {
  const [firmwareData, setFirmwareData] = useState({
    app_name: "",
    target_version: "",
    comments: "",
    android_os: "",
    build_number: "",
    file: null,
    release: null,
  });
  const [loading, setLoading] = useState(null);
  const [loadingBar, setLoadingBar] = useState(null);
  const [version, setVersion] = useState([]);
  const [errorBox, setErrorBox] = useState(false);
  const [source, setSource] = useState(axios.CancelToken.source());
  const fileRef = useRef();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "file" || name === "release") {
      if (name === "file" && files[0].type !== "application/x-zip-compressed") {
        setErrorAlert(true);
        setDescription("File format is incorrect (Please upload a Zip File)");
        setFirmwareData((prevState) => ({
          ...prevState,
          file: null,
        }));
        fileRef.current.value = "";
      } else {
        setFirmwareData((prevState) => ({
          ...prevState,
          [name]: files[0],
        }));
      }
    } else {
      setFirmwareData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    if (!version.length) {
      setErrorAlert(true);
      setDescription("Please enter version number (by pressing enter button)");
      return false;
    } else if (!firmwareData.file) {
      setLoadingBar(false);
      setErrorAlert(true);
      setDescription("No file is uploaded");
      return false;
    } else {
      const { REACT_APP_URL } = process.env;
      let data = secureLocalStorage.getItem("auth");
      let token = data.replace(/['"]+/g, "");

      const formData = new FormData();
      formData.append("file", firmwareData.file);
      formData.append("version", version);
      formData.append("target_version", firmwareData.target_version);
      formData.append("android_os", firmwareData.android_os);
      formData.append("build_number", firmwareData.build_number);
      formData.append("hardware_id", hardwareId);
      formData.append("comments", firmwareData.comments);
      formData.append("release", firmwareData.release);

      axios
        .post(`${REACT_APP_URL}/api/auth/uploadFirmware`, formData, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type":
              "multipart/form-data; charset=utf-8; boundary=" +
              Math.random().toString().substr(2),
          },
          onUploadProgress: (e) => {
            const { loaded, total } = e;
            let percent = Math.floor((loaded * 100) / total);
            setLoadingBar(true);
            setLoading(percent);
          },
          cancelToken: source.token,
        })
        .then((res) => {
          if (res.data.error) {
            setErrorAlert(true);
            setDescription(res.data.error);
            setLoadingBar(false);
          } else {
            setUploaded(true);
            toggleModal();
            setSuccessAlert(true);
            setDescription("Firmware has been successfully uploaded");
          }
        })
        .catch((er) => {
          console.error(er);
        });
    }
  };

  const cancelRequest = () => {
    source.cancel("Request has been canceled");
    setSource(axios.CancelToken.source());
    setFirmwareData((prevState) => ({
      ...prevState,
      file: null,
    }));
    setLoading(null);
    setLoadingBar(false);
    fileRef.current.value = "";
  };

  // Multiple Inputs
  const addVersion = (e) => {
    if (e.target.value !== "") {
      if (
        version.some(
          (item) => item.toUpperCase() === e.target.value.toUpperCase()
        )
      ) {
        setErrorBox(true);
      } else {
        setErrorBox(false);
        setVersion([...version, e.target.value]);
      }
      e.target.value = "";
    }
  };
  const removeVersion = (ver) => {
    setVersion(version.filter((_, index) => index !== ver));
  };

  return (
    <>
      <AvForm
        onValidSubmit={handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <Label>Version</Label>
        <br></br>
        <div
          style={{
            borderColor: errorBox === true ? "#ec536c" : "",
          }}
          className="tags-input"
        >
          <ul id="tags">
            {version.map((tag, index) => (
              <li key={index} className="tag">
                <span className="tag-title">{tag}</span>
                <span
                  className="tag-close-icon"
                  onClick={() => removeVersion(index)}
                >
                  x
                </span>
              </li>
            ))}
          </ul>
          <input
            type="text"
            onKeyUp={(e) => (e.key === "Enter" ? addVersion(e) : null)}
            placeholder="Press enter to add Versions"
          />
        </div>
        {errorBox === true ? (
          <div className="invalid-error">Version already entered</div>
        ) : (
          ""
        )}
        <br></br>

        <AvField
          name="target_version"
          id="target_version"
          label="Target Version"
          placeholder="Type text"
          type="text"
          value={firmwareData.target_version || ""}
          onChange={handleChange}
        />
        <UncontrolledTooltip placement="top" target="target_version">
          i.e. 1.1, 2.3, 5.1 etc
        </UncontrolledTooltip>

        <AvField
          name="android_os"
          id="android_os"
          label="Android OS"
          placeholder="Type text"
          type="text"
          value={firmwareData.android_os || ""}
          onChange={handleChange}
        />
        <UncontrolledTooltip placement="top" target="android_os">
          i.e. 5.1, 6.0, 8.1, etc
        </UncontrolledTooltip>

        <AvField
          name="build_number"
          id="build_number"
          label="Build Number"
          placeholder="Type text"
          type="text"
          value={firmwareData.build_number || ""}
          onChange={handleChange}
        />
        <UncontrolledTooltip placement="top" target="build_number">
          MCT-10HPQ.AP6255_US.MIMO.KD101N9_40NA_A45.V1.1_1.11.12g.20210728
        </UncontrolledTooltip>

        <FormGroup>
          <Label>Upload Firmware</Label>
          <br></br>
          <input
            type="file"
            name="file"
            onChange={handleChange}
            className="filestyle"
            data-buttonname="btn-secondary"
            ref={fileRef}
          />
        </FormGroup>

        {loadingBar ? (
          <div className="mb-4">
            <button
              type="button"
              className="close"
              style={{ marginTop: "-4px" }}
              onClick={cancelRequest}
            >
              <span aria-hidden="true" style={{ color: "#E0121E" }}>
                ×
              </span>
            </button>

            <Progress color="primary" value={loading}>
              {loading}
            </Progress>
          </div>
        ) : (
          ""
        )}

        <FormGroup>
          <Label>Comments</Label>
          <textarea
            className="form-control"
            rows="3"
            name="comments"
            placeholder="Type Comments"
            type="text"
            value={firmwareData.comments || ""}
            onChange={handleChange}
          ></textarea>
        </FormGroup>

        <FormGroup>
          <Label>Release Notes</Label>
          <br></br>
          <input
            type="file"
            name="release"
            onChange={handleChange}
            className="filestyle"
            data-buttonname="btn-secondary"
          />
        </FormGroup>

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default CreateFirmVersion;
