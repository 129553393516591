import { MIMO_LOADER } from "./actionTypes";

const initialState = {
  mimoLoader: false,
};

const MimoLoader = (state = initialState, action) => {
  switch (action.type) {
    case MIMO_LOADER:
      return {
        ...state,
        mimoLoader: action.payload.mimoLoader,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default MimoLoader;
