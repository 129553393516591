import React, { useState } from "react";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Label, Button, Input } from "reactstrap";
import { getCurrentUser } from "../../services/authService";
import { storeCustomer } from "./redux/customerApi";
import { mimoLoader } from "../../store/actions";
import { useDispatch } from "react-redux";

const CompanyCreate = ({
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [customerData, setCustomerData] = useState({
    resellerId:
      getCurrentUser().role_id !== 3 ? getCurrentUser().reseller_id : "all",
    name: "",
    email: "",
    phone: "",
    address: "",
    twoFAStatus: "disable",
    customFirmware: "No",
  });
  const [fieldError, setFieldError] = useState(null);

  const handleReseller = (id) => {
    setCustomerData((prevState) => ({
      ...prevState,
      resellerId: id,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const validateForm = () => {
    if (customerData.resellerId === "all") {
      setFieldError("Please select a reseller");
      return;
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    dispatch(mimoLoader(true));
    try {
      const convertedData = {
        ...customerData,
        customFirmware: customerData.customFirmware === 'No'? 0 : 1,
      };
      await storeCustomer(convertedData);

      toggleModal();
      setSuccessAlert(true);
      setDescription("Company has been created");
    } catch (ex) {
      setErrorAlert(true);
      setDescription(ex.response.data.message);
    }
    dispatch(mimoLoader(false));
  };

  return (
    <>
      <AvForm onValidSubmit={validateForm}>
        {getCurrentUser().role_id === 3 ? (
          <SelectReseller
            className=""
            fieldError={fieldError}
            resellerId={customerData.resellerId}
            handleReseller={handleReseller}
          />
        ) : (
          ""
        )}

        <AvField
          name="name"
          label="Company Name"
          placeholder="Type Something"
          type="text"
          value={customerData.name}
          onChange={handleChange}
          errorMessage="Enter Name"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="email"
          label="Email"
          placeholder="Enter Valid Email"
          type="email"
          onChange={handleChange}
          value={customerData.email}
          errorMessage="Invalid Email"
          validate={{
            required: { value: true },
            email: { value: true },
          }}
        />

        <AvField
          name="phone"
          label="Phone Number  "
          placeholder="Enter Only number"
          type="number"
          onChange={handleChange}
          value={customerData.phone}
          errorMessage="Enter Only Number"
          validate={{
            required: { value: true },
            pattern: {
              value: "^[0-9]+$",
              errorMessage: "Only Numbers",
            },
          }}
        />

        <FormGroup>
          <AvField
            name="address"
            label="Address"
            placeholder="Enter address"
            type="text"
            onChange={handleChange}
            value={customerData.address}
          />
        </FormGroup>

        <FormGroup>
          <Label>Two Factor Authentication</Label>
          <Input type="select" name="twoFAStatus" onChange={handleChange}>
            <option value="disable">Disable</option>
            <option value="enable">Enable</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label>Custom Firmware</Label>
          <Input type="select" name="customFirmware" onChange={handleChange}>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </Input>
        </FormGroup>

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default CompanyCreate;
