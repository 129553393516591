import React, { useEffect, useState } from "react";
import { deleteHelp, getHelpGuide } from "./redux/helpGuideApi";
import ModalLayout from "../../component/Common/ModalLayout";
import { setBreadcrumbItems } from "../../store/actions";
import { Col, Row, Card, CardBody } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { MDBDataTable } from "mdbreact";
import { connect } from "react-redux";
import CreateHelp from "./CreateHelp";
import { Button } from "reactstrap";
import EditHelp from "./EditHelp";

const HelpGuide = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Help Guides", link: "#" },
  ]);
  const [helpData, setHelpData] = useState([]);
  const [helpId, setHelpId] = useState(null);
  const [successAlert, setSuccessAlert] = useState(false);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShowCreate, setModalShowCreate] = useState(false);

  useEffect(() => {
    if (modalShow || modalShowCreate) return;
    (async () => {
      props.setBreadcrumbItems("Help Guides", breadcrumbItems, true);

      try {
        const { data: helpData } = await getHelpGuide();
        setHelpData(helpData);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Help Guides", breadcrumbItems, false);
    })();
  }, [modalShow, modalShowCreate]);

  const handleDelete = async () => {
    props.setBreadcrumbItems("Help Guides", breadcrumbItems, true);

    try {
      await deleteHelp(helpId);

      setSuccessAlert(true);
      setDescription("Help guide successfully deleted.");
      setHelpData(helpData.filter((item) => item.id != helpId));
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("Help Guides", breadcrumbItems, false);
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  const toggleModalCreate = () => {
    setModalShowCreate(!modalShowCreate);
  };

  const dataTable = {
    columns: [
      {
        label: "Category",
        field: "type",
        sort: "asc",
        width: 150,
      },
      {
        label: "File Name",
        field: "fileName",
        sort: "asc",
        width: 270,
      },
      {
        label: "File",
        field: "location",
        sort: "asc",
        width: 200,
      },
      {
        label: "Actions",
        field: "actions",
        sort: "asc",
        width: 100,
      },
    ],

    rows: helpData.map((item) => ({
      type: item.category,
      fileName: item.fileName,
      location: (
        <a
          className="btn btn-success waves-effect waves-light mr-3"
          href={process.env.REACT_APP_URL + "/" + item.location}
        >
          <i className="fas fa-download fa-1.2x"></i> Download ({item.fileSize}
          MB)
        </a>
      ),
      actions: (
        <>
          <i
            className="mdi mdi-square-edit-outline mr-1 iconHover"
            style={{ color: "#20C0ED", fontSize: "23px" }}
            role="button"
            onClick={() => {
              setHelpId(item.id);
              toggleModal();
            }}
          ></i>
          <i
            className="mdi mdi mdi-delete  mr-1 iconHover"
            style={{
              color: "#DB4C3F",
              fontSize: "23px",
            }}
            role="button"
            onClick={() => {
              setConfirmAlert(true);
              setHelpId(item.id);
            }}
          ></i>
        </>
      ),
    })),
  };

  return (
    <>
      {confirmAlert ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            setConfirmAlert(false);
            handleDelete();
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          You won't be able to revert this!
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          error
          title={this.state.alertTitle}
          onConfirm={() => setErrorAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}
      {successAlert ? (
        <SweetAlert
          success
          title="Success"
          onConfirm={() => setSuccessAlert(false)}
        >
          {description}
        </SweetAlert>
      ) : null}

      <Row>
        <Col xs="12">
          <Card>
            <CardBody>
              <Button
                style={{ marginRight: "10px", marginBottom: "10px" }}
                onClick={toggleModalCreate}
              >
                Upload Help Guide
              </Button>

              <MDBDataTable responsive bordered striped data={dataTable} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      {modalShow ? (
        <ModalLayout
          toggleModal={toggleModal}
          isOpen={modalShow}
          title="Edit Help Guide"
          size="lg"
        >
          <EditHelp
            helpId={helpId}
            toggleModal={toggleModal}
            setSuccessAlert={setSuccessAlert}
            setDescription={setDescription}
            setErrorAlert={setErrorAlert}
          />
        </ModalLayout>
      ) : (
        ""
      )}

      {modalShowCreate ? (
        <ModalLayout
          toggleModal={toggleModalCreate}
          isOpen={modalShowCreate}
          title="Upload Help Guide"
          size="lg"
        >
          <CreateHelp
            toggleModal={toggleModalCreate}
            setSuccessAlert={setSuccessAlert}
            setErrorAlert={setErrorAlert}
            setDescription={setDescription}
          />
        </ModalLayout>
      ) : (
        ""
      )}
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(HelpGuide);
