import React, { Component } from "react";
import { getHeader } from "../../services/authService";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_URL;

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    try {
      const tokenParts = secureLocalStorage.getItem("auth").split(".");
      const payload = JSON.parse(atob(tokenParts[1]));
      const tokenId = payload.jti;

      axios.get(`/api/auth/logout/${tokenId}`, getHeader());

      secureLocalStorage.removeItem("user");
      secureLocalStorage.removeItem("auth");
      secureLocalStorage.removeItem("permissions");
      secureLocalStorage.removeItem("twoFactorStatus");

      this.props.history.push("/login");
    } catch (ex) {
      console.error(ex);
    }
  }

  render() {
    return (
      <>
        <h1>&nbsp;</h1>
      </>
    );
  }
}

export default Logout;
