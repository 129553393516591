import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

import Breadcrumb from "./Breadcrumb/reducer";
import MimoLoader from "./MimoLoader/reducer";

// Authentication Module
import Account from "./auth/register/reducer";
import Login from "./auth/login/reducer";
import Forget from "./auth/forgetpwd/reducer";
import TwoFactorAuth from "./auth/twoFactorAuth/reducer";

const rootReducer = combineReducers({
  // public
  Layout,

  // Breadcrumb items
  Breadcrumb,

  // Loader Items
  MimoLoader,

  // Authentication
  Account,
  Login,
  Forget,
  TwoFactorAuth,
});

export default rootReducer;
