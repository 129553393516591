import React, { useState, useEffect } from "react";
import { setBreadcrumbItems } from "../../store/actions";
import { getLogs } from "./../../services/mainService";
import { connect } from "react-redux";
import "../../assets/css/logs.css";
import {
  Col,
  Row,
  Card,
  CardBody,
  Collapse,
  FormGroup,
  Button,
} from "reactstrap";
import Select from "react-select";
import { getFormattedDate } from "./../../helpers/utils";

const Logs = (props) => {
  const [breadcrumbItems] = useState([
    { title: "Mimo", link: "/" },
    { title: "Logs", link: "#" },
  ]);
  const [availableLogDates, setAvailableLogDates] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [collapse, setCollapse] = useState(null);

  useEffect(() => {
    (async () => {
      props.setBreadcrumbItems("Logs", breadcrumbItems, true);

      try {
        const {
          data: { data: logsData, availableLogDates },
        } = await getLogs(getFormattedDate(new Date()));

        setLogsData(logsData);
        setFilteredData(logsData);
        setAvailableLogDates(availableLogDates);
      } catch (ex) {
        console.error(ex);
      }

      props.setBreadcrumbItems("Logs", breadcrumbItems, false);
    })();
  }, []);

  const toggleOpen = (e) => {
    const { id } = e.target;
    if (!collapse || id !== collapse) {
      setCollapse(id);
    } else if (collapse === id) {
      setCollapse(null);
    }
  };

  const getFilterLog = async (e) => {
    props.setBreadcrumbItems("Logs", breadcrumbItems, true);

    try {
      const {
        data: { data: logsData },
      } = await getLogs(e.value);

      setLogsData(logsData);
      setFilteredData(logsData);
    } catch (ex) {
      console.error(ex);
    }

    props.setBreadcrumbItems("Logs", breadcrumbItems, false);
  };

  const dateOptions = [
    {
      options: availableLogDates.map((item) => {
        return { label: item, value: item };
      }),
    },
  ];

  return (
    <>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <Col xs="12">
                <Row>
                  <Col xs="10">
                    <Button
                      className="mr-2 p-1 badge-success"
                      onClick={() => {
                        setFilteredData(
                          logsData.filter(
                            (item) => item.description === "created"
                          )
                        );
                      }}
                    >
                      CREATED
                    </Button>
                    <Button
                      className="mr-2 p-1 badge-primary"
                      onClick={() => {
                        setFilteredData(
                          logsData.filter(
                            (item) => item.description === "updated"
                          )
                        );
                      }}
                    >
                      UPDATED
                    </Button>
                    <Button
                      className="mr-2 p-1 badge-warning"
                      onClick={() => {
                        setFilteredData(
                          logsData.filter(
                            (item) => item.description === "deleted"
                          )
                        );
                      }}
                    >
                      DELETED
                    </Button>
                    <Button
                      className="mr-2 p-1 badge-info"
                      onClick={() => {
                        setFilteredData(
                          logsData.filter(
                            (item) => item.description === "login"
                          )
                        );
                      }}
                    >
                      LOGIN
                    </Button>
                    <Button
                      className="mr-2 p-1 badge-dark"
                      onClick={() => {
                        setFilteredData(logsData);
                      }}
                    >
                      ALL
                    </Button>
                  </Col>

                  <Col xs="2">
                    {availableLogDates ? (
                      <FormGroup>
                        <Select
                          className="select2"
                          autoComplete="nope"
                          name="date"
                          options={dateOptions}
                          onChange={(e) => {
                            getFilterLog(e);
                          }}
                        />
                      </FormGroup>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              </Col>

              <div className="responsive_table_logs">
                <table>
                  <thead>
                    <tr>
                      <td>LOG INFO</td>
                      <td>MODULE</td>
                      <td>OPERATION</td>
                      <td>MODIFIED BY</td>
                      <td>TIMESTAMP</td>
                    </tr>
                  </thead>

                  <tbody>
                    {filteredData.map((item, idx) => {
                      return (
                        <tr
                          ng-repeat="log in data.logs |filter: selectedType track by $index"
                          key={idx}
                        >
                          <td>
                            <>
                              <h5>
                                <span
                                  className={
                                    item.event === "created"
                                      ? "badge badge-success"
                                      : item.event === "updated"
                                      ? "badge badge-primary"
                                      : item.event === "deleted"
                                      ? "badge badge-warning"
                                      : "badge badge-info"
                                  }
                                  type="button"
                                  id={idx}
                                  onClick={(e) => {
                                    toggleOpen(e);
                                  }}
                                >
                                  {item.event}
                                </span>
                              </h5>

                              <Collapse isOpen={collapse == idx}>
                                {item.properties.old ? (
                                  <>
                                    <b>Old:</b>
                                    <div>
                                      {Object.keys(item.properties.old).map(
                                        (key, i) => {
                                          return (
                                            <code className="h6 ml-2" key={i}>
                                              {key}
                                              <span className="mr-2">:</span>
                                              <span style={{ color: "#690" }}>
                                                {item.properties.old[key]}
                                              </span>
                                              <span>,</span>
                                              <br></br>
                                            </code>
                                          );
                                        }
                                      )}
                                    </div>
                                    <br></br>
                                    {item.properties.attributes ? (
                                      <>
                                        <b>Attributes:</b>
                                        <div>
                                          {Object.keys(
                                            item.properties.attributes
                                          ).map((key, i) => {
                                            return (
                                              <code className="h6 ml-2" key={i}>
                                                {key}
                                                <span className="mr-2">:</span>
                                                <span
                                                  style={{
                                                    color: "#690",
                                                  }}
                                                >
                                                  {
                                                    item.properties.attributes[
                                                      key
                                                    ]
                                                  }
                                                </span>
                                                <span>,</span>
                                                <br></br>
                                              </code>
                                            );
                                          })}
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <b>Attributes:</b>
                                    <div>
                                      {Object.keys(
                                        item.properties.attributes
                                      ).map((key, idx) => {
                                        return (
                                          <code className="h6 ml-2" key={idx}>
                                            {key}
                                            <span className="mr-2">:</span>
                                            <span style={{ color: "#690" }}>
                                              {item.properties.attributes[key]}
                                            </span>
                                            <span>,</span>
                                            <br></br>
                                          </code>
                                        );
                                      })}
                                    </div>
                                  </>
                                )}
                              </Collapse>
                            </>
                          </td>
                          <td>{item.log_name}</td>
                          <td>{item.description}</td>
                          <td>
                            <b>{item.userName}</b>
                            <br></br>
                            {item.userEmail}
                          </td>
                          <td>{item.createdAt}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(Logs);
