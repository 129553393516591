import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function getReseller() {
  return axios.get(`/api/auth/getReseller`, getHeader());
}

export function storeReseller(data) {
  return axios.post("/api/auth/storeReseller", data, getHeader());
}

export function editReseller(id) {
  return axios.get(`/api/auth/editReseller/${id}`, getHeader());
}

export function updateReseller(data) {
  return axios.post(`/api/auth/updateReseller`, data, getHeader());
}

export function deleteReseller(id) {
  return axios.get(`/api/auth/deleteReseller/${id}`, getHeader());
}
