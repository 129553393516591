import React, { useEffect, useState } from "react";
import { editCustomer, updateCustomer } from "./redux/customerApi";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { mimoLoader } from "../../store/actions";
import { FormGroup, Button } from "reactstrap";
import { useDispatch } from "react-redux";

const EditCompany = ({
  customerId,
  toggleModal,
  setSuccessAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    customFirmware:"",
  });

  useEffect(() => {
    (async () => {
      dispatch(mimoLoader(true));
      try {
        const {
          data: { data: customerDetails },
        } = await editCustomer(customerId);
        const { name, email, phone, address,custom_firmware: customFirmware} = customerDetails;
        const customer = {
          name, email, phone, address, customFirmware:customFirmware === 0 ? 'No': 'Yes'
        }
        setCustomerData(customer);
      } catch (ex) {
        console.error(ex);
      }
      dispatch(mimoLoader(false));
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    dispatch(mimoLoader(true));
    try {
      const convertedData = {
        ...customerData,
        customFirmware: customerData.customFirmware === 'No'? 0 : 1,
      };
      await updateCustomer(customerId, convertedData);

      toggleModal();
      setSuccessAlert(true);
      setDescription("Company successfully updated");
    } catch (ex) {
      console.error(ex);
    }
    dispatch(mimoLoader(false));
  };

  return (
    <>
      <AvForm onValidSubmit={handleSubmit}>
        <AvField
          name="name"
          label="Company Name"
          placeholder="Enter name"
          type="text"
          value={customerData.name}
          onChange={handleChange}
          errorMessage="Enter Name"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="email"
          label="E-Mail  "
          placeholder="Enter valid email"
          type="email"
          value={customerData.email}
          disabled={true}
        />

        <AvField
          name="phone"
          label="Phone Number  "
          placeholder="Enter phone number"
          type="number"
          onChange={handleChange}
          value={customerData.phone}
          errorMessage="Enter Only Number"
          validate={{
            required: { value: true },
            pattern: {
              value: "^[0-9]+$",
              errorMessage: "Only Numbers",
            },
          }}
        />

        <AvField
          name="address"
          label="Address"
          placeholder="Enter address"
          type="text"
          value={customerData.address}
          onChange={handleChange}
        />

        <AvField
        type="select"
        name="customFirmware"
        label="Custom Firmware"
        value={customerData.customFirmware}
        onChange={handleChange}
      >
         <option value="No">No</option>
        <option value="Yes">Yes</option>
      </AvField>

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default EditCompany;
