import React, { Component } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Table,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import SweetAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import { getHeader } from "../../services/authService";

class ModalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal_data: this.props.data,
      profileData: this.props.profiles,
      data: {},
      header: {
        userName: "Admin",
        password: "0000",
      },
      command: "profiles",
      profileName: "",
      mimoLoader: false,
      successAlert: false,
      alertDescription: "",
    };
  }

  handleSubmit = () => {
    const { REACT_APP_URL } = process.env;

    var arr = [];
    var macArr = [];
    this.state.modal_data.map((item) => {
      if (item.isChecked && item.isChecked === true) {
        arr.push(item.id);
        macArr.push(item.mac_address);
      }
    });

    this.setState(
      {
        data: {
          ...this.state.data,
          customer: this.state.modal_data[0].customer_id,
          address: JSON.stringify(arr),
          macAddress: macArr,
          header: this.state.header,
          command: this.state.command,
        },
        mimoLoader: true,
      },
      () => {
        axios
          .post(
            `${REACT_APP_URL}/api/auth/addGroup`,
            this.state.data,
            getHeader()
          )
          .then((res) => {
            if (res.data.error) {
              this.setState({
                alert: res.data.error,
                error: true,
              });
            } else {
              this.setState(
                {
                  mimoLoader: false,
                  successAlert: true,
                  alertDescription: "Group has been created",
                },
                () => {}
              );
            }
          });
      }
    );
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      modal_data: this.props.data,
      data: {},
    });
  };

  handleChange = (event) => {
    event.preventDefault();
    this.setState({
      data: {
        ...this.state.data,
        [event.target.name]: event.target.value,
      },
    });
  };

  handleCancel = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      data: {},
    }));
  };

  render() {
    return (
      <>
        {this.state.successAlert ? (
          <SweetAlert
            success
            title="Success"
            onConfirm={() =>
              this.setState(
                {
                  successAlert: false,
                },
                () => {
                  this.handleCancel();
                }
              )
            }
          >
            {this.state.successDescription}
          </SweetAlert>
        ) : null}

        {/* BUTTON */}
        <i
          className="mdi mdi-database-plus mr-1 iconHover"
          style={{
            color: "#5A6268",
            fontSize: "35px",
            marginTop: "-3px",
          }}
          id="createGroupIcon"
          role="button"
          onClick={(e) => {
            this.toggle();
          }}
        ></i>
        <UncontrolledTooltip placement="top" target="createGroupIcon">
          Create Group
        </UncontrolledTooltip>

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          autoFocus={true}
          centered={true}
          size="lg"
        >
          <ModalHeader toggle={this.toggle}>Create Group</ModalHeader>

          <ModalBody>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <AvForm onValidSubmit={this.handleSubmit}>
                      <AvField
                        name="group_name"
                        id="group_name"
                        label="Group Name"
                        placeholder="Type Name"
                        type="text"
                        value={this.state.data.group_name || ""}
                        onChange={this.handleChange}
                        errorMessage="Enter Group Name"
                        validate={{ required: { value: true } }}
                      />

                      <FormGroup>
                        <div>
                          <Label>Select Profile</Label>
                          <br></br>
                          <select
                            style={{
                              marginLeft: "0px",
                              width: "100%",
                              maxHeight: "50%",
                            }}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Select profile"
                            required
                            onChange={(e) => {
                              this.setState({
                                profileName: e.target.value,
                                data: {
                                  ...this.state.data,
                                  profileName: e.target.value,
                                },
                              });
                            }}
                            name="profileName"
                            className="custom-select"
                          >
                            <option defaultValue value="">
                              Select Profile
                            </option>
                            {this.state.profileData.map((key, index) => {
                              return (
                                <option key={index} value={key.id}>
                                  {key.profile_name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </FormGroup>

                      <Table className="table table-bordered mb-3">
                        <tbody>
                          <tr>
                            <th>Device Name</th>
                            <th>Serial Number</th>
                            <th>Mac Address</th>
                          </tr>
                          {this.state.modal_data.map((item, index) => {
                            if (item.isChecked && item.isChecked === true) {
                              return (
                                <tr key={index}>
                                  <td>{item.device_name}</td>
                                  <td>{item.serial_number}</td>
                                  <td>{item.mac_address}</td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </Table>

                      <FormGroup className="mb-0">
                        <div style={{ display: "flex" }}>
                          <Button
                            type="submit"
                            color="primary"
                            className="waves-effect waves-light mr-1"
                            id="sa-title"
                          >
                            Submit
                          </Button>

                          <Button
                            type="reset"
                            onClick={this.handleCancel}
                            color="secondary"
                            className="waves-effect"
                            id="e1"
                          >
                            Cancel
                          </Button>

                          {this.state.mimoLoader === true ? (
                            <div
                              style={{ width: "33px", height: "33px" }}
                              className="mimoLoader ml-2"
                            ></div>
                          ) : (
                            ""
                          )}
                        </div>
                      </FormGroup>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
export default ModalPage;
