import React, { useState, useEffect } from "react";
import { FormGroup, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { getCurrentUser } from "../../services/authService";
import { setBreadcrumbItems } from "../../store/actions";
import secureLocalStorage from "react-secure-storage";
import SweetAlert from "react-bootstrap-sweetalert";
import { deviceEdit } from "./redux/deviceApi";
import { connect } from "react-redux";
import axios from "axios";

const EditDevice = ({ isOpenEdit, toggleEdit, deviceId }) => {
  const [deviceData, setDeviceData] = useState({
    deviceName: "",
    serialNumber: "",
  });
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [description, setDescription] = useState("");
  const [fieldError, setFieldError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { data: deviceData } = await deviceEdit(deviceId);
        setDeviceData(deviceData);
      } catch (ex) {
        console.error(ex);
      }
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name == "file") {
      setDeviceData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setDeviceData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    const formData = new FormData();
    formData.append("deviceName", deviceData.deviceName);
    formData.append("serialNumber", deviceData.serialNumber);

    axios
      .post(`${REACT_APP_URL}/api/auth/device/update/${deviceId}`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type":
            "multipart/form-data; charset=utf-8; boundary=" +
            Math.random().toString().substr(2),
        },
      })
      .then((res) => {
        if (res.data.error) {
          setErrorAlert(true);
          setDescription(res.data.error);
        } else {
          setSuccessAlert(true);
          setDescription("Device has been successfully updated");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      {successAlert ? (
        <SweetAlert
          title="Success"
          success
          onConfirm={() => {
            setSuccessAlert(false);
            toggleEdit();
          }}
        >
          {description}
        </SweetAlert>
      ) : null}
      {errorAlert ? (
        <SweetAlert
          title="Failed"
          error
          onConfirm={() => {
            setErrorAlert(false);
          }}
        >
          {description}
        </SweetAlert>
      ) : null}

      <Modal isOpen={isOpenEdit} toggle={toggleEdit} autoFocus={true} size="lg">
        <ModalHeader toggle={toggleEdit}>Edit Device</ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleSubmit}>
            <FormGroup>
              <AvField
                name="deviceName"
                label="Device Name"
                placeholder="Enter device name"
                type="text"
                value={deviceData.deviceName || ""}
                onChange={handleChange}
                errorMessage="Enter Device Name"
                validate={{ required: { value: true } }}
              />

              {getCurrentUser().role_id === 1 ? (
                <AvField
                  name="serialNumber"
                  label="Serial Number"
                  placeholder="Enter serial number"
                  type="text"
                  value={deviceData.serialNumber || ""}
                  onChange={handleChange}
                  errorMessage="Enter Serial Number"
                  validate={{ required: { value: true } }}
                />
              ) : (
                ""
              )}
            </FormGroup>

            <FormGroup>
              <Button
                type="submit"
                color="primary"
                className="btn btn-primary mr-1"
              >
                Submit
              </Button>

              <Button
                type="reset"
                onClick={toggleEdit}
                color="secondary"
                className="btn btn-secondary ml-1"
              >
                Cancel
              </Button>
            </FormGroup>
          </AvForm>
        </ModalBody>
      </Modal>
    </>
  );
};

export default connect(null, { setBreadcrumbItems })(EditDevice);
