import React, { useState, useEffect, useRef } from "react";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Label, Button, Progress } from "reactstrap";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";

const EditFirmVersion = ({
  firmwareId,
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
  setUpdate,
}) => {
  const [firmwareData, setFirmwareData] = useState({});
  const [lState, setLState] = useState(null);
  const [loading, setLoading] = useState(null);
  const [version, setVersion] = useState([]);
  const [errorBox, setErrorBox] = useState(false);
  const [source, setSource] = useState(axios.CancelToken.source());
  const fileRef = useRef();

  useEffect(() => {
    (async () => {
      const { REACT_APP_URL } = process.env;
      let data = secureLocalStorage.getItem("auth");
      let token = data.replace(/['"]+/g, "");

      axios
        .get(`${REACT_APP_URL}/api/auth/firmware/edit/${firmwareId}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setFirmwareData(res.data);
          setVersion(JSON.parse(res.data.version));
        })
        .catch((error) => {
          console.error(error);
        });
    })();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "editUrl" || name === "editRelease") {
      if (
        name === "editUrl" &&
        files[0].type !== "application/x-zip-compressed"
      ) {
        setErrorAlert(true);
        setDescription("File format is incorrect (Please upload a Zip File)");
        setFirmwareData((prevState) => ({
          ...prevState,
          editUrl: null,
        }));
        fileRef.current.value = "";
      } else {
        setFirmwareData((prevState) => ({
          ...prevState,
          [name]: files[0],
        }));
      }
    } else {
      setFirmwareData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    const { REACT_APP_URL } = process.env;
    let data = secureLocalStorage.getItem("auth");
    let token = data.replace(/['"]+/g, "");

    const formData = new FormData();
    formData.append("id", firmwareData.id);
    formData.append("version", version);
    formData.append("target_version", firmwareData.target_version);
    formData.append("android_os", firmwareData.android_os);
    formData.append("build_number", firmwareData.build_number);
    formData.append("comments", firmwareData.comments);
    formData.append("editUrl", firmwareData.editUrl);
    formData.append("editRelease", firmwareData.editRelease);

    axios
      .post(`${REACT_APP_URL}/api/auth/firmware/update`, formData, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type":
            "multipart/form-data; charset=utf-8; boundary=" +
            Math.random().toString().substr(2),
        },
        onUploadProgress: (e) => {
          const { loaded, total } = e;
          let percent = Math.floor((loaded * 100) / total);
          setLState(true);
          setLoading(percent);
        },
      })
      .then((res) => {
        setUpdate(true);
        toggleModal();
        setSuccessAlert(true);
        setLState(false);
        setDescription("Firmware has been successfully updated");
      })
      .catch((er) => {
        console.error(er);
        setErrorAlert(true);
        setDescription(er);
      });
  };

  // Multiple Inputs
  const addVersion = (e) => {
    if (e.target.value !== "") {
      if (
        version.some(
          (item) => item.toUpperCase() === e.target.value.toUpperCase()
        )
      ) {
        setErrorBox(true);
      } else {
        setErrorBox(false);
        setVersion([...version, e.target.value]);
      }
      e.target.value = "";
    }
  };
  const removeVersion = (ver) => {
    setVersion(version.filter((_, index) => index !== ver));
  };

  return (
    <>
      <AvForm
        onValidSubmit={handleSubmit}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <Label>Version</Label>
        <br></br>
        <div
          style={{
            borderColor: errorBox === true ? "#ec536c" : "",
          }}
          className="tags-input"
        >
          <ul id="tags">
            {version.map((tag, index) => (
              <li key={index} className="tag">
                <span className="tag-title">{tag}</span>
                <span
                  className="tag-close-icon"
                  onClick={() => removeVersion(index)}
                >
                  x
                </span>
              </li>
            ))}
          </ul>
          <input
            type="text"
            onKeyUp={(event) =>
              event.key === "Enter" ? addVersion(event) : null
            }
            placeholder="Press enter to add Versions"
          />
        </div>
        {errorBox === true ? (
          <div className="invalid-error">Version already entered</div>
        ) : (
          ""
        )}
        <br></br>

        <AvField
          name="target_version"
          id="target_version"
          label="Target Version"
          placeholder="Type text"
          type="text"
          value={firmwareData.target_version || ""}
          onChange={handleChange}
        />

        <AvField
          name="android_os"
          id="android_os"
          label="Android OS"
          placeholder="Type text"
          type="text"
          value={firmwareData.android_os || ""}
          onChange={handleChange}
        />

        <AvField
          name="build_number"
          id="build_number"
          label="Build Number"
          placeholder="Type text"
          type="text"
          value={firmwareData.build_number || ""}
          onChange={handleChange}
        />

        <AvField
          name="url"
          label="Firmware URL"
          placeholder="Type Something"
          type="text"
          value={process.env.REACT_APP_URL + "/" + firmwareData.url}
          errorMessage="Enter URL"
          readOnly
          validate={{ required: { value: true } }}
        />

        <FormGroup>
          <input
            type="file"
            name="editUrl"
            onChange={handleChange}
            className="filestyle"
            data-buttonname="btn-secondary"
            ref={fileRef}
          />
        </FormGroup>

        {lState ? (
          <div className="">
            <div className="mb-4">
              <Progress color="primary" value={loading}>
                {loading}
              </Progress>
            </div>
          </div>
        ) : (
          ""
        )}

        <FormGroup>
          <Label>Comments</Label>
          <textarea
            className="form-control"
            rows="3"
            name="comments"
            placeholder="Type Comments"
            type="text"
            value={firmwareData.comments || ""}
            onChange={handleChange}
          ></textarea>
        </FormGroup>

        <FormGroup>
          <Label>Release Notes URL</Label>
          <input
            name="release"
            className="form-control"
            placeholder="Type Something"
            type="text"
            value={
              firmwareData.release
                ? process.env.REACT_APP_URL + "/" + firmwareData.release
                : "Not Uploaded"
            }
            readOnly
          />
        </FormGroup>

        <FormGroup>
          <input
            type="file"
            name="editRelease"
            onChange={handleChange}
            className="filestyle"
            data-buttonname="btn-secondary"
          />
        </FormGroup>
        <FormGroup className="mb-0">
          <div>
            <Button
              type="submit"
              color="primary"
              className="waves-effect waves-light mr-1"
              id="sa-title"
            >
              Submit
            </Button>

            <Button
              type="reset"
              onClick={toggleModal}
              color="secondary"
              className="waves-effect"
              id="e1"
            >
              Cancel
            </Button>
          </div>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default EditFirmVersion;
