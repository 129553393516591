import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function changePassword(data) {
  return axios.post(`/api/auth/changepassword`, data, getHeader());
}

export function addNotificationDL(data) {
  return axios.post(`/api/auth/SaveFailureNotificationDL`, data, getHeader());
}
