import React from "react";
import { Card, CardBody } from "reactstrap";
import C3Chart from "react-c3js";
import "c3/c3.css";

const DeviceStatus = ({ reports }) => {
  const data = {
    columns: [
      ["Online Now", reports.onlineDevices ? reports.onlineDevices.length : 0],
      [
        "Online within last 24h",
        reports.onlineDevices24h ? reports.onlineDevices24h : 0,
      ],
      [
        "Offline for more than 24h",
        reports.onlineDevicesMore24h ? reports.onlineDevicesMore24h : 0,
      ],
    ],
    type: "donut",
  };

  // const donut = {
  //   title:
  //     reports.onlineDevices !== undefined
  //       ? reports.onlineDevices + " Online Now"
  //       : " Status",
  //   width: 30,
  //   label: { show: !1 },
  // };

  const color = {
    pattern: ["#7a6fbe", "#28bbe3", "#ADE5F4"],
  };
  const title = reports.onlineDevices
    ? reports.onlineDevices.length + " Online"
    : "0 Online";

  const size = {
    height: 300,
    width: 207.25,
  };

  return (
    <>
      <Card style={{ textAlign: "center", height: "383px" }}>
        <CardBody>
          <h4 className="card-title mb-4">Devices Status</h4>
          {reports.onlineDevices ? (
            <C3Chart
              data={data}
              donut={{
                title: title,
                width: 30,
                label: { show: !1 },
              }}
              color={color}
              size={size}
            />
          ) : (
            ""
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default DeviceStatus;
