import React, { useState } from "react";
import { SelectReseller } from "../../component/Common/SelectReseller";
import { SelectCustomer } from "../../component/Common/SelectCustomer";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { FormGroup, Label, Button, Input } from "reactstrap";
import { getCurrentUser } from "../../services/authService";
import { getFormattedDate } from "../../helpers/utils";
import { storeActKey } from "./redux/activationKeyApi";
import { mimoLoader } from "../../store/actions";
import { useDispatch } from "react-redux";

const ActivationCreate = ({
  toggleModal,
  setSuccessAlert,
  setErrorAlert,
  setDescription,
}) => {
  const dispatch = useDispatch();
  const [activationKeyData, setActivationKeyData] = useState({
    app_name: "MService",
    activation_key: "",
    customer: "",
    key: "Trial",
    valid: getFormattedDate(new Date()),
    device_quantity: "",
    comments: "",
  });
  const [appName, setAppName] = useState([
    { app_name: "MService" },
    { app_name: "Mtemp" },
    { app_name: "Mlock" },
  ]);
  const [keyType, setKeyType] = useState([
    { value: "Trial" },
    { value: "Annual" },
    { value: "Perpetual" },
  ]);
  const [customerId, setCustomerId] = useState(null);
  const [fieldError, setFieldError] = useState(null);
  const [resellerId, setResellerId] = useState("all");
  const currentDate = new Date().toISOString().split("T")[0];

  const handleReseller = (id) => {
    setResellerId(id);
    setCustomerId("all");
  };

  const handleCustomer = (id) => {
    setCustomerId(id === "all" ? "all" : parseInt(id));
    setActivationKeyData((prevState) => ({
      ...prevState,
      customer: id,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "key") {
      if (value === "Trial") {
        const date = new Date();
        const trialDate = `${date.getFullYear()}-${(
          "0" +
          (date.getMonth() + 1)
        ).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;

        setActivationKeyData((prevState) => ({
          ...prevState,
          [name]: value,
          valid: trialDate,
        }));
      }
      if (value === "Annual") {
        const annualDate =
          new Date(
            new Date().setFullYear(new Date().getFullYear() + 1)
          ).getFullYear() +
          "-" +
          (
            new Date(
              new Date().setFullYear(new Date().getFullYear() + 1)
            ).getMonth() + 1
          )
            .toString()
            .padStart(2, "0") +
          "-" +
          new Date(new Date().setFullYear(new Date().getFullYear() + 1))
            .getDate()
            .toString()
            .padStart(2, "0");

        setActivationKeyData((prevState) => ({
          ...prevState,
          [name]: value,
          valid: annualDate,
        }));
      }
      if (value === "Perpetual") {
        setActivationKeyData((prevState) => ({
          ...prevState,
          [name]: value,
          valid: "",
        }));
      }
    } else {
      setActivationKeyData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    if (
      activationKeyData.customer === "" ||
      activationKeyData.customer === "all"
    ) {
      setFieldError("Please select a company");
      return;
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    dispatch(mimoLoader(true));
    try {
      await storeActKey(activationKeyData);

      toggleModal();
      setSuccessAlert(true);
      setDescription("Activation key has been successfully created");
    } catch (ex) {
      console.log(ex);
      setErrorAlert(true);
      setDescription(ex.response.data.message);
    }
    dispatch(mimoLoader(false));
  };

  const randomGenerate = () => {
    let length = 6;
    setActivationKeyData((prevState) => ({
      ...prevState,
      activation_key: Math.round(
        Math.pow(36, length + 1) - Math.random() * Math.pow(36, length)
      )
        .toString(36)
        .slice(1),
    }));
  };

  return (
    <>
      <AvForm onValidSubmit={validateForm}>
        {getCurrentUser().role_id === 3 ? (
          <SelectReseller
            className=""
            fieldError={null}
            resellerId={resellerId}
            handleReseller={handleReseller}
          />
        ) : (
          ""
        )}

        <FormGroup>
          <SelectCustomer
            className=""
            fieldError={fieldError}
            resellerId={resellerId}
            customerId={customerId}
            handleCustomer={handleCustomer}
          />
        </FormGroup>

        <FormGroup>
          <Label>Select Application Name</Label>
          <select
            required
            onChange={handleChange}
            name="app_name"
            className="custom-select"
          >
            {appName.map((item, index) => {
              return (
                <option key={index} value={item.app_name}>
                  {item.app_name}
                </option>
              );
            })}
          </select>
        </FormGroup>

        <FormGroup>
          <Label>Select Key Type</Label>
          <select
            required
            onChange={handleChange}
            name="key"
            className="custom-select"
          >
            {keyType.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.value}
                </option>
              );
            })}
          </select>
        </FormGroup>

        {activationKeyData.key !== "Perpetual" ? (
          <FormGroup>
            <Label>Expiration Date</Label>
            <Input
              className="form-control"
              onChange={handleChange}
              value={activationKeyData.valid}
              name="valid"
              type="date"
              min={currentDate}
            />
          </FormGroup>
        ) : (
          ""
        )}

        <AvField
          name="activation_key"
          label="Activation Key"
          placeholder="Type Something"
          type="text"
          value={activationKeyData.activation_key || ""}
          onChange={handleChange}
          errorMessage="Enter Activation Key"
          validate={{ required: { value: true } }}
        />

        <AvField
          name="device_quantity"
          label="Device Quantity"
          placeholder="Enter Device Quantity"
          type="number"
          value={activationKeyData.device_quantity || ""}
          onChange={handleChange}
          errorMessage="Enter Device Quantity"
          validate={{
            required: { value: true },
            pattern: {
              value: "^[0-9]+$",
              errorMessage: "Only Numbers",
            },
          }}
        />

        <FormGroup>
          <Label>Comments</Label>
          <textarea
            className="form-control"
            rows="3"
            name="comments"
            placeholder="Type Comments"
            type="text"
            value={activationKeyData.comments || ""}
            onChange={handleChange}
          ></textarea>
        </FormGroup>

        <FormGroup className="mb-0">
          <Button
            type="submit"
            color="primary"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Submit
          </Button>

          <Button
            type="reset"
            onClick={toggleModal}
            color="secondary"
            className="waves-effect"
            id="e1"
          >
            Cancel
          </Button>

          <Button
            style={{ marginLeft: "10px" }}
            onClick={() => randomGenerate()}
            color="success"
            className="waves-effect waves-light mr-1"
            id="sa-title"
          >
            Generate Activation Key
          </Button>
        </FormGroup>
      </AvForm>
    </>
  );
};

export default ActivationCreate;
