import axios from "axios";
import { getHeader } from "../../../services/authService";

axios.defaults.baseURL = process.env.REACT_APP_URL;

export function updateApkData(app) {
  return axios.get(`/api/auth/updateApkData/${app}`, getHeader());
}

export function editMimoApk(id) {
  return axios.get(`/api/auth/editMimoApk/${id}`, getHeader());
}

export function updateMimoApk(id, data) {
  return axios.post(`/api/auth/updateMimoApk/${id}`, data, getHeader());
}

export function deleteMimoApk(id) {
  return axios.get(`/api/auth/deleteMimoApk/${id}`, getHeader());
}
