import React, { Component } from "react";
import ChartistGraph from "react-chartist";

class linechart extends Component {
  render() {
    var lineChartData = {
      labels: this.props.reports.totalUpdatesByMonths
        ? this.props.reports.totalUpdatesByMonths.map((item) => {
            return item.month;
          })
        : [],
      // "January", "February", "March", "April", "May", "June"]

      series: [
        this.props.reports.totalUpdatesByMonths
          ? this.props.reports.totalUpdatesByMonths.map((item) => {
              return item.total;
            })
          : [],
      ],
    };

    var lineChartOptions = {
      fullWidth: true,
      chartPadding: {
        right: 40,
      },
    };
    return (
      <React.Fragment>
        <ChartistGraph
          data={lineChartData}
          options={lineChartOptions}
          type={"Line"}
          style={{ height: "300px" }}
        />
      </React.Fragment>
    );
  }
}

export default linechart;
