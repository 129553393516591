import React from "react";
import { Redirect } from "react-router-dom";

import Pageslogin from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPassword from "../pages/Authentication/ForgetPassword";
import emailVerify from "../pages/Authentication/emailVerify";
import LockScreen from "../pages/Authentication/pages-lock-screen";
import Settings from "../pages/Authentication/Settings";
import Profile from "../pages/Authentication/profile";
import resetPassword from "../pages/Authentication/resetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/Dashboard";

// Reseller Accounts
import ResellerAccounts from "./../pages/ResellerAccounts/ResellerAccounts";

// User Roles
import UserRoles from "../pages/RolePermission/UserRoles";
import CreateRole from "../pages/RolePermission/CreateRole";

// Companies
import Company from "../pages/Company/Company";

// Activation Key
import ActivationKey from "../pages/ActivationKey/ActivationKey";
import ActivationKeyReport from "../pages/ActivationKey/ActivationKeyReport";

// Admins
import Admin from "../pages/Admin/Admin";

// User
import User from "../pages/User/User";

// Company's User
import CustomerUser from "../pages/CustomerUser/CustomerUser";
import CreateCustomerUser from "../pages/CustomerUser/CreateCustomerUser";

// Devices
import Devices from "../pages/Device/Devices";
import deviceCreate from "../pages/Device/deviceCreate";
import DeviceUpdate from "../pages/Device/DeviceUpdate";

// Manage Devices
import ActivateDevice from "../pages/MDM/ActivateDevice";

// Profiles
import Profiles from "../pages/Profile/Profile";

// Device Groups
import Group from "../pages/Group/Group";

// MimoApks
import MimoApks from "../pages/MimoApks/MimoApks";

// Upload Apks
import ShowUploadApk from "../pages/Upload/ShowUploadApk";

// Upload File
import ShowUploadFile from "../pages/UploadFile/ShowUploadFile";

// Documents
import ShowUploadDocument from "../pages/UploadDocuments/ShowUploadDocument";

// Download Audit Trail
import DownloadAuditTrail from "../pages/DownloadAuditTrail/DownloadAuditTrail";

// Firmware Images
import FirmwareProduct from "../pages/Firmware/FirmwareProduct";

import FirmHardwareProfile from "../pages/Firmware/FirmHardwareProfile";

// Two Factor Authentication
import checkTwoFactor from "../pages/Authentication/TwoFactorAuth/CheckTwoFactor";
import EnableTwoFactor from "../pages/Authentication/TwoFactorAuth/EnableTwoFactor";

// Help Guides
import help from "../component/Layout/Menus/help-button";
import HelpGuide from "../pages/HelpGuide/HelpGuide";

// Logs
import Logs from "../pages/Logging/Logs";

// Other Pages
import Pages404 from "../pages/Extra Pages/pages-404";
import Pages500 from "../pages/Extra Pages/pages-500";

const resellerProtectedRoutes = [
  // Dashboard
  { path: "/dashboard", component: Dashboard },

  // Other Pages
  { path: "/logout", component: Logout },

  // User Roles
  { path: "/user-roles", component: UserRoles },
  { path: "/create-role", component: CreateRole },

  // Companies
  { path: "/company", component: Company },

  // Activation Key
  { path: "/activation", component: ActivationKey },
  { path: "/activation-key-report/:id", component: ActivationKeyReport },

  // Admins
  { path: "/admin", component: Admin },

  // User
  { path: "/user", component: User },

  // Devices
  { path: "/device/:customerId?", component: Devices },
  { path: "/device-create", component: deviceCreate },
  { path: "/devices-updates", component: DeviceUpdate },

  // Manage Devices
  { path: "/activated-device", component: ActivateDevice },

  // Profiles
  { path: "/profiles", component: Profiles },

  // Device Groups
  { path: "/group", component: Group },

  // Upload Apks
  { path: "/show-upload-apk", component: ShowUploadApk },

  // Upload File
  { path: "/show-upload-file", component: ShowUploadFile },

  // Documents
  { path: "/show-upload-document", component: ShowUploadDocument },

  // Firmware Images
  { path: "/firmware-product", component: FirmwareProduct },

  { path: "/hardware-profile/:id/:hId?", component: FirmHardwareProfile },

  // Two Factor Authentication
  { path: "/enable-two-factor", component: EnableTwoFactor },

  // Gelp Guides
  { path: "/help", component: help },

  // Logs
  { path: "/Logs", component: Logs },

  // Other Pages
  { path: "/settings", component: Settings },
  { path: "/profile", component: Profile },
  { path: "/pages-404", component: Pages404 },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const userProtectedRoutes = [
  // DashBoard
  { path: "/dashboard", component: Dashboard },

  // Other Pages
  { path: "/logout", component: Logout },

  // User Roles
  { path: "/user-roles", component: UserRoles },
  { path: "/create-role", component: CreateRole },

  // Customer User
  { path: "/user", component: CustomerUser },
  { path: "/create-user/:id", component: CreateCustomerUser },

  { path: "/settings", component: Settings },
  { path: "/profile", component: Profile },
  { path: "/pages-404", component: Pages404 },

  // Devices Routes
  { path: "/device", component: Devices },

  { path: "/device-create", component: deviceCreate },
  { path: "/devices-updates", component: DeviceUpdate },

  // Manage Devices Routes
  { path: "/remote-device", component: ActivateDevice },

  // Upload apks Videos documetns
  { path: "/show-upload-apk", component: ShowUploadApk },

  // Upload File
  { path: "/show-upload-file", component: ShowUploadFile },

  // Upload Documents
  { path: "/show-upload-document", component: ShowUploadDocument },

  { path: "/group", component: Group },

  { path: "/mimo-apks", component: MimoApks },

  { path: "/profiles", component: Profiles },

  // Support
  { path: "/help", component: help },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const superAdminRoutes = [
  //Dashboard
  { path: "/dashboard", component: Dashboard },

  // Reseller Accounts
  { path: "/reseller-accounts", component: ResellerAccounts },

  // User Roles
  { path: "/user-roles", component: UserRoles },
  { path: "/create-role", component: CreateRole },

  // Companies
  { path: "/company", component: Company },

  // Activation Key
  { path: "/activation", component: ActivationKey },
  { path: "/activation-key-report/:id", component: ActivationKeyReport },

  // Admins
  { path: "/admin", component: Admin },

  // User
  { path: "/user", component: User },

  // Devices
  { path: "/device/:customerId?", component: Devices },

  // Manage Devices
  { path: "/activated-device", component: ActivateDevice },

  // Profiles
  { path: "/profiles", component: Profiles },

  // Device Groups
  { path: "/group", component: Group },

  // Mimo Apks
  { path: "/mimo-apks", component: MimoApks },

  // Upload Apks
  { path: "/show-upload-apk", component: ShowUploadApk },

  // Upload File
  { path: "/show-upload-file", component: ShowUploadFile },

  // Documents
  { path: "/show-upload-document", component: ShowUploadDocument },

  // Firmware Images
  { path: "/firmware-product", component: FirmwareProduct },

  { path: "/hardware-profile/:id/:hId?", component: FirmHardwareProfile },

  // Download Audit Trail
  { path: "/audit-trail", component: DownloadAuditTrail },

  // Two Factor Authentication
  { path: "/enable-two-factor", component: EnableTwoFactor },

  // Help Guides
  { path: "/help", component: help },
  { path: "/help-guide", component: HelpGuide },

  // Logs
  { path: "/Logs", component: Logs },

  // Other Pages
  { path: "/settings", component: Settings },
  { path: "/profile", component: Profile },
  { path: "/logout", component: Logout },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/login", component: Pageslogin },
  // { path: "/register", component: Pagesregister },
  { path: "/forget-password", component: ForgetPassword },
  { path: "/pages-lock-screen", component: LockScreen },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/email-verify", component: emailVerify },
  { path: "/password-reset", component: resetPassword },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

const twoFactorRoutes = [
  { path: "/check-two-factor", component: checkTwoFactor },

  { path: "/logout", component: Logout },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/check-two-factor" />,
  },
];

export {
  publicRoutes,
  twoFactorRoutes,
  resellerProtectedRoutes,
  userProtectedRoutes,
  superAdminRoutes,
};
