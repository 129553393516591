import axios from "axios";
import { getHeader } from "../../../services/authService";

const { REACT_APP_URL } = process.env;
axios.defaults.baseURL = REACT_APP_URL;

export function getAdminData() {
  return axios.get("/api/auth/getAdmin", getHeader());
}

export function storeAdmin(data) {
  return axios.post("/api/auth/createAdmin", data, getHeader());
}

export function editAdmin(id) {
  return axios.get(`/api/auth/editAdmin/${id}`, getHeader());
}

export function updateAdmin(data) {
  return axios.post("/api/auth/updateAdmin", data, getHeader());
}

export function deleteAdmin(id) {
  return axios.get(`/api/auth/deleteAdmin/${id}`, getHeader());
}
